import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";

export default class Tab extends React.Component {
  render = () => {
    const { id, items, title, currentTab, setCurrentTab, disabled } =
      this.props;

    if (items && items.length > 0) {
      let active = items.some((item) => item.id === currentTab);
      let dropdownTitle = items[0].title;
      if (active) {
        dropdownTitle = items.filter((item) => item.id === currentTab)[0].title;
      }
      return (
        <UncontrolledDropdown nav inNavbar disabled={disabled}>
          <DropdownToggle
            nav
            caret
            color="secondary"
            className={classnames("mb-sm-3 mb-md-0", {
              active: active,
            })}
            role="tab"
          >
            {dropdownTitle}
          </DropdownToggle>
          <DropdownMenu>
            {items.map((item) => (
              <DropdownItem
                key={item.id}
                href={`#${item.id}`}
                onClick={(e) => setCurrentTab(item.id)}
              >
                {item.title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return (
      <NavItem>
        <NavLink
          disabled={disabled}
          id={id}
          aria-selected={currentTab === id}
          className={classnames("mb-sm-3 mb-md-0", {
            active: currentTab === id,
          })}
          onClick={(e) => setCurrentTab(id)}
          role="tab"
          href={`#${id}`}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  };
}

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as shippingsActions from "stores/orders/shippings/actions";
import * as shippingMethodsActions from "stores/orders/shippingMethods/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NumericInput from "components/Form/NumericInput";
import CountrySelector from "../orders/cards/CountrySelector";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class Shipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shipping: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      costSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        shipping: {
          id: undefined,
          title: "",
          line1: "",
          line2: "",
          city: "",
          countryCode: "",
          postalCode: "",
          state: "",
          phone: "",
          email: "",
        },
        loading: false,
      });
    }
    this.props.listMethods();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeShippingData = (event) => {
    const value = this.parseValue(event.target);
    let shipping = { ...this.state.shipping, [value.name]: value.value };
    this.setState({ shipping });
  };

  submitForm = (event) => {
    var shipping = { ...this.state.shipping };
    if (shipping.id) {
      this.setState({ action: "update" });
      this.props.update(shipping);
    } else {
      this.setState({ action: "create" });
      this.props.create(shipping);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.shipping.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.shipping || this.props.loading;

    const { shipping } = this.state;
    const { auth } = this.props;

    if (!shipping || shipping.id === auth.id || !shipping.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.shipping || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { shipping } = this.state;

    if (!shipping || !shipping.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={shipping ? shipping.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardAddress = () => {
    const { shipping } = this.state;

    if (!shipping) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane adresowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Adres</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-book" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres"
                      type="text"
                      name="line1"
                      value={shipping.line1}
                      onChange={this.changeShippingData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3"></Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-book" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Dodatkowa linia"
                      type="text"
                      name="line2"
                      value={shipping.line2}
                      onChange={this.changeShippingData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Miejscowość</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-map" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Miejscowość"
                      type="text"
                      name="city"
                      value={shipping.city}
                      onChange={this.changeShippingData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kod pocztowy</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kod pocztowy"
                      type="text"
                      name="postalCode"
                      value={shipping.postalCode}
                      onChange={this.changeShippingData}
                      maxLength={16}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Region</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-map-marker" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Region administracyjny"
                      type="text"
                      name="state"
                      value={shipping.state}
                      onChange={this.changeShippingData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kraj</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-flag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <CountrySelector
                      value={shipping?.countryCode}
                      name="countryCode"
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardContact = () => {
    const { shipping } = this.state;

    if (!shipping) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane kontaktowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Telefon</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Telefon"
                      type="text"
                      name="phone"
                      value={shipping.phone}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Adres e-mail</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres e-mail"
                      type="text"
                      name="email"
                      value={shipping.email}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardMap = () => {
    const { shipping } = this.state;

    if (!shipping) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane do mapy</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Szerokość</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-compass" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Szerokość geograficzna"
                      name="latitude"
                      value={shipping.latitude}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Wysokość</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-compass" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Wysokość geograficzna"
                      name="altitude"
                      value={shipping.altitude}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { shipping, methods } = this.state;

    if (!shipping) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={shipping.title}
                      onChange={this.changeShippingData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Metoda dostawy</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-truck" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Metoda dostawy"
                      type="select"
                      name="methodId"
                      value={shipping.methodId}
                      onChange={this.changeShippingData}
                    >
                      <option value="">Brak</option>
                      {methods
                        .filter((m) => !m.isShippingAddressRequired)
                        .map((method) => (
                          <option key={method.id} value={method.id}>
                            {localizer(method)?.currentTranslation?.title}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.shipping || this.props.loading;

    const { shipping } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/shipping/shippings/" />;
    }

    return (
      <>
        <SimpleHeader
          name={shipping ? shipping.id : ""}
          link="/admin/shipping/shippings"
          parentName="Dostawy"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardMap()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardAddress()}
                {this.renderCardContact()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shipping !== this.props.shipping) {
      this.setState({
        shipping: this.props.shipping,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.methods !== this.props.methods) {
      this.setState({
        methods: this.props.methods,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    shipping: state.shippings.current,
    loading: state.shippings.loading,
    auth: state.auth.user,
    session: state.session,
    methods: state.shippingMethods.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(shippingsActions.read(id)),
    remove: (id) => dispatch(shippingsActions.remove(id)),
    update: (shipping) => dispatch(shippingsActions.update(shipping)),
    create: (shipping) => dispatch(shippingsActions.create(shipping)),
    listMethods: () => dispatch(shippingMethodsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Shipping),
);

export const READ = "pbs/COLLECT_POINTS/READ/PENDING";
export const READ_SUCCESS = "pbs/COLLECT_POINTS/READ/SUCCESS";
export const CREATE = "pbs/COLLECT_POINTS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/COLLECT_POINTS/CREATE/SUCCESS";
export const UPDATE = "pbs/COLLECT_POINTS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/COLLECT_POINTS/UPDATE/SUCCESS";
export const REMOVE = "pbs/COLLECT_POINTS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/COLLECT_POINTS/REMOVE/SUCCESS";
export const LIST = "pbs/COLLECT_POINTS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/COLLECT_POINTS/LIST/SUCCESS";
export const LIST_BY_SHIPPING = "pbs/COLLECT_POINTS/LIST_BY_SHIPPING/PENDING";
export const LIST_BY_SHIPPING_SUCCESS =
  "pbs/COLLECT_POINTS/LIST_BY_SHIPPING/SUCCESS";

export const ACTIVE = "pbs/COLLECT_POINTS/ACTIVE";

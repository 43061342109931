import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Row,
  UncontrolledCollapse,
} from "reactstrap";

export default class ProductAdditives extends React.Component {
  changeCulture = this.props.changeCulture;
  changeProductData = this.props.changeProductData;
  changeProductTranslation = this.props.changeProductTranslation;

  changeProductAdditive = this.props.changeProductAdditive;
  orderAdditiveGroup = this.props.orderAdditiveGroup;
  orderAdditive = this.props.orderAdditive;

  renderAdditiveGroup = (group) => {
    const { additives, product } = this.props;
    const groupAdditives = additives.filter((o) => o.groupId === group.id);
    var additivesCount = { available: 0, selected: 0 };
    const additivesRender = groupAdditives
      .map((additive) => {
        return {
          additive: additive,
          productAdditive: product.productAdditives.find(
            (po) => po.additiveId === additive.id,
          ),
        };
      })
      .sort((a, b) => {
        const aOrder = a.productAdditive ? a.productAdditive.order : 0;
        const bOrder = b.productAdditive ? b.productAdditive.order : 0;
        return bOrder - aOrder;
      })
      .map((additive) => additive.additive)
      .map((additive) => this.renderAdditive(additive.id, additivesCount));

    return (
      <Row key={group.id}>
        <Col md="12" className="mb-3">
          <div className="clearfix">
            <div
              id={`additiveGroup${group.id}head`}
              style={{ cursor: "pointer" }}
              className="float-left"
            >
              <b>{group.currentTranslation?.title}</b>
            </div>
            {additivesCount.selected ? (
              <div className="float-right ml-3">
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.orderAdditiveGroup(group.id, 1)}
                ></i>
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.orderAdditiveGroup(group.id, -1)}
                ></i>
              </div>
            ) : (
              <React.Fragment />
            )}
            <div className="float-right">
              {additivesCount.selected}/{additivesCount.available}
            </div>
          </div>
        </Col>
        {additivesRender}
      </Row>
    );
  };

  renderAdditive = (additiveId, additivesCount) => {
    const { additives, product } = this.props;
    const additive = additives.find((a) => a.id === additiveId);
    const productAdditive = product.productAdditives.find(
      (pa) => pa.additiveId === additiveId,
    );

    if (productAdditive) {
      additivesCount.selected++;
    }
    additivesCount.available++;

    return (
      <Col md="12" key={additive.id}>
        <UncontrolledCollapse toggler={`#additiveGroup${additive.groupId}head`}>
          <FormGroup>
            <div className="form-check mb-3 clearfix">
              <div className="float-left">
                <input
                  className="form-check-input"
                  id={"additive" + additive.id}
                  type="checkbox"
                  name="additives[]"
                  value={additive.id}
                  checked={productAdditive}
                  onChange={(event) => this.changeProductAdditive(event)}
                />
                <label
                  className="form-check-label"
                  htmlFor={"additive" + additive.id}
                >
                  {additive.currentTranslation?.title || ""}
                </label>
              </div>
              {productAdditive ? (
                <div className="float-right">
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.orderAdditive(additiveId, 1)}
                  ></i>
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.orderAdditive(additiveId, -1)}
                  ></i>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>
          </FormGroup>
        </UncontrolledCollapse>
      </Col>
    );
  };

  render() {
    const { product, loading, additives, additiveGroups } = this.props;

    if (!product) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dodatki</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {[
              ...additiveGroups,
              { id: null, currentTranslation: { title: "Brak grupy" } },
            ]
              .map((group) => {
                const groupAdditives = additives
                  .filter((o) => o.groupId === group.id)
                  .map((o) => o.id);
                const groupProductAdditivesOrders = product.productAdditives
                  .filter((pa) => groupAdditives.includes(pa.additiveId))
                  .map((pa) => pa.order || 0) || [0];
                const obj = {
                  group: group,
                  order: Math.max(...groupProductAdditivesOrders),
                };
                return obj;
              })
              .sort((a, b) => b.order - a.order)
              .map((group) => group.group)
              .map((group) => this.renderAdditiveGroup(group))}
          </Form>
        </CardBody>
      </Card>
    );
  }
}

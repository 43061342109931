import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Row,
  UncontrolledCollapse,
} from "reactstrap";

export default class ProductOptions extends React.Component {
  changeCulture = this.props.changeCulture;
  changeProductData = this.props.changeProductData;
  changeProductTranslation = this.props.changeProductTranslation;

  changeProductOption = this.props.changeProductOption;
  orderAttribute = this.props.orderAttribute;
  orderOption = this.props.orderOption;

  renderAttribute = (attributeId) => {
    const { attributes, options, product } = this.props;
    const attribute = attributes.find((a) => a.id === attributeId);
    const attributeOptions = options.filter(
      (o) => o.attributeId === attributeId,
    );
    var optionsCount = { available: 0, selected: 0 };
    const optionsRender = attributeOptions
      .map((option) => {
        return {
          option: option,
          productOption: product.productOptions.find(
            (po) => po.optionId === option.id,
          ),
        };
      })
      .sort((a, b) => {
        const aOrder = a.productOption ? a.productOption.order : 0;
        const bOrder = b.productOption ? b.productOption.order : 0;
        return bOrder - aOrder;
      })
      .map((option) => option.option)
      .map((option) => this.renderOption(option.id, optionsCount));

    return (
      <Row key={attribute.id}>
        <Col md="12" className="mb-3">
          <div className="clearfix">
            <div
              id={`attribute${attribute.id}head`}
              style={{ cursor: "pointer" }}
              className="float-left"
            >
              <b>{attribute.currentTranslation?.title}</b>
            </div>
            {optionsCount.selected ? (
              <div className="float-right ml-3">
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.orderAttribute(attributeId, 1)}
                ></i>
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.orderAttribute(attributeId, -1)}
                ></i>
              </div>
            ) : (
              <React.Fragment />
            )}
            <div className="float-right">
              {optionsCount.selected}/{optionsCount.available}
            </div>
          </div>
        </Col>
        {optionsRender}
      </Row>
    );
  };

  renderOption = (optionId, optionsCount) => {
    const { options, product } = this.props;
    const option = options.find((o) => o.id === optionId);
    const productOption = product.productOptions.find(
      (po) => po.optionId === optionId,
    );

    if (productOption) {
      optionsCount.selected++;
    }
    optionsCount.available++;

    return (
      <Col md="12" key={option.id}>
        <UncontrolledCollapse toggler={`#attribute${option.attributeId}head`}>
          <FormGroup>
            <div className="form-check mb-3 clearfix">
              <div className="float-left">
                <input
                  className="form-check-input"
                  id={"option" + option.id}
                  type="checkbox"
                  name="options[]"
                  value={option.id}
                  checked={productOption}
                  onChange={(event) => this.changeProductOption(event)}
                />
                <label
                  className="form-check-label"
                  htmlFor={"option" + option.id}
                >
                  {option.currentTranslation?.title || ""}
                </label>
              </div>
              {productOption ? (
                <div className="float-right">
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.orderOption(optionId, 1)}
                  ></i>
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.orderOption(optionId, -1)}
                  ></i>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>
          </FormGroup>
        </UncontrolledCollapse>
      </Col>
    );
  };

  render() {
    const { product, loading, options, attributes } = this.props;

    if (!product) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Opcje</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {attributes
              .map((attribute) => {
                const attributeOptions = options
                  .filter((o) => o.attributeId === attribute.id)
                  .map((o) => o.id);
                const attributeProductOptionsOrders = product.productOptions
                  .filter((po) => attributeOptions.includes(po.optionId))
                  .map((po) => po.order || 0) || [0];
                const obj = {
                  attribute: attribute,
                  order: Math.max(...attributeProductOptionsOrders),
                };
                return obj;
              })
              .sort((a, b) => b.order - a.order)
              .map((attribute) => attribute.attribute)
              .map((attribute) => this.renderAttribute(attribute.id))}
          </Form>
        </CardBody>
      </Card>
    );
  }
}

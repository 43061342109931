import React from "react";

import { withRouter, Redirect } from "react-router-dom";

import ReactToPrint from "react-to-print";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import CustomersTable from "./CustomersTable";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      orders: [],
      meta: {},
      filter: [],
      redirect: null,
    };
    this.myRef = React.createRef();
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/customers/create" });
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { redirect, alert } = this.state;
    const { loading } = this.props;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        {alert}
        <SimpleHeader
          name="Klienci"
          link="/admin/customers"
          parentName="Klienci"
        >
          <div className="text-right">
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    className="buttons-copy buttons-html5"
                  >
                    <i className="fas fa-print mr-3"></i>
                    Drukuj
                  </Button>
                )}
                content={() => this.myRef.current}
              />
              <Button
                className="buttons-copy buttons-html5"
                color="success"
                onClick={this.onClickCreate}
              >
                <i className="fas fa-plus-circle mr-3"></i>
                <span>Dodaj klienta</span>
              </Button>
            </ButtonGroup>
          </div>
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                {loading ? this.loadingOverlay() : ""}
                <CardHeader>
                  <h3 className="mb-0">Klienci</h3>
                </CardHeader>
                <CardBody>
                  <CustomersTable type="assigned" />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

export default withRouter(Customers);

import * as constants from "./constants";
import IOrderProduct from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (product: IOrderProduct) => ({
  type: constants.READ_SUCCESS,
  payload: product,
});

export const create = (product: IOrderProduct) => ({
  type: constants.CREATE,
  payload: product,
});
export const createSuccess = (product: IOrderProduct) => ({
  type: constants.CREATE_SUCCESS,
  payload: product,
});

export const update = (product: IOrderProduct) => ({
  type: constants.UPDATE,
  payload: product,
});
export const updateSuccess = (product: IOrderProduct) => ({
  type: constants.UPDATE_SUCCESS,
  payload: product,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (skip: Number = 0, take: Number = 10) => {
  return {
    type: constants.LIST,
    payload: { skip, take },
  };
};
export const listSuccess = (list: IOrderProduct[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const listByOrder = (
  id: string,
  skip: Number = 0,
  take: Number = 10,
) => {
  return {
    type: constants.LIST_BY_ORDER,
    payload: { id, skip, take },
  };
};
export const listByOrderSuccess = (list: IOrderProduct[], listMeta: any) => ({
  type: constants.LIST_BY_ORDER_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (product: IOrderProduct) => ({
  type: constants.ACTIVE,
  payload: product,
});

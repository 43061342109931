import * as constants from "./constants";
import IOrder from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (order: IOrder) => ({
  type: constants.READ_SUCCESS,
  payload: order,
});

export const create = (order: IOrder) => ({
  type: constants.CREATE,
  payload: order,
});
export const createSuccess = (order: IOrder) => ({
  type: constants.CREATE_SUCCESS,
  payload: order,
});

export const update = (order: IOrder) => ({
  type: constants.UPDATE,
  payload: order,
});
export const updateSuccess = (order: IOrder) => ({
  type: constants.UPDATE_SUCCESS,
  payload: order,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (
  skip: Number = 0,
  take: Number = 10,
  searchText: string = "",
  filters: any[] = [],
  order: any = undefined,
  statuses: string = ""
) => {
  return {
    type: constants.LIST,
    payload: { skip, take, filters, order, searchText, statuses },
  };
};
export const listSuccess = (list: IOrder[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (order: IOrder) => ({
  type: constants.ACTIVE,
  payload: order,
});

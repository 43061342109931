import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as invoiceSeriesActions from "stores/invoices/invoiceSeries/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { InvoiceTypes } from "../invoices/Invoice";

import { API_URL as SELLERS_API_URL } from "stores/invoices/sellersData/epics";
import apiDriver from "stores/api.driver";
import AsyncSelector from "views/pages/orders/orders/cards/AsyncSelector";
import NumericInput from "components/Form/NumericInput";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

export const InvoiceSeriesPeriods = [
  { id: 0, label: "dzień" },
  { id: 1, label: "tydzień" },
  { id: 2, label: "miesiąc" },
  { id: 3, label: "rok" },
];

class SellerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      costSelectedCountry: "",
      availabilitySelectedCountry: "",
      sellersData: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        data: {
          id: undefined,
          defaultForType: 1,
          period: 2,
          format: "yyyy/XXX",
          currentNumber: 1,
          translations: [],
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "select-one":
        const val = parsedValue;
        parsedValue = isNaN(val) ? val : Number(val);
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeData = (event) => {
    const value = this.parseValue(event.target);
    let data = { ...this.state.data, [value.name]: value.value };
    this.setState({ data });
  };

  submitForm = (event) => {
    var { sellerData, ...data } = this.state.data;
    if (data.id) {
      this.setState({ action: "update" });
      this.props.update(data);
    } else {
      this.setState({ action: "create" });
      this.props.create(data);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  sellerDataLabel = (data) => {
    return (
      <div>
        <div>
          <span className="font-weight-bold">{data?.name}</span>{" "}
          {data?.vatID ? <span>(VAT: {data?.vatID})</span> : <React.Fragment />}
        </div>
        <div className="text-muted">
          <small>
            <div>
              <div className="d-inline-block">
                <i className="fas fa-map mr-3"></i>
              </div>
              <div className="d-inline-block">
                <div>{data.line1}</div>
                {data.line2 ? <div>{data.line2}</div> : <React.Fragment />}
                <div>
                  {data.postalCode} {data.city}
                </div>
              </div>
            </div>
            {data.bankName || data.bankAccountNumber ? (
              <div>
                <i className="fas fa-credit-card mr-3"></i> {data.bankName}{" "}
                {data.bankAccountNumber}
              </div>
            ) : (
              <React.Fragment />
            )}
          </small>
        </div>
      </div>
    );
  };
  renderSellerData = (data) => ({
    value: data.id,
    label: this.sellerDataLabel(data),
  });
  loadSellersData = (inputValue) => {
    return apiDriver
      .get(SELLERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => {
        this.setState({ sellersData: result.response });
        return result.response;
      })
      .then((sellerData) => sellerData.map(this.renderSellerData));
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.data };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ data: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.data.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.data || this.props.loading;

    const { data } = this.state;
    const { auth } = this.props;

    if (!data || data.id === auth.id || !data.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.data || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { data } = this.state;

    if (!data || !data.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={data ? data.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { data, sellersData } = this.state;

    if (!data) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Domyślny dla rodzaju</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-box" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Rodzaj"
                      type="select"
                      name="defaultForType"
                      value={data.defaultForType}
                      onChange={this.changeData}
                    >
                      {InvoiceTypes.map((t) => (
                        <option key={t.id} value={t.id}>
                          {t.label}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Okres serii</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-calendar" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Okres serii"
                      type="select"
                      name="period"
                      value={data.period}
                      onChange={this.changeData}
                    >
                      {InvoiceSeriesPeriods.map((t) => (
                        <option key={t.id} value={t.id}>
                          {t.label}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Format</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-file-invoice" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Format"
                      type="text"
                      name="format"
                      value={data.format}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Numeracja</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-file-invoice" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Numeracja"
                      name="currentNumber"
                      value={data.currentNumber}
                      onChange={this.changeData}
                      step={1}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Sprzedawca</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Sprzedawca"
                    name="sellerDataId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(sellersData.length
                      ? sellersData
                      : data.sellerData
                      ? [data.sellerData]
                      : []
                    ).map(this.renderSellerData)}
                    value={data.sellerDataId}
                    onChange={this.changeData}
                    onSearch={this.loadSellersData}
                    allowCreate={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.data || this.props.loading;

    const { data } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/accountancy/invoiceSeries/" />;
    }

    return (
      <>
        <SimpleHeader
          name={data ? data.currentTranslation?.title : ""}
          link="/admin/accountancy/invoiceSeries"
          parentName="Dane sprzedawcy"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    data: state.invoiceSeries.current,
    loading: state.invoiceSeries.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(invoiceSeriesActions.read(id)),
    remove: (id) => dispatch(invoiceSeriesActions.remove(id)),
    update: (data) => dispatch(invoiceSeriesActions.update(data)),
    create: (data) => dispatch(invoiceSeriesActions.create(data)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SellerData),
);

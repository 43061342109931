export const READ = "pbs/INVOICES/READ/PENDING";
export const READ_SUCCESS = "pbs/INVOICES/READ/SUCCESS";
export const CREATE = "pbs/INVOICES/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/INVOICES/CREATE/SUCCESS";
export const UPDATE = "pbs/INVOICES/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/INVOICES/UPDATE/SUCCESS";
export const REMOVE = "pbs/INVOICES/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/INVOICES/REMOVE/SUCCESS";
export const LIST = "pbs/INVOICES/LIST/PENDING";
export const LIST_SUCCESS = "pbs/INVOICES/LIST/SUCCESS";

export const ACTIVE = "pbs/INVOICES/ACTIVE";
export const REFRESH = "pbs/INVOICES/REFRESH";

import React from "react";

import { withRouter } from "react-router-dom";

import Dropzone from "dropzone";
import { Card, CardHeader, CardBody, Col, Row } from "reactstrap";

import OrdersTable from "../../orders/OrdersTable";

Dropzone.autoDiscover = false;

class CustomerOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rebates: undefined,
      products: undefined,
      productCategories: undefined,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {};

  renderOrdersCard = () => {
    const { customer } = this.props;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Zamówienia klienta</h3>
        </CardHeader>
        <CardBody>
          <OrdersTable
            filters={{
              userId: {
                filterVal: customer.id,
                filterType: "TEXT",
                comparator: "=",
                caseSensitive: false,
                filterKey: "userId",
              },
            }}
            hideUser={true}
          />
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">{this.renderOrdersCard()}</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) {}
}

export default withRouter(CustomerOrders);

export const READ = "pbs/BILLING_INFOS/READ/PENDING";
export const READ_SUCCESS = "pbs/BILLING_INFOS/READ/SUCCESS";
export const CREATE = "pbs/BILLING_INFOS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/BILLING_INFOS/CREATE/SUCCESS";
export const UPDATE = "pbs/BILLING_INFOS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/BILLING_INFOS/UPDATE/SUCCESS";
export const REMOVE = "pbs/BILLING_INFOS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/BILLING_INFOS/REMOVE/SUCCESS";
export const LIST = "pbs/BILLING_INFOS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/BILLING_INFOS/LIST/SUCCESS";
export const LIST_BY_SHIPPING = "pbs/BILLING_INFOS/LIST_BY_SHIPPING/PENDING";
export const LIST_BY_SHIPPING_SUCCESS =
  "pbs/BILLING_INFOS/LIST_BY_SHIPPING/SUCCESS";

export const ACTIVE = "pbs/BILLING_INFOS/ACTIVE";

import IProductAdditiveGroup from "stores/products/productAdditiveGroups/model";
import IProductAdditive from "stores/products/productAdditives/model";
import IProductAttribute from "stores/products/productAttributes/model";
import IProductCategory from "stores/products/productCategories/model";
import IProductOption from "stores/products/productOptions/model";

export enum EColor {
  OneOverZero,
  OneOverOne,
  FourOverZero,
  FourOverOne,
  FourOverFour,
  TwoOverZero,
  TwoOverOne,
  FiveOverZero,
  FiveOverOne,
  FiveOverFour,
  FiveOverFive,
}

export enum ERealizationTimeType {
  Standard,
  Express,
  Economic,
}

export interface IProductTranslation {
  culture: string;
  title: string;
}

export interface IPriceCalculation {
  productId: string;
  options: string;
  amount: Number;
  area: Number;
  result: Number;
  time: Number;
}

export interface IWeightCalculation {
  productId: string;
  options: string;
  amount: Number;
  area: Number;
  result: Number;
}

export interface IProductOptionRelation {
  productId: string;
  optionId: Number;
}

export interface IProductAdditiveRelation {
  productId: string;
  additiveId: string;
}

export interface IProductConfiguration {
  baseRealizationTime: Number;
  amount: IProductAmountConfiguration;
  format: IProductFormatConfiguration;
  pages: IProductPagesConfiguration;
  pricing: IProductPricingConfiguration;
  weight: IProductWeightConfiguration;
  project: IProductProjectConfiguration;
}

type Nullable<T> = T | null;
export interface ISize {
  x: Number;
  y: Number;
}
export interface INullableSize {
  x: Nullable<Number>;
  y: Nullable<Number>;
}

export enum EProductPagesType {
  Const = "Const",
  Defined = "Defined",
  Range = "Range",
}

export enum EProductPricingType {
  Const = "Const",
  Standard = "Standard",
  Table = "Table",
  TableMetric = "TableMetric",
  Resource = "Resource",
}

export enum EProductWeightType {
  Const = "Const",
  Standard = "Standard",
  Table = "Table",
}

export interface ISheet {
  size: INullableSize;
  margin: INullableSize;
  overprint: INullableSize[];
  price: Number;
  priceType: Number;
}

export interface IPrintKey {
  width: Number;
  color: EColor;
}

export enum EInputType {
  Select = "Select",
  Checkbox = "Checkbox",
  Dropdown = "Dropdown",
  Buttons = "Buttons",
}

export interface IPrint {
  key: IPrintKey;
  value: Number;
}

export interface IDependsOnPages<Type> {
  inside: Type;
  outside: Type;
}
export interface IProductAmountConfiguration {
  canUseCustom: Boolean;
  defined: [];
  minimal: Number;
  maximal: Nullable<Number>;
}

export interface IProductFormatConfiguration {
  inputType: EInputType;
  unit: Number;
  canUseCustom: Boolean;
  defined: ISize[];
  minimal: INullableSize;
  maximal: INullableSize;
  default: INullableSize;
}

export interface IProductPagesConfiguration {
  type: EProductPagesType;
  minimal: IDependsOnPages<Number>;
  maximal: IDependsOnPages<Nullable<Number>>;
  default: IDependsOnPages<Number>;
  defined: IDependsOnPages<Number>[];
  maximalThickness: IDependsOnPages<Nullable<Number>>;
  canDetermineColor: Boolean;
}

export interface IProductPricingConfiguration {
  type: EProductPricingType;
  base: Number;
  sheet: ISheet[];
  metrics: Number[];
  minimal: Nullable<Number>;
  modifier: Number;
  prints: IPrint[];
  resourcePrice?: Number;
  resourcePriceType?: Number;
}

export interface IProductWeightConfiguration {
  type: EProductWeightType;
  base: Nullable<Number>;
}

export interface IProductProjectConfiguration {
  realizationTime: Number;
  basePrice: Number;
  pricePerPage: IDependsOnPages<Number>;
}

export default interface IProduct {
  id: string;
  isHidden: Boolean;
  isAvailable: Boolean;
  isGuestAccessible: Boolean;
  taxRate: Number;
  order: Number;
  categoryId: string;
  category: IProductCategory;

  translations: IProductTranslation[];
  currentTranslation: IProductTranslation;
  priceCalculations: IPriceCalculation[];
  weightCalculations: IWeightCalculation[];
  productOptions: IProductOptionRelation[];
  attributes: IProductAttribute[];
  options: IProductOption[];
  productAdditives: IProductAdditiveRelation[];
  additives: IProductAdditive[];
  additiveGroups: IProductAdditiveGroup[];
  productConfiguration: IProductConfiguration[];
  productTables: object;
}

import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText,
  Form,
  Row,
  Button,
  Alert,
} from "reactstrap";
import AsyncSelector from "./../../../orders/orders/cards/AsyncSelector";

import apiDriver from "stores/api.driver";
import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import { API_URL as BUSINESSES_API_URL } from "stores/orders/businesses/epics";
import CultureSelector from "views/pages/products/products/Cards/CultureSelector";
import generatePassword from "components/Form/PasswordGenerator";

Dropzone.autoDiscover = false;

class CustomerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      paymentModalOpen: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    //const { customer } = this.props;
  };

  loadingOverlay = () => this.props.loadingOverlay();
  parseValue = (e) => this.props.parseValue(e);

  changeCustomerData = (e) => {
    const value = this.parseValue(e.target);

    if (value.value === "_") {
      switch (value.name) {
        case "shippingAddressId":
          this.props.changeCustomerDataEx("shippingAddress", {});
          break;
        case "billingInfoId":
          this.props.changeCustomerDataEx("billingInfo", {});
          break;
        case "senderAddressId":
          this.props.changeCustomerDataEx("senderAddress", {});
          break;
        default:
          break;
      }
    }

    this.props.changeCustomerDataEx(value.name, value.value);
  };

  renderBusiness = (business) => ({
    value: business.id,
    label: business.title,
  });
  loadBusinesses = (inputValue) => {
    return apiDriver
      .get(BUSINESSES_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((businesses) =>
        businesses.map((business) => ({
          value: business.id,
          label: business.title,
        })),
      );
  };

  renderUser = (user) => ({ value: user.id, label: user.username });
  loadUsers = (inputValue) => {
    return apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((users) =>
        users.map((user) => ({
          value: user.id,
          label: user.username,
        })),
      );
  };

  generatePassword = () => {
    const generatedPassword = generatePassword({
      useSymbols: true,
      useLowerCase: true,
      useUpperCase: true,
      useNumbers: true,
      passwordLength: 12
    });
    this.props.changeCustomerDataArray({
      newPassword: generatedPassword,
      confirmNewPassword: generatedPassword,
      generatedPassword: generatedPassword
    });
  }

  renderId = () => {
    const { customer } = this.state;

    if (!customer || !customer.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={customer ? customer.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardPassword = () => {
    const { customer } = this.props;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <div className="clearfix">
            <div className="float-left">
              <h3 className="mb-0 float-left">Ustalenie hasla</h3>
            </div>
            <div className="float-right">
              <Button color="primary" size="sm" onClick={this.generatePassword}>Generuj hasło</Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Hasło"
                      type="password"
                      name="newPassword"
                      value={customer ? customer.newPassword : ""}
                      onChange={this.changeCustomerData}
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Powtórz hasło"
                      type="password"
                      name="confirmNewPassword"
                      value={customer ? customer.confirmNewPassword : ""}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {customer.generatedPassword &&
                <Col md="12">
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-key" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Wygenerowane hasło"
                        type="text"
                        value={customer?.generatedPassword}
                        readOnly
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              }
              {customer.generatedPassword &&
                <Col md="12">
                  <Alert color="info">
                    <p>
                      <b>Bezpieczeństwo generatora</b>
                    </p>
                    <p>
                      Hasła są szyfrowane za pomocą Twojej przeglądarki, nie są wysyłane przez Internet (jedynie przy zapisie za pomocą szyforwanego protokułu HTTPS).
                      Generowanie haseł tą metodą jest bezpieczne.
                    </p>
                  </Alert>
                </Col>
              }
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { customer } = this.props;
    const { users, businesses } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Język</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-language" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <CultureSelector
                      currentCulture={customer.culture}
                      changeCulture={this.changeCustomerData}
                      name="culture"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Login</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Login"
                      type="text"
                      name="username"
                      value={customer.username}
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Adres e-mail</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Adres e-mail"
                      type="text"
                      name="email"
                      value={customer.email}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Imie</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Imie"
                      type="text"
                      name="givenName"
                      value={customer.givenName}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Nazwisko</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Nazwisko"
                      type="text"
                      name="surname"
                      value={customer.surname}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opiekun</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Opiekun"
                    name="assigneeId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(
                      users || (customer.assignee ? [customer.assignee] : [])
                    ).map(this.renderUser)}
                    value={customer.assigneeId}
                    onChange={this.changeCustomerData}
                    onSearch={this.loadUsers}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Przedsiębiorstwo</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Przedsiębiorstwo"
                    name="businessId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(
                      businesses ||
                      (customer.business ? [customer.business] : [])
                    ).map(this.renderBusiness)}
                    value={customer.businessId}
                    onChange={this.changeCustomerData}
                    onSearch={this.loadBusinesses}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Podwyzszone uprawnienia</Col>
              <Col md="9">
                <FormGroup>
                  <label className="custom-toggle">
                    <Input
                      type="checkbox"
                      name="hasSpecialPermissions"
                      onChange={this.changeCustomerData}
                      value={true}
                      checked={customer.hasSpecialPermissions}
                      defaultChecked={customer.hasSpecialPermissions}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">{this.renderCardData()}</div>
          </Col>
          <Col lg="6">
            <div className="card-wrapper">{this.renderCardPassword()}</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) { }
}

function mapStateToProps(state) {
  return {
    loading: state.customers.loading,
    auth: state.auth.user,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerData),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productAdditivesActions from "stores/products/productAdditives/actions";
import * as productAdditiveGroupsActions from "stores/products/productAdditiveGroups/actions";

import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";

import Search from "components/Table/Search";

class ProductAdditives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      productAdditives: [],
      productAdditiveGroups: [],
      meta: {},
      filter: [],
      redirect: null,
    };
    this.myRef = React.createRef();
    if (!this.state.productAdditives.list) {
      this.props.list();
    }
    this.props.listGroups();
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => <div className="dataTables_length" id="datatable-basic_length"></div>,
  };

  onTableChange = (type, newState) => {
    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      newState.filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: newState.filters.length
          ? [...newState.filters].map((f) => f.comparator + "," + f.filterVal)
          : [],
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
    });
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/products/additives/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/products/additives/" + row.id });
  };

  onClickGroup = (row) => {
    this.setState({
      redirect: "/admin/products/additives/groups/" + row.group.id,
    });
  };

  onClickGroups = () => {
    this.setState({ redirect: "/admin/products/additives/groups" });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { data, meta, groups } = this.props;

    const metaState = this.state.meta;

    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    const dictionary = Object.assign(
      {},
      ...groups.map((x) => ({ [x.id]: x.currentTranslation?.title })),
    );

    if (!groups) {
      return (
        <div className="text-center">
          <i className="fas fa-spin fa-spinner fa-4x"></i>
        </div>
      );
    }

    let columns = [
      {
        dataField: "currentTranslation.title",
        text: "Tytuł",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "groupId",
        text: "Grupa",
        sort: false,
        filter: selectFilter({
          options: dictionary,
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
        formatter: (cell, row) =>
          cell && row.group ? (
            <a
              href={"#" + row.group.id}
              className="btn btn-link m-0 p-0"
              key={row.group.id}
              onClick={() => {
                this.onClickGroup(row);
              }}
            >
              {row.group.currentTranslation?.title}
            </a>
          ) : (
            <span className="text-muted">Brak</span>
          ),
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];

    return (
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={columns}
        search
        bootstrap4={true}
      >
        {(props) => {
          return (
            <div className="py-4 table-responsive">
              <Container fluid>
                <Row>
                  <Col xs={12} sm={6}></Col>
                  <Col xs={12} sm={6}>
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1 float-right"
                    >
                      <label>
                        
                        <Search
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                  </Col>
                </Row>
              </Container>
              <PaginationProvider pagination={paginationFactory(pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <BootstrapTable
                    remote
                    ref={this.myRef}
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    id="react-bs-table"
                    wrapperClasses="table-responsive"
                    onTableChange={this.onTableChange}
                    filter={filterFactory()}
                    {...paginationTableProps}
                  />
                )}
              </PaginationProvider>
            </div>
          );
        }}
      </ToolkitProvider>
    );
  };

  render = () => {
    const { redirect, alert } = this.state;

    const { loading } = this.props;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        {alert}
        <SimpleHeader
          name="Lista dodatków produktów"
          link="/admin/products"
          parentName="Produkty"
        >
          <div className="text-right">
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    className="buttons-copy buttons-html5"
                  >
                    <i className="fas fa-print mr-3"></i>
                    Drukuj
                  </Button>
                )}
                content={() => this.myRef.current}
              />
              <Button
                className="buttons-copy buttons-html5"
                color="primary"
                onClick={this.onClickGroups}
              >
                <i className="fas fa-stream"></i>
                <span>Grupy dodatków</span>
              </Button>
              <Button
                className="buttons-copy buttons-html5"
                color="success"
                onClick={this.onClickCreate}
              >
                <i className="fas fa-plus-circle mr-3"></i>
                <span>Dodaj dodatek</span>
              </Button>
            </ButtonGroup>
          </div>
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                {loading ? this.loadingOverlay() : ""}
                <CardHeader>
                  <h3 className="mb-0">Dodatki produktów</h3>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.productAdditives.list,
    groups: state.productAdditiveGroups.list,
    loading: state.productAdditives.loading,
    auth: state.auth.user,
    meta: state.productAdditives.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) =>
      dispatch(
        productAdditivesActions.list(skip, take, searchText, filters, order),
      ),
    read: (id) => dispatch(productAdditivesActions.read(id)),
    remove: (id) => dispatch(productAdditivesActions.remove(id)),
    listGroups: (
      skip = 0,
      take = 1000,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) =>
      dispatch(
        productAdditiveGroupsActions.list(
          skip,
          take,
          searchText,
          filters,
          order,
        ),
      ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAdditives),
);

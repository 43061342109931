import config from "config/global";
import translate from "deepl";

const translator = (from, to, object, keys) => {
  return new Promise((onSuccess, onFail) => {
    const ignoredKeys = ["entityId", "culture"];
    const sourceTranslation =
      object.translations.find((t) => t.culture === from) || {};
    const targetTranslation = object.translations.find((t) => t.culture === to);

    if (!keys) {
      keys = Object.keys(sourceTranslation)
        .filter((k) => isNaN(Number(k)))
        .filter((k) => !ignoredKeys.includes(k));
    }

    if (!sourceTranslation) {
      console.warn(`no source translation`);
      return;
    }
    const promises = [];
    keys.forEach((key) => {
      const value = sourceTranslation[key];
      if (!value || value === " ") {
        console.warn(`no value to translate for key ${key}`);
        return;
      }

      const promise = new Promise((resolve, reject) => {
        const data = {
          text: sourceTranslation[key],
          source_lang: from,
          target_lang: to,
          preserve_formatting: true,
          auth_key: config.deeplKey,

        };
        if (data.text.includes("<") && data.text.includes(">")) {
          data.tag_handling = "html";
        }
        translate(data)
          .then((result) => {
            resolve({ [key]: result.data.translations[0].text });
          })
          .catch((error) => {
            reject(error);
          });
      });

      promises.push(promise);
    });

    Promise.all(promises).then((values) => {
      let result = {
        ...targetTranslation,
        culture: to,
        entityId: sourceTranslation.entityId,
      };
      for (var value of values) {
        result = { ...result, ...value };
      }
      onSuccess(result);
    });
  });
};

export default translator;

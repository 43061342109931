
import React from 'react';
import ReactToPrint from "react-to-print";
import { Button } from 'reactstrap';

const TablePrint = ({ toPrint, title }) => {
  return (
    <ReactToPrint
      trigger={() => (
        <Button
          color="default"
          className="buttons-copy buttons-html5"
        >
          <i className="fas fa-print"></i>
        </Button>
      )}
      content={() => toPrint.current}
      documentTitle={title || 'Printweb.pl'}
    />
  )
}

export default TablePrint;
import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as customPricingActions from "stores/orders/customPricings/actions";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Button, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

import Search from "components/Table/Search";

class CustomPricingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      customPricings: [],
      meta: {},
      filter: [],
      redirect: null,
      type: this.props.type || [],
      tableState: {},
    };
    if (!this.state.customPricings.list) {
      this.props.list(0, 10, undefined, this.getFilters());
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.type.length !== this.state.type.length) {
      this.onTableChange("type", this.state.tableState);
    }
  };

  getFilters = (state) => {
    const { auth } = this.props;
    const staticFilters = this.props.filters ? { ...this.props.filters } : [];
    const typeFilters = Object.assign(
      {},
      ...[...this.state.type]
        .map((type) => {
          if (type === "assigned") {
            return {
              assigneeId: {
                filterVal: auth.id,
                filterType: "Guid",
                comparator: "=",
                caseSensitive: false,
                filterKey: "assigneeId",
              },
            };
          }
          return null;
        })
        .filter((a) => a),
    );
    const dynamicFilters = state && state.filters ? { ...state.filters } : [];
    const filters = { ...dynamicFilters, ...typeFilters, ...staticFilters };
    return filters;
  };

  onTableChange = (type, newState) => {
    const filters = this.getFilters(newState);

    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: filters,
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
      tableState: { ...newState },
    });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/orders/customPricings/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/orders/customPricings/" + row.id });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  toggleType = (type) => {
    let newType = [...this.state.type];
    if (type) {
      if (newType.includes(type)) {
        newType = [...newType.filter((t) => t !== type)];
      } else {
        newType.push(type);
      }
    } else {
      newType = [];
    }
    this.setState({ type: newType });
  };

  render = () => {
    const { redirect, type } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { data, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "number",
        text: "Numer",
        sort: true,
      },
      {
        hidden: this.props.hideUser,
        dataField: "user",
        text: "Zamawiający",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          row.user ? (
            row.user.username
          ) : (
            <span className="text-muted">Brak</span>
          ),
      },
      {
        hidden: this.props.hideAssignee,
        dataField: "assignee",
        text: "Opiekun",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          row.assignee ? (
            row.assignee.username
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "created",
        text: "Utworzono",
        formatter: (cell, row, rowIndex, formatExtraData) => cell ? (new Date(cell)).toLocaleString() : <span className="text-muted">Brak</span>,
        sort: true,
      },
      {
        dataField: "updated",
        text: "Zaktualizowano",
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => cell ? (new Date(cell)).toLocaleString() : <span className="text-muted">Brak</span>
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={columns}
        search
        bootstrap4={true}
      >
        {(props) => {
          return (
            <>
              <Row>
                <Col xs={12} sm={3}>
                  <Search
                    className="form-control"
                    placeholder="Szukaj..."
                    {...props.searchProps}
                  />
                </Col>
                <Col xs={12} sm={9}>
                  <Nav pills fill>
                    <NavItem onClick={() => this.toggleType()}>
                      <NavLink href="#" active={type.length === 0}>
                        Wszystkie
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.toggleType("assigned")}>
                      <NavLink href="#" active={type.includes("assigned")}>
                        Pod moją opieką
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <PaginationProvider pagination={paginationFactory(pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <BootstrapTable
                    remote
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    id="react-bs-table"
                    onTableChange={this.onTableChange}
                    wrapperClasses="table-responsive"
                    {...paginationTableProps}
                    noDataIndication={() => (
                      <div className="text-center text-muted py-5">
                        <i className="fas fa-database fa-5x d-block mb-3"></i>
                        Brak danych
                      </div>
                    )}
                    defaultSorted={[{ dataField: "created", order: "desc" }]}
                  />
                )}
              </PaginationProvider>
            </>
          );
        }}
      </ToolkitProvider>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.customPricings.list,
    loading: state.customPricings.loading,
    auth: state.auth.user,
    meta: state.customPricings.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) =>
      dispatch(
        customPricingActions.list(skip, take, searchText, filters, order),
      ),
    read: (id) => dispatch(customPricingActions.read(id)),
    remove: (id) => dispatch(customPricingActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomPricingsTable),
);

import * as constants from "./constants";
import IShipping from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (shipping: IShipping) => ({
  type: constants.READ_SUCCESS,
  payload: shipping,
});

export const create = (shipping: IShipping) => ({
  type: constants.CREATE,
  payload: shipping,
});
export const createSuccess = (shipping: IShipping) => ({
  type: constants.CREATE_SUCCESS,
  payload: shipping,
});

export const update = (shipping: IShipping) => ({
  type: constants.UPDATE,
  payload: shipping,
});
export const updateSuccess = (shipping: IShipping) => ({
  type: constants.UPDATE_SUCCESS,
  payload: shipping,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (
  skip: Number = 0,
  take: Number = 10,
  orderId: string = null,
) => {
  return {
    type: constants.LIST,
    payload: { skip, take, orderId },
  };
};
export const listSuccess = (list: IShipping[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (shipping: IShipping) => ({
  type: constants.ACTIVE,
  payload: shipping,
});

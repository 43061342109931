import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Input,
  Table,
  Form,
  Row,
  ButtonGroup,
  Button,
  InputGroupAddon,
  InputGroup,
  Alert,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import { API_URL as REBATES_API_URL } from "stores/orders/rebates/epics";
import { API_URL as PRODUCTS_API_URL } from "stores/products/products/epics";
import { API_URL as PRODUCT_CATEGORIES_API_URL } from "stores/products/productCategories/epics";
import NumericInput from "components/Form/NumericInput";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class CustomerRebates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rebates: undefined,
      products: undefined,
      productCategories: undefined,
    };
    this.myRef = React.createRef();
  }

  loadRebates = () => {
    const { customer } = this.props;
    const attributes = apiDriver.buildIndexAttributes({
      take: 1000,
      skip: 0,
      filters: {
        userId: {
          filterVal: customer.id,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    });
    apiDriver
      .get(REBATES_API_URL + attributes)
      .pipe(
        map((response) => {
          this.setState({ rebates: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  loadProducts = () => {
    apiDriver
      .get(PRODUCTS_API_URL + "?Take=1000")
      .pipe(
        map((response) => {
          this.setState({ products: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  loadProductCategories = () => {
    apiDriver
      .get(PRODUCT_CATEGORIES_API_URL + "?Take=1000")
      .pipe(
        map((response) => {
          this.setState({ productCategories: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  componentDidMount = () => {
    this.loadRebates();
    this.loadProducts();
    this.loadProductCategories();
  };

  notify = (title, content, type = "default") =>
    this.props.notify(title, content, type);
  loadingOverlay = () => this.props.loadingOverlay();
  tableLoadingIndicator = () => (
    <tr>
      <td colSpan={4} align="center">
        <i className="fas fa-circle-notch fa-spin fa-4x my-5"></i>
      </td>
    </tr>
  );
  parseValue = (e) => this.props.parseValue(e);

  changeRebateData = (index, e) => {
    const value = this.parseValue(e.target);
    this.setState({
      rebates: this.state.rebates.map((address, id) => {
        if (id !== index) return address;
        return { ...address, [value.name]: value.value, dirty: true };
      }),
    });
  };

  loadingAdresssData = (index, value) => {
    this.setState({
      rebates: this.state.rebates.map((address, id) => {
        if (id !== index) return address;
        return { ...address, loading: value };
      }),
    });
  };

  undirtyAdresssData = (index) => {
    this.setState({
      rebates: this.state.rebates.map((address, id) => {
        if (id !== index) return address;
        return { ...address, loading: false, dirty: false };
      }),
    });
  };

  addRebate = () => this.setState({ rebates: [...this.state.rebates, {}] });
  saveDirtyRebates = () =>
    this.state.rebates.map((e, i) => {
      if (e.dirty) this.saveRebate(i);
      return true;
    });
  saveRebate = (index) => {
    const { customer } = this.props;
    this.loadingAdresssData(index, true);
    const rebate = this.state.rebates[index];
    /*if(!rebate.productId && !rebate.productCategoryId) {
      this.notify('Błąd walidacji', 'Wybierz produkt lub kategorię produktu dla rabatu', 'danger');
      return;
    }*/
    if (!rebate.rate || rebate.rate < 0 || rebate.rate > 100) {
      this.notify("Błąd walidacji", "Wpisz właściwą wartość rabatu", "danger");
      return;
    }
    rebate.userId = customer.id;
    const method = rebate.id ? apiDriver.patch : apiDriver.put;
    method(REBATES_API_URL + (rebate.id || ""), rebate)
      .pipe(
        map((response) => {
          this.setState({
            rebates: this.state.rebates.map((e, i) => {
              if (i !== index) return e;
              return response.response;
            }),
          });
          this.undirtyRebate(index);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };
  deleteRebate = (index) => {
    if (!this.state.rebates[index].id) {
      return this.setState({
        rebates: [...this.state.rebates.filter((e, i) => i !== index)],
      });
    }
    this.loadingAdresssData(index, true);
    apiDriver
      .remove(REBATES_API_URL + this.state.rebates[index].id)
      .pipe(
        map((response) => {
          this.setState({
            rebates: this.state.rebates.filter((e, i) => i !== index),
          });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  renderChoose = () => <option value="null">Wybierz</option>;
  renderProduct = (product) => (
    <option key={product.id} value={product.id}>
      {product.translations ? localizer(product)?.currentTranslation?.title : product.id}
    </option>
  );
  renderProductCategory = (productCategory) => (
    <option key={productCategory.id} value={productCategory.id}>
      {productCategory.translations
        ? localizer(productCategory)?.currentTranslation?.title
        : productCategory.id}
    </option>
  );

  renderRebate = (rebate, index) => {
    const { products, productCategories } = this.state;
    const onClickSave = (e) => this.saveRebate(index);
    const onClickDelete = (e) => this.deleteRebate(index);
    const onChange = (e) => this.changeRebateData(index, e);
    return (
      <tr>
        <td>
          <FormGroup>
            <Input
              type="select"
              placeholder="Produkt"
              name="productId"
              onChange={onChange}
              value={rebate.productId}
            >
              {this.renderChoose()}
              {products && products.map(this.renderProduct)}
            </Input>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="select"
              placeholder="Kategoria produktu"
              name="productCategoryId"
              onChange={onChange}
              value={rebate.productCategoryId}
            >
              {this.renderChoose()}
              {productCategories &&
                productCategories.map(this.renderProductCategory)}
            </Input>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <InputGroup style={{ width: "128px" }}>
              <NumericInput
                placeholder="Wartość rabatu"
                name="rate"
                onChange={onChange}
                value={rebate.rate}
                step={0.1}
              />
              <InputGroupAddon addonType="append">%</InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </td>
        <td align="right">
          <ButtonGroup>
            <Button
              type="button"
              color="primary"
              disabled={!rebate.dirty}
              onClick={onClickSave}
            >
              <i className="fas fa-save"></i>
            </Button>
            <Button type="button" color="danger" onClick={onClickDelete}>
              <i className="fas fa-times"></i>
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  };

  renderRebatesCard = () => {
    const { customer } = this.props;
    const { rebates } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Rabaty</h3>
        </CardHeader>
        <CardBody>
          {customer.businessId ? (
            <Alert color="info">
              <p>
                <b>Dziedziczenie rabatów</b>
              </p>
              <p>
                Klient posiada przypisane konto do przedsiębiorstwa - moze
                posiadać rabaty dziedziczone.
              </p>
            </Alert>
          ) : (
            <React.Fragment />
          )}
          <Form>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Kategoria produktu</th>
                  <th>Wartość rabatu</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rebates
                  ? rebates.map(this.renderRebate)
                  : this.tableLoadingIndicator()}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} align="right">
                    <Button
                      type="button"
                      color="success"
                      disabled={!rebates}
                      onClick={this.addRebate}
                    >
                      <i className="fas fa-plus-circle"></i> &nbsp; Dodaj
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      disabled={!rebates || !rebates.some((a) => a.dirty)}
                      onClick={this.saveDirtyRebates}
                    >
                      <i className="fas fa-save"></i> &nbsp; Zapisz wszystkie
                      zmiany
                    </Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">{this.renderRebatesCard()}</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) { }
}

function mapStateToProps(state) {
  return {
    loading: state.customers.loading,
    auth: state.auth.user,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerRebates),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as galleriesActions from "stores/cms/galleries/actions";
import * as galleryCategoriesActions from "stores/cms/galleryCategories/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";

import apiDriver from "stores/api.driver";
import { API_URL, PHOTOS_URL } from "stores/cms/galleries/epics.ts";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import NumericInput from "components/Form/NumericInput";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      gallery: null,
      categories: [],
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      photos: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        gallery: {
          id: undefined,
          author: null,
          created: "",
          updated: "",
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listCategories();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeGalleryData = (event) => {
    const { name, value, type } = event.target;
    var gallery = { ...this.state.gallery };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      default:
        break;
    }
    gallery[name] = parsedValue;
    this.setState({ gallery });
  };

  changeGalleryTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var gallery = { ...this.state.gallery };
    if (!gallery.translations || gallery.translations.length === 0) {
      gallery.translations.push({ culture: currentCulture });
    }
    gallery.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ gallery });
  };

  changeGalleryTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeGalleryTranslationEx(name, value);
  };

  submitForm = (event) => {
    var gallery = { ...this.state.gallery };
    if (gallery.id) {
      this.setState({ action: "update" });
      this.props.update(gallery);
    } else {
      this.setState({ action: "create" });
      this.props.create(gallery);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.gallery };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ gallery: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.gallery.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.gallery || this.props.loading;

    const { gallery } = this.state;
    const { auth } = this.props;

    if (!gallery || gallery.id === auth.id || !gallery.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.gallery || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { gallery } = this.state;

    if (!gallery || !gallery.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={gallery ? gallery.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { gallery } = this.state;
    const { currentCulture } = this.state;

    if (!gallery) {
      return <></>;
    }

    const { categories } = this.state;
    const loading = this.props.loading;
    const editedTranslation =
      gallery.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Priorytet</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Priorytet"
                      name="priority"
                      value={gallery.priority}
                      onChange={this.changeGalleryData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kategoria</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="categoryId"
                  value={gallery.categoryId}
                  onChange={this.changeGalleryData}
                >
                  <option value="00000000-0000-0000-0000-000000000000">
                    Brak
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeGalleryTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Identyfikator adresu</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-globe" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Identyfikator adresu"
                      type="text"
                      name="url"
                      value={editedTranslation ? editedTranslation?.url : ""}
                      onChange={this.changeGalleryTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <b>Metadane</b>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="metaTitle"
                    value={
                      editedTranslation ? editedTranslation?.metaTitle : ""
                    }
                    onChange={this.changeGalleryTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="metaDescription"
                    value={
                      editedTranslation
                        ? editedTranslation?.metaDescription
                        : ""
                    }
                    onChange={this.changeGalleryTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Słowa kluczowe</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Słowa kluczowe"
                    type="text"
                    name="metaKeywords"
                    value={
                      editedTranslation ? editedTranslation?.metaKeywords : ""
                    }
                    onChange={this.changeGalleryTranslation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  getPhotos = () => {
    const { gallery } = this.props;
    if (!gallery || !gallery.id || gallery.id === "create") {
      return;
    }
    const url = PHOTOS_URL + gallery.id + "/Photos/";
    apiDriver
      .get(url)
      .pipe(
        map((response) => response.response.map((p) => p.name)),
        catchError((error) => {
          console.error("error: ", error);
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({ photos: data });
      });
  };

  deletePhoto = (photo) => {
    const { gallery, photos } = this.state;
    if (!gallery || !gallery.id || gallery.id === "create") {
      return;
    }
    const url = API_URL + gallery.id + "/Photos/" + photo;

    apiDriver
      .remove(url)
      .pipe(
        map((response) => response.response.value),
        catchError((error) => {
          console.error("error: ", error);
          return of(error);
        }),
      )
      .subscribe((data) => {
        let p = [...photos].filter((p2) => p2 !== photo);
        this.setState({ photos: [...new Set(p)] });
      });
  };

  onChangeImage = (event) => {
    const { gallery, photos } = this.state;
    if (
      !gallery ||
      !gallery.id ||
      gallery.id === "create" ||
      event.target.files.length !== 1
    ) {
      return;
    }
    const file = event.target.files[0];
    const url = API_URL + gallery.id + "/Photos";

    apiDriver
      .upload(url, file, file.name)
      .pipe(
        map((response) => response.response.value),
        catchError((error) => {
          console.error("error: ", error);
          return of(error);
        }),
      )
      .subscribe((data) => {
        let p = [...photos];
        p.push(file.name);
        this.setState({ photos: [...new Set(p)] });
      });
  };

  renderCardPhotos = () => {
    const { gallery, photos } = this.state;

    if (!gallery || !gallery.id || gallery.id === "create") {
      return <></>;
    }

    const loading = this.props.loading;
    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Zdjęcia</h3>
        </CardHeader>
        <CardBody>
          <Row>
            {photos.map((photo) => (
              <Col lg="3" key={photo}>
                <img
                  alt="..."
                  className="img-center img-responsive center-block img-fluid shadow shadow-lg--hover img-thumbnail rounded cover-image"
                  src={PHOTOS_URL + gallery.id + "/Photos/" + photo}
                  style={{
                    width: "100%",
                    height: "128px",
                    marginBottom: "-32px",
                  }}
                />
                <Button
                  color="danger"
                  onClick={() => {
                    this.deletePhoto(photo);
                  }}
                >
                  <i className="fas fa-trash" />
                </Button>
              </Col>
            ))}

            <Col lg="3">
              <label
                className="text-center d-block btn p-0 m-0"
                htmlFor="addPhoto"
              >
                <p className="text-center text-muted">
                  <i className="fas fa-camera fa-2x" />
                </p>
                <p className="text-center text-muted">Dodaj nowe zdjęcie</p>
                <Form>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="addPhoto"
                      lang="en"
                      type="file"
                      onChange={this.onChangeImage}
                    />
                  </div>
                </Form>
              </label>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.gallery || this.props.loading;

    const { gallery } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/cms/galleries/" />;
    }

    return (
      <>
        <SimpleHeader
          name={gallery ? gallery.currentTranslation?.title : ""}
          link="/admin/cms/galleries"
          parentName="Galerie"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardPhotos()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.gallery !== this.props.gallery) {
      this.setState({
        gallery: this.props.gallery,
        loading: this.props.loading,
        auth: this.props.auth,
      });
      this.getPhotos();
    }
    if (prevProps.categories !== this.props.categories) {
      this.setState({
        categories: this.props.categories,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.galleries.current,
    loading: state.galleries.loading,
    auth: state.auth.user,
    session: state.session,
    categories: state.galleryCategories.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(galleriesActions.read(id)),
    remove: (id) => dispatch(galleriesActions.remove(id)),
    update: (gallery) => dispatch(galleriesActions.update(gallery)),
    create: (gallery) => dispatch(galleriesActions.create(gallery)),
    listCategories: () => dispatch(galleryCategoriesActions.list()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Gallery),
);

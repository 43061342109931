export const READ = "pbs/BOOKINGS/READ/PENDING";
export const READ_SUCCESS = "pbs/BOOKINGS/READ/SUCCESS";
export const CREATE = "pbs/BOOKINGS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/BOOKINGS/CREATE/SUCCESS";
export const UPDATE = "pbs/BOOKINGS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/BOOKINGS/UPDATE/SUCCESS";
export const REMOVE = "pbs/BOOKINGS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/BOOKINGS/REMOVE/SUCCESS";
export const LIST = "pbs/BOOKINGS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/BOOKINGS/LIST/SUCCESS";

export const ACTIVE = "pbs/BOOKINGS/ACTIVE";

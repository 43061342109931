import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactDatetime from "react-datetime";

import * as orderProductsActions from "stores/orders/orderProducts/actions";
import * as productsActions from "stores/products/products/actions";
import * as productOptionsActions from "stores/products/productOptions/actions";
import * as productAdditivesActions from "stores/products/productAdditives/actions";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardFooter,
  Button,
  FormGroup,
  InputGroup,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  ButtonGroup,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import Uploader from "./Uploader";
import OrderNotes from "./OrderNotes";
import NumericInput from "components/Form/NumericInput";
import printColors from "views/pages/products/PrintColors";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

const TaxRates = [
  { value: 0, label: "0%" },
  { value: 0.05, label: "5%" },
  { value: 0.08, label: "8%" },
  { value: 0.23, label: "23%" },
];

function File(props) {
  const { file, entityType, onDelete, onDownload } = props;

  const [preview, setPreview] = useState(null);

  const onClickDownload = () => {
    apiDriver
      .get(
        config.api.orders +
        `pl/OrderProducts/${file.orderProductId}/${entityType}s/${file.objectName}/Download`,
      )
      .pipe(
        map((response) => {
          const link = document.createElement("a");
          link.href = response.response;
          link.setAttribute("download", file.objectName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          if (onDownload) {
            onDownload(response.response);
          }
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  const onClickDelete = () => {
    apiDriver
      .remove(
        config.api.orders +
        `pl/OrderProducts/${file.orderProductId}/${entityType}s/${file.objectName}`,
      )
      .pipe(
        map((response) => {
          if (onDelete) {
            onDelete();
          }
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  useEffect(() => {
    if (entityType === "Material") {
      return;
    }
    apiDriver
      .get(
        config.api.orders +
        `pl/OrderProducts/${file.orderProductId}/${entityType}s/${file.objectName}/Preview?Take=1`,
      )
      .pipe(
        map((response) => {
          if (response && response.response && response.response.length > 0) {
            setPreview(response.response[0]);
          }
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col md="2" className="my-3 text-center">
        {preview ? (
          <img
            src={preview}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "96px" }}
          />
        ) : (
          <React.Fragment />
        )}
      </Col>
      <Col md="10" className="my-3">
        <Row>
          <Col md="10">{file.objectName}</Col>
          <Col md="2">
            <Button
              type="button"
              size="sm"
              color="danger"
              onClick={onClickDelete}
            >
              <i className="fas fa-times"></i>
            </Button>
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={onClickDownload}
            >
              <i className="fas fa-download"></i>
            </Button>
          </Col>
          <Col md="12">
            <small>
              {new Date(file.stat.lastModified).toLocaleDateString()} &nbsp;
              &bull; &nbsp;
              {file.accepted !== null && file.accepted !== undefined ? (
                file.accepted === true ? (
                  <span>
                    <i className="fa fa-check-circle text-success"></i>{" "}
                    Zaakceptowany
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-times-circle text-danger"></i> Odrzucony
                  </span>
                )
              ) : (
                <span>
                  <i className="fa fa-question-circle text-muted"></i> Oczekuje
                  na status
                </span>
              )}
            </small>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

class OrderProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderProduct: null,
      product: null,
      review: "",
      fileToUploadPreview: null,
      fileToUpload: null,
      fileToUploadInvalid: false,
      isRecalculating: false,
      projects: [],
      previews: [],
      materials: [],
      attributes: [],
      options: [],
      additives: [],
      additiveGroups: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const { orderProductId } = this.props;
    const fileTypes = ["Material", "Project", "Preview"];
    this.props.readOrderProduct(orderProductId);

    for (const fileType of fileTypes) {
      apiDriver
        .get(
          config.api.orders +
          `pl/OrderProducts/${orderProductId}/${fileType}s/`,
        )
        .pipe(
          map((response) => response.response),
          catchError((error) => {
            return of(error);
          }),
        )
        .subscribe((data) => {
          if (data) {
            this.setState({ [fileType.toLowerCase() + "s"]: data });
          }
        });
    }
  };

  loadingOverlay = () => this.props.loadingOverlay();
  parseValue = (e) => this.props.parseValue(e);

  getOptions = () => {
    const { product, options } = this.state;
    return product?.options || options || [];
  };

  getAttribute = (id) => {
    const { orderProduct, product, options } = this.state;
    if (orderProduct.productId) {
      return product?.attributes?.find((a) => a.id === id) || null;
    } else {
      return options?.find((o) => o.attributeId === id)?.attribute || null;
    }
  };

  getAdditives = () => {
    const { product, additives } = this.state;
    return product?.additives || additives || [];
  };

  getAdditiveGroup = (id) => {
    const { orderProduct, product, additives } = this.state;
    if (orderProduct.productId) {
      return product?.additiveGroups?.find((a) => a.id === id) || null;
    } else {
      return additives?.find((a) => a.groupId === id)?.group || null;
    }
  };

  changeDotNotationValue = (entity, notation, value) =>
    this.props.changeDotNotationValue(entity, notation, value);

  onClickDelete = () => {
    const { orderProduct } = this.state;
    const { onDelete } = this.props;
    apiDriver
      .remove(config.api.orders + `pl/OrderProducts/${orderProduct?.id}`)
      .pipe(
        map((response) => {
          this.setState({ loading: false });
          onDelete(orderProduct?.id);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
    this.setState({ loading: true });
  };

  calculateWithRebate = (price, rebate) => {
    const { orderProduct } = this.state;
    if (price === null) {
      price = orderProduct.price;
    }
    if (rebate === undefined || rebate === null) {
      rebate = orderProduct.rebate || 0;
    }
    const result = Math.ceil(price * (100 - rebate)) / 100;
    return result;
  };

  changeOrderProductData = (e) => {
    const value = this.parseValue(e.target);
    const orderProduct = this.changeDotNotationValue(
      { ...this.state.orderProduct },
      value.name,
      value.value,
    );
    if (value.name === "rebate") {
      orderProduct.costNet = this.calculateWithRebate(
        this.state.orderProduct.price,
        value.value,
      );
    }
    this.setState({ orderProduct: orderProduct });
  };

  changeOrderData = (e) => {
    const value = this.parseValue(e.target);
    this.props.changeOrderDataEx(value.name, value.value);
  };

  changeOrderProductDate = (name, value) => {
    this.changeOrderProductData({
      target: { name: name, value: value, type: "datetime" },
    });
  };

  changeOrderProductAdditive = (e) => {
    const { type, checked, value, name } = e.target;
    const { orderProduct } = this.state;

    const additives = this.getAdditives();

    let productAdditives = orderProduct?.personalization?.additives || [];
    const additive = [...additives].find((a) => a.id === value);

    if (!additive) {
      const additivesInGroup = additives
        .filter((a) => a.groupId === name)
        .map((a) => a.id);
      productAdditives = productAdditives.filter(
        (a) => !additivesInGroup.includes(a),
      );
    } else if (type === "select-one" || type === "radio") {
      const additivesInGroup = additives
        .filter((a) => a.groupId === name)
        .map((a) => a.id);
      productAdditives = productAdditives.filter(
        (a) => !additivesInGroup.includes(a),
      );
      if (additive) {
        productAdditives.push(additive.id);
      }
    } else if (type === "checkbox") {
      if (checked) {
        productAdditives.push(additive.id);
      } else {
        productAdditives = [...productAdditives].filter(
          (a) => a !== additive.id,
        );
      }
    }

    orderProduct.personalization.additives = productAdditives;
    this.setState({ orderProduct: orderProduct });
  };

  changeOrderProductOption = (e) => {
    const { orderProduct } = this.state;
    const { value, name } = e.target;

    const options = [...this.getOptions()];

    const optionsInAttribute = [...options]
      .filter((a) => a.attributeId === name)
      .map((a) => a.id);

    let productOptions = (
      [...orderProduct?.personalization?.options] || []
    ).filter((a) => !optionsInAttribute.includes(a));

    const option = [...options].find((o) => o.id === Number(value));
    const attribute = this.getAttribute(option.attributeId);
    if (option) {
      productOptions.push(option.id);
    }

    if (attribute.isAffectingOnColor !== 0) {
      if (attribute.isAffectingOnColor & 1 && option.defineInsideColor) {
        const colorProfile = orderProduct.personalization.colorProfiles.find(cp => cp.name === 'main.inside');
        if (colorProfile) {
          colorProfile.color = option.defineInsideColor;
        } else {
          const newColorProfile = { name: 'main.inside', color: option.defineInsideColor, pages: { inside: 0, outside: 0 } };
          orderProduct.personalization.colorProfiles.push(newColorProfile);
        }
      }
      if (attribute.isAffectingOnColor & 2 && option.defineOutsideColor) {
        const colorProfile = orderProduct.personalization.colorProfiles.find(cp => cp.name === 'main.outside');
        if (colorProfile) {
          colorProfile.color = option.defineOutsideColor;
        } else {
          const newColorProfile = { name: 'main.outside', color: option.defineOutsideColor, pages: { inside: 0, outside: 0 } };
          orderProduct.personalization.colorProfiles.push(newColorProfile);
        }
      }
    }

    if (attribute.isAffectingOnAlternateColor !== 0) {
      if (attribute.isAffectingOnAlternateColor & 1 && option.defineInsideAlternateColor) {
        const colorProfile = orderProduct.personalization.colorProfiles.find(cp => cp.name === 'alt.inside');
        if (colorProfile) {
          colorProfile.color = option.defineInsideAlternateColor;
        } else {
          const newColorProfile = { name: 'alt.inside', color: option.defineInsideAlternateColor, pages: { inside: 0, outside: 0 } };
          orderProduct.personalization.colorProfiles.push(newColorProfile);
        }
      }
      if (attribute.isAffectingOnAlternateColor & 2 && option.defineOutsideAlternateColor) {
        const colorProfile = orderProduct.personalization.colorProfiles.find(cp => cp.name === 'alt.outside');
        if (colorProfile) {
          colorProfile.color = option.defineOutsideAlternateColor;
        } else {
          const newColorProfile = { name: 'alt.outside', color: option.defineOutsideAlternateColor, pages: { inside: 0, outside: 0 } };
          orderProduct.personalization.colorProfiles.push(newColorProfile);
        }
      }
    }

    orderProduct.personalization.options = productOptions;
    this.setState({ orderProduct: orderProduct });
  };

  changeOrderProperty = (e, propertyName) => {
    const value = this.props.parseValue(e.target);
    let property = {
      ...this.props.order[propertyName],
      [value.name]: value.value,
    };
    this.props.changeOrderDataEx(propertyName, property);
  };

  getProductCalculationRequest = () => {
    const { orderProduct } = this.state;
    return {
      size: orderProduct.personalization.format,
      amount: orderProduct.personalization.amount,
      customAmount: [orderProduct.personalization.amount],
      pages: orderProduct.personalization.pages,
      options: orderProduct.personalization.options,
      additives: orderProduct.personalization.additives,
      colorProfiles: orderProduct.personalization.colorProfiles,
      project: orderProduct.personalization.project,
      checking: orderProduct.personalization.checking,
      color: orderProduct.personalization.color,
      rebate: orderProduct.rebate,
      realizationTimeType: orderProduct.personalization.realizationTimeType
    };
  };

  getProductCalculation = (e, type) => {
    const { orderProduct } = this.state;
    const url = `${config.api.orders}pl/Products/${orderProduct.orderId}/${orderProduct.productId}/Calculation`;
    const body = this.getProductCalculationRequest();
    apiDriver
      .post(url, body)
      .pipe(
        map((response) => {
          if (response.response) {
            const calculation = response.response;
            switch (type) {
              case "full":
                this.setState({
                  isRecalculating: false,
                  orderProduct: {
                    ...orderProduct,
                    realizationTerm: calculation.realizationTerm,
                    price: calculation.price,
                    weight: calculation.weight,
                    technicalFeedback: calculation.feedback,
                  },
                });
                this.getProductRebate(e);
                break;
              case "realizationTerm":
                this.setState({
                  isRecalculating: false,
                  orderProduct: {
                    ...orderProduct,
                    realizationTerm: calculation.realizationTerm,
                  },
                });
                break;
              case "price":
                this.setState({
                  isRecalculating: false,
                  orderProduct: {
                    ...orderProduct,
                    price: calculation.price,
                    technicalFeedback: calculation.feedback,
                  },
                });
                break;
              case "weight":
                this.setState({
                  isRecalculating: false,
                  orderProduct: { ...orderProduct, weight: calculation.weight },
                });
                break;
              default:
                break;
            }
          }
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
    this.setState({ isRecalculating: true });
  };

  getProductRebate = (e) => {
    const { orderProduct } = this.state;
    const { order } = this.props;
    this.setState({ isRecalculating: true });
    const url = `${config.api.orders}pl/Rebates/GetForCustomer/${order.userId}/${orderProduct.productId}`;
    const body = this.getProductCalculationRequest();
    apiDriver
      .get(url, body)
      .subscribe({
        next: (response) => {
          const rebate = response.response;
          this.setState({ orderProduct: { ...orderProduct, rebate: rebate?.rate || 0 } });
        },
        error: alert,
        complete: () => this.setState({ isRecalculating: false })
      })
  }

  clearFileToUpload = () => {
    this.setState({ fileToUpload: null });
  };

  handleFileToUploadChange = (e) => {
    const { files } = e.target;
    const cancel = !files.length;
    if (cancel) return;
    let reader = new FileReader();
    let file = files[0];

    reader.onloadend = () => {
      this.setState({
        fileToUpload: file,
        fileToUploadInvalid: false,
        fileToUploadPreview: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  checkForNotVerified = (arrayOfFiles) =>
    !arrayOfFiles ||
    arrayOfFiles.length < 1 ||
    ![...arrayOfFiles].some(
      (p) => p.accepted === undefined || p.accepted === null,
    );

  submitForm = (event) => {
    const {
      projects,
      materials,
      previews,
      status,
      order,
      product,
      shippings,
      priceObj,
      ...orderProduct
    } = this.state.orderProduct;
    if (orderProduct?.id) {
      this.setState({ action: "update" });
      this.props.updateOrderProduct(orderProduct);
    } else {
      this.setState({ action: "create" });
      this.props.createOrderProduct(orderProduct);
    }
  };

  onReviewFile = (status) => {
    const { orderProduct, review, projects, previews } = this.state;

    const filesSortByDate = (a, b) =>
      b.stat.lastModified.localeCompare(a.stat.lastModified);

    if (this.checkForNotVerified(projects)) {
      alert("Brak niezweryfikowanych plikow projektowych");
      return;
    }

    if (status === 0 && !review) {
      alert("Uzupelnij notatke weryfikacyjna");
      return;
    }

    const lastProject = [...projects]
      .filter((p) => p.accepted === undefined || p.accepted === null)
      .sort(filesSortByDate)[0];

    switch (status) {
      case 0:
      case 1:
        apiDriver
          .post(
            config.api.orders +
            `pl/OrderProducts/${orderProduct?.id}/Projects/${lastProject.objectName}/Review`,
            {
              accepted: status === 1,
              note: review,
            },
          )
          .pipe(
            map((response) => {
              if (response.response) {
                this.setState({
                  projects: projects.map((p) => {
                    if (p.objectName !== lastProject.objectName) return p;
                    return { ...p, accepted: status === 1, note: review };
                  }),
                });
              }
              return response.response;
            }),
            catchError((error) => {
              return of(error);
            }),
          )
          .subscribe((data) => { });
        break;
      case 2:
        if (this.checkForNotVerified(previews)) {
          alert("Wgraj najpierw plik pogladowy");
          return;
        }

        const lastPreview = [...previews]
          .filter((p) => p.accepted === undefined || p.accepted === null)
          .sort(filesSortByDate)[0];

        apiDriver
          .post(
            config.api.orders +
            `pl/OrderProducts/${orderProduct?.id}/Projects/${lastProject.objectName}/Review`,
            {
              accepted: true,
              note: review,
            },
          )
          .pipe(
            map((response) => {
              if (response.response) {
                this.setState({
                  projects: projects.map((p) => {
                    if (p.objectName !== lastProject.objectName) return p;
                    return { ...p, accepted: true, note: review };
                  }),
                });
                apiDriver
                  .post(
                    config.api.orders +
                    `pl/OrderProducts/${orderProduct?.id}/Previews/${lastPreview.objectName}/Review`,
                    {
                      accepted: true,
                      note: review,
                    },
                  )
                  .pipe(
                    map((response) => {
                      if (response.response) {
                        this.setState({
                          previews: previews.map((p) => {
                            if (p.objectName !== lastPreview.objectName)
                              return p;
                            return { ...p, accepted: true, note: review };
                          }),
                        });
                      }
                      return response.response;
                    }),
                    catchError((error) => {
                      return of(error);
                    }),
                  )
                  .subscribe((data) => { });
              }
              return response.response;
            }),
            catchError((error) => {
              return of(error);
            }),
          )
          .subscribe((data) => { });
        break;
      default:
        break;
    }
  };

  onPutFile = (type, object) => {
    const { projects, previews, materials } = this.state;

    switch (type) {
      case "Preview":
        this.setState({ previews: [...previews, object] });
        break;
      case "Project":
        this.setState({ projects: [...projects, object] });
        break;
      case "Material":
        this.setState({ materials: [...materials, object] });
        break;
      default:
        break;
    }
  };

  onDeleteFile = (type, objectName) => {
    const { projects, previews, materials } = this.state;

    switch (type) {
      case "Preview":
        this.setState({
          previews: [...previews].filter((f) => f.objectName !== objectName),
        });
        break;
      case "Project":
        this.setState({
          projects: [...projects].filter((f) => f.objectName !== objectName),
        });
        break;
      case "Material":
        this.setState({
          materials: [...materials].filter((f) => f.objectName !== objectName),
        });
        break;
      default:
        break;
    }
  };

  renderMaterialsAndProjects = () => {
    const { orderProduct, materials, projects } = this.state;

    return (
      <Card>
        <CardHeader>
          <CardTitle>Projekty i materiały</CardTitle>
        </CardHeader>
        <CardBody>
          <h3>Materiały</h3>
          {materials &&
            materials.map((file) => (
              <File key={file.objectName} file={file} entityType="Material" />
            ))}
          <h3>Projekty</h3>
          {projects &&
            projects.map((file) => (
              <File key={file.objectName} file={file} entityType="Project" />
            ))}
        </CardBody>
        <CardFooter>
          <h3>Wgraj projekt</h3>
          <Uploader
            url={
              config.api.orders +
              `pl/OrderProducts/${orderProduct?.id}/Projects/FILENAME`
            }
            isPresigned={true}
            onUploadComplete={(response) =>
              this.onPutFile("Project", response.response)
            }
          />
        </CardFooter>
      </Card>
    );
  };

  renderProjectsAndPreviews = () => {
    const { orderProduct, projects, previews } = this.state;

    return (
      <Card>
        <CardHeader>
          <CardTitle>Projekty i pliki poglądowe</CardTitle>
        </CardHeader>
        <CardBody>
          <h3>Projekty</h3>
          {projects &&
            projects.map((file) => (
              <File
                key={file.objectName}
                file={file}
                entityType="Project"
                onDelete={() => this.onDeleteFile("Project", file.objectName)}
              />
            ))}
          <h3>Pliki poglądowe</h3>
          {previews &&
            previews.map((file) => (
              <File
                key={file.objectName}
                file={file}
                entityType="Preview"
                onDelete={() => this.onDeleteFile("Preview", file.objectName)}
              />
            ))}
        </CardBody>
        <CardFooter>
          <h3>Wgraj projekt</h3>
          <Uploader
            url={
              config.api.orders +
              `pl/OrderProducts/${orderProduct?.id}/Projects/FILENAME`
            }
            isPresigned={true}
            onUploadComplete={(response) =>
              this.onPutFile("Project", response.response)
            }
          />
          <h3>Wgraj plik poglądowy</h3>
          <Uploader
            url={
              config.api.orders +
              `pl/OrderProducts/${orderProduct?.id}/Previews/FILENAME`
            }
            isPresigned={true}
            onUploadComplete={(response) =>
              this.onPutFile("Preview", response.response)
            }
          />
        </CardFooter>
      </Card>
    );
  };

  renderFilesCard = () => {
    const { orderProduct } = this.state;
    if (!orderProduct || !orderProduct.personalization) {
      return (
        <Card>
          <CardHeader>
            <CardTitle>Projekty i materiały</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="text-center">
              <Spinner />
            </div>
          </CardBody>
        </Card>
      );
    }

    if (orderProduct.personalization.project) {
      return this.renderMaterialsAndProjects();
    } else {
      return this.renderProjectsAndPreviews();
    }
  };

  changeOrderProductFormat = (event) => {
    const { orderProduct } = this.state;
    const { value } = event.target;
    if (value === "") {
      return;
    }
    let tmp = value.split("x");
    const newFormat = { x: Number(tmp[0]), y: Number(tmp[1]) };
    this.setState({
      orderProduct: {
        ...orderProduct,
        personalization: { ...orderProduct.personalization, format: newFormat },
      },
    });
  };

  onChangeReview = (event) => this.setState({ review: event.target.value });

  renderVerificationCard = () => {
    const { orderProduct, review, projects, previews } = this.state;

    if (this.checkForNotVerified(projects)) {
      return <React.Fragment />;
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle>Weryfikacja</CardTitle>
        </CardHeader>
        <CardBody>
          <h3>Notatka weryfikacyjna</h3>
          <Input
            type="textarea"
            name="review"
            id="review"
            value={review}
            onChange={this.onChangeReview}
            placeholder="Notatka weryfikacyjna"
            rows="5"
          />
        </CardBody>
        <CardFooter>
          <h3>Wgraj plik poglądowy</h3>
          <Uploader
            url={
              config.api.orders +
              `pl/OrderProducts/${orderProduct?.id}/Previews/FILENAME`
            }
            isPresigned={true}
            onUploadComplete={(response) =>
              this.onPutFile("Preview", response.response)
            }
          />
        </CardFooter>
        <CardFooter className="text-right">
          <ButtonGroup>
            {!this.checkForNotVerified(previews) ? (
              <Button
                type="button"
                color="primary"
                onClick={(e) => this.onReviewFile(2)}
              >
                <i className="fas fa-print"></i> &nbsp; Zaakceptuj i zatwierdz
                do druku
              </Button>
            ) : (
              <React.Fragment />
            )}
            <Button
              type="button"
              color="success"
              onClick={(e) => this.onReviewFile(1)}
            >
              <i className="fas fa-check-circle"></i> &nbsp; Zaakceptuj
            </Button>
            <Button
              type="button"
              color="danger"
              onClick={(e) => this.onReviewFile(0)}
            >
              <i className="fas fa-times-circle"></i> &nbsp; Odrzuc
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    );
  };

  renderPrintFormats = () => {
    const { orderProduct, product } = this.state;

    if (orderProduct?.productId && !product?.printFormats?.length) {
      return <></>;
    }

    if (!orderProduct?.productId) {
      return <></>;
    }

    return (
      <>
        <FormGroup>
          <Input
            type="select"
            onChange={this.changeOrderProductFormat}
            value={`${orderProduct?.personalization?.format?.x}x${orderProduct?.personalization?.format?.y}`}
          >
            <option value="">Niestandardowy</option>
            {product.printFormats.map((f, i) => (
              <option value={`${f.x}x${f.y}`} id={i}>
                {localizer(f).currentTranslation.title} ({f.x} x {f.y} mm)
              </option>
            ))}
          </Input>
        </FormGroup>
      </>
    );
  };

  changeColorProfilePages = (e) => {
    const personalization = { ...this.state.orderProduct.personalization };
    const { name, value } = e.target;
    const [colorProfileName, pagesType] = name.split('_');

    const otherColorProfiles = [...personalization.colorProfiles.filter(cp => cp.name !== colorProfileName)];
    const colorProfile = { ...personalization.colorProfiles.find(cp => cp.name === colorProfileName) };
    colorProfile.pages[pagesType] = Number(value);
    personalization.colorProfiles = [...otherColorProfiles, colorProfile];

    this.setState({ orderProduct: { ...this.state.orderProduct, personalization: personalization } });
    return false
  }

  renderProductColorProfile = (colorProfile) => {
    return (
      <Row>
        <Col md={1} className="text-center"><i title={colorProfile.name.includes('main') ? "Zadruk pierwszorzędny" : "Zadruk drugorzędny"} className={colorProfile.name.includes('main') ? "fas fa-circle" : "far fa-circle"}></i></Col>
        <Col md={3}>{printColors.find(c => c.value === colorProfile.color)?.label || "Brak"}</Col>
        <Col md={4}><NumericInput name={`${colorProfile.name}_inside`} value={colorProfile.pages.inside} step={1} min={0} onChange={this.changeColorProfilePages} /></Col>
        <Col md={4}><NumericInput name={`${colorProfile.name}_outside`} value={colorProfile.pages.outside} step={1} min={1} onChange={this.changeColorProfilePages} /></Col>
      </Row>
    )
  }

  renderProductColorProfiles = () => {
    const { orderProduct, product } = this.state;

    if (!product?.productConfiguration?.pages?.canDetermineColor) {
      return <></>;
    }

    return (
      <>
        <Row>
          <Col md={4}>Zadruk</Col>
          <Col md={4}>Wewnętrzne</Col>
          <Col md={4}>Zewnętrzne</Col>
        </Row>
        {orderProduct?.personalization?.colorProfiles?.sort((a, b) => b.name.localeCompare(a.name)).map(this.renderProductColorProfile)}
      </>
    )
  }

  renderPersonalizationCardContent = () => {
    const { product, orderProduct } = this.state;
    const loading = orderProduct.productId
      ? !product || !product.additives || !product.options
      : false;
    if (loading) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <Label for="personalization.format.x">Wymiary</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="personalization.format.x"
                  name="personalization.format.x"
                  value={orderProduct?.personalization?.format?.x}
                  min={product?.productConfiguration?.pages?.minimal?.x}
                  max={product?.productConfiguration?.pages?.maximal?.x}
                  step={1}
                  placeholder={product?.productConfiguration?.pages?.default?.x}
                  onChange={this.changeOrderProductData}
                />
                <NumericInput
                  id="personalization.format.y"
                  name="personalization.format.y"
                  value={orderProduct?.personalization?.format?.y}
                  min={product?.productConfiguration?.pages?.minimal?.y}
                  max={product?.productConfiguration?.pages?.maximal?.y}
                  step={1}
                  placeholder={product?.productConfiguration?.pages?.default?.y}
                  onChange={this.changeOrderProductData}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>mm</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {this.renderPrintFormats()}
          </Col>
          <Col md={4}>
            <Label for="personalization.pages.inside">Strony</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="personalization.pages.inside"
                  name="personalization.pages.inside"
                  value={orderProduct?.personalization?.pages?.inside}
                  min={product?.productConfiguration?.pages?.minimal?.inside}
                  max={product?.productConfiguration?.pages?.maximal?.inside}
                  step={1}
                  placeholder={
                    product?.productConfiguration?.pages?.default?.inside || 0
                  }
                  readOnly={
                    product?.productConfiguration.pages.type === "Const" ||
                    product?.productConfiguration?.pages?.minimal?.inside ===
                    product?.productConfiguration?.pages?.maximal?.inside
                  }
                  onChange={this.changeOrderProductData}
                />
                <NumericInput
                  id="personalization.pages.outside"
                  name="personalization.pages.outside"
                  value={orderProduct?.personalization?.pages?.outside}
                  min={product?.productConfiguration?.pages?.minimal?.outside}
                  max={product?.productConfiguration?.pages?.maximal?.outside}
                  step={1}
                  placeholder={
                    product?.productConfiguration?.pages?.default?.outside || 0
                  }
                  readOnly={
                    product?.productConfiguration.pages.type === "Const" ||
                    product?.productConfiguration?.pages?.minimal?.outside ===
                    product?.productConfiguration?.pages?.maximal?.outside
                  }
                  onChange={this.changeOrderProductData}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {this.renderProductColorProfiles()}
        <hr />
        {this.renderProductAttributes()}
        <hr />
        {this.renderProductAdditives()}
        <Row>
          <Col md={4}>
            <Label for="project">Projekt</Label>
          </Col>
          <Col md={8} className="text-right">
            <label className="custom-toggle">
              <Input
                type="checkbox"
                name="personalization.project"
                onChange={this.changeOrderProductData}
                value={true}
                checked={orderProduct.personalization.project}
                defaultChecked={orderProduct.personalization.project}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Label for="checking">Sprawdzanie</Label>
          </Col>
          <Col md={6} className="text-right">
            <Input
              id="checking"
              type="select"
              name="personalization.checking"
              onChange={this.changeOrderProductData}
              value={orderProduct.personalization.checking}
            >
              <option value="None">Brak</option>
              <option value="Basic">Podstawowe</option>
              <option value="Extended">Rozszerzone</option>
            </Input>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderPersonalizationCard = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Personalizacja</CardTitle>
        </CardHeader>
        <CardBody>{this.renderPersonalizationCardContent()}</CardBody>
      </Card>
    );
  };

  renderProductAttributes = () => {
    const { orderProduct, product } = this.state;

    const options = [...this.getOptions()];

    var attributeIds = [
      ...new Set(options.map(({ attributeId }) => attributeId)),
    ];

    return attributeIds
      .map((attributeId) => ({
        id: attributeId,
        order: Math.max(
          ...options
            .filter((o) => o.attributeId === attributeId)
            .map(
              (o) =>
                product?.productOptions?.find((po) => po.optionId === o.id)
                  ?.order || 0,
            )
            .filter((o) => o),
        ),
      }))
      .sort((a, b) => b.order - a.order)
      .map((a) => a.id)
      .map((attributeId) => {
        const attributeOptions = options
          .filter((o) => o.attributeId === attributeId)
          .map((option) => ({
            option,
            order:
              product?.productOptions?.find((po) => po.optionId === option.id)
                ?.order || 0,
          }))
          .sort((a, b) => b.order - a.order)
          .map((o) => o.option);
        const attribute = this.getAttribute(attributeId);
        return (
          <Row key={attribute.id}>
            <Col md={6}>
              <Label for={attribute.id}>
                {localizer(attribute).currentTranslation.title}
              </Label>
            </Col>
            <Col md={6}>
              <FormGroup>
                <InputGroup>
                  <Input
                    type="select"
                    id={attribute.id}
                    name={attribute.id}
                    onChange={this.changeOrderProductOption}
                    value={attributeOptions
                      .map((o) => o.id)
                      .find(
                        (o) =>
                          orderProduct?.personalization?.options?.includes(o) ||
                          false,
                      )}
                  >
                    {!attribute.isRequired || !orderProduct.productId ? (
                      <option value={undefined}>Brak</option>
                    ) : (
                      <React.Fragment />
                    )}
                    {attributeOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {localizer(option)?.currentTranslation?.title}
                      </option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        );
      });
  };

  renderAdditive = (additive, type) => {
    const { orderProduct } = this.state;
    return (
      <Row>
        <Col md={12}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                id={additive.id}
                name={additive.groupId}
                value={additive.id}
                onChange={this.changeOrderProductAdditive}
                checked={
                  orderProduct?.personalization?.additives?.includes(
                    additive.id,
                  ) ?? false
                }
              />
              {localizer(additive)?.currentTranslation?.title}
            </Label>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  renderAdditiveGroup = (group, additives) => {
    const { orderProduct } = this.state;
    switch (group.inputType) {
      case "Buttons":
      case "Select":
        return (
          <div className="mb-3">
            <Row key={group.id}>
              <Col md={6}>
                <Label for={group.id}>{localizer(group)?.currentTranslation?.title}</Label>
              </Col>
              <Col md={6}>
                <Input
                  type="select"
                  name={group.id}
                  onChange={this.changeOrderProductAdditive}
                  value={additives
                    .map((a) => a.id)
                    .find(
                      (a) =>
                        orderProduct?.personalization?.additives?.includes(a) ||
                        false,
                    )}
                >
                  {!orderProduct.productId && (
                    <option value={undefined}>Brak</option>
                  )}
                  {additives.map((a) => (
                    <option value={a.id} key={a.id}>
                      {localizer(a)?.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </div>
        );
      case "Checkbox":
      default:
        return (
          <div className="mb-3">
            <Row key={group.id}>
              <Col md={12}>
                <Label for={group.id}>{localizer(group)?.currentTranslation?.title}</Label>
              </Col>
            </Row>
            {this.renderAdditivesArray(
              additives,
              group.type === "Checkbox" ? "checkbox" : "radio",
            )}
          </div>
        );
    }
  };

  renderAdditivesArray = (array, type) => {
    return array.map((a) => this.renderAdditive(a, type));
  };

  renderProductAdditives = () => {
    const { product } = this.state;
    const additives = [...this.getAdditives()];

    var groupIds = [...new Set(additives.map(({ groupId }) => groupId))]
      .map((groupId) => ({
        id: groupId,
        order: Math.max(
          ...additives
            .filter((o) => o.groupId === groupId)
            .map(
              (a) =>
                product?.productAdditives?.find((pa) => pa.additiveId === a.id)
                  ?.order || 0,
            )
            .filter((o) => o),
        ),
      }))
      .sort((a, b) => b.order - a.order)
      .map((a) => a.id);

    return (
      <React.Fragment>
        {groupIds.map((groupId) => {
          const additivesInGroup = additives
            .filter((a) => a.groupId === groupId)
            .map((additive) => ({
              additive,
              order:
                product?.productAdditives?.find(
                  (pa) => pa.additiveId === additive.id,
                )?.order || 0,
            }))
            .sort((a, b) => b.order - a.order)
            .map((a) => a.additive);
          const group = this.getAdditiveGroup(groupId);
          if (!group) {
            const additivesRender = this.renderAdditivesArray(additivesInGroup);
            return additivesRender;
          }
          return this.renderAdditiveGroup(group, additivesInGroup);
        })}
        {this.renderAdditivesArray(additives.filter((a) => !a.groupId))}
      </React.Fragment>
    );
  };

  setRealizationTimeType = (event) => {
    const e = {
      target: {
        value: event.target.value,
        name: "personalization.realizationTimeType",
        type: "text",
      },
    };
    this.changeOrderProductData(e);
  }

  renderDataCardContent = () => {
    const { statuses } = this.props;
    const { product, orderProduct, isRecalculating } = this.state;
    const loading = !orderProduct.orderId;

    if (loading) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <Label for="title">Id</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  id="id"
                  name="id"
                  value={orderProduct?.id}
                  readOnly={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="title">Numer</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  id="number"
                  name="number"
                  value={orderProduct?.number}
                  readOnly={true}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="title">
              Status
              <br />
              {!orderProduct.statusLock && (
                <small>
                  <small className="text-muted">Automatyczny</small>
                </small>
              )}
            </Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="select"
                  name="statusId"
                  value={orderProduct.statusId}
                  onChange={this.changeOrderProductData}
                >
                  <option value="00000000-0000-0000-0000-000000000000">
                    Automatyczny
                  </option>
                  {statuses
                    ?.sort((a, b) => a.tag - b.tag)
                    .map((status) => (
                      <option key={status.id} value={status.id}>
                        {localizer(status)?.currentTranslation?.title}
                      </option>
                    ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="title">Nazwa</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  id="title"
                  name="title"
                  placeholder={
                    product && product.translations
                      ? localizer(product)?.currentTranslation?.title
                      : "Nazwa"
                  }
                  value={orderProduct.title}
                  onChange={this.changeOrderProductData}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          {product && (
            <>
              <Col md={4}>
                <Label for="product">Produkt</Label>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      id="product"
                      name="product"
                      value={
                        product && product.translations
                          ? localizer(product)?.currentTranslation?.title
                          : "Niestandardowy"
                      }
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </>
          )}
          <Col md={4}>
            <Label for="product">Stawka VAT</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="select"
                  id="taxRate"
                  name="taxRate"
                  value={orderProduct.taxRate}
                  onChange={this.changeOrderProductData}
                >
                  {TaxRates.map((rate) => (
                    <option value={rate.value} key={rate.value}>
                      {rate.label}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="amount">Nakład</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="personalization.amount"
                  name="personalization.amount"
                  value={orderProduct.personalization.amount}
                  step={1}
                  min={1}
                  onChange={this.changeOrderProductData}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          {product && (
            <>
              <Col md={4}>
                <Label for="amount">Czas realizacji</Label>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="select"
                      id="personalization.realizationTimeType"
                      name="personalization.realizationTimeType"
                      value={orderProduct.personalization.realizationTimeType}
                      onChange={this.setRealizationTimeType}
                    >
                      {product?.productConfiguration?.realizationTimes
                        ?.filter(
                          (t) =>
                            t.realizationTime !== null &&
                            t.priceMultiplier !== null,
                        )
                        .map((t) => (
                          <option value={t.type} key={t.type}>
                            {t.type}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </>
          )}
          <Col md={4}>
            <Label for="price">Cena</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="price"
                  name="price"
                  value={orderProduct.price}
                  step={0.01}
                  min={0.00}
                  onChange={this.changeOrderProductData}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>PLN</InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    type="button"
                    color="primary"
                    onClick={(e) => this.getProductCalculation(e, "price")}
                    disabled={orderProduct.productId === null || isRecalculating}
                    outline
                  >
                    <i className="fas fa-calculator"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="rebate">Rabat</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="rebate"
                  name="rebate"
                  value={orderProduct.rebate}
                  onChange={this.changeOrderProductData}
                  step={0.1}
                  min={0}
                  max={100}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    type="button"
                    color="primary"
                    onClick={(e) => this.getProductRebate(e)}
                    disabled={isRecalculating}
                    outline
                  >
                    <i className="fas fa-search"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="costNet">Wartość po rabacie</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="costNet"
                  name="costNet"
                  step={0.01}
                  min={0.01}
                  max={10000000}
                  value={Math.ceil(orderProduct.price * (100 - orderProduct.rebate)) / 100}
                  onChange={this.changeOrderProductData}
                  disabled={true}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>PLN</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="costGross">Wartość brutto</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="costGross"
                  name="costGross"
                  step={0.01}
                  value={Math.ceil(orderProduct.price * (100 - orderProduct.rebate) * (1 + (orderProduct.taxRate || orderProduct.product?.taxRate || 0.23))) / 100}
                  disabled
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>PLN</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="weight">Waga</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <NumericInput
                  id="weight"
                  name="weight"
                  min={0}
                  step={0.001}
                  value={orderProduct.weight}
                  onChange={this.changeOrderProductData}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>kg</InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Button
                    type="button"
                    color="primary"
                    onClick={(e) => this.getProductCalculation(e, "weight")}
                    disabled={
                      orderProduct.productId === null || isRecalculating
                    }
                    outline
                  >
                    <i className="fas fa-calculator"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label for="realizationTerm">Termin realizacji</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <InputGroup>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Termin realizacji",
                    className: "form-control",
                  }}
                  value={
                    orderProduct.realizationTerm
                      ? new Date(orderProduct.realizationTerm)
                      : null
                  }
                  timeFormat="HH:mm"
                  dateFormat="yyyy-MM-DD"
                  onChange={(e) =>
                    this.changeOrderProductDate("realizationTerm", e)
                  }
                  name="realizationTerm"
                  className="form-control p-0 m-0 border-0"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    type="button"
                    color="primary"
                    onClick={(e) =>
                      this.getProductCalculation(e, "realizationTerm")
                    }
                    disabled={
                      orderProduct.productId === null || isRecalculating
                    }
                    outline
                  >
                    <i className="fas fa-calculator"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={12}>
            {orderProduct.technicalFeedback ? (
              <div>
                <p>
                  <b>Informacje o wycenie</b>
                </p>
                {orderProduct.technicalFeedback.split("\n").map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderDataCard = () => {
    const { orderProduct, isRecalculating } = this.state;
    const loading = !orderProduct.orderId;

    return (
      <Card>
        <CardHeader>
          <CardTitle>Podstawowe informacje</CardTitle>
        </CardHeader>
        <CardBody>{this.renderDataCardContent()}</CardBody>
        <CardFooter className="text-right">
          <Button
            type="button"
            color="primary"
            onClick={this.submitForm}
            disabled={loading}
          >
            Zapisz produkt
          </Button>
          <Button
            type="button"
            onClick={(e) => this.getProductCalculation(e, "full")}
            disabled={orderProduct.productId === null || isRecalculating}
          >
            Rekalkuluj
          </Button>
          <Button
            type="button"
            color="danger"
            onClick={this.onClickDelete}
            disabled={loading}
          >
            Usuń produkt
          </Button>
        </CardFooter>
      </Card>
    );
  };

  render = () => {
    const { order } = this.props;
    const { orderProduct } = this.state;
    const loading = !orderProduct?.id;

    if (!orderProduct) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    return (
      <Row>
        <Col lg="6">
          {this.renderDataCard()}
          {this.renderVerificationCard()}
          {loading ? (
            <React.Fragment />
          ) : (
            <OrderNotes order={order} orderProduct={orderProduct} />
          )}
        </Col>
        <Col lg="6">
          {this.renderPersonalizationCard()}
          {this.renderFilesCard()}
        </Col>
      </Row>
    );
  };

  getDefaultOptions = () => {
    return this.props.product
      .attributes
      .map(a => {
        const opts = a.options
          .map(o => ({
            id: o.id,
            order: this.props.product.productOptions.find(pa => pa.optionId === o.id)?.order || 0
          }))
          .sort((a, b) => b.order - a.order);
        return opts[0].id;
      });
  }

  getDefaultAdditives = () => {
    return this.props.product
      .additiveGroups
      .map(g => {
        const opts = g.additives
          .map(a => ({
            id: a.id,
            order: this.props.product.productAdditives.find(pa => pa.additiveId === a.id)?.order || 0
          }))
          .sort((a, b) => b.order - a.order);
        return opts[0].id;
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderProduct !== this.props.orderProduct) {
      if (this.props?.orderProduct?.id !== this.state?.orderProduct?.id) {
        if (
          this.props?.orderProduct?.productId &&
          this.props?.orderProduct?.productId !== this.state?.product?.id
        ) {
          this.props.readProduct(this.props.orderProduct.productId);
        } else {
          this.props.readAdditives();
          this.props.readOptions();
        }
      }
      this.setState({
        orderProduct: this.props.orderProduct,
      });
      if (this.state.action === "update") {
        this.props.notify(
          "Aktualizacja produktu",
          `Pomyślnie zaktualizowano produkt`,
          "success",
        );
      }
      if (this.state.action === "create") {
        this.props.notify(
          "Utworzenie produktu",
          `Pomyślnie utworzono produkt`,
          "success",
        );
      }
    }
    if (prevProps.product !== this.props.product) {
      const orderProduct = { ...this.state.orderProduct };
      if (orderProduct.personalization.options?.length !== this.props.product.attributes.length) {
        orderProduct.personalization.options = this.getDefaultOptions();
        orderProduct.personalization.additives = this.getDefaultAdditives();
      }
      this.setState({
        product: this.props.product,
        orderProduct: orderProduct
      });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
    if (prevProps.additives !== this.props.additives) {
      this.setState({
        additives: this.props.additives,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    product: state.products.current,
    orderProduct: state.orderProducts.current,
    options: state.productOptions.list,
    additives: state.productAdditives.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    readProduct: (id) => dispatch(productsActions.read(id + "?Full=true")),
    readOrderProduct: (id) => dispatch(orderProductsActions.read(id)),
    removeOrderProduct: (id) => dispatch(orderProductsActions.remove(id)),
    createOrderProduct: (body) => dispatch(orderProductsActions.create(body)),
    updateOrderProduct: (body) => dispatch(orderProductsActions.update(body)),
    readAdditives: () => dispatch(productAdditivesActions.list(0, 1000)),
    readOptions: () => dispatch(productOptionsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderProduct),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productOptionsActions from "stores/products/productOptions/actions";
import * as productAttributesActions from "stores/products/productAttributes/actions";
import * as productAdditivesActions from "stores/products/productAdditives/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
  Label,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import PrintColors from "../PrintColors";
import NumericInput from "components/Form/NumericInput";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class ProductOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      option: null,
      auth: null,
      action: "",
      attributes: [],
      session: null,
      currentCulture: this.props.session.culture,
      additives: [],
      options: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const attributeId = this.props.match.params.attributeId;
    const id = this.props.match.params.optionId;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        option: {
          id: undefined,
          attributeId: attributeId,
          hidden: false,
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listAttributes();
    this.props.listAdditives();
    this.props.listOptions();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  toggleAdditiveConflict = (event) => {
    const { value, checked } = event.target;
    let option = { ...this.state.option };
    if (checked) {
      option.additiveConflicts.push({
        additiveId: value,
      });
    } else {
      option.additiveConflicts = [
        ...option.additiveConflicts.filter((a) => a.additiveId !== value),
      ];
    }
    this.setState({ option });
  };

  toggleOptionConflict = (event) => {
    const { value, checked } = event.target;
    const parsedValue = Number(value);
    let option = { ...this.state.option };
    if (checked) {
      option.optionConflicts2.push({
        firstId: parsedValue,
      });
    } else {
      option.optionConflicts1 = [
        ...option.optionConflicts1.filter((a) => a.secondId !== parsedValue),
      ];
      option.optionConflicts2 = [
        ...option.optionConflicts2.filter((a) => a.firstId !== parsedValue),
      ];
    }
    this.setState({ option });
  };

  changeOptionData = (event) => {
    const { name, value, type } = event.target;
    var option = { ...this.state.option };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "checkbox":
        parsedValue = event.target.checked;
        break;
      case "select":
      case "select-one":
        if (parsedValue === "null") {
          parsedValue = null;
          break;
        }
        parsedValue = isNaN(Number(value)) ? value : Number(value);
        break;
      default:
        break;
    }
    if (
      name === "defineInsideSheetPriceType" ||
      name === "defineOutsideSheetPriceType"
    ) {
      parsedValue = parsedValue ? "PerKilogram" : "PerSquareMeter";
    }
    option[name] = parsedValue;
    this.setState({ option });
  };

  changeOptionTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var option = { ...this.state.option };
    if (!option.translations || option.translations.length === 0) {
      option.translations.push({ culture: currentCulture });
    }
    option.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ option });
  };

  changeOptionTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeOptionTranslationEx(name, value);
  };

  submitForm = (event) => {
    var option = { ...this.state.option };
    if (option.id) {
      this.setState({ action: "update" });
      this.props.update(option);
    } else {
      this.setState({ action: "create" });
      this.props.create(option);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.option };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ option: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.option.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.option || this.props.loading;

    const { option } = this.state;
    const { auth } = this.props;

    if (!option || option.id === auth.id || !option.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.option || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { option } = this.state;

    if (!option || !option.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={option ? option.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardAdditiveConflicts = () => {
    const { option } = this.state;

    if (!option) {
      return <></>;
    }

    const loading = this.props.loading;
    const { additives } = this.state;

    let additiveGroups = [];
    additives.forEach((additive) => {
      if (additive.group) {
        const group = additive.group;
        if (!additiveGroups.some((g) => g.id === group.id)) {
          additiveGroups.push(group);
        }
      }
    });

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Konflikty (dodatki)</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {additiveGroups.map((group) => {
              let additivesInGroup = [0, 0];
              let additivesRender = (
                <>
                  {additives
                    .filter((a) => a.groupId === group.id)
                    .sort((a, b) =>
                      (a?.currentTranslation?.title || "").localeCompare(
                        b?.currentTranslation?.title || "",
                      ),
                    )
                    .map((a) => {
                      let checked = option.additiveConflicts
                        ? option.additiveConflicts.some(
                          (pa) => pa.additiveId === a.id,
                        )
                        : false;
                      additivesInGroup[0]++;
                      if (checked) {
                        additivesInGroup[1]++;
                      }
                      return (
                        <Col md="12" key={a.id}>
                          <UncontrolledCollapse
                            toggler={`#additiveGroup${group.id}head`}
                          >
                            <FormGroup>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  id={"additive" + a.id}
                                  type="checkbox"
                                  name="additives[]"
                                  value={a.id}
                                  checked={checked}
                                  onChange={(event) =>
                                    this.toggleAdditiveConflict(event)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"additive" + a.id}
                                >
                                  {a.currentTranslation?.title || ""}
                                </label>
                              </div>
                            </FormGroup>
                          </UncontrolledCollapse>
                        </Col>
                      );
                    })}
                </>
              );
              return (
                <Row key={group.id}>
                  <Col
                    md="12"
                    className="mb-3"
                    id={`additiveGroup${group.id}head`}
                  >
                    <b>{group.currentTranslation?.title}</b>{" "}
                    {additivesInGroup[1]}/{additivesInGroup[0]}
                  </Col>
                  {additivesRender}
                </Row>
              );
            })}
            <Row>
              <Col md="12" className="mb-3" id={`additiveGroup0head`}>
                <b>Niezalezne</b>
              </Col>
              {additives
                .filter((a) => !a.group)
                .map((a) => (
                  <Col md="12" key={a.id}>
                    <FormGroup>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          id={"additive" + a.id}
                          type="checkbox"
                          name="additives[]"
                          value={a.id}
                          checked={
                            option.additiveConflicts
                              ? option.additiveConflicts.some(
                                (pa) => pa.additiveId === a.id,
                              )
                              : false
                          }
                          onChange={(event) =>
                            this.toggleAdditiveConflict(event)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"additive" + a.id}
                        >
                          {a.currentTranslation?.title || ""}
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                ))}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardOptionConflicts = () => {
    const { option } = this.state;

    if (!option) {
      return <></>;
    }

    const loading = this.props.loading;
    const { options, attributes } = this.state;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Konflikty (opcje)</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {attributes.map((attribute) => {
              let optionsInAttribute = [0, 0];
              let optionsRender = (
                <>
                  {options
                    .filter((o) => o.attributeId === attribute.id)
                    .filter((o) => o.id !== option.id)
                    .sort((a, b) =>
                      (a?.currentTranslation?.title || "").localeCompare(
                        b?.currentTranslation?.title || "",
                      ),
                    )
                    .map((o) => {
                      let checked =
                        (option.optionConflicts1
                          ? option.optionConflicts1.some(
                            (pa) => pa.secondId === o.id,
                          )
                          : false) ||
                        (option.optionConflicts2
                          ? option.optionConflicts2.some(
                            (pa) => pa.firstId === o.id,
                          )
                          : false);
                      optionsInAttribute[0]++;
                      if (checked) {
                        optionsInAttribute[1]++;
                      }
                      return (
                        <Col md="12" key={o.id}>
                          <UncontrolledCollapse
                            toggler={`#attribute${attribute.id}head`}
                          >
                            <FormGroup>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  id={"option" + o.id}
                                  type="checkbox"
                                  name="options[]"
                                  value={o.id}
                                  checked={checked}
                                  onChange={(event) =>
                                    this.toggleOptionConflict(event)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"option" + o.id}
                                >
                                  {o.currentTranslation?.title || ""}
                                </label>
                              </div>
                            </FormGroup>
                          </UncontrolledCollapse>
                        </Col>
                      );
                    })}
                </>
              );
              return (
                <Row key={attribute.id}>
                  <Col
                    md="12"
                    className="mb-3"
                    id={`attribute${attribute.id}head`}
                  >
                    <b>{attribute.currentTranslation?.title}</b>{" "}
                    {optionsInAttribute[1]}/{optionsInAttribute[0]}
                  </Col>
                  {optionsRender}
                </Row>
              );
            })}
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { option, currentCulture, attributes } = this.state;

    if (!option) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = option.translations
      ? option.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Atrybut</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="attributeId"
                  value={option.attributeId}
                  onChange={this.changeProductData}
                >
                  <option disabled value="">
                    Wybierz
                  </option>
                  {attributes.map((attribute) => (
                    <option key={attribute.id} value={attribute.id}>
                      {attribute.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeOptionTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="description"
                    value={
                      editedTranslation ? editedTranslation?.description : ""
                    }
                    onChange={this.changeOptionTranslation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardOptions = () => {
    const { option, attributes } = this.state;

    if (!option) {
      return <></>;
    }

    const loading = this.props.loading;
    const attribute = attributes.filter((a) => option.attributeId === a.id)[0];

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Właściwości opcji</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {attribute && attribute.isAffectingOnFormat ? (
              <Row>
                <Col md="4">Definiuj format</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Szerokość"
                        name="defineFormatX"
                        value={option.defineFormatX || ""}
                        onChange={(event) => this.changeOptionData(event)}
                      />
                      <NumericInput
                        placeholder="Wysokość"
                        name="defineFormatY"
                        value={option.defineFormatY || ""}
                        onChange={(event) => this.changeOptionData(event)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>mm</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnPages ? (
              <Row>
                <Col md="4">Definiuj stronicowanie</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Środka"
                        step="1"
                        name="defineInsidePages"
                        value={option.defineInsidePages || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnPages & 1) !== 1}
                        readOnly={(attribute.isAffectingOnPages & 1) !== 1}
                      />
                      <NumericInput
                        placeholder="Okładki"
                        step="1"
                        name="defineOutsidePages"
                        value={option.defineOutsidePages || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnPages & 2) !== 2}
                        readOnly={(attribute.isAffectingOnPages & 2) !== 2}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>stron</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnThickness ? (
              <Row>
                <Col md="4">Definiuj grubość</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Środka"
                        name="defineInsideThickness"
                        step={0.001}
                        value={option.defineInsideThickness || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnThickness & 1) !== 1}
                        readOnly={(attribute.isAffectingOnThickness & 1) !== 1}
                      />
                      <NumericInput
                        placeholder="Okładki"
                        name="defineOutsideThickness"
                        step={0.001}
                        value={option.defineOutsideThickness || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnThickness & 2) !== 2}
                        readOnly={(attribute.isAffectingOnThickness & 2) !== 2}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>mm</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnGrammage ? (
              <Row>
                <Col md="4">Definiuj gramaturę</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Środka"
                        name="defineInsideGrammage"
                        step={0.001}
                        value={option.defineInsideGrammage || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnGrammage & 1) !== 1}
                        readOnly={(attribute.isAffectingOnGrammage & 1) !== 1}
                      />
                      <NumericInput
                        placeholder="Okładki"
                        name="defineOutsideGrammage"
                        step={0.001}
                        value={option.defineOutsideGrammage || ""}
                        onChange={(event) => this.changeOptionData(event)}
                        disabled={(attribute.isAffectingOnGrammage & 2) !== 2}
                        readOnly={(attribute.isAffectingOnGrammage & 2) !== 2}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          g/m<sup>2</sup>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnSheetPrice ? (
              <Row>
                <Col md="6">Definiuj cenę za arkusz środka</Col>
                <Col md="6">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Cena za arkusz"
                        name="defineInsideSheetPrice"
                        step={0.01}
                        value={option.defineInsideSheetPrice || ""}
                        onChange={(event) => this.changeOptionData(event)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          m<sup>2</sup>
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <label className="custom-toggle">
                            <Input
                              type="checkbox"
                              name="defineInsideSheetPriceType"
                              onChange={(event) => this.changeOptionData(event)}
                              value={true}
                              checked={
                                option.defineInsideSheetPriceType ===
                                "PerKilogram"
                              }
                              defaultChecked={option.defineInsideSheetPriceType}
                              readOnly={
                                (attribute.isAffectingOnSheetPrice & 1) !== 1
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="m2"
                              data-label-on="kg"
                            />
                          </label>
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>kg</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnSheetPrice ? (
              <Row>
                <Col md="6">Definiuj cenę za arkusz okładki</Col>
                <Col md="6">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Cena za arkusz"
                        name="defineOutsideSheetPrice"
                        step={0.01}
                        value={option.defineOutsideSheetPrice || ""}
                        onChange={(event) => this.changeOptionData(event)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          m<sup>2</sup>
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <label className="custom-toggle">
                            <Input
                              type="checkbox"
                              name="defineOutsideSheetPriceType"
                              onChange={(event) => this.changeOptionData(event)}
                              value={true}
                              checked={
                                option.defineOutsideSheetPriceType ===
                                "PerKilogram"
                              }
                              defaultChecked={
                                option.defineOutsideSheetPriceType
                              }
                              readOnly={
                                (attribute.isAffectingOnSheetPrice & 2) !== 2
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="m2"
                              data-label-on="kg"
                            />
                          </label>
                        </InputGroupText>
                      </InputGroupAddon>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>kg</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnColor ? (
              <Row>
                <Col md="4">Definiuj kolorystykę</Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      <small className="text-uppercase text-muted">
                        Wnętrza
                      </small>
                    </Label>
                    <Input
                      type="select"
                      name="defineInsideColor"
                      onChange={(event) => this.changeOptionData(event)}
                      value={option.defineInsideColor}
                      disabled={(attribute.isAffectingOnColor & 1) !== 1}
                      readOnly={(attribute.isAffectingOnColor & 1) !== 1}
                    >
                      <option value={"null"}>Brak</option>
                      {PrintColors.map((color) => (
                        <option key={color.value} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      <small className="text-uppercase text-muted">
                        Okładki
                      </small>
                    </Label>
                    <Input
                      type="select"
                      name="defineOutsideColor"
                      onChange={(event) => this.changeOptionData(event)}
                      value={option.defineOutsideColor}
                      disabled={(attribute.isAffectingOnColor & 2) !== 2}
                      readOnly={(attribute.isAffectingOnColor & 2) !== 2}
                    >
                      <option value={"null"}>Brak</option>
                      {PrintColors.map((color) => (
                        <option key={color.value} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnAlternateColor ? (
              <Row>
                <Col md="4"></Col>
                <Col md="8">
                  <small className="text-uppercase">
                    Kolorystyka drugorzędna
                  </small>
                </Col>
                <Col md="4"></Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      <small className="text-uppercase text-muted">
                        Wnętrza
                      </small>
                    </Label>
                    <Input
                      type="select"
                      name="defineInsideAlternateColor"
                      onChange={(event) => this.changeOptionData(event)}
                      value={option.defineInsideAlternateColor}
                      disabled={
                        (attribute.isAffectingOnAlternateColor & 1) !== 1
                      }
                      readOnly={
                        (attribute.isAffectingOnAlternateColor & 1) !== 1
                      }
                    >
                      <option value={"null"}>Brak</option>
                      {PrintColors.map((color) => (
                        <option key={color.value} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      <small className="text-uppercase text-muted">
                        Okładki
                      </small>
                    </Label>
                    <Input
                      type="select"
                      name="defineOutsideAlternateColor"
                      onChange={(event) => this.changeOptionData(event)}
                      value={option.defineOutsideAlternateColor}
                      disabled={
                        (attribute.isAffectingOnAlternateColor & 2) !== 2
                      }
                      readOnly={
                        (attribute.isAffectingOnAlternateColor & 2) !== 2
                      }
                    >
                      <option value={"null"}>Brak</option>
                      {PrintColors.map((color) => (
                        <option key={color.value} value={color.value}>
                          {color.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnModel ? (
              <Row>
                <Col md="4">Definiuj model</Col>
                <Col md="8">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="defineModel"
                      type="file"
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
            {attribute && attribute.isAffectingOnTemplate ? (
              <Row>
                <Col md="4">Definiuj szablon</Col>
                <Col md="8">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="defineTemplate"
                      type="file"
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.option || this.props.loading;
    const attributeId = this.props.match.params.attributeId;

    const { option } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return (
        <Redirect to={`/admin/products/attributes/${attributeId}/options`} />
      );
    }

    return (
      <>
        <SimpleHeader
          name={option ? option.currentTranslation?.title : ""}
          link={`/admin/products/attributes/${attributeId}/options`}
          parentName="Opcje produktów"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardAdditiveConflicts()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardOptions()}
                {this.renderCardOptionConflicts()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.option !== this.props.option) {
      this.setState({
        option: this.props.option,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.attributes !== this.props.attributes) {
      this.setState({
        attributes: this.props.attributes,
      });
    }
    if (prevProps.additives !== this.props.additives) {
      this.setState({
        additives: this.props.additives,
      });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    option: state.productOptions.current,
    loading: state.productOptions.loading,
    attributes: state.productAttributes.list,
    options: state.productOptions.list,
    additives: state.productAdditives.list,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(productOptionsActions.read(id)),
    remove: (id) => dispatch(productOptionsActions.remove(id)),
    update: (option) => dispatch(productOptionsActions.update(option)),
    create: (option) => dispatch(productOptionsActions.create(option)),
    listAttributes: () => dispatch(productAttributesActions.list(0, 1000)),
    listAdditives: () => dispatch(productAdditivesActions.list(0, 1000)),
    listOptions: () => dispatch(productOptionsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductOption),
);

import React, { createRef } from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as customersActions from "stores/orders/customers/actions";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Button, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import CultureFlag from "views/pages/products/products/Cards/CultureFlag";

import Search from "components/Table/Search";
import TablePrint from "components/Table/TablePrint";
import apiDriver from "stores/api.driver";
import config from "config/global";

class CustomersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      orders: [],
      meta: {},
      filter: [],
      redirect: null,
      type: this.props.type || [],
      tableState: {},
    };
    if (!this.state.orders.list) {
      this.props.list(0, 10, undefined, this.getFilters());
    }
    this.tableRef = createRef();
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.type.length !== this.state.type.length) {
      this.onTableChange("type", this.state.tableState);
    }
  };

  getFilters = (state) => {
    const { auth } = this.props;
    const staticFilters = this.props.filters ? { ...this.props.filters } : [];
    const typeFilters = Object.assign(
      {},
      ...[...this.state.type]
        .map((type) => {
          if (type === "assigned") {
            return {
              assigneeId: {
                filterVal: auth.id,
                filterType: "Guid",
                comparator: "=",
                caseSensitive: false,
                filterKey: "assigneeId",
              },
            };
          }
          return null;
        })
        .filter((a) => a),
    );
    const dynamicFilters = state && state.filters ? { ...state.filters } : [];
    const filters = { ...dynamicFilters, ...typeFilters, ...staticFilters };
    return filters;
  };

  onTableChange = (type, newState) => {
    const filters = this.getFilters(newState);

    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: filters,
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
      tableState: { ...newState },
    });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/customers/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/customers/" + row.id });
  };

  onClickBalanceNotify = (row) => {
    apiDriver.get(`${config.api.accountancy}pl/BuyersData/ByUser/${row.id}/BalanceNotify`)
      .subscribe({
        next: (result) => alert('Wysłano!'),
        error: (e) => alert('Bład')
      });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
        <Button
          size="sm"
          color="warning"
          className="d-print-none"
          title="Powiadom o saldzie"
          onClick={() => {
            this.onClickBalanceNotify(row);
          }}
        >
          <i className="fas fa-file-invoice"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  toggleType = (type) => {
    let newType = [...this.state.type];
    if (type) {
      if (newType.includes(type)) {
        newType = [...newType.filter((t) => t !== type)];
      } else {
        newType.push(type);
      }
    } else {
      newType = [];
    }
    this.setState({ type: newType });
  };

  render = () => {
    const { redirect, type } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { data, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "culture",
        text: "Język",
        sort: true,
        formatter: (cell) => <CultureFlag code={cell} height="12" className="mr-3" />
      },
      {
        dataField: "username",
        text: "Nazwa",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "givenName",
        text: "Imie",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "surname",
        text: "Nazwisko",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "email",
        text: "Adres e-mail",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={columns}
        search
        bootstrap4={true}
      >
        {(props) => {
          return (
            <>
              <Row>
                <Col xs={3} md="auto">
                  <TablePrint toPrint={this.tableRef} title="Dokumenty sprzedazy" />
                </Col>
                <Col xs={12} md={3}>
                  <Search className="form-control" placeholder="Szukaj..." {...props.searchProps} />
                </Col>
                <Col xs={12} md="auto">
                  <Nav pills fill>
                    <NavItem onClick={() => this.toggleType()}>
                      <NavLink href="#" active={type.length === 0}>
                        Wszyscy
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={type.includes("consumers")}
                        disabled={true}
                      >
                        Konsumenci
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        active={type.includes("business")}
                        disabled={true}
                      >
                        Firmy
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.toggleType("assigned")}>
                      <NavLink href="#" active={type.includes("assigned")}>
                        Pod moją opieką
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <PaginationProvider pagination={paginationFactory(pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <BootstrapTable
                    ref={this.tableRef}
                    remote
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    id="react-bs-table"
                    onTableChange={this.onTableChange}
                    filter={filterFactory()}
                    wrapperClasses="table-responsive"
                    {...paginationTableProps}
                    noDataIndication={() => (
                      <div className="text-center text-muted py-5">
                        <i className="fas fa-database fa-5x d-block mb-3"></i>
                        Brak danych
                      </div>
                    )}
                    defaultSorted={[{ dataField: "created", order: "desc" }]}
                  />
                )}
              </PaginationProvider>
            </>
          );
        }}
      </ToolkitProvider>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.customers.list,
    loading: state.customers.loading,
    auth: state.auth.user,
    meta: state.customers.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) =>
      dispatch(customersActions.list(skip, take, searchText, filters, order)),
    read: (id) => dispatch(customersActions.read(id)),
    remove: (id) => dispatch(customersActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomersTable),
);

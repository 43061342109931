import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as rolesActions from "stores/iam/roles/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

Dropzone.autoDiscover = false;

class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      role: null,
      auth: null,
      action: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        role: {
          id: undefined,
          name: "",
        },
        loading: false,
      });
    }
  };

  changeRoleData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var role = { ...this.state.role };
    role[name] = value;
    this.setState({ role });
  };

  submitForm = (event) => {
    var role = { ...this.state.role };
    if (role.id) {
      this.setState({ action: "update" });
      this.props.update(role);
    } else {
      this.setState({ action: "create" });
      this.props.create(role);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.role.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.role || this.props.loading;

    const { role } = this.state;
    const { auth } = this.props;

    if (!role || role.id === auth.id || !role.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.role || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { role } = this.state;

    if (!role || !role.id) {
      return <></>;
    }

    return (
      <Col md="12">
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-tag" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Id"
              type="text"
              name="id"
              value={role ? role.id : ""}
              readOnly={true}
            />
          </InputGroup>
        </FormGroup>
      </Col>
    );
  };

  renderCardData = () => {
    const { role } = this.state;

    if (!role) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nazwa"
                      type="text"
                      name="name"
                      value={role ? role.name : ""}
                      onChange={this.changeRoleData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.role || this.props.loading;

    const { role } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/cp/roles/" />;
    }

    return (
      <>
        <SimpleHeader
          name={role ? role.name : ""}
          link="/admin/cp/roles"
          parentName="Role"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.setState({
        role: this.props.role,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    role: state.roles.current,
    loading: state.roles.loading,
    auth: state.auth.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(rolesActions.read(id)),
    remove: (id) => dispatch(rolesActions.remove(id)),
    update: (role) => dispatch(rolesActions.update(role)),
    create: (role) => dispatch(rolesActions.create(role)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Role));

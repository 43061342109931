import config from "config/global";

import Lock from "views/pages/auth/Lock.js";
import Login from "views/pages/auth/Login.js";

import Employees from "views/pages/cp/employees/Employees";
import Employee from "views/pages/cp/employees/Employee";
import Roles from "views/pages/cp/roles/Roles";
import Role from "views/pages/cp/roles/Role";

import NotificationTypes from "views/pages/cp/notificationTypes/NotificationTypes";
import NotificationType from "views/pages/cp/notificationTypes/NotificationType";

import Pages from "views/pages/cms/pages/Pages";
import Page from "views/pages/cms/pages/Page";
import Galleries from "views/pages/cms/galleries/Galleries";
import Gallery from "views/pages/cms/galleries/Gallery";
import GalleryCategories from "views/pages/cms/galleryCategories/Categories";
import GalleryCategory from "views/pages/cms/galleryCategories/Category";

import Diets from "views/pages/bookings/diets/Diets";
import Diet from "views/pages/bookings/diets/Diet";
import PersonTypes from "views/pages/bookings/personTypes/PersonTypes";
import PersonType from "views/pages/bookings/personTypes/PersonType";
import Rooms from "views/pages/bookings/rooms/Rooms";
import Room from "views/pages/bookings/rooms/Room";
import RoomType from "views/pages/bookings/roomTypes/RoomType";
import RoomTypes from "views/pages/bookings/roomTypes/RoomTypes";
import RoomAddition from "views/pages/bookings/roomAdditions/RoomAddition";
import RoomAdditions from "views/pages/bookings/roomAdditions/RoomAdditions";
import Bookings from "views/pages/bookings/bookings/Bookings";
import Booking from "views/pages/bookings/bookings/Booking";

import Products from "views/pages/products/products/Products";
import Product from "views/pages/products/products/Product";
import ProductCategories from "views/pages/products/productCategories/Categories";
import ProductCategory from "views/pages/products/productCategories/Category";
import ProductAttributes from "views/pages/products/productAttributes/Attributes";
import ProductAttribute from "views/pages/products/productAttributes/Attribute";
import ProductOptions from "views/pages/products/productAttributes/Options";
import ProductOption from "views/pages/products/productAttributes/Option";
import AdditiveGroup from "views/pages/products/productAdditiveGroups/AdditiveGroup";
import AdditiveGroups from "views/pages/products/productAdditiveGroups/AdditiveGroups";
import ProductAdditive from "views/pages/products/productAdditives/Additive";
import ProductAdditives from "views/pages/products/productAdditives/Additives";

import ShippingMethod from "views/pages/orders/shippingMethods/Method";
import ShippingMethods from "views/pages/orders/shippingMethods/Methods";
import CollectPoint from "views/pages/orders/collectPoints/Point";
import CollectPoints from "views/pages/orders/collectPoints/Points";
import Shipping from "views/pages/orders/shippings/Shipping";
import Shippings from "views/pages/orders/shippings/Shippings";

import PaymentMethod from "views/pages/orders/paymentMethods/Method";
import PaymentMethods from "views/pages/orders/paymentMethods/Methods";
import Payment from "views/pages/orders/payments/Payment";
import Payments from "views/pages/orders/payments/Payments";
import Coupon from "views/pages/orders/coupons/Coupon";
import Coupons from "views/pages/orders/coupons/Coupons";
import VerificationTemplate from "views/pages/orders/verifcationTemplates/VerificationTemplate";
import VerificationTemplates from "views/pages/orders/verifcationTemplates/VerificationTemplates";
import Orders from "views/pages/orders/orders/Orders";
import Order from "views/pages/orders/orders/Order";
import Invoices from "views/pages/invoices/invoices/Invoices";
import Invoice from "views/pages/invoices/invoices/Invoice";
import Customers from "views/pages/orders/customer/Customers";
import Customer from "views/pages/orders/customer/Customer";
import PrintFormat from "views/pages/products/printFormats/PrintFormat";
import PrintFormats from "views/pages/products/printFormats/PrintFormats";
import Business from "views/pages/orders/businesses/Business";
import Businesses from "views/pages/orders/businesses/Businesses";

import CustomPricings from "views/pages/orders/customPricings/CustomPricings";

import SellerData from "views/pages/invoices/sellersData/SellerData";
import SellersData from "views/pages/invoices/sellersData/SellersData";

import InvoiceSerie from "views/pages/invoices/invoiceSeries/InvoiceSerie";
import InvoiceSeries from "views/pages/invoices/invoiceSeries/InvoiceSeries";

import Alternative from "views/pages/dashboards/Alternative";
import CustomPricing from "views/pages/orders/customPricings/CustomPricing";

const routes = [
  {
    collapse: true,
    name: "Dashboard",
    icon: "ni ni-compass-04 text-primary",
    state: "dashboardsCollapse",
    display: true,
    views: [
      {
        path: "/dashboard",
        name: "Strona główna",
        miniName: "D",
        component: Alternative,
        layout: "/admin",
        display: true,
      },
    ],
  },
  {
    collapse: true,
    name: "CMS",
    icon: "fa fa-edit text-primary",
    state: "cmsCollapse",
    display: config.support.cms !== undefined,
    views: [
      {
        path: "/cms/pages/:id",
        name: "Strona",
        miniName: "P",
        component: Page,
        layout: "/admin",
      },
      {
        path: "/cms/pages",
        name: "Strony",
        miniName: "P",
        component: Pages,
        layout: "/admin",
        display: config.support.cms.pages,
      },
      {
        path: "/cms/posts/:id",
        name: "Post",
        miniName: "P",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/cms/posts",
        name: "Posty",
        miniName: "P",
        component: Employees,
        layout: "/admin",
        display: config.support.cms.blog,
      },
      {
        path: "/cms/posts/categories/:id",
        name: "Kategoria postów",
        miniName: "PC",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/cms/posts/categories/",
        name: "Kategorie postów",
        miniName: "PC",
        component: Employees,
        layout: "/admin",
      },
      {
        path: "/cms/galleries/categories/:id",
        name: "Kategoria galerii",
        miniName: "PG",
        component: GalleryCategory,
        layout: "/admin",
      },
      {
        path: "/cms/galleries/categories/",
        name: "Kategorie galerii",
        miniName: "PG",
        component: GalleryCategories,
        layout: "/admin",
      },
      {
        path: "/cms/galleries/:id",
        name: "Galeria",
        miniName: "G",
        component: Gallery,
        layout: "/admin",
      },
      {
        path: "/cms/galleries",
        name: "Galerie",
        miniName: "G",
        component: Galleries,
        layout: "/admin",
        display: config.support.cms.gallery,
      },
      {
        path: "/cms/slides/:id",
        name: "Slajd",
        miniName: "PG",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/cms/slides/",
        name: "Slajdy",
        miniName: "S",
        component: Employees,
        layout: "/admin",
        display: config.support.cms.slides,
      },
    ],
  },
  {
    collapse: true,
    name: "CRM",
    icon: "fa fa-envelope text-primary",
    state: "crmCollapse",
    display: config.support.crm !== undefined,
    views: [
      {
        path: "/crm/tickets/:id",
        name: "Zgłoszenie",
        miniName: "T",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/crm/tickets",
        name: "Zgłoszenia",
        miniName: "T",
        component: Employees,
        layout: "/admin",
        display: config.support.crm && config.support.crm.tickets,
      },
      {
        path: "/crm/forms/:id",
        name: "Formularz",
        miniName: "F",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/crm/forms",
        name: "Formularze",
        miniName: "F",
        component: Employees,
        layout: "/admin",
        display: config.support.crm && config.support.crm.forms,
      },
      {
        path: "/crm/newsletters/:id",
        name: "Newsletter",
        miniName: "N",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/crm/newsletters",
        name: "Newslettery",
        miniName: "N",
        component: Employees,
        layout: "/admin",
        display: config.support.crm && config.support.crm.newsletter,
      },
      {
        path: "/crm/templates/:id",
        name: "Szablon powiadomień",
        miniName: "T",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/crm/templates",
        name: "Szablony powiadomień",
        miniName: "T",
        component: Employees,
        layout: "/admin",
        display: config.support.crm && config.support.crm.notifications,
      },
    ],
  },
  {
    collapse: true,
    name: "Sprzedaz",
    icon: "fa fa-shopping-cart text-primary",
    state: "ordersCollapse",
    display: config.support.sale !== undefined,
    views: [
      {
        path: "/orders/verificationTemplates/:id",
        name: "Szablon weryfikacyjny",
        miniName: "T",
        component: VerificationTemplate,
        layout: "/admin",
      },
      {
        path: "/orders/verificationTemplates/",
        name: "Szablony weryfikacyjne",
        miniName: "T",
        component: VerificationTemplates,
        layout: "/admin",
        display: config.support.sale && config.support.sale.verification,
      },
      {
        path: "/orders/coupons/:id",
        name: "Kupon rabatowy",
        miniName: "C",
        component: Coupon,
        layout: "/admin",
      },
      {
        path: "/orders/coupons/",
        name: "Kupony rabatowe",
        miniName: "C",
        component: Coupons,
        layout: "/admin",
        display: config.support.sale && config.support.sale.coupons,
      },
      {
        path: "/orders/orders/:id",
        name: "Zamówienie",
        miniName: "O",
        component: Order,
        layout: "/admin",
      },
      {
        path: "/orders/orders/",
        name: "Zamówienia",
        miniName: "O",
        component: Orders,
        layout: "/admin",
        display: config.support.sale !== undefined,
      },
      {
        path: "/orders/customPricings/:id",
        name: "Wycena niestandardowa",
        miniName: "C",
        component: CustomPricing,
        layout: "/admin",
      },
      {
        path: "/orders/customPricings/",
        name: "Wyceny niestandardowe",
        miniName: "C",
        component: CustomPricings,
        layout: "/admin",
        display: config.support.sale !== undefined,
      },
    ],
  },
  {
    collapse: true,
    name: "Rezerwacje",
    icon: "fa fa-file-invoice text-primary",
    state: "bookingCollapse",
    display: config.support.booking !== undefined,
    views: [
      {
        path: "/booking/bookings/:id",
        name: "Rezerwacja",
        miniName: "B",
        component: Booking,
        layout: "/admin",
      },
      {
        path: "/booking/bookings/",
        name: "Rezerwacje",
        miniName: "B",
        component: Bookings,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
      {
        path: "/booking/rooms/types/:id",
        name: "Rodzaj zakwaterowania",
        miniName: "T",
        component: RoomType,
        layout: "/admin",
      },
      {
        path: "/booking/rooms/types/",
        name: "Rodzaje zakwaterowania",
        miniName: "T",
        component: RoomTypes,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
      {
        path: "/booking/rooms/additions/:id",
        name: "Dodatek zakwaterowania",
        miniName: "A",
        component: RoomAddition,
        layout: "/admin",
      },
      {
        path: "/booking/rooms/additions/",
        name: "Dodatki zakwaterowania",
        miniName: "A",
        component: RoomAdditions,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
      {
        path: "/booking/rooms/:id",
        name: "Zakwaterowanie",
        miniName: "R",
        component: Room,
        layout: "/admin",
      },
      {
        path: "/booking/rooms/",
        name: "Zakwaterowania",
        miniName: "R",
        component: Rooms,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
      {
        path: "/booking/diets/:id",
        name: "Dieta",
        miniName: "D",
        component: Diet,
        layout: "/admin",
      },
      {
        path: "/booking/diets/",
        name: "Diety",
        miniName: "D",
        component: Diets,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
      {
        path: "/booking/personTypes/:id",
        name: "Kategoria wiekowa",
        miniName: "R",
        component: PersonType,
        layout: "/admin",
      },
      {
        path: "/booking/personTypes/",
        name: "Kategorie wiekowe",
        miniName: "R",
        component: PersonTypes,
        layout: "/admin",
        display: config.support.booking !== undefined,
      },
    ],
  },
  {
    collapse: true,
    name: "Księgowość",
    icon: "fa fa-file-invoice text-primary",
    state: "accountancyCollapse",
    display: config.support.accountancy !== undefined,
    views: [
      {
        path: "/accountancy/invoices/:id",
        name: "Dokument sprzedazy",
        miniName: "I",
        component: Invoice,
        layout: "/admin",
      },
      {
        path: "/accountancy/invoices/",
        name: "Dokumenty sprzedazy",
        miniName: "I",
        component: Invoices,
        layout: "/admin",
        display:
          config.support.accountancy && config.support.accountancy.invoices,
      },
      {
        path: "/accountancy/sellersData/:id",
        name: "Dane sprzedawcy",
        miniName: "T",
        component: SellerData,
        layout: "/admin",
      },
      {
        path: "/accountancy/sellersData/",
        name: "Dane sprzedawcy",
        miniName: "T",
        component: SellersData,
        layout: "/admin",
        display:
          config.support.accountancy && config.support.accountancy.payments,
      },
      {
        path: "/accountancy/invoiceSeries/:id",
        name: "Seria dokumentów sprzedazy",
        miniName: "T",
        component: InvoiceSerie,
        layout: "/admin",
      },
      {
        path: "/accountancy/invoiceSeries/",
        name: "Serie dokumentów sprzedazy",
        miniName: "T",
        component: InvoiceSeries,
        layout: "/admin",
        display:
          config.support.accountancy && config.support.accountancy.invoices,
      },
    ],
  },
  {
    collapse: true,
    name: "Płatności",
    icon: "fa fa-credit-card text-primary",
    state: "paymentCollapse",
    display: config.support.payment !== undefined,
    views: [
      {
        path: "/payment/payments/:id",
        name: "Płatność",
        miniName: "I",
        component: Payment,
        layout: "/admin",
      },
      {
        path: "/payment/payments/",
        name: "Płatności",
        miniName: "O",
        component: Payments,
        layout: "/admin",
        display: config.support.payment && config.support.payment.payment,
      },
      {
        path: "/payment/methods/:id",
        name: "Metoda płatności",
        miniName: "T",
        component: PaymentMethod,
        layout: "/admin",
      },
      {
        path: "/payment/methods/",
        name: "Metody płatności",
        miniName: "T",
        component: PaymentMethods,
        layout: "/admin",
        display: config.support.payment && config.support.payment.payment,
      },
    ],
  },
  {
    collapse: true,
    name: "Wysyłka",
    icon: "fa fa-truck text-primary",
    state: "shippingCollapse",
    display: config.support.shipping !== undefined,
    views: [
      {
        path: "/shipping/shippings/:id",
        name: "Dostawa",
        miniName: "I",
        component: Shipping,
        layout: "/admin",
      },
      {
        path: "/shipping/shippings/",
        name: "Dostawy",
        miniName: "O",
        component: Shippings,
        layout: "/admin",
        display: config.support.shipping && config.support.shipping.shipping,
      },
      {
        path: "/shipping/methods/:id",
        name: "Metoda dostawy",
        miniName: "T",
        component: ShippingMethod,
        layout: "/admin",
      },
      {
        path: "/shipping/methods/",
        name: "Metody dostawy",
        miniName: "T",
        component: ShippingMethods,
        layout: "/admin",
        display: config.support.shipping && config.support.shipping.shipping,
      },
      {
        path: "/shipping/collectPoints/:id",
        name: "Punkt odbioru",
        miniName: "T",
        component: CollectPoint,
        layout: "/admin",
      },
      {
        path: "/shipping/collectPoints/",
        name: "Punkty odbioru",
        miniName: "T",
        component: CollectPoints,
        layout: "/admin",
        display: config.support.shipping && config.support.shipping.collect,
      },
    ],
  },
  {
    collapse: true,
    name: "Baza produktów",
    icon: "fa fa-cubes text-primary",
    state: "productsCollapse",
    display: config.support.sale !== undefined,
    views: [
      {
        path: "/products/additives/groups/:id",
        name: "Grupa dodatków",
        miniName: "C",
        component: AdditiveGroup,
        layout: "/admin",
      },
      {
        path: "/products/additives/groups",
        name: "Grupy dodatków",
        miniName: "C",
        component: AdditiveGroups,
        layout: "/admin",
      },
      {
        path: "/products/additives/:id",
        name: "Dodatek",
        miniName: "C",
        component: ProductAdditive,
        layout: "/admin",
      },
      {
        path: "/products/additives/",
        name: "Dodatki",
        miniName: "C",
        component: ProductAdditives,
        layout: "/admin",
        display:
          config.support.sale !== undefined &&
          config.support.sale.products !== undefined &&
          config.support.sale.products.attributes,
      },
      {
        path: "/products/attributes/:attributeId/options/:optionId",
        name: "Opcja",
        miniName: "C",
        component: ProductOption,
        layout: "/admin",
      },
      {
        path: "/products/attributes/:attributeId/options",
        name: "Opcje",
        miniName: "C",
        component: ProductOptions,
        layout: "/admin",
      },
      {
        path: "/products/attributes/:id",
        name: "Atrybut",
        miniName: "C",
        component: ProductAttribute,
        layout: "/admin",
      },
      {
        path: "/products/attributes/",
        name: "Atrybuty",
        miniName: "C",
        component: ProductAttributes,
        layout: "/admin",
        display:
          config.support.sale !== undefined &&
          config.support.sale.products !== undefined &&
          config.support.sale.products.attributes,
      },
      {
        path: "/products/categories/:id",
        name: "Kategoria",
        miniName: "C",
        component: ProductCategory,
        layout: "/admin",
      },
      {
        path: "/products/categories/",
        name: "Kategorie",
        miniName: "C",
        component: ProductCategories,
        layout: "/admin",
        display:
          config.support.sale !== undefined &&
          config.support.sale.products !== undefined &&
          config.support.sale.products.categories,
      },
      {
        path: "/products/printFormats/:id",
        name: "Format druku",
        miniName: "F",
        component: PrintFormat,
        layout: "/admin",
      },
      {
        path: "/products/printFormats/",
        name: "Formaty druku",
        miniName: "F",
        component: PrintFormats,
        layout: "/admin",
        display:
          config.support.sale !== undefined &&
          config.support.sale.products !== undefined &&
          config.support.sale.products.categories,
      },
      {
        path: "/products/:id",
        name: "Produkt",
        miniName: "P",
        component: Product,
        layout: "/admin",
      },
      {
        path: "/products/",
        name: "Produkty",
        miniName: "P",
        component: Products,
        layout: "/admin",
        display: config.support.sale !== undefined,
      },
    ],
  },
  {
    collapse: true,
    name: "Baza klientow",
    icon: "fa fa-users text-primary",
    state: "customersCollapse",
    display: config.support.sale !== undefined,
    views: [
      {
        path: "/customers/businesses/:id",
        name: "Przedsiebiorstwo",
        miniName: "C",
        component: Business,
        layout: "/admin",
      },
      {
        path: "/customers/businesses",
        name: "Przedsiebiorstwa",
        miniName: "C",
        component: Businesses,
        layout: "/admin",
        display: true,
      },
      {
        path: "/customers/:id",
        name: "Klient",
        miniName: "C",
        component: Customer,
        layout: "/admin",
      },
      {
        path: "/customers",
        name: "Klienci",
        miniName: "C",
        component: Customers,
        layout: "/admin",
        display: true,
      },
    ],
  },
  {
    collapse: true,
    name: "System",
    icon: "fa fa-cog text-primary",
    state: "systemCollapse",
    display: true,
    views: [
      {
        path: "/cp/users/:id",
        name: "Uzytkownik",
        miniName: "U",
        component: Employee,
        layout: "/admin",
      },
      {
        path: "/cp/users/",
        name: "Uzytkownicy",
        miniName: "U",
        component: Employees,
        layout: "/admin",
        display: true,
      },
      {
        path: "/cp/roles/:id",
        name: "Rola",
        miniName: "R",
        component: Role,
        layout: "/admin",
      },
      {
        path: "/cp/roles/",
        name: "Role",
        miniName: "R",
        component: Roles,
        layout: "/admin",
        display: true,
      },
      {
        path: "/cp/notificationTypes/:id",
        name: "Powiadomienie",
        miniName: "N",
        component: NotificationType,
        layout: "/admin",
      },
      {
        path: "/cp/notificationTypes/",
        name: "Powiadomienia",
        miniName: "N",
        component: NotificationTypes,
        layout: "/admin",
        display: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
    ],
  },
];

export default routes;

import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { store } from "./store";
import * as authActions from "stores/iam/auth/actions";

export let defaultHeaders = () => {
  const state = store.getState();
  return {
    Authorization: "Bearer " + state.auth.token,
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Results-Count-Total, Results-Count-Filtered, Results-Pages, Results-Skip, Results-Take",
    "Access-Control-Expose-Headers":
      "Results-Count-Total, Results-Count-Filtered, Results-Pages, Results-Skip, Results-Take",
  };
};

const catchHttpErrorsPipe = catchError((error) => {
  if (error.status === 401) {
    store.dispatch(authActions.logout());
    document.location.href = "/auth/login";
  } else {
    console.error(`#${error.status}: ${error.message}`);
    document.location.href = "/";
  }
  return {};
});

const apiDriver = {
  get: (url, headers) =>
    ajax
      .get(url, Object.assign({}, defaultHeaders(), headers))
      .pipe(catchHttpErrorsPipe),
  post: (url, data, headers) =>
    ajax
      .post(
        url,
        JSON.stringify(data),
        Object.assign({}, defaultHeaders(), headers),
      )
      .pipe(catchHttpErrorsPipe),
  patch: (url, data, headers) =>
    ajax
      .patch(
        url,
        JSON.stringify(data),
        Object.assign({}, defaultHeaders(), headers),
      )
      .pipe(catchHttpErrorsPipe),
  put: (url, data, headers) =>
    ajax
      .put(
        url,
        JSON.stringify(data),
        Object.assign({}, defaultHeaders(), headers),
      )
      .pipe(catchHttpErrorsPipe),
  upload: (url, file, name, headers = {}) => {
    const progressSubscriber = new Subject();
    let headersMerge = Object.assign({}, defaultHeaders(), {
      "Content-Type": "multipart/form-data",
    });

    delete headersMerge["Content-Type"];

    const data = new FormData();
    data.append("file", file, name);

    const attrs = {
      method: "POST",
      url: url,
      body: data,
      headers: headersMerge,
      progressSubscriber,
    };

    const request = ajax(attrs);
    return [request, progressSubscriber];
  },
  uploadPresigned: (url, file, headers = {}) => {
    const progressSubscriber = new Subject();

    const data = new FormData();
    data.append("file", file);

    const attrs = {
      method: "PUT",
      url: url,
      body: file,
      headers: { "Content-Type": "multipart/form-data" },
      progressSubscriber,
    };

    const request = ajax(attrs);
    return [request, progressSubscriber];
  },
  remove: (url, headers) =>
    ajax
      .delete(url, Object.assign({}, defaultHeaders(), headers))
      .pipe(catchHttpErrorsPipe),
  removeDirect: (url, headers) =>
    ajax
      .delete(url, Object.assign({}, defaultHeaders(), headers)),
  download: (name, url, headers, body, method = 'GET') =>
    ajax({
      url: url,
      headers: Object.assign({}, defaultHeaders(), headers),
      responseType: "blob",
      body: body ? JSON.stringify(body) : undefined,
      method: method ?? "GET"
    })
      .pipe(catchHttpErrorsPipe)
      .pipe(
        map((response) => {
          if (response.response && response.status === 200) {
            var blob = new Blob([response.response], {
              type: response.response.type,
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            link.click();
          }
          return response;
        }),
      ),
  buildIndexAttributes: (payload) => {
    let attributes = [];
    if (payload.skip) {
      attributes.push("skip=" + payload.skip);
    }
    if (payload.take) {
      attributes.push("take=" + payload.take);
    }
    if (payload.filters) {
      let filterKeys = Object.keys({ ...payload.filters });
      let filterValues = filterKeys.map((i) => ({
        ...payload.filters[i],
        filterKey: i,
      }));
      attributes.push(
        "filters=" + encodeURIComponent(JSON.stringify(filterValues)),
      );
    }
    if (payload.order) {
      attributes.push(
        "order=" + encodeURIComponent(JSON.stringify(payload.order)),
      );
    }
    if (payload.searchText) {
      attributes.push("searchText=" + encodeURIComponent(payload.searchText));
    }
    if (attributes.length === 0) {
      return "";
    }
    return "?" + attributes.join("&");
  },
};

export default apiDriver;

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productsActions from "stores/products/products/actions";
import * as productCategoriesActions from "stores/products/productCategories/actions";
import * as productAdditivesActions from "stores/products/productAdditives/actions";
import * as productAdditiveGroupsActions from "stores/products/productAdditiveGroups/actions";
import * as productAttributesActions from "stores/products/productAttributes/actions";
import * as productOptionsActions from "stores/products/productOptions/actions";
import * as printFormatsActions from "stores/products/printFormats/actions";

import {
  EColor,
  EProductPagesType,
  EProductPricingType,
  EProductWeightType,
} from "stores/products/products/model";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Table,
  Alert,
  Label,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import TagsInput from "components/TagsInput/TagsInput.js";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import config from "config/global.ts";
import { API_URL } from "stores/products/products/epics";
import apiDriver, { defaultHeaders } from "stores/api.driver";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import ProductData from "./Cards/Data";
import ProductOptions from "./Cards/Options";
import ProductAdditives from "./Cards/Additives";
import Uploader from "views/pages/orders/orders/cards/Uploader";
import NumericInput from "components/Form/NumericInput";
import translator from "services/translator/translator";
import Editor from "components/Editor/Editor";

Dropzone.autoDiscover = false;

const SheetPriceType = {
  PerSquareMeter: "PerSquareMeter",
  PerKilogram: "PerKilogram",
};

const MockupTypes = [
  { key: "Default", label: "Domyślna" },
  { key: "BookCover", label: "Okładka ksiązki" },
];

export const InputTypes = [
  { key: "Select", label: "Lista rozwijana" },
  { key: "Checkbox", label: "Pola typu checkbox" },
  { key: "Dropdown", label: "Zaawansowana lista rozwijana" },
  { key: "Buttons", label: "Przyciski" },
];

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      product: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      categories: [],
      additives: [],
      additiveGroups: [],
      options: [],
      printFormats: [],
      attributes: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        product: {
          id: undefined,
          categoryId: null,
          productAdditives: [],
          productOptions: [],
          translations: [],
          printFormats: [],
        },
        loading: false,
      });
    }
    this.props.listCategories();
    this.props.listAdditives();
    this.props.listAdditiveGroups();
    this.props.listAttributes();
    this.props.listOptions();
    this.props.listPrintFormats();
  };

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  downloadTable = (name) => {
    let product = { ...this.state.product };
    this.notify(
      "Tabela kalkulacji cenowych",
      "Przygotowywanie do pobierania tabeli...",
    );
    fetch(API_URL + product.id + "/Tables/" + name, {
      method: "GET",
      headers: defaultHeaders(),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${product.id}-${name}.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.notify(
          "Tabela kalkulacji cenowych",
          "Rozpoczęto pobieranie tabeli...",
          "success",
        );
      });
  };

  onPutTable = (response) => {
    if (response) {
      this.notify(
        "Tabela kalkulacji cenowych",
        "Wgrano nową tabelę, przeparsowanie moze potrwać chwilę...",
        "success",
      );
    }
  };

  uploadTable = (event) => {
    const { product } = this.state;
    this.notify("Tabela kalkulacji cenowych", "Trwa wgrywanie nowej tabeli...");
    if (
      !product ||
      !product.id ||
      product.id === "create" ||
      event.target.files.length !== 1
    ) {
      return;
    }
    const file = event.target.files[0];
    const url = API_URL + product.id + "/Tables/unparsed";
    apiDriver
      .upload(url, file, file.name)
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          console.error("error: ", error);
          return of(error);
        }),
      )
      .subscribe((data) => {
        if (product.productTables.hasOwnProperty("parsed")) {
          delete product.productTables.parsed;
        }
        this.notify(
          "Tabela kalkulacji cenowych",
          "Wgrano nową tabelę, przeparsowanie moze potrwać chwilę...",
          "success",
        );
      });
  };

  getPrintColors = () => [
    { value: EColor.OneOverZero, label: "1+0 czarny" },
    { value: EColor.OneOverOne, label: "1+1 czarny" },
    { value: EColor.FourOverZero, label: "4+0 CMYK" },
    { value: EColor.FourOverOne, label: "4+1 CMYK" },
    { value: EColor.FourOverFour, label: "4+4 CMYK" },
    { value: EColor.FiveOverZero, label: "5+0 CMYK+W" },
    { value: EColor.FiveOverFive, label: "5+5 CMYK+W" },
  ];

  getDefaultSheet = () => ({
    size: { x: null, y: null },
    margin: { x: null, y: null },
    overprint: { x: null, y: null },
    price: 0,
    priceType: SheetPriceType.PerSquareMeter,
  });

  getDefaultConfiguration = () => ({
    amount: {
      canUseCustom: false,
      defined: [],
      minimal: 1,
      maximal: null,
    },
    format: {
      inputType: "Buttons",
      unit: 10,
      canUseCustom: false,
      defined: [],
      minimal: { x: 1, y: 1 },
      maximal: { x: null, y: null },
      default: { x: null, y: null },
    },
    pages: {
      type: 0,
      minimal: { inside: 1, outside: 0 },
      maximal: { inside: null, outside: null },
      step: { inside: 1, outside: 1 },
      maximalThickness: { inside: null, outside: null },
      default: { inside: 1, outside: 0 },
      defined: [],
    },
    pricing: {
      type: 0,
      base: 0.0,
      sheet: null,
      metrics: [],
      prints: [],
      minimal: null,
      modifier: 1.0,
    },
    project: {
      realizationTime: null,
      basePrice: null,
      pricePerPage: { inside: null, outside: null },
    },
    weight: {
      type: 0,
      base: 0,
    },
  });

  purizeProductConfiguration = (productConfiguration) => {
    let configuration = { ...productConfiguration };
    if (configuration.amount) {
      if (
        configuration.amount.defined.length === 0 &&
        configuration.amount.canUseCustom === false
      ) {
        configuration.amount = null;
      }
    }
    if (configuration.format) {
      if (
        configuration.format.defined.length === 0 &&
        configuration.format.canUseCustom === false
      ) {
        configuration.format = null;
      }
    }
    if (configuration.pages) {
      if (
        (configuration.pages.type === `${EProductPagesType.Const}` &&
          configuration.pages.default.inside === 0 &&
          configuration.pages.default.outside === 0) ||
        (configuration.pages.type === `${EProductPagesType.Defined}` &&
          configuration.pages.defined.length === 0) ||
        (configuration.pages.type === `${EProductPagesType.Range}` &&
          (isNaN(configuration.pages.minimal.inside) ||
            configuration.pages.minimal.inside === 0 ||
            isNaN(configuration.pages.minimal.outside) ||
            configuration.pages.minimal.outside === 0 ||
            isNaN(configuration.pages.maximal.inside) ||
            configuration.pages.maximal.inside === 0 ||
            isNaN(configuration.pages.maximal.outside) ||
            configuration.pages.maximal.outside === 0))
      ) {
        configuration.pages = null;
      }
    }
    if (configuration.pricing) {
      if (
        (configuration.pricing.type === `${EProductPricingType.Const}` &&
          configuration.pricing.base === 0) ||
        (configuration.pricing.type === `${EProductPricingType.TableMetric}` &&
          configuration.pricing.metrics.length === 0)
      ) {
        configuration.pricing = null;
      } else {
        if (configuration.pricing.sheet) {
          configuration.pricing.sheet = configuration.pricing.sheet.filter(
            (sheet) =>
              sheet.size.x !== null &&
              !isNaN(sheet.size.x) &&
              sheet.size.y !== null &&
              !isNaN(sheet.size.y),
          );
          if (configuration.pricing.sheet.length === 0) {
            configuration.pricing.sheet = null;
          }
        }
      }
    }
    if (configuration.project) {
      if (
        configuration.project.realizationTime === null &&
        configuration.project.basePrice === null
      ) {
        configuration.project = null;
      }
    }
    if (configuration.weight) {
      if (
        configuration.weight.type === `${EProductWeightType.Const}` &&
        configuration.weight.base === 0
      ) {
        configuration.weight = null;
      }
    }
    return configuration;
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeProductConfigurationEvent = (section, event) => {
    return this.changeProductConfiguration(
      section,
      event.target.type,
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value,
    );
  };

  changeProductConfiguration = (section, type, name, value) => {
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration || this.getDefaultConfiguration();
    if (
      !productConfiguration.hasOwnProperty(section) ||
      !productConfiguration[section]
    ) {
      productConfiguration[section] = this.getDefaultConfiguration()[section];
    }
    let parsedValue = value;
    switch (type) {
      case "select-one":
        parsedValue = isNaN(Number(value)) ? value : Number(value);
        break;
      case "number":
        parsedValue = Number(value);
        break;
      case "checkbox":
        parsedValue = value ? true : false;
        break;
      default:
        break;
    }
    if (!name) {
      productConfiguration[section] = parsedValue;
    } else {
      if (name.split(".").length === 5) {
        let tmp = name.split(".");
        productConfiguration[section][tmp[0]][tmp[1]][tmp[2]][tmp[3]][tmp[4]] =
          parsedValue;
      } else if (name.split(".").length === 4) {
        let tmp = name.split(".");
        productConfiguration[section][tmp[0]][tmp[1]][tmp[2]][tmp[3]] =
          parsedValue;
      } else if (name.split(".").length === 3) {
        let tmp = name.split(".");
        productConfiguration[section][tmp[0]][tmp[1]][tmp[2]] = parsedValue;
      } else if (name.split(".").length === 2) {
        let tmp = name.split(".");
        productConfiguration[section][tmp[0]][tmp[1]] = parsedValue;
      } else {
        productConfiguration[section][name] = parsedValue;
      }
    }
    product.productConfiguration = productConfiguration;
    this.setState({ product });
  };

  addProductPrint = () => {
    const printColors = this.getPrintColors();
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;
    let values = [];
    printColors.forEach((model) => {
      values.push({ color: model.value, value: 0 });
    });
    if (!productConfiguration.prints) {
      productConfiguration.prints = [];
    }
    productConfiguration.prints.push({ width: 0, values: values });
    this.changeProductConfiguration(
      "pricing",
      "object",
      "prints",
      productConfiguration.prints,
    );
  };

  pureProductPrints = (productConfiguration) => {
    const printColors = this.getPrintColors();
    if (!productConfiguration.prints) {
      return;
    }
    let prints = [...productConfiguration.prints];

    return prints.map((print) => {
      let values = print.values;
      printColors.forEach((model) => {
        if (!values.some((v) => v.color === model.value)) {
          values.push({ color: model.value, value: 0 });
        }
      });
      return { ...print, values: values };
    });
  };

  removeProductPrint = (index) => {
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;
    if (!productConfiguration.prints) {
      return;
    }
    productConfiguration.prints = productConfiguration.prints.filter(
      (e, i) => i !== index,
    );
    this.changeProductConfiguration(
      "pricing",
      "object",
      "prints",
      productConfiguration.prints,
    );
  };

  removeProductSheet = (index) => {
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;
    productConfiguration.sheet = productConfiguration.sheet.filter(
      (e, i) => i !== index,
    );
    this.changeProductConfiguration(
      "pricing",
      "object",
      "sheet",
      productConfiguration.sheet,
    );
  };

  addProductSheet = () => {
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;
    productConfiguration.sheet.push(this.getDefaultSheet());
    this.changeProductConfiguration(
      "pricing",
      "object",
      "sheet",
      productConfiguration.sheet,
    );
  };

  toggleProductSheet = (event) => {
    const { checked } = { ...event.target };
    let product = { ...this.state.product };
    let productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;
    if (checked) {
      productConfiguration.sheet = [this.getDefaultSheet()];
    } else {
      productConfiguration.sheet = null;
    }
    this.changeProductConfiguration(
      "pricing",
      "object",
      "sheet",
      productConfiguration.sheet,
    );
  };

  changeProductMockups = (event) => {
    const { value, checked } = event.target;
    let mockupTypes = this.state?.product?.productConfiguration?.mockupTypes
      ? [...this.state?.product?.productConfiguration?.mockupTypes]
      : [];
    if (checked) {
      if (!mockupTypes.includes(value)) {
        mockupTypes.push(value);
      }
    } else {
      mockupTypes = mockupTypes.filter((t) => t !== value);
    }
    this.changeProductConfiguration("mockupTypes", "object", "", mockupTypes);
  };

  changeProductPrintFormat = (event) => {
    var product = { ...this.state.product };
    let { id, selected } = event.params.data;
    product.productFormats = product.productFormats || [];
    if (selected) {
      if (!product.productFormats.some((v) => v.formatId === id)) {
        product.productFormats.push({ formatId: id });
      }
    } else {
      product.productFormats = product.productFormats.filter(
        (v) => v.formatId !== id,
      );
    }
    this.setState({ product });
  };

  changeProductData = (event) => {
    const { name, value, type } = event.target;
    var product = { ...this.state.product };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "select":
      case "select-one":
        parsedValue = isNaN(Number(value)) ? value : Number(value);
        break;
      case "checkbox":
        const { checked } = event.target;
        parsedValue = checked;
        break;
      default:
        break;
    }
    product[name] = parsedValue;
    this.setState({ product });
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.product };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ product: object });
    });
  };

  //#region Additives
  changeProductAdditive = (event) => {
    const { checked } = event.target;
    const value = event.target.value;
    let { product } = this.state;
    let someResult = product.productAdditives.some(
      (pa) => pa.additiveId === value,
    );
    if (someResult && !checked) {
      product.productAdditives = product.productAdditives.filter(
        (pa) => pa.additiveId !== value,
      );
    } else if (!someResult && checked) {
      product.productAdditives.push({
        additiveId: value,
        productId: product.id,
      });
    }
    this.setState({ product });
  };

  standardAdditiveGroupsOrder = () => {
    const { product, additives, additiveGroups } = this.state;

    return additiveGroups.map((group) => {
      const attributeAdditives = additives
        .filter((o) => o.groupId === group.id)
        .map((o) => o.id);
      const attributeProductAdditivesOrders = product.productAdditives
        .filter((po) => attributeAdditives.includes(po.additiveId))
        .map((po) => po.order || 0) || [0];
      const obj = {
        group: group,
        order: Math.max(...attributeProductAdditivesOrders) || 0,
      };
      return obj;
    });
  };

  normalizeAdditiveGroupsOrder = (productAdditives, groupsWithOrder) => {
    const { additives } = this.state;

    if (!groupsWithOrder) {
      groupsWithOrder = this.standardAdditiveGroupsOrder();
    }

    let i = 0;
    return [
      ...productAdditives
        .map((po) => {
          const additive = additives.find((o) => po.additiveId === o.id);
          const group = groupsWithOrder.find(
            (a) => a.group.id === additive.groupId,
          );
          const obj = { ...po, groupOrder: group.order };
          return obj;
        })
        .sort((a, b) => a.groupOrder - b.groupOrder || a.order - b.order)
        .map((po) => ({ ...po, order: ++i })),
    ];
  };

  orderAdditiveGroup = (groupId, direction) => {
    const { product, additives, additiveGroups } = this.state;
    let productAdditives = [...product.productAdditives];

    let groupsWithOrder = additiveGroups.map((group) => {
      const groupAdditives = additives
        .filter((o) => o.groupId === group.id)
        .map((o) => o.id);
      const groupProductAdditivesOrders = product.productAdditives
        .filter((po) => groupAdditives.includes(po.additiveId))
        .map((po) => po.order || 0) || [0];
      const obj = {
        group: group,
        order: Math.max(...groupProductAdditivesOrders) || 0,
      };
      return obj;
    });

    let i = 0;
    groupsWithOrder = groupsWithOrder
      .sort((a, b) => a.order - b.order)
      .map((a) => ({ ...a, newOrder: ++i }));
    const groupWithOrder = groupsWithOrder.find((a) => a.group.id === groupId);
    groupWithOrder.newOrder += direction;
    groupsWithOrder = groupsWithOrder.map((a) => {
      if (a.group.id === groupId) {
        return { ...a, order: groupWithOrder.newOrder };
      }
      const order = a.newOrder || 0;
      return {
        ...a,
        order:
          direction > 0
            ? order >= groupWithOrder.newOrder
              ? order - 1
              : order
            : order <= groupWithOrder.newOrder
              ? order + 1
              : order,
      };
    });

    this.setState({
      product: {
        ...product,
        productAdditives: this.normalizeAdditiveGroupsOrder(
          productAdditives,
          groupsWithOrder,
        ),
      },
    });
  };

  orderAdditive = (additiveId, direction) => {
    const { product } = this.state;
    let productAdditives = [...product.productAdditives];
    let productAdditive = productAdditives.find(
      (o) => o.additiveId === additiveId,
    );
    productAdditive.order = productAdditive.order
      ? productAdditive.order + direction
      : 0;

    productAdditives = productAdditives.map((po) => {
      if (po.additiveId === additiveId) {
        return { ...po, order: productAdditive.order };
      }
      const order = po.order || 0;
      return {
        ...po,
        order:
          direction > 0
            ? order >= productAdditive.order
              ? order - 1
              : order
            : order <= productAdditive.order
              ? order + 1
              : order,
      };
    });
    this.setState({
      product: {
        ...product,
        productAdditives: this.normalizeAdditiveGroupsOrder(productAdditives),
      },
    });
  };
  //#endregion

  //#region Options
  changeProductOption = (event) => {
    const { checked } = event.target;
    const value = Number(event.target.value);
    let { product } = this.state;
    let someResult = product.productOptions.some((pa) => pa.optionId === value);
    if (someResult && !checked) {
      product.productOptions = product.productOptions.filter(
        (pa) => pa.optionId !== value,
      );
    } else if (!someResult && checked) {
      product.productOptions.push({ optionId: value, productId: product.id });
    }
    this.setState({ product });
  };

  standardAttributesOrder = () => {
    const { product, options, attributes } = this.state;

    return attributes.map((attribute) => {
      const attributeOptions = options
        .filter((o) => o.attributeId === attribute.id)
        .map((o) => o.id);
      const attributeProductOptionsOrders = product.productOptions
        .filter((po) => attributeOptions.includes(po.optionId))
        .map((po) => po.order || 0) || [0];
      const obj = {
        attribute: attribute,
        order: Math.max(...attributeProductOptionsOrders) || 0,
      };
      return obj;
    });
  };

  normalizeAttributesOrder = (productOptions, attributesWithOrder) => {
    const { options } = this.state;

    if (!attributesWithOrder) {
      attributesWithOrder = this.standardAttributesOrder();
    }

    let i = 0;
    return [
      ...productOptions
        .map((po) => {
          const option = options.find((o) => po.optionId === o.id);
          const attribute = attributesWithOrder.find(
            (a) => a.attribute.id === option.attributeId,
          );
          const obj = { ...po, attributeOrder: attribute.order };
          return obj;
        })
        .sort(
          (a, b) => a.attributeOrder - b.attributeOrder || a.order - b.order,
        )
        .map((po) => ({ ...po, order: ++i })),
    ];
  };

  orderAttribute = (attributeId, direction) => {
    const { product, options, attributes } = this.state;
    let productOptions = [...product.productOptions];

    let attributesWithOrder = attributes.map((attribute) => {
      const attributeOptions = options
        .filter((o) => o.attributeId === attribute.id)
        .map((o) => o.id);
      const attributeProductOptionsOrders = product.productOptions
        .filter((po) => attributeOptions.includes(po.optionId))
        .map((po) => po.order || 0) || [0];
      const obj = {
        attribute: attribute,
        order: Math.max(...attributeProductOptionsOrders) || 0,
      };
      return obj;
    });

    let i = 0;
    attributesWithOrder = attributesWithOrder
      .sort((a, b) => a.order - b.order)
      .map((a) => ({ ...a, newOrder: ++i }));
    const attributeWithOrder = attributesWithOrder.find(
      (a) => a.attribute.id === attributeId,
    );
    attributeWithOrder.newOrder += direction;
    attributesWithOrder = attributesWithOrder.map((a) => {
      if (a.attribute.id === attributeId) {
        return { ...a, order: attributeWithOrder.newOrder };
      }
      const order = a.newOrder || 0;
      return {
        ...a,
        order:
          direction > 0
            ? order >= attributeWithOrder.newOrder
              ? order - 1
              : order
            : order <= attributeWithOrder.newOrder
              ? order + 1
              : order,
      };
    });

    this.setState({
      product: {
        ...product,
        productOptions: this.normalizeAttributesOrder(
          productOptions,
          attributesWithOrder,
        ),
      },
    });
  };

  orderOption = (optionId, direction) => {
    const { product } = this.state;
    let productOptions = [...product.productOptions];
    let productOption = productOptions.find((o) => o.optionId === optionId);
    productOption.order = productOption.order
      ? productOption.order + direction
      : 0;

    productOptions = productOptions.map((po) => {
      if (po.optionId === optionId) {
        return { ...po, order: productOption.order };
      }
      const order = po.order || 0;
      return {
        ...po,
        order:
          direction > 0
            ? order >= productOption.order
              ? order - 1
              : order
            : order <= productOption.order
              ? order + 1
              : order,
      };
    });
    this.setState({
      product: {
        ...product,
        productOptions: this.normalizeAttributesOrder(productOptions),
      },
    });
  };
  //#endregion

  changeProductTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var product = { ...this.state.product };
    let translation = product.translations.filter(
      (t) => t.culture === currentCulture,
    );
    if (!translation || translation.length === 0) {
      product.translations.push({ culture: currentCulture });
    }
    product.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ product });
  };

  changeProductTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeProductTranslationEx(name, value);
  };

  submitForm = (event) => {
    const {
      additives,
      attributes,
      options,
      printFormats,
      category,
      ...product
    } = { ...this.state.product };
    if (product.categoryId === "") {
      product.categoryId = null;
    }
    this.notify("Produkt", "Trwa zapisywanie produktu...");
    product.productConfiguration = this.purizeProductConfiguration(
      product.productConfiguration,
    );
    if (product.id) {
      this.setState({ action: "update" });
      this.props.update(product);
    } else {
      this.setState({ action: "create" });
      this.props.create(product);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickRemove = () => {
    const { product } = this.state;
    this.props.remove(product.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.product || this.props.loading;

    const { product } = this.state;
    const { auth } = this.props;

    if (!product || product.id === auth.id || !product.id) {
      return <></>;
    }

    return (
      <Button color="danger" disabled={loading} onClick={this.onClickRemove}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.product || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderCardDescription = () => {
    const { product } = this.state;
    const { currentCulture } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      product.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Opisy produktu</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">Nagłówek</Col>
            <Col md="12">
              <FormGroup>
                <Editor
                  name="descriptionHeader"
                  value={editedTranslation?.descriptionHeader}
                  onChange={this.changeProductTranslation}
                  uploadUrl={`${config.api.products}pl/Products/${product.id}/Uploads/[[name]]`}
                  uploadPresignedUrl={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">Panel boczny</Col>
            <Col md="12">
              <FormGroup>
                <Editor
                  name="descriptionSidebar"
                  value={editedTranslation?.descriptionSidebar}
                  onChange={this.changeProductTranslation}
                  uploadUrl={`${config.api.products}pl/Products/${product.id}/Uploads/[[name]]`}
                  uploadPresignedUrl={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">Stopka</Col>
            <Col md="12">
              <FormGroup>
                <Editor
                  name="descriptionFooter"
                  value={editedTranslation?.descriptionFooter}
                  onChange={this.changeProductTranslation}
                  uploadUrl={`${config.api.products}pl/Products/${product.id}/Uploads/[[name]]`}
                  uploadPresignedUrl={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderCardHints = () => {
    const { product } = this.state;
    const { currentCulture } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      product.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podpowiedzi produktu</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">Format</Col>
            <Col md="12">
              <FormGroup>
                <Input
                  placeholder="Format"
                  type="textarea"
                  name="hintFormat"
                  value={editedTranslation?.hintFormat || ""}
                  onChange={this.changeProductTranslation}
                  rows={2}
                />
              </FormGroup>
            </Col>
            <Col md="12">Stronicowanie</Col>
            <Col md="12">
              <FormGroup>
                <Input
                  placeholder="Stronicowanie"
                  type="textarea"
                  name="hintPaging"
                  value={editedTranslation?.hintPaging || ""}
                  onChange={this.changeProductTranslation}
                  rows={2}
                />
              </FormGroup>
            </Col>
            <Col md="12">Projekt</Col>
            <Col md="12">
              <FormGroup>
                <Input
                  placeholder="Projekt"
                  type="textarea"
                  name="hintProject"
                  value={editedTranslation?.hintProject || ""}
                  onChange={this.changeProductTranslation}
                  rows={2}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderCardConfigurationAmount = () => {
    const { product } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const productConfiguration = product.productConfiguration?.amount
      ? product.productConfiguration.amount
      : this.getDefaultConfiguration().amount;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Ilość, nakład</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">Zdefiniowany nakład</Col>
            <Col md="8">
              <FormGroup>
                <TagsInput
                  onlyUnique
                  className="form-control bootstrap-tagsinput border"
                  onChange={(value) => {
                    this.changeProductConfiguration(
                      "amount",
                      "text",
                      "defined",
                      value.map((v) => Number(v)).filter((v) => !isNaN(v)),
                    );
                  }}
                  value={productConfiguration.defined}
                  tagProps={{ className: "tag badge mr-1" }}
                  inputProps={{
                    className: "",
                    placeholder: "1",
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="9">Czy mozna uzyc nakładu niestandardowego?</Col>
            <Col md="3" className="text-right">
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="canUseCustom"
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("amount", event)
                  }
                  value={true}
                  checked={productConfiguration.canUseCustom}
                  defaultChecked={productConfiguration.canUseCustom}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
            <Col md="4">Minimalny nakład</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Minimalny nakład"
                    name="minimal"
                    value={productConfiguration.minimal || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("amount", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>szt</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">Maksymalny nakład</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Maksymalny nakład"
                    name="maximal"
                    value={productConfiguration.maximal || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("amount", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>szt</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderCardConfigurationFormat = () => {
    const { product, printFormats } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const productConfiguration =
      product.productConfiguration && product.productConfiguration.format
        ? product.productConfiguration.format
        : this.getDefaultConfiguration().format;

    const units = [
      { id: 1, title: "mm" },
      { id: 10, title: "cm" },
      { id: 100, title: "dm" },
      { id: 1000, title: "m" },
      { id: 1000000, title: "km" },
    ];
    const unit =
      units.filter(
        (u) =>
          u.id === productConfiguration.unit ||
          String(u.id) === productConfiguration.unit,
      )[0] || units[0];

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Format</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">Komponent formularza</Col>
            <Col md="8">
              <Input
                className="form-control mb-3"
                type="select"
                name="inputType"
                value={productConfiguration.inputType}
                onChange={(event) =>
                  this.changeProductConfigurationEvent("format", event)
                }
              >
                <option disabled value="">
                  Wybierz
                </option>
                {InputTypes.map((u) => (
                  <option key={u.key} value={u.key}>
                    {u.label}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="4">Jednostka miary</Col>
            <Col md="8">
              <Input
                className="form-control mb-3"
                type="select"
                name="unit"
                value={productConfiguration.unit}
                onChange={(event) =>
                  this.changeProductConfigurationEvent("format", event)
                }
              >
                <option disabled value="">
                  Wybierz
                </option>
                {units.map((u) => (
                  <option key={u.id} value={u.id}>
                    {u.title}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="4">Makiety</Col>
            <Col md="8">
              <FormGroup>
                {MockupTypes.map((type) => (
                  <InputGroup>
                    <Input
                      type="checkbox"
                      id={`mockup_${type.key}`}
                      value={type.key}
                      onChange={this.changeProductMockups}
                      checked={product?.productConfiguration?.mockupTypes?.includes(
                        type.key,
                      )}
                    />
                    <Label for={`mockup_${type.key}`}>{type.label}</Label>
                  </InputGroup>
                ))}
              </FormGroup>
            </Col>
            <Col md="4">Zdefiniowane formaty</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <Select2
                    className="form-control"
                    value={product.productFormats.map((f) => f.formatId) || []}
                    multiple
                    data={printFormats.map((f) => ({
                      id: f.id,
                      text: f.currentTranslation?.title,
                    }))}
                    onSelect={this.changeProductPrintFormat}
                    onUnselect={this.changeProductPrintFormat}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="9">Czy mozna uzyc formatu niestandardowego?</Col>
            <Col md="3" className="text-right">
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="canUseCustom"
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("format", event)
                  }
                  value={true}
                  checked={productConfiguration.canUseCustom}
                  defaultChecked={productConfiguration.canUseCustom}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
            <Col md="4">Minimalny format</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Szerokość"
                    name="minimal.x"
                    value={productConfiguration.minimal.x || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("format", event)
                    }
                  />
                  <NumericInput
                    placeholder="Wysokość"
                    name="minimal.y"
                    value={productConfiguration.minimal.y || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("format", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{unit.title}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">Maksymalny format</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Wysokość"
                    name="maximal.x"
                    value={productConfiguration.maximal.x || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("format", event)
                    }
                  />
                  <NumericInput
                    placeholder="Szerokość"
                    name="maximal.y"
                    value={productConfiguration.maximal.y || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("format", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{unit.title}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderCardConfigurationPages = () => {
    const { product } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const pagingTypes = [
      { id: "Const", title: "Stałe" },
      { id: "Pool", title: "Pula wyboru" },
      { id: "Range", title: "Zakres" },
    ];

    const productConfiguration =
      product.productConfiguration && product.productConfiguration.pages
        ? product.productConfiguration.pages
        : this.getDefaultConfiguration().pages;

    if (
      productConfiguration.type === EProductPagesType.Range &&
      productConfiguration.step == null
    ) {
      productConfiguration.step = { inside: 1, outside: 1 };
    }

    //todo: checkbox on header
    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Stronicowanie</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">Rodzaj stronicowania</Col>
            <Col md="8">
              <Input
                className="form-control mb-3"
                type="select"
                name="type"
                value={productConfiguration.type}
                onChange={(event) =>
                  this.changeProductConfigurationEvent("pages", event)
                }
              >
                <option disabled value="">
                  Wybierz
                </option>
                {pagingTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.title}
                  </option>
                ))}
              </Input>
            </Col>
            {productConfiguration.type === EProductPagesType.Defined ? (
              <React.Fragment>
                <Col md="4">Zdefiniowane stronicowania</Col>
                <Col md="8">
                  <FormGroup>
                    <TagsInput
                      onlyUnique
                      className="form-control bootstrap-tagsinput border"
                      placeholder="Wewnętrzne,Zewnętrzne"
                      onChange={(value) => {
                        this.changeProductConfiguration(
                          "pages",
                          "text",
                          "defined",
                          value
                            .map((v) => v.split(",").map((x) => Number(x)))
                            .filter((v) => !isNaN(v[0]) && !isNaN(v[1]))
                            .map((v) => ({ inside: v[0], outside: v[1] })),
                        );
                      }}
                      value={productConfiguration.defined.map(
                        (v) => v.inside + "," + v.outside,
                      )}
                      tagProps={{ className: "tag badge mr-1" }}
                      inputProps={{
                        className: "",
                        placeholder: "WEWNĘTRZNE,ZEWNĘTRZNE",
                      }}
                    />
                  </FormGroup>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {productConfiguration.type === EProductPagesType.Range ? (
              <React.Fragment>
                <Col md="4">Minimalna ilość stron</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Wewnątrz"
                        name="minimal.inside"
                        value={productConfiguration.minimal.inside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                      <NumericInput
                        placeholder="Zewnątrz"
                        name="minimal.outside"
                        value={productConfiguration.minimal.outside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">Maksymalna ilość stron</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Wewnątrz"
                        name="maximal.inside"
                        value={productConfiguration.maximal.inside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                      <NumericInput
                        placeholder="Zewnątrz"
                        name="maximal.outside"
                        value={productConfiguration.maximal.outside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">Krok ilości stron</Col>
                <Col md="8">
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        placeholder="Wewnątrz"
                        name="step.inside"
                        value={productConfiguration.step.inside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                      <NumericInput
                        placeholder="Zewnątrz"
                        name="step.outside"
                        value={productConfiguration.step.outside || ""}
                        onChange={(event) =>
                          this.changeProductConfigurationEvent("pages", event)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            <Col md="4">Domyślna ilość stron</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Wewnątrz"
                    name="default.inside"
                    value={productConfiguration.default.inside || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("pages", event)
                    }
                  />
                  <NumericInput
                    placeholder="Zewnątrz"
                    name="default.outside"
                    value={productConfiguration.default.outside || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("pages", event)
                    }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">Maksymalna grubość</Col>
            <Col md="8">
              <FormGroup>
                <InputGroup>
                  <NumericInput
                    placeholder="Wewnątrz"
                    name="maximalThickness.inside"
                    value={
                      productConfiguration.maximalThickness
                        ? productConfiguration.maximalThickness.inside || ""
                        : ""
                    }
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("pages", event)
                    }
                  />
                  <NumericInput
                    placeholder="Zewnątrz"
                    name="maximalThickness.outside"
                    value={
                      productConfiguration.maximalThickness
                        ? productConfiguration.maximalThickness.outside || ""
                        : ""
                    }
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("pages", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mm</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="9">Mozna zdefiniować kolorystykę dla ilości stron?</Col>
            <Col md="3" className="text-right">
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="canDetermineColor"
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("pages", event)
                  }
                  value={true}
                  checked={productConfiguration.canDetermineColor}
                  defaultChecked={productConfiguration.canDetermineColor}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderCardConfigurationProject = () => {
    const { product } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const productConfiguration = product?.productConfiguration?.project;

    //todo: checkbox on header
    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <div className="clearfix">
            <div className="float-left">
              <h3 className="mb-0 float-left">Usługa projektowa</h3>
            </div>
            <div className="float-right">
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="sheet"
                  onChange={(event) =>
                    productConfiguration
                      ? this.changeProductConfiguration(
                        "project",
                        "text",
                        "",
                        null,
                      )
                      : this.changeProductConfiguration(
                        "project",
                        "text",
                        "",
                        this.getDefaultConfiguration().project,
                      )
                  }
                  value={true}
                  checked={productConfiguration}
                  defaultChecked={productConfiguration}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
          </div>
        </CardHeader>
        {productConfiguration ? (
          <CardBody>
            <Row>
              <Col md="4">Czas realizacji *</Col>
              <Col md="8" className="mb-3">
                <InputGroup>
                  <NumericInput
                    placeholder="Czas realizacji"
                    name="realizationTime"
                    value={productConfiguration.realizationTime || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("project", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>h</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="4">Stała cena *</Col>
              <Col md="8" className="mb-3">
                <InputGroup>
                  <NumericInput
                    placeholder="Stała wliczana do ceny usługi"
                    name="basePrice"
                    value={productConfiguration.basePrice || ""}
                    onChange={(event) =>
                      this.changeProductConfigurationEvent("project", event)
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>zł</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="4">Cena za stronę</Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <NumericInput
                      placeholder="Za stronę wewnętrzną"
                      name="pricePerPage.inside"
                      value={productConfiguration.pricePerPage.inside || ""}
                      step={0.01}
                      onChange={(event) =>
                        this.changeProductConfigurationEvent("project", event)
                      }
                    />
                    <NumericInput
                      placeholder="Za stronę zewnętrzną"
                      name="pricePerPage.outside"
                      value={productConfiguration.pricePerPage.outside || ""}
                      step={0.01}
                      onChange={(event) =>
                        this.changeProductConfigurationEvent("project", event)
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>zł</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        ) : (
          <React.Fragment />
        )}
      </Card>
    );
  };

  renderCardConfigurationPricing = () => {
    const { product } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const pricingTypes = [
      { id: "Const", title: "Stała" },
      { id: "Standard", title: "Standard" },
      { id: "Table", title: "Tabela" },
      { id: "TableMetric", title: "Tabela z przedziałami" },
      { id: "Resource", title: "Według kosztu odbitki" },
    ];

    const realizationTimeTypes = [
      { id: "Standard", title: "Standard" },
      { id: "Express", title: "Ekspres" },
      { id: "Economic", title: "Ekonomiczny" },
    ];

    const printColors = this.getPrintColors();

    const productConfigurationRealizationTimes =
      product.productConfiguration?.realizationTimes || [];

    const toggleRealizationTimeType = (type, state) => {
      let rtt = [...productConfigurationRealizationTimes];
      if (state === true) {
        if (!rtt.some((r) => r.typer === type)) {
          rtt.push({ type: type, realizationTime: 0, priceMultiplier: 1.0 });
        }
      } else {
        rtt = rtt.filter((t) => t.type !== type);
      }
      this.setState({
        product: {
          ...product,
          productConfiguration: {
            ...product.productConfiguration,
            realizationTimes: rtt,
          },
        },
      });
    };

    const setRealizationTimeType = (type, name, value) => {
      let rtt = [...productConfigurationRealizationTimes].map((t) =>
        t.type === type ? { ...t, [name]: Number(value) } : t,
      );
      this.setState({
        product: {
          ...product,
          productConfiguration: {
            ...product.productConfiguration,
            realizationTimes: rtt,
          },
        },
      });
    };

    const productConfiguration =
      product.productConfiguration && product.productConfiguration.pricing
        ? product.productConfiguration.pricing
        : this.getDefaultConfiguration().pricing;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Wycena</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">Rodzaj kalkulacji</Col>
            <Col md="8">
              <Input
                className="form-control mb-3"
                type="select"
                name="type"
                value={productConfiguration.type}
                onChange={(event) =>
                  this.changeProductConfigurationEvent("pricing", event)
                }
              >
                <option disabled value="">
                  Wybierz
                </option>
                {pricingTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.title}
                  </option>
                ))}
              </Input>
            </Col>
            {productConfiguration.type === "TableMetric" ? (
              <React.Fragment>
                <Col md="4">Zdefiniowane przedziały</Col>
                <Col md="8">
                  <FormGroup>
                    <TagsInput
                      onlyUnique
                      className="form-control bootstrap-tagsinput border"
                      onChange={(value) => {
                        this.changeProductConfiguration(
                          "pricing",
                          "text",
                          "metrics",
                          value.map((v) => Number(v)).filter((v) => !isNaN(v)),
                        );
                      }}
                      value={productConfiguration.metrics}
                      tagProps={{ className: "tag badge mr-1" }}
                      inputProps={{
                        className: "",
                        placeholder: "1",
                      }}
                    />
                  </FormGroup>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}

            <Col md="4">Stała bazowa</Col>
            <Col md="8" className="mb-3">
              <InputGroup>
                <NumericInput
                  placeholder="Stała bazowa"
                  name="base"
                  value={productConfiguration.base || ""}
                  step={0.01}
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("pricing", event)
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>zł</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="4">Minimalna wycena</Col>
            <Col md="8" className="mb-3">
              <InputGroup>
                <NumericInput
                  placeholder="Minimalna wycena"
                  name="minimal"
                  value={productConfiguration.minimal || ""}
                  step={0.01}
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("pricing", event)
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>zł</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="4">Mnoznik</Col>
            <Col md="8" className="mb-3">
              <InputGroup>
                <NumericInput
                  placeholder="Mnoznik"
                  name="modifier"
                  value={productConfiguration.modifier || ""}
                  step={0.01}
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("pricing", event)
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="4">Czas realizacji</Col>
            <Col md="8" className="mb-3">
              {realizationTimeTypes.map((type) => {
                let realizationTime =
                  productConfigurationRealizationTimes.find(
                    (t) => t.type === type.id,
                  ) || undefined;
                return (
                  <Row>
                    <Col md={1}>
                      <Input
                        id={`realizationTimeType_${type.id}`}
                        type="checkbox"
                        value={true}
                        checked={realizationTime !== undefined}
                        onChange={(e) =>
                          toggleRealizationTimeType(type.id, e.target.checked)
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <Label for={`realizationTimeType_${type.id}`}>
                        {type.title}
                      </Label>
                    </Col>
                    <Col md={4}>
                      <NumericInput
                        value={realizationTime?.realizationTime || 0}
                        onChange={(e) =>
                          setRealizationTimeType(
                            type.id,
                            "realizationTime",
                            e.target.value,
                          )
                        }
                        min={1}
                      />
                    </Col>
                    <Col md={4}>
                      <NumericInput
                        value={realizationTime?.priceMultiplier || 0}
                        step={0.01}
                        onChange={(e) =>
                          setRealizationTimeType(
                            type.id,
                            "priceMultiplier",
                            e.target.value,
                          )
                        }
                        min={0.01}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col md={productConfiguration.sheet ? 7 : 9}>
              Produkt wykrajany z arkusza?
            </Col>
            <Col md="3" className="text-right">
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="sheet"
                  onChange={(event) => this.toggleProductSheet(event)}
                  value={true}
                  checked={productConfiguration.sheet}
                  defaultChecked={productConfiguration.sheet}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
            {productConfiguration.sheet ? (
              <Col md="2" style={{ textAlign: "right" }}>
                <Button
                  color="success"
                  size="sm"
                  onClick={(event) => this.addProductSheet()}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </Col>
            ) : (
              <React.Fragment />
            )}
          </Row>
          {productConfiguration.sheet ? (
            <React.Fragment>
              <Row>
                <Col md="12">
                  <hr className="mt-0" />
                </Col>
              </Row>
              {productConfiguration.sheet.map((sheet, index) => (
                <Row key={index}>
                  <Col md="10">
                    <h4 className="mb-5">Arkusz #{index}</h4>
                  </Col>
                  <Col md="2" style={{ textAlign: "right" }}>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={(event) => this.removeProductSheet(index)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                  <Col md="4">Rozmiar</Col>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          placeholder="Szerokość"
                          name={`sheet.${index}.size.x`}
                          value={sheet.size.x || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <NumericInput
                          placeholder="Wysokość"
                          name={`sheet.${index}.size.y`}
                          value={sheet.size.y || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mm</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4">Wielkość wycinki</Col>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          placeholder="Szerokość"
                          name={`sheet.${index}.margin.x`}
                          value={sheet.margin.x || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <NumericInput
                          placeholder="Wysokość"
                          name={`sheet.${index}.margin.y`}
                          value={sheet.margin.y || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mm</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4">Pole zadruku</Col>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          placeholder="Wysokość"
                          name={`sheet.${index}.overprint.x`}
                          value={sheet.overprint.x || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <NumericInput
                          placeholder="Szerokość"
                          name={`sheet.${index}.overprint.y`}
                          value={sheet.overprint.y || ""}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mm</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4">Cena podłoza</Col>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          placeholder="Cena"
                          name={`sheet.${index}.price`}
                          value={sheet.price || ""}
                          step={0.01}
                          onChange={(event) =>
                            this.changeProductConfigurationEvent(
                              "pricing",
                              event,
                            )
                          }
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>zł/</InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            m<sup>2</sup>
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <label className="custom-toggle">
                              <Input
                                type="checkbox"
                                name={`sheet.${index}.priceType`}
                                onChange={(event) =>
                                  this.changeProductConfiguration(
                                    "pricing",
                                    "text",
                                    event.target.name,
                                    event.target.checked
                                      ? SheetPriceType.PerKilogram
                                      : SheetPriceType.PerSquareMeter,
                                  )
                                }
                                value={true}
                                checked={
                                  sheet.priceType === SheetPriceType.PerKilogram
                                }
                                defaultChecked={
                                  sheet.priceType === SheetPriceType.PerKilogram
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="m2"
                                data-label-on="kg"
                              />
                            </label>
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>kg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              ))}
            </React.Fragment>
          ) : (
            <Row>
              <Col md="4">Cena surowca</Col>
              <Col md="8">
                <FormGroup>
                  <InputGroup>
                    <NumericInput
                      placeholder="Cena"
                      step={0.01}
                      name="resourcePrice"
                      value={productConfiguration.resourcePrice || ""}
                      onChange={(event) =>
                        this.changeProductConfigurationEvent("pricing", event)
                      }
                    />

                    <InputGroupAddon addonType="append">
                      <InputGroupText>zł/</InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        m<sup>2</sup>
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            name="resourcePriceType"
                            onChange={(event) =>
                              this.changeProductConfigurationEvent(
                                "pricing",
                                event,
                              )
                            }
                            value={true}
                            checked={
                              productConfiguration.resourcePriceType === 1
                            }
                            defaultChecked={
                              productConfiguration.resourcePriceType === 1
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="m2"
                            data-label-on="kg"
                          />
                        </label>
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>kg</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {productConfiguration.type === "Table" ||
            productConfiguration.type === "TableMetric" ? (
            <Row>
              <Col md="12">
                <hr />
                <h4 className="mb-5">Tabela wyceny</h4>
              </Col>
              <Col md="3">
                <Button
                  color="success"
                  block={true}
                  onClick={() => this.downloadTable("empty")}
                  disabled={!product?.productTables?.hasOwnProperty("Empty")}
                >
                  <i className="fas fa-download"></i> Pusta
                </Button>
              </Col>
              <Col md="3">
                <Button
                  color="success"
                  block={true}
                  onClick={() => this.downloadTable("filled")}
                  disabled={
                    product?.productTables?.hasOwnProperty("Unparsed") ||
                    !product?.productTables?.hasOwnProperty("Parsed")
                  }
                >
                  <i className="fas fa-download"></i> Aktualna
                </Button>
              </Col>
              <Col md="4">
                <Uploader
                  url={API_URL + product.id + "/Tables/unparsed"}
                  isPresigned={false}
                  onUpload
                  onUploadComplete={(response) =>
                    this.onPutTable(response.response)
                  }
                />
              </Col>
              <Col md="2">
                <Button color="primary" block={true}>
                  <i className="fas fa-upload"></i>
                </Button>
              </Col>
            </Row>
          ) : (
            <React.Fragment />
          )}
          {productConfiguration.type === "Resource" ? (
            <Row>
              <Col md="10">
                <h4 className="mb-5">Tabela kosztów odbitki</h4>
              </Col>
              <Col md="12">
                <Alert color="info">
                  Uzupełnij informacje o kosztach odbitki uzupełniając ponizsza
                  tabele.
                  <br />
                  Kilka niezbędnych wskazówek:
                  <br />
                  <ul>
                    <li>
                      Jezeli chcesz aby dana kolorystyka byla niedostepna przy
                      danej szerokosci arkusza wpisz 0,
                    </li>
                    <li>
                      Wpisywana szerokość arkusza jest szerokością maksymalną{" "}
                      <span className="text-uppercase">włącznie</span>,
                    </li>
                    <li>
                      mozesz dodać kolejne zakresy szerokości arkusza klikając
                      przycisk plus,
                    </li>
                    <li>
                      mozesz usuwać zakresy szerokości arkusza klikając przycisk
                      kosza na śmieci.
                    </li>
                  </ul>
                </Alert>
                <Table>
                  <thead>
                    <tr>
                      <td>Szerokość arkusza</td>
                      {productConfiguration.prints != null &&
                        productConfiguration.prints.map((print, index) => (
                          <td key={index}>
                            <NumericInput
                              placeholder="Szerokość arkusza mm"
                              name={`prints.${index}.width`}
                              value={print.width || ""}
                              onChange={(event) =>
                                this.changeProductConfigurationEvent(
                                  "pricing",
                                  event,
                                )
                              }
                            />
                          </td>
                        ))}
                      <td align="center">
                        <i
                          className="fas fa-plus"
                          onClick={(event) => this.addProductPrint()}
                        ></i>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {printColors.map((model) => (
                      <tr>
                        <td>{model.label}</td>
                        {productConfiguration.prints != null &&
                          productConfiguration.prints.map((print, index) => {
                            let printColorValue = print.values.filter(
                              (v) => v.color === model.value,
                            );
                            let printColorValueId = print.values.findIndex(
                              (v) => v.color === model.value,
                            );
                            return (
                              <td key={index}>
                                <NumericInput
                                  placeholder="Cena za m2"
                                  step={0.01}
                                  name={`prints.${index}.values.${printColorValueId}.value`}
                                  value={
                                    printColorValue.length > 0
                                      ? printColorValue[0].value
                                      : null
                                  }
                                  onChange={(event) =>
                                    this.changeProductConfigurationEvent(
                                      "pricing",
                                      event,
                                    )
                                  }
                                />
                              </td>
                            );
                          })}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      {productConfiguration.prints != null &&
                        productConfiguration.prints.map((print, index) => {
                          return (
                            <td key={index}>
                              <i
                                className="fas fa-trash"
                                onClick={(event) =>
                                  this.removeProductPrint(index)
                                }
                              ></i>
                            </td>
                          );
                        })}
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          ) : (
            <React.Fragment />
          )}
        </CardBody>
      </Card>
    );
  };

  renderCardConfigurationWeight = () => {
    const { product } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;

    const pricingTypes = [
      { id: 0, title: "Stała" },
      { id: 1, title: "Standard" },
      { id: 2, title: "Tabela" },
    ];

    const productConfiguration =
      product.productConfiguration && product.productConfiguration.weight
        ? product.productConfiguration.weight
        : this.getDefaultConfiguration().weight;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Waga</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">Rodzaj kalkulacji</Col>
            <Col md="8">
              <Input
                className="form-control mb-3"
                type="select"
                name="type"
                value={productConfiguration.type}
                onChange={(event) =>
                  this.changeProductConfigurationEvent("weight", event)
                }
              >
                <option disabled value="">
                  Wybierz
                </option>
                {pricingTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.title}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="4">Stała bazowa</Col>
            <Col md="8" className="mb-3">
              <InputGroup>
                <NumericInput
                  placeholder="Stała bazowa"
                  step={0.001}
                  name="base"
                  value={productConfiguration.base || ""}
                  onChange={(event) =>
                    this.changeProductConfigurationEvent("weight", event)
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>g</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          {productConfiguration.type === 2 ? (
            <Row>
              <Col md="12">
                <h4 className="mb-5">Tabela wagi</h4>
              </Col>
              <Col md="3">
                <Button color="success" block={true}>
                  <i className="fas fa-download"></i> Czysta
                </Button>
              </Col>
              <Col md="3">
                <Button color="success" block={true} disabled={true}>
                  <i className="fas fa-download"></i> Aktualna
                </Button>
              </Col>
              <Col md="4">
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="projectCoverUploads"
                    type="file"
                  />
                </div>
              </Col>
              <Col md="2">
                <Button color="primary" block={true}>
                  <i className="fas fa-upload"></i>
                </Button>
              </Col>
            </Row>
          ) : (
            <React.Fragment />
          )}
        </CardBody>
      </Card>
    );
  };

  renderCardMetaData = () => {
    const { product } = this.state;
    const { currentCulture } = this.state;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      product.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Metadane</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">Tytuł</Col>
            <Col md="9">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-font" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="metaTitle"
                    value={editedTranslation?.metaTitle || ""}
                    onChange={this.changeProductTranslation}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="3">Opis</Col>
            <Col md="9">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-font" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="metaDescription"
                    value={editedTranslation?.metaDescription || ""}
                    onChange={this.changeProductTranslation}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="3">Słowa kluczowe</Col>
            <Col md="9">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-font" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Słowa kluczowe"
                    type="text"
                    name="metaKeywords"
                    value={editedTranslation?.metaKeywords || ""}
                    onChange={this.changeProductTranslation}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.product || this.props.loading;

    const { product } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/products/" />;
    }

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name={product ? product.currentTranslation?.title : ""}
          link="/admin/products"
          parentName="Produkty"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Form onSubmit={this.submitForm}>
            {loading ? this.loadingOverlay() : ""}
            <Row>
              <Col lg="6">
                <div className="card-wrapper">
                  <ProductData
                    product={this.state.product}
                    categories={this.state.categories}
                    currentCulture={this.state.currentCulture}
                    changeCulture={this.changeCulture}
                    changeProductData={this.changeProductData}
                    changeProductTranslation={this.changeProductTranslation}
                    translate={this.translate}
                  />
                  {this.renderCardHints()}
                </div>
              </Col>
              <Col lg="6">
                <div className="card-wrapper">
                  {this.renderCardMetaData()}
                  {this.renderCardDescription()}
                </div>
              </Col>
              {product && product.id ? (
                <>
                  <Col lg="12">
                    <h2>Podstawowa konfiguracja</h2>
                  </Col>
                  <Col lg="5">
                    <div className="card-wrapper">
                      {this.renderCardConfigurationFormat()}
                      {this.renderCardConfigurationWeight()}
                      {this.renderCardConfigurationProject()}
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="card-wrapper">
                      {this.renderCardConfigurationAmount()}
                      {this.renderCardConfigurationPricing()}
                      {this.renderCardConfigurationPages()}
                    </div>
                  </Col>
                  <Col lg="12">
                    <h2>Personalizacja produktu</h2>
                  </Col>
                  <Col lg="6">
                    <ProductAdditives
                      product={this.state.product}
                      loading={this.state.loading}
                      additives={this.state.additives}
                      additiveGroups={this.state.additiveGroups}
                      changeProductAdditive={this.changeProductAdditive}
                      orderAdditiveGroup={this.orderAdditiveGroup}
                      orderAdditive={this.orderAdditive}
                    />
                  </Col>
                  <Col lg="6">
                    <ProductOptions
                      product={this.state.product}
                      loading={this.state.loading}
                      attributes={this.state.attributes}
                      options={this.state.options}
                      changeProductOption={this.changeProductOption}
                      orderAttribute={this.orderAttribute}
                      orderOption={this.orderOption}
                    />
                  </Col>
                </>
              ) : (
                <React.Fragment />
              )}
            </Row>
          </Form>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    let { action } = this.state;
    if (action === "update" && !this.props.loading && prevProps.loading) {
      if (this.props.product) {
        this.notify("Produkt", "Pomyślnie zapisano produkt...", "success");
      } else {
        this.notify(
          "Produkt",
          "Wystąpił błąd w trakcie zapisywania produktu...",
          "danger",
        );
      }
    }
    if (prevProps.product !== this.props.product) {
      let product = this.props.product;
      if (
        product.productConfiguration &&
        product.productConfiguration.pricing
      ) {
        product.productConfiguration.pricing.sheet =
          product.productConfiguration.pricing.sheet &&
            product.productConfiguration.pricing.sheet.length > 0
            ? product.productConfiguration.pricing.sheet
            : null;
        product.productConfiguration.pricing.prints = this.pureProductPrints(
          product.productConfiguration.pricing,
        );
      }
      this.setState({
        product: product,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.categories !== this.props.categories) {
      this.setState({
        categories: this.props.categories,
      });
    }
    if (prevProps.additives !== this.props.additives) {
      this.setState({
        additives: this.props.additives,
      });
    }
    if (prevProps.additiveGroups !== this.props.additiveGroups) {
      this.setState({
        additiveGroups: this.props.additiveGroups,
      });
    }
    if (prevProps.attributes !== this.props.attributes) {
      this.setState({
        attributes: this.props.attributes,
      });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
    if (prevProps.printFormats !== this.props.printFormats) {
      this.setState({
        printFormats: this.props.printFormats,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    product: state.products.current,
    loading: state.products.loading,
    auth: state.auth.user,
    session: state.session,
    categories: state.productCategories.list,
    additives: state.productAdditives.list,
    additiveGroups: state.productAdditiveGroups.list,
    attributes: state.productAttributes.list,
    options: state.productOptions.list,
    printFormats: state.printFormats.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(productsActions.read(id)),
    remove: (id) => dispatch(productsActions.remove(id)),
    update: (product) => dispatch(productsActions.update(product)),
    create: (product) => dispatch(productsActions.create(product)),
    listCategories: () => dispatch(productCategoriesActions.list(0, 1000)),
    listAdditives: () => dispatch(productAdditivesActions.list(0, 1000)),
    listAdditiveGroups: () =>
      dispatch(productAdditiveGroupsActions.list(0, 1000)),
    listAttributes: () => dispatch(productAttributesActions.list(0, 1000)),
    listOptions: () => dispatch(productOptionsActions.list(0, 1000)),
    listPrintFormats: () => dispatch(printFormatsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product),
);

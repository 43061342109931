import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productCategoriesActions from "stores/products/productCategories/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class ProductCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      category: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        category: {
          id: undefined,
          hidden: false,
          translations: [],
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeCategoryData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var category = { ...this.state.category };
    category[name] = value;
    this.setState({ category });
  };

  changeCategoryTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var category = { ...this.state.category };
    if (!category.translations || category.translations.length === 0) {
      category.translations.push({ culture: currentCulture });
    }
    category.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ category });
  };

  changeCategoryTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeCategoryTranslationEx(name, value);
  };

  submitForm = (event) => {
    var category = { ...this.state.category };
    if (category.id) {
      this.setState({ action: "update" });
      this.props.update(category);
    } else {
      this.setState({ action: "create" });
      this.props.create(category);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.category };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ category: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.category.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.category || this.props.loading;

    const { category } = this.state;
    const { auth } = this.props;

    if (!category || category.id === auth.id || !category.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.category || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { category } = this.state;

    if (!category || !category.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={category ? category.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { category } = this.state;
    const { currentCulture } = this.state;

    if (!category) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = category.translations
      ? category.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeCategoryTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="description"
                    value={
                      editedTranslation ? editedTranslation?.description : ""
                    }
                    onChange={this.changeCategoryTranslation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.category || this.props.loading;

    const { category } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/products/categories/" />;
    }

    return (
      <>
        <SimpleHeader
          name={category ? category.currentTranslation?.title : ""}
          link="/admin/products/categories"
          parentName="Kategorie produktów"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.setState({
        category: this.props.category,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    category: state.productCategories.current,
    loading: state.productCategories.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(productCategoriesActions.read(id)),
    remove: (id) => dispatch(productCategoriesActions.remove(id)),
    update: (category) => dispatch(productCategoriesActions.update(category)),
    create: (category) => dispatch(productCategoriesActions.create(category)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCategory),
);

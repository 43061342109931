import React from 'react';

export default function CultureFlag(props) {
  const { className } = props;
  const code = getCountryCodeByLocaleCode(props.code);
  return (
    <img
      src={`https://flagcdn.com/w80/${code}.webp`}
      width="20"
      alt={code}
      className={className}
    />
  )
}

const getCountryCodeByLocaleCode = (localeCode) => {
  if (!localeCode) return undefined;
  localeCode = localeCode.toUpperCase()
  const mappings = {
    EN: "gb",
    UK: "ua"
  };

  if (mappings.hasOwnProperty(localeCode)) {
    return mappings[localeCode];
  }

  const countryName = new Intl.DisplayNames(['pl'], { type: 'region' });
  const name = countryName.of(localeCode);
  if (localeCode !== name) {
    return localeCode.toLowerCase();
  }

  return "";
}
import React from "react";
import localizer from "stores/localizer";

function FileStatus(props) {
  const { status } = props;
  if (status === null || status === undefined) {
    return <span>Oczekuje</span>;
  }
  return <span>{status ? "Zaakceptowany" : "Odrzucony"}</span>;
}

function Price(props) {
  const { amount } = props;
  return (
    <span>
      {new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "PLN",
      }).format(amount)}
    </span>
  );
}

class OrderProductPrint extends React.Component {
  render() {
    const { product } = this.props;
    if (!product) {
      return <div></div>;
    }
    const verifivicationLog = [
      ...product.projects?.map((f) => ({ ...f, type: "Projekt" })),
      ...product.previews?.map((f) => ({ ...f, type: "Plik poglądowy" })),
    ].sort((a, b) => a.stat.lastModified > b.stat.lastModified);
    const customerFiles = product.personalization?.project
      ? [...product.materials?.map((f) => ({ ...f, type: "Materiały" }))]
      : [...product.projects?.map((f) => ({ ...f, type: "Projekt" }))];
    const productTitle =
      localizer(product.product)?.currentTranslation?.title ||
      product.title ||
      "Produkt niestandardowy";
    const projectLabel = product.personalization?.project
      ? "Wykonywany przez drukarnie"
      : "Dostarczany przez klienta";
    const checkingLabel = product.personalization?.checking
      ? "Rozszerzone"
      : "Standardowe";
    return (
      <div
        style={{
          breakInside: "avoid",
          breakAfter: "always",
          pageBreakInside: "avoid",
          pageBreakAfter: "always",
        }}
      >
        <hr />
        <table width="100%" cellPadding="5" border="1" cellSpacing="0">
          <tbody>
            <tr>
              <th width="50%">
                <h2>{product.number}</h2>
                <h3>{productTitle}</h3>
              </th>
              <td>
                <b>Produkt:</b>{" "}
                {localizer(product.product)?.currentTranslation?.title || "Niestandardowy"}
                <br />
                <b>Projekt:</b> {projectLabel}
                <br />
                <b>Sprawdzanie:</b> {checkingLabel}
                <br />
                <hr />
                <b>Wartość produktu:</b>{" "}
                <Price amount={product.priceObj?.netCost} /> /{" "}
                <Price amount={product.priceObj?.grossCost} />
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table width="100%" border="1" cellSpacing="0" cellPadding="5">
          <tbody>
            <tr>
              <th width="25%">Identyfikator</th>
              <td width="25%">{product.number}</td>
              <th width="25%">Nazwa</th>
              <td width="25%">{productTitle}</td>
            </tr>
            <tr>
              <th>Nakład</th>
              <td>{product.personalization?.amount}</td>
              <th>Czas realizacji</th>
              <td>{product.personalization?.realizationTimeType}</td>
            </tr>
            <tr>
              <th>Wymiary</th>
              <td>
                {product.personalization?.format?.x} x{" "}
                {product.personalization?.format?.y} mm
              </td>
              <th>Stronicowanie</th>
              <td>
                {product.personalization?.pages?.inside} wewnetrzne,{" "}
                {product.personalization?.pages?.outside} zewnetrzne
              </td>
            </tr>
            <tr>
              <th>Projekt</th>
              <td>{projectLabel}</td>
              <th>Sprawdzanie</th>
              <td>{checkingLabel}</td>
            </tr>
            <tr>
              <th>Cena</th>
              <td>
                <Price amount={product.priceObj?.grossCost} /> brutto
                <br />
                <small>
                  <Price amount={product.priceObj?.netCost} /> netto
                </small>
              </td>
              <th>Termin realizacji</th>
              <td>{product.realizationTerm.substr(0, 10)}</td>
            </tr>
            <tr>
              <th colSpan={2}>Opcje</th>
              <th colSpan={2}>Dodatki</th>
            </tr>
            <tr>
              <td valign="top" colSpan={2}>
                <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
                  {product.personalization?.optionsLoaded?.map((option) => (
                    <li key={option.id}>
                      <b>{localizer(option.attribute).currentTranslation?.title}</b>:{" "}
                      {localizer(option).currentTranslation?.title}
                    </li>
                  ))}
                </ul>
              </td>
              <td valign="top" colSpan={2}>
                <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
                  {product.personalization?.additivesLoaded?.map((additive) =>
                    additive.group ? (
                      <li key={additive.id}>
                        <b>{localizer(additive.group).currentTranslation?.title}</b>:{" "}
                        {localizer(additive).currentTranslation?.title}
                      </li>
                    ) : (
                      <li key={additive.id}>
                        <b>{localizer(additive).currentTranslation?.title}</b>
                      </li>
                    ),
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table width="100%" border="1" cellSpacing="0" cellPadding="5">
          <tbody>
            <tr>
              <th width="50%">Lista plików wgranych przez klienta</th>
              <th>Wycena</th>
            </tr>
            <tr>
              <td valign="top">
                <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
                  {customerFiles?.map((log) => (
                    <li>
                      {log.objectName}
                      <br />
                      <small>
                        <small>
                          {log.type}, {log.stat.lastModified.substr(0, 10)}
                        </small>
                      </small>
                    </li>
                  ))}
                </ul>
              </td>
              <td valign="top" rowSpan={7}>
                {product.technicalFeedback?.split("\n").map((f, i) => (
                  <div key={i}>
                    <small>
                      <small>{f}</small>
                    </small>
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>Historia weryfikacyjna</th>
            </tr>
            <tr>
              <td valign="top">
                <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
                  {verifivicationLog?.map((log) => (
                    <li>
                      {log.objectName}
                      <br />
                      <small>
                        <small>
                          {log.type}, {log.stat.lastModified.substr(0, 10)},{" "}
                          <FileStatus status={log.accepted} />
                        </small>
                      </small>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th>Uwagi do projektu</th>
            </tr>
            <tr>
              <td valign="top">
                <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
                  {product.notes?.map((n) => (
                    <li>
                      {n.content}
                      <br />
                      <small>
                        <small>
                          {n.created.substr(0, 10)}, {n.title}
                        </small>
                      </small>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <th>Pliki poglądowe</th>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export class OrderPrint extends React.Component {
  render() {
    const { order, products } = this.props;
    if (!order) {
      return <div></div>;
    }
    return (
      <div
        className="d-none"
        style={{
          overflow: "clip",
          margin: "2.5%",
          fontFamily: "Arial",
          fontSize: "9px",
        }}
      >
        <br />
        <div
          style={{
            breakInside: "avoid",
            breakAfter: "always",
            pageBreakInside: "avoid",
            pageBreakAfter: "always",
          }}
        >
          <table width="100%" cellPadding="5" border="1" cellSpacing="0">
            <tbody>
              <tr>
                <th width="50%">
                  <h1>{order.number}</h1>
                  <h3>Zamówienie z dnia {order.ordered?.substr(0, 10)}</h3>
                </th>
                <td>
                  <b>Metoda dostawy:</b>{" "}
                  {localizer(order.shippingMethod)?.currentTranslation?.title}
                  <br />
                  <b>Metoda płatności:</b>{" "}
                  {localizer(order.paymentMethod)?.currentTranslation?.title}
                  <br />
                  <hr />
                  <b>Wartość produktów:</b>{" "}
                  <Price
                    amount={products
                      ?.map((p) => p.priceObj?.netCost || 0)
                      .reduce((a, b) => a + b, 0)}
                  />
                  {" "}/{" "}
                  <Price
                    amount={products
                      ?.map((p) => p.priceObj?.grossCost || 0)
                      .reduce((a, b) => a + b, 0)}
                  />
                  <br />
                  <b>Wartość zamówienia:</b>{" "}
                  <Price amount={order.priceSum?.netCost} />
                  {" "}/{" "}
                  <Price amount={order.priceSum?.grossCost} />
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table width="100%" cellPadding="5" border="1" cellSpacing="0">
            <tbody>
              <tr>
                <th width="33%">Zamawiający</th>
                <th>Adres dostawy</th>
                <th width="33%">Dane rozliczeniowe</th>
              </tr>
              <tr>
                <td>
                  <b>
                    {order.user
                      ? order.user.givenName + " " + order.user.surname
                      : order.email && order.phone
                        ? "Gość"
                        : "Brak"}
                  </b>
                  <br />
                  mail: {order.user?.email || order.email || ""}
                  <br />
                  tel.: {order.phone || order.shippingAddress?.phone || ""}
                </td>
                <td>
                  {order.shippingAddress?.person}
                  {order.shippingAddress?.person && <br />}
                  {order.shippingAddress?.business}
                  {order.shippingAddress?.business && <br />}
                  {order.shippingAddress?.line1}
                  <br />
                  {order.shippingAddress?.line2}
                  {order.shippingAddress?.line2 && <br />}
                  {order.shippingAddress?.postalCode}{" "}
                  {order.shippingAddress?.city}
                  <br />
                  {order.shippingAddress?.email}
                  <br />
                  {order.shippingAddress?.phone}
                </td>
                <td>
                  {order.billingInfo?.buyerName}
                  {order.billingInfo?.buyerName && <br />}
                  {order.billingInfo?.vatId}
                  {order.billingInfo?.vatId && <br />}
                  {order.billingInfo?.line1}
                  <br />
                  {order.billingInfo?.line2}
                  {order.billingInfo?.line2 && <br />}
                  {order.billingInfo?.postalCode} {order.billingInfo?.city}
                  <br />
                  {order.billingInfo?.email}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {products?.map((product) => (
          <OrderProductPrint key={product.id} product={product} />
        ))}
      </div>
    );
  }
}

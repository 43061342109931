import React from "react";

import Dropzone from "dropzone";
import { Row, Col, FormGroup, Input, Label, Spinner } from "reactstrap";

import onChangeParser from "views/pages/products/OnChangeParser";
import dotNotationParser from "views/pages/products/DotNotationParser";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

export default class OrderProductAdditives extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  parseValue = onChangeParser;
  changeDotNotationValue = dotNotationParser;
  onUpdate = this.props.onUpdate;

  changeOrderProductAdditive = (e) => {
    const { orderProduct } = this.props;
    let additives =
      this.props.orderProduct.personalization &&
        this.props.orderProduct.personalization.additives
        ? [...this.props.orderProduct.personalization.additives]
        : [];
    const additive = [...this.props.product.additives].find(
      (a) => a.id === e.target.value,
    );
    if (
      (e.target.type === "select-one" || e.target.type === "radio") &&
      additive.groupId
    ) {
      const additivesInGroup = [...this.props.product.additives]
        .filter((a) => a.groupId === additive.groupId)
        .map((a) => a.id);
      additives = additives.filter((a) => !additivesInGroup.includes(a));
    }
    additives.push(additive.id);
    orderProduct.personalization.additives = additives;
    this.onUpdate(orderProduct);
  };

  renderAdditive = (additive, type) => {
    const { orderProduct } = this.props;
    return (
      <Row key={additive.id}>
        <Col md={12}>
          <FormGroup check>
            <Label check>
              <Input
                type={type}
                id={additive.id}
                name={additive.groupId}
                value={additive.id}
                onChange={this.changeOrderProductAdditive}
                checked={
                  orderProduct.personalization &&
                    orderProduct.personalization.additives
                    ? orderProduct.personalization.additives.includes(
                      additive.id,
                    )
                    : false
                }
              />
              {localizer(additive)?.currentTranslation?.title}
            </Label>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  renderAdditiveGroup = (group, additives) => {
    const { orderProduct, product } = this.props;
    const groupAdditives = product.additives.filter(
      (a) => a.groupId === group.id,
    );
    switch (group.type) {
      case 0:
      case 1:
        return (
          <div className="mb-3">
            <Row key={group.id}>
              <Col md={6}>
                <Label for={group.id}>{localizer(group)?.currentTranslation?.title}</Label>
              </Col>
              <Col md={6}>
                <Input
                  type="select"
                  name={group.id}
                  onChange={this.changeOrderProductAdditive}
                  value={groupAdditives
                    .map((o) => o.id)
                    .find((o) =>
                      orderProduct?.personalization?.additives?.includes(o),
                    )}
                >
                  {additives.map((a) => (
                    <option value={a.id} key={a.id}>
                      {localizer(a)?.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </div>
        );
      case 2:
      case 3:
        return (
          <div className="mb-3">
            <Row key={group.id}>
              <Col md={12}>
                <Label for={group.id}>{localizer(group)?.currentTranslation?.title}</Label>
              </Col>
            </Row>
            {this.renderAdditivesArray(
              additives,
              group.type === 2 ? "checkbox" : "radio",
            )}
          </div>
        );
      default:
        return <React.Fragment />;
    }
  };

  renderAdditivesArray = (array, type) => {
    return array.map((a) => this.renderAdditive(a, type || "radio"));
  };

  render = () => {
    const { orderProduct, product } = this.props;

    if (!orderProduct || !product) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    var groupIds = [
      ...new Set([...product.additives].map(({ groupId }) => groupId)),
    ];
    return (
      <React.Fragment>
        {groupIds.map((groupId) => {
          const additives = [...product.additives].filter(
            (a) => a.groupId === groupId,
          );
          const group = [...additives][0].groupId
            ? [...product.additiveGroups].find(
              (g) => g.id === [...additives][0].groupId,
            )
            : null;
          const additivesRender = this.renderAdditivesArray(additives);
          if (!group) {
            return additivesRender;
          }
          return this.renderAdditiveGroup(group, additives);
        })}
        {this.renderAdditivesArray(product.additives.filter((a) => !a.groupId))}
      </React.Fragment>
    );
  };
}

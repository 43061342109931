import React from "react";
import classnames from "classnames";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as shippingMethodsActions from "stores/orders/shippingMethods/actions";
import * as paymentMethodsActions from "stores/orders/paymentMethods/actions";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import { API_URL as COLLECT_POINTS_API_URL } from "stores/orders/collectPoints/epics";
import { API_URL as SHIPPING_ADDRESSES_API_URL } from "stores/orders/shippingAddresses/epics";
import { API_URL as SENDER_ADDRESSES_API_URL } from "stores/orders/senderAddresses/epics";
import { API_URL as BILLING_INFOS_API_URL } from "stores/orders/billingInfos/epics";

import ShippingAddressForm from "./ShippingAddressForm";
import AsyncSelector from "./AsyncSelector";
import BillingInfoForm from "./BillingInfoForm";
import CultureSelector from "views/pages/products/products/Cards/CultureSelector";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery:
        this.props.order && this.props.order.shippingMethod
          ? this.props.order.shippingMethod.isShippingAddressRequired
          : false,
      shippingMethods: [],
      shippingMethodsLoaded: false,
      shippingAddresses: [],
      shippingAddressesLoaded: false,
      senderAddresses: [],
      senderAddressesLoaded: false,
      collectPoints: [],
      collectPointsLoaded: false,
      paymentMethods: [],
      paymentMethodsLoaded: false,
      billingInfos: [],
      billingInfosLoaded: false,
    };
    this.myRef = React.createRef();
  }

  loadingOverlay = () => {
    return this.props.loadingOverlay();
  };

  changeOrderData = (e) => {
    const value = this.props.parseValue(e.target);

    if (value.value === "_") {
      switch (value.name) {
        case "shippingAddressId":
          this.props.changeOrderDataEx("shippingAddress", {});
          break;
        case "billingInfoId":
          this.props.changeOrderDataEx("billingInfo", {});
          break;
        case "senderAddressId":
          this.props.changeOrderDataEx("senderAddress", {});
          break;
        default:
          break;
      }
    }

    this.props.changeOrderDataEx(value.name, value.value);
  };

  changeOrderProperty = (e, propertyName) => {
    const value = this.props.parseValue(e.target);
    let property = {
      ...this.props.order[propertyName],
      [value.name]: value.value,
    };
    this.props.changeOrderDataEx(propertyName, property);
  };

  renderUser = (user) => ({ value: user.id, label: user.username });

  renderPaymentMethod = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  filterPaymentMethods = (inputValue) => {
    const { paymentMethods } = this.state;
    return new Promise(async (resolve, reject) => {
      let result = paymentMethods;
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map((m) => this.renderPaymentMethod(m));
      resolve(result);
    });
  };

  renderShippingMethod = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  filterShippingMethods = (inputValue) => {
    const { shippingMethods } = this.state;
    return new Promise((resolve, reject) => {
      let result = shippingMethods.filter((m) => m.isShippingAddressRequired);
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map((m) => this.renderShippingMethod(m));
      resolve(result);
    });
  };

  filterCollectMethods = (inputValue) => {
    const { shippingMethods } = this.state;
    return new Promise((resolve, reject) => {
      let result = shippingMethods.filter((m) => !m.isShippingAddressRequired);
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map((m) => this.renderShippingMethod(m));
      resolve(result);
    });
  };

  abstractAddressString = (address) =>
    `${address.line1}${address.line2 ? " " + address.line2 : ""}, ${address.postalCode
    } ${address.city}`;

  abstractContactString = (address) => {
    let contactString = [address.person, address.phone, address.email].filter(
      (a) => a,
    );
    return contactString.length > 0 ? "(" + contactString.join(", ") + ")" : "";
  };

  billingInfoLabel = (info) => {
    return (
      <div>
        <div>
          {info.buyerName ? info.buyerName + " - " : ""}{" "}
          {this.abstractAddressString(info)}
        </div>
        <div className="text-muted">
          <small>
            {info.vatID ? "VAT: " + info.vatID + " " : ""}
            {this.abstractContactString(info)}
          </small>
        </div>
      </div>
    );
  };

  senderAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  shippingAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  shippingAddressLabel = (address) => {
    let string = `${address.line1}${address.line2 ? " " + address.line2 : ""
      }, ${address.postalCode} ${address.city}`;
    let contactString = [address.person, address.phone, address.email].filter(
      (a) => a,
    );
    return (
      <div>
        <div>{string}</div>
        <div className="text-muted">
          <small>
            {contactString.length > 0
              ? "(" + contactString.join(", ") + ")"
              : ""}
          </small>
        </div>
      </div>
    );
  };

  listBillingInfos = () => {
    const { order } = this.props;
    if (!order || !order.userId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        userId: {
          filterVal: order.userId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    };
    apiDriver
      .get(BILLING_INFOS_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({ billingInfos: data || [], billingInfosLoaded: true });
      });
  };

  listShippingAddresses = () => {
    const { order } = this.props;
    if (!order || !order.userId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        userId: {
          filterVal: order.userId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    };
    apiDriver
      .get(SHIPPING_ADDRESSES_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({
          shippingAddresses: data || [],
          shippingAddressesLoaded: true,
        });
      });
  };

  listSenderAddresses = () => {
    const { order } = this.props;
    if (!order || !order.userId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        userId: {
          filterVal: order.userId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    };
    apiDriver
      .get(SENDER_ADDRESSES_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({
          senderAddresses: data || [],
          senderAddressesLoaded: true,
        });
      });
  };

  listCollectPoints = () => {
    const { order } = this.props;
    if (!order || !order.shippingMethodId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        methodId: {
          filterVal: order.shippingMethodId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "methodId",
        },
      },
    };
    apiDriver
      .get(COLLECT_POINTS_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({ collectPoints: data || [], collectPointsLoaded: true });
      });
  };

  renderBillingInfo = (address) => ({
    value: address.id,
    label: this.billingInfoLabel(address),
  });
  renderShippingAddress = (address) => ({
    value: address.id,
    label: this.shippingAddressLabel(address),
  });
  renderSenderAddress = (address) => ({
    value: address.id,
    label: this.senderAddressLabel(address),
  });

  filterShippingAddresses = (inputValue) => {
    const { shippingAddresses } = this.state;
    return new Promise((resolve, reject) => {
      let options = shippingAddresses || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.title.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderShippingAddress(option));
      resolve(options);
    });
  };

  filterSenderAddresses = (inputValue) => {
    const { senderAddresses } = this.state;
    return new Promise((resolve, reject) => {
      let options = senderAddresses || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.title.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderSenderAddress(option));
      resolve(options);
    });
  };

  filterBillingInfos = (inputValue) => {
    const { billingInfos } = this.state;
    inputValue = inputValue.toLowerCase();
    return new Promise((resolve, reject) => {
      let options = billingInfos || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.buyerName.toLowerCase().includes(inputValue) ||
            a.vatID.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderBillingInfo(option));
      resolve(options);
    });
  };

  filterCollectPoints = (inputValue) => {
    const { collectPoints } = this.state;
    inputValue = inputValue.toLowerCase();
    return new Promise((resolve, reject) => {
      let options = collectPoints || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.title.toLowerCase().includes(inputValue) ||
            a.person.toLowerCase().includes(inputValue) ||
            a.email.toLowerCase().includes(inputValue) ||
            a.phone.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderShippingAddress(option));
      resolve(options);
    });
  };

  loadUsers = async (inputValue) => {
    const result = await apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise();
    const users = result.response;
    return users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  };

  renderId = () => {
    const { order } = this.props;

    if (!order || !order.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={order ? order.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardPayment = () => {
    const { order, loading } = this.props;
    const { paymentMethods, paymentMethodsLoaded, billingInfos } = this.state;

    if (!order || !paymentMethodsLoaded) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Płatność / dane rozliczeniowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Metoda płatności</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Metoda płatności"
                    name="paymentMethodId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={paymentMethods.map(this.renderPaymentMethod)}
                    value={order.paymentMethodId}
                    onChange={this.changeOrderData}
                    onSearch={this.filterPaymentMethods}
                  />
                </FormGroup>
              </Col>
              {order.userId && (
                <>
                  <Col md="3">Dane rozliczeniowe</Col>
                  <Col md="9">
                    <FormGroup>
                      <AsyncSelector
                        title="Dane rozliczeniowe"
                        name="billingInfoId"
                        placeholder="Szukaj..."
                        allowNull={true}
                        allowCreate={true}
                        options={billingInfos.map(this.renderBillingInfo)}
                        value={order.billingInfoId}
                        onChange={this.changeOrderData}
                        onSearch={this.filterBillingInfos}
                        render={this.renderBillingInfo}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {order.billingInfoId === "_" || !order.userId ? (
                <Col md="12">
                  <BillingInfoForm
                    address={order.billingInfo}
                    onChange={(e) => this.changeOrderProperty(e, "billingInfo")}
                  />
                </Col>
              ) : (
                <React.Fragment />
              )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  isOrderDelivered = () => {
    const { delivery } = this.state;
    return delivery;
  };

  renderCardShipping = () => {
    const { order, loading } = this.props;

    if (!order) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dostawa / odbiór osobisty</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-3">
              <Col md="12">
                <Nav
                  className="nav-fill flex-column flex-sm-row"
                  id="tabs-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={this.isOrderDelivered()}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.isOrderDelivered(),
                      })}
                      onClick={(e) => this.setState({ delivery: true })}
                      role="tab"
                    >
                      Dostawa
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={!this.isOrderDelivered()}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: !this.isOrderDelivered(),
                      })}
                      onClick={(e) => this.setState({ delivery: false })}
                      role="tab"
                    >
                      Odbiór
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            {this.isOrderDelivered()
              ? this.renderDeliveryOptions()
              : this.renderCollectOptions()}
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCollectOptions = () => {
    const { order } = this.props;
    const {
      shippingMethods,
      shippingMethodsLoaded,
      collectPoints,
      collectPointsLoaded,
    } = this.state;

    if (!order || !shippingMethodsLoaded) {
      return <></>;
    }

    return (
      <Row>
        <Col md="3">Metoda odbioru</Col>
        <Col md="9">
          <FormGroup>
            <AsyncSelector
              title="Metoda odbioru"
              name="shippingMethodId"
              placeholder="Szukaj..."
              allowNull={true}
              options={shippingMethods
                .filter((m) => !m.isShippingAddressRequired)
                .map(this.renderShippingMethod)}
              value={order.shippingMethodId}
              onChange={this.changeOrderData}
              onSearch={this.filterCollectMethods}
            />
          </FormGroup>
        </Col>
        {collectPointsLoaded && (
          <>
            <Col md="3">Punkt odbioru</Col>
            <Col md="9">
              <FormGroup>
                <AsyncSelector
                  title="Punkt odbioru"
                  name="collectPointId"
                  placeholder="Szukaj..."
                  allowNull={true}
                  options={collectPoints.map(this.renderShippingAddress)}
                  value={order.collectPointId}
                  onChange={this.changeOrderData}
                  onSearch={this.filterCollectPoints}
                />
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
    );
  };

  renderDeliveryOptions = () => {
    const { order } = this.props;
    const { shippingAddresses, senderAddresses, shippingMethods, shippingMethodsLoaded } =
      this.state;

    if (!order || !shippingMethodsLoaded) {
      return <></>;
    }

    return (
      <Row>
        <Col md="3">Metoda dostawy</Col>
        <Col md="9">
          <FormGroup>
            <AsyncSelector
              title="Metoda dostawy"
              name="shippingMethodId"
              placeholder="Szukaj..."
              allowNull={true}
              options={shippingMethods
                .filter((m) => m.isShippingAddressRequired)
                .map(this.renderShippingMethod)}
              value={order.shippingMethodId}
              onChange={this.changeOrderData}
              onSearch={this.filterShippingMethods}
            />
          </FormGroup>
        </Col>
        {order.userId && (
          <>
            <Col md="3">Adres dostawy</Col>
            <Col md="9">
              <FormGroup>
                <AsyncSelector
                  title="Adres dostawy"
                  name="shippingAddressId"
                  placeholder="Szukaj..."
                  allowNull={true}
                  allowCreate={true}
                  options={shippingAddresses.map(this.renderShippingAddress)}
                  value={order.shippingAddressId}
                  onChange={this.changeOrderData}
                  onSearch={this.filterShippingAddresses}
                />
              </FormGroup>
            </Col>
          </>
        )}
        {order.shippingAddressId === "_" || !order.userId ? (
          <Col md="12">
            <ShippingAddressForm
              address={order.shippingAddress}
              onChange={(e) => this.changeOrderProperty(e, "shippingAddress")}
            />
          </Col>
        ) : (
          <React.Fragment />
        )}
        {order.userId && (
          <>
            <Col md="3">Adres nadawcy</Col>
            <Col md="9">
              <FormGroup>
                <AsyncSelector
                  title="Adres nadawcy"
                  name="senderAddressId"
                  placeholder="Szukaj..."
                  allowNull={true}
                  allowCreate={true}
                  options={senderAddresses.map(this.renderSenderAddress)}
                  value={order.senderAddressId}
                  onChange={this.changeOrderData}
                  onSearch={this.filterSenderAddresses}
                />
              </FormGroup>
            </Col>
          </>
        )}
        {order.senderAddressId === "_" ? (
          <Col md="12">
            <ShippingAddressForm
              address={order.senderAddress}
              onChange={(e) => this.changeOrderProperty(e, "senderAddress")}
            />
          </Col>
        ) : (
          <React.Fragment />
        )}
      </Row>
    );
  };

  renderCardTimestamps = () => {
    const { order, loading } = this.props;

    if (!order) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Stemple czasowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Utworzono</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Utworzono"
                      type="text"
                      name="created"
                      readOnly={true}
                      value={order.created ? new Date(order.created).toLocaleString() : ""}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Zaktualizowano</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Zaktualizowano"
                      type="text"
                      name="updated"
                      readOnly={true}
                      value={(order.updated ? (new Date(order.updated)).toLocaleString() : 'Nigdy')}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Zamówiono</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Zamówiono"
                      type="text"
                      name="ordered"
                      readOnly={true}
                      value={
                        order.ordered
                          ? new Date(order.ordered).toLocaleString()
                          : ""
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Zamknięto</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Zamknięto"
                      type="text"
                      name="closed"
                      readOnly={true}
                      value={
                        order.closed
                          ? new Date(order.closed).toLocaleString()
                          : ""
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Usunięto</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Usunięto"
                      type="text"
                      name="deleted"
                      readOnly={true}
                      value={
                        order.deleted
                          ? new Date(order.deleted).toLocaleString()
                          : ""
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { order, loading, statuses } = this.props;
    const { users } = this.state;

    if (!order) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Źródło</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-stream" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={order.source}
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Język</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-language" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <CultureSelector
                      currentCulture={order.culture}
                      changeCulture={this.changeOrderData}
                      name="culture"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Numer</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-barcode" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Numer"
                      type="text"
                      name="code"
                      readOnly={true}
                      value={order.number}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                Status
                <br />
                {!order.statusLock && (
                  <small>
                    <small className="text-muted">Automatyczny</small>
                  </small>
                )}
              </Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="select"
                      name="statusId"
                      value={order.statusId}
                      onChange={this.changeOrderData}
                    >
                      <option value="00000000-0000-0000-0000-000000000000">
                        Automatyczny
                      </option>
                      {statuses
                        ?.sort((a, b) => a.tag - b.tag)
                        .map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.tag}. {localizer(status)?.currentTranslation?.title}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                Zamawiający
                <br />
                <small>
                  <small className="text-muted">Klient</small>
                </small>
              </Col>
              <Col md="8">
                <FormGroup>
                  <AsyncSelector
                    title="Zamawiający"
                    name="userId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(users || (order.user ? [order.user] : [])).map(
                      this.renderUser,
                    )}
                    value={order.userId}
                    onChange={this.changeOrderData}
                    onSearch={this.loadUsers}
                  />
                </FormGroup>
              </Col>
              {!order.userId && (
                <>
                  <Col md="3">
                    Zamawiający
                    <br />
                    <small>
                      <small className="text-muted">Adres e-mail</small>
                    </small>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        onChange={this.changeOrderData}
                        name="email"
                        value={order.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    Zamawiający
                    <br />
                    <small>
                      <small className="text-muted">Numer telefonu</small>
                    </small>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        onChange={this.changeOrderData}
                        name="phone"
                        value={order.phone}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col md="1" className="mt-2 text-right">
                {order && order.userId ? (
                  <Link target="_blank" to={`/admin/customers/${order.userId}`}>
                    <i className="fas fa-external-link-alt"></i>
                  </Link>
                ) : (
                  <React.Fragment />
                )}
              </Col>
              <Col md="3">Opiekun</Col>
              <Col md="8">
                <FormGroup>
                  <AsyncSelector
                    title="Opiekun"
                    name="assigneeId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(
                      users || (order.assignee ? [order.assignee] : [])
                    ).map(this.renderUser)}
                    value={order.assigneeId}
                    onChange={this.changeOrderData}
                    onSearch={this.loadUsers}
                  />
                </FormGroup>
              </Col>
              <Col md="1" className="mt-2 text-right">
                {order && order.assigneeId ? (
                  <Link
                    target="_blank"
                    to={`/admin/cp/users/${order.assigneeId}`}
                  >
                    <i className="fas fa-external-link-alt"></i>
                  </Link>
                ) : (
                  <React.Fragment />
                )}
              </Col>
              {order.creator ? (
                <React.Fragment>
                  <Col md="3">Twórca</Col>
                  <Col md="9">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Twórca"
                          type="text"
                          name="creator"
                          readOnly={true}
                          value={order.creator.username}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </Row>
            <hr />
            <Row>
              <Col md="6">
                <h1 className="text-muted mt-3">Wycena zamówienia</h1>
              </Col>
              <Col md="6" className="text-right">
                <FormGroup>
                  <h1>
                    {new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "PLN",
                    }).format(order?.priceSum?.grossCost)} brutto
                  </h1>
                  <h3>
                    {new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "PLN",
                    }).format(order?.priceSum?.netCost)} netto
                  </h3>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <Row>
        <Col lg="6">
          <div className="card-wrapper">
            {this.renderCardData()}
            {this.renderCardTimestamps()}
          </div>
        </Col>
        <Col lg="6">
          <div className="card-wrapper">
            {this.renderCardShipping()}
            {this.renderCardPayment()}
          </div>
        </Col>
      </Row>
    );
  };

  onChangeUser = () => {
    const { order } = this.props;
    if (order?.userId) {
      this.setState({
        shippingAddressesLoaded: false,
        billingInfosLoaded: false,
        senderAddressesLoaded: false,
      });
      this.listShippingAddresses();
      this.listSenderAddresses();
      this.listBillingInfos();
    }
  };

  onChangeAddressData = (order, propertyId, propertyData, array) => {
    if (!order) {
      return;
    }

    const id = order[propertyId];
    if (id === "_") {
      return;
    }
    if (id === "") {
      //this.props.changeOrderDataEx(propertyData, null);
      return;
    }

    //const data = array.find((e) => e.id === order[propertyId]);
    //if (data) {
    //this.props.changeOrderDataEx(propertyData, data);
    //}
  };

  onChangeShippingAddress = () => {
    const { order } = this.props;
    const { shippingAddresses } = this.state;

    this.onChangeAddressData(
      order,
      "shippingAddressId",
      "shippingAddress",
      shippingAddresses,
    );
  };

  onChangeSenderAddress = () => {
    const { order } = this.props;
    const { senderAddress } = this.state;

    this.onChangeAddressData(
      order,
      "senderAddressId",
      "senderAddress",
      senderAddress,
    );
  };

  onChangeCollectPoint = () => {
    const { order } = this.props;
    const { collectPoints } = this.state;

    this.onChangeAddressData(
      order,
      "collectPointId",
      "collectPoint",
      collectPoints,
    );
  };

  onChangeBillingInfo = () => {
    const { order } = this.props;
    const { billingInfos } = this.state;

    this.onChangeAddressData(
      order,
      "billingInfoId",
      "billingInfo",
      billingInfos,
    );
  };

  onChangeShippingMethod = () => {
    const { order } = this.props;
    const { shippingMethods } = this.state;
    const shippingMethod =
      shippingMethods.find((s) => s.id === order?.shippingMethodId) ||
      order?.shippingMethod;
    if (!shippingMethod?.isShippingAddressRequired) {
      this.props.changeOrderDataEx("shippingAddressId", "");
      this.props.changeOrderDataEx("shippingAddress", null);
      this.props.changeOrderDataEx("senderAddressId", "");
      this.props.changeOrderDataEx("senderAddress", null);
      this.setState({ collectPointsLoaded: false, delivery: false });
      this.listCollectPoints();
    } else {
      this.props.changeOrderDataEx("collectPointId", "");
      this.props.changeOrderDataEx("collectPoint", null);
      this.setState({
        collectPoints: [],
        collectPointsLoaded: false,
        delivery: true,
      });
    }
  };

  componentDidMount = () => {
    const { order } = this.props;
    this.props.listShippingMethods();
    this.props.listPaymentMethods();
    if (order) {
      if (order?.userId) {
        this.onChangeUser();
      }
      if (order?.shippingMethod) {
        this.onChangeShippingMethod();
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this?.props?.order) {
      if (
        prevProps?.order?.userId !== this?.props?.order?.userId ||
        (prevProps?.order?.shippingAddressId === "_" &&
          prevProps?.order?.shippingAddressId !==
          this?.props?.order?.shippingAddressId) ||
        (prevProps?.order?.senderAddressId === "_" &&
          prevProps?.order?.senderAddressId !==
          this?.props?.order?.senderAddressId) ||
        (prevProps?.order?.billingInfoId === "_" &&
          prevProps?.order?.billingInfoId !== this?.props?.order?.billingInfoId)
      ) {
        this.onChangeUser();
      }
      if (
        prevProps?.order?.shippingMethodId !==
        this?.props?.order?.shippingMethodId
      ) {
        this.onChangeShippingMethod();
      }
      if (
        prevProps?.order?.shippingAddressId !==
        this?.props?.order?.shippingAddressId
      ) {
        this.onChangeShippingAddress();
      }
      if (
        prevProps?.order?.senderAddressId !==
        this?.props?.order?.senderAddressId
      ) {
        this.onChangeSenderAddress();
      }
      if (
        prevProps?.order?.billingInfoId !== this?.props?.order?.billingInfoId
      ) {
        this.onChangeBillingInfo();
      }
      if (
        prevProps?.order?.collectPointId !== this?.props?.order?.collectPointId
      ) {
        this.onChangeCollectPoint();
      }
    }
    if (prevProps.shippingMethods !== this.props.shippingMethods) {
      this.setState({
        shippingMethods: this.props.shippingMethods,
        shippingMethodsLoaded: true,
      });
    }
    if (prevProps.paymentMethods !== this.props.paymentMethods) {
      this.setState({
        paymentMethodsLoaded: true,
        paymentMethods: this.props.paymentMethods,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    shippingMethods: state.shippingMethods.list,
    paymentMethods: state.paymentMethods.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    listShippingMethods: () => dispatch(shippingMethodsActions.list(0, 1000)),
    listPaymentMethods: () => dispatch(paymentMethodsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderSummary),
);

import ISessionState from "./state";

const session = (state: ISessionState = { culture: "pl" }, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default session;

import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { connect } from "react-redux";
import * as authActions from "stores/iam/auth/actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {
        user: null,
        token: null,
      },
      email: "",
      password: "",
      loading: false,
    };
  }

  changeEmailState = (event) => {
    this.setState({ email: event.target.value });
  };

  changePasswordState = (event) => {
    this.setState({ password: event.target.value });
  };

  clickLoginButton = (event) => {
    this.props.login(this.state.email, this.state.password);
  };

  renderError() {
    const { error } = this.props;

    if (!error) {
      return <div />;
    }

    if (error === 401) {
      return (
        <div className="alert alert-danger">
          Podałeś nieprawidłowe dane logowania
        </div>
      );
    }

    return <div className="alert alert-danger">Wystąpił błąd</div>;
  }

  render() {
    const { token } = this.props;
    const { loading } = this.props;
    const { email } = this.state;
    const { password } = this.state;

    if (token) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <>
        <AuthHeader title="Witaj!" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-muted text-center my-2">
                    <small>Logowanie</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    {this.renderError()}
                    <FormGroup classnmae="is-invalid">
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          value={email}
                          onChange={this.changeEmailState}
                          disabled={loading}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={this.changePasswordState}
                          disabled={loading}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Zapamiętaj mnie</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={this.clickLoginButton}
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Zaloguj się"
                        )}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    token: state.auth.token,
    error: state.auth.error,
    loading: state.auth.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    login: (login, password) => dispatch(authActions.login(login, password)),
    logout: () => dispatch(authActions.logout()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

export const READ = "pbs/SHIPPING_STATUSES/READ/PENDING";
export const READ_SUCCESS = "pbs/SHIPPING_STATUSES/READ/SUCCESS";
export const CREATE = "pbs/SHIPPING_STATUSES/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/SHIPPING_STATUSES/CREATE/SUCCESS";
export const UPDATE = "pbs/SHIPPING_STATUSES/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/SHIPPING_STATUSES/UPDATE/SUCCESS";
export const REMOVE = "pbs/SHIPPING_STATUSES/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/SHIPPING_STATUSES/REMOVE/SUCCESS";
export const LIST = "pbs/SHIPPING_STATUSES/LIST/PENDING";
export const LIST_SUCCESS = "pbs/SHIPPING_STATUSES/LIST/SUCCESS";

export const ACTIVE = "pbs/SHIPPING_STATUSES/ACTIVE";

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as customersActions from "stores/orders/customers/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Tabs from "components/Tabs/Tabs";

import CustomerData from "./tabs/Data";
import CustomerRebates from "./tabs/Rebates";
import CustomerAddresses from "./tabs/Addresses";
import CustomerOrders from "./tabs/Orders";
import CustomerInvoices from "./tabs/Invoices";
import CustomerNotifications from "./tabs/Notifications";
import NotificationAlert from "react-notification-alert";
import apiDriver from "stores/api.driver";
import config from "config/global";

Dropzone.autoDiscover = false;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customer: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
      currentTab: "data",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        customer: {
          id: undefined,
          givenName: "",
          surname: "",
          email: "",
          newPassword: "",
          confirmNewPassword: "",
          culture: 'pl',
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  setCurrentTab = (id) => this.setState({ ...this.state, currentTab: id });

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  parseValue = (target) => {
    const { name, value, checked, type } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "select":
      case "select-one":
        if (parsedValue === "null") {
          parsedValue = null;
        }
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeCustomerDate = (name, value) => {
    const valueSet = this.parseValue({
      name: name,
      value: value,
      checked: false,
      type: "datetime",
    });
    let customer = { ...this.state.customer, [valueSet.name]: valueSet.value };
    this.setState({ customer });
  };

  changeCustomerDataEx = (name, value) => {
    let customer = { ...this.state.customer, [name]: value };
    this.setState({ customer });
  };

  changeCustomerDataArray = (data) => {
    let customer = { ...this.state.customer, ...data };
    this.setState({ customer });
  };

  changeCustomerData = (event) => {
    const value = this.parseValue(event.target);
    let customer = { ...this.state.customer };
    customer = { ...customer, [value.name]: value.value };
    this.setState({ customer });
  };

  authorize = () => {
    const { id, culture } = this.state.customer;
    const dashboardHost = window.location.host;
    let clientHost = dashboardHost.replace('dashboard.', '');
    apiDriver
      .get(`${config.api.orders}pl/Customers/${id}/Authorize`)
      .subscribe({
        next: (r) => window.location.href = `https://${clientHost}/${culture}/authorize/${r.response}`
      });
  };

  submitForm = (event) => {
    const { assignee, business, ...customer } = { ...this.state.customer };
    if (customer.id) {
      this.setState({ action: "update" });
      this.props.update(customer);
    } else {
      this.setState({ action: "create" });
      this.props.create(customer);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickRemove = () => {
    const { customer } = this.state;
    this.props.remove(customer.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.customer || this.props.loading;

    const { customer } = this.state;
    const { auth } = this.props;

    if (!customer || customer.id === auth.id || !customer.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickRemove} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.customer || this.props.loading;

    return (
      <div className="text-right">
        <Button color="secondary" onClick={this.authorize} disabled={loading}>
          Autoryzuj
        </Button>
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { customer } = this.state;

    if (!customer || !customer.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={customer ? customer.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardPassword = () => {
    const { customer } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Ustalenie hasla</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Hasło"
                      type="password"
                      name="newPassword"
                      value={customer ? customer.newPassword : ""}
                      onChange={this.changeCustomerData}
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Powtórz hasło"
                      type="password"
                      name="confirmNewPassword"
                      value={customer ? customer.confirmNewPassword : ""}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { customer } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Login</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Login"
                      type="text"
                      name="username"
                      value={customer.username}
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Adres e-mail</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Adres e-mail"
                      type="text"
                      name="email"
                      value={customer.email}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Imie</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Imie"
                      type="text"
                      name="givenName"
                      value={customer.givenName}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Nazwisko</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Nazwisko"
                      type="text"
                      name="surname"
                      value={customer.surname}
                      onChange={this.changeCustomerData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.customer || this.props.loading;
    const id = this.props.match.params.id;

    const { customer, action, currentTab } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/customers/" />;
    }

    const tabs = [
      { id: "data", title: "Dane" },
      //      { id: "stream", title: "Aktywność", disabled: true },
      { id: "addresses", title: "Adresy", disabled: id === 'create' },
      { id: "notifications", title: "Powiadomienia", disabled: id === 'create' },
      { id: "rebates", title: "Rabaty", disabled: id === 'create' },
      { id: "orders", title: "Zamowienia", disabled: id === 'create' },
      { id: "invoices", title: "Dokumenty sprzedazowe", disabled: id === 'create' },
    ];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name={customer ? customer.username : ""}
          link="/admin/customers"
          parentName="Klienci"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12" className="mb-3">
              <Tabs
                tabs={tabs}
                currentTab={currentTab}
                setCurrentTab={this.setCurrentTab}
              />
            </Col>
          </Row>
          {currentTab === "data" ? (
            <CustomerData
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              changeCustomerDataArray={this.changeCustomerDataArray}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
          {currentTab === "addresses" ? (
            <CustomerAddresses
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
          {currentTab === "rebates" ? (
            <CustomerRebates
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
          {currentTab === "notifications" ? (
            <CustomerNotifications
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
          {currentTab === "orders" ? (
            <CustomerOrders
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
          {currentTab === "invoices" ? (
            <CustomerInvoices
              loading={loading}
              customer={customer}
              changeCustomerDataEx={this.changeCustomerDataEx}
              parseValue={this.parseValue}
              loadingOverlay={this.loadingOverlay}
              notify={this.notify}
            />
          ) : (
            <React.Fragment />
          )}
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        customer: this.props.customer,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customers.current,
    loading: state.customers.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(customersActions.read(id)),
    remove: (id) => dispatch(customersActions.remove(id)),
    update: (customer) => dispatch(customersActions.update(customer)),
    create: (customer) => dispatch(customersActions.create(customer)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customer),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productAdditivesActions from "stores/products/productAdditives/actions";
import * as productAdditiveGroupsActions from "stores/products/productAdditiveGroups/actions";
import * as productAttributesActions from "stores/products/productAttributes/actions";
import * as productOptionsActions from "stores/products/productOptions/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import NumericInput from "components/Form/NumericInput";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class ProductAdditive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      additive: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      attributes: [],
      additives: [],
      options: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        additive: {
          id: undefined,
          hidden: false,
          realizationTime: 0,
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listGroups();
    this.props.listAdditives();
    this.props.listAttributes();
    this.props.listOptions();
  };

  getAdditiveTypes = () => [
    { id: 1, label: "Suma stała" },
    { id: 2, label: "Iloczyn stały" },
    { id: 3, label: "Suma razy nakład" },
    { id: 4, label: "Iloczyn razy nakład" },
    { id: 0, label: "Brak" },
  ];

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  toggleAdditiveConflict = (event) => {
    const { value, checked } = event.target;
    let additive = { ...this.state.additive };
    if (checked) {
      additive.additiveConflicts2.push({
        firstId: value,
      });
    } else {
      additive.additiveConflicts1 = [
        ...additive.additiveConflicts1.filter((a) => a.secondId !== value),
      ];
      additive.additiveConflicts2 = [
        ...additive.additiveConflicts2.filter((a) => a.firstId !== value),
      ];
    }
    this.setState({ additive });
  };

  toggleOptionConflict = (event) => {
    const { value, checked } = event.target;
    const parsedValue = Number(value);
    let additive = { ...this.state.additive };
    if (checked) {
      additive.optionConflicts.push({
        additiveId: additive.id,
        optionId: parsedValue,
      });
    } else {
      additive.optionConflicts = [
        ...additive.optionConflicts.filter((a) => a.optionId !== parsedValue),
      ];
    }
    this.setState({ additive });
  };

  changeAdditiveData = (event) => {
    const { name, value, type } = event.target;
    let parsedValue = value;
    switch (type) {
      case "checkbox":
        parsedValue = event.target.checked;
        break;
      case "number":
        parsedValue = value ? Number(value) : "";
        break;
      default:
        break;
    }
    if (parsedValue === "null") {
      parsedValue = null;
    }
    var additive = { ...this.state.additive };
    additive[name] = parsedValue;
    this.setState({ additive });
  };

  changeAdditiveTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var additive = { ...this.state.additive };
    if (!additive.translations || additive.translations.length === 0) {
      additive.translations.push({ culture: currentCulture });
    }
    additive.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ additive });
  };

  changeAdditiveTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeAdditiveTranslationEx(name, value);
  };

  submitForm = (event) => {
    var additive = { ...this.state.additive };
    additive.realizationTime = Number(additive.realizationTime);
    if (additive.id) {
      this.setState({ action: "update" });
      this.props.update(additive);
    } else {
      this.setState({ action: "create" });
      this.props.create(additive);
    }
  };

  onClickOptions = (row) => {
    this.setState({ action: "options" });
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.additive };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ additive: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.additive.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.additive || this.props.loading;

    const { additive } = this.state;
    const { auth } = this.props;

    if (!additive || additive.id === auth.id || !additive.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.additive || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { additive } = this.state;

    if (!additive || !additive.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={additive ? additive.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { additive, groups } = this.state;
    const { currentCulture } = this.state;

    if (!additive || !groups) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = additive.translations
      ? additive.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Właściwości dodatku</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Grupa</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="groupId"
                  value={additive.groupId}
                  onChange={this.changeAdditiveData}
                >
                  <option value="null">Wybierz</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeAdditiveTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="description"
                    value={
                      editedTranslation ? editedTranslation?.description : ""
                    }
                    onChange={this.changeAdditiveTranslation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardOptions = () => {
    const { additive } = this.state;

    if (!additive) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">Rodzaj wyceny</Col>
            <Col md="9">
              <FormGroup>
                <Input
                  placeholder="Rodzaj wyceny"
                  type="select"
                  name="type"
                  value={additive ? additive.type : ""}
                  onChange={this.changeAdditiveData}
                >
                  {this.getAdditiveTypes().map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="3">Algorytm wyceny</Col>
            <Col md="9">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-calculator" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Algorytm wyceny"
                    type="text"
                    name="algorithm"
                    value={additive ? additive.algorithm : ""}
                    onChange={this.changeAdditiveData}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="3">Czas realizacji</Col>
            <Col md="9">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-clock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <NumericInput
                    placeholder="Czas realizacji (h)"
                    name="realizationTime"
                    value={additive ? additive.realizationTime : null}
                    onChange={this.changeAdditiveData}
                    step={1}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Button color="secondary" href="#collapseExample" id="linkToggler">
            Dostępne wartości
          </Button>
          <UncontrolledCollapse toggler="#linkToggler">
            <div className="alert alert-info">
              <p>
                <b>Dostępne wartości dla wymiarów:</b>
              </p>
              <ul>
                <li>Size.X - szerokość</li>
                <li>Size.Y - wysokość</li>
                <li>Size.Area - powierzchnia</li>
                <li>Size.Circuit - obwód</li>
                <li>Size.Longest - dłuzszy bok</li>
                <li>Size.Shortest - krótszy bok</li>
              </ul>
              <p>
                <b>Dostępne wartości dla arkusza:</b>
              </p>
              <ul>
                <li>Sheet.Size.X - szerokość arkusza</li>
                <li>Sheet.Size.Y - wysokość arkusza</li>
                <li>Sheet.Size.Area - powierzchnia arkusza</li>
                <li>Sheet.Size.Circuit - obwód arkusza</li>
                <li>Sheet.Size.Longest - dłuzszy bok arkusza</li>
                <li>Sheet.Size.Shortest - krótszy bok arkusza</li>
                <li>Sheet.Margin.X - szerokość wycinki arkusza</li>
                <li>Sheet.Margin.Y - wysokość wycinki arkusza</li>
                <li>Sheet.Margin.Area - powierzchnia wycinki arkusza</li>
                <li>Sheet.Margin.Circuit - obwód wycinki arkusza</li>
                <li>Sheet.Margin.Longest - dłuzszy bok wycinki arkusza</li>
                <li>Sheet.Margin.Shortest - krótszy bok wycinki arkusza</li>
                <li>Sheet.Overprint.X - szerokość pola zadruku arkusza</li>
                <li>Sheet.Overprint.Y - wysokość pola zadruku arkusza</li>
                <li>
                  Sheet.Overprint.Area - powierzchnia pola zadruku arkusza
                </li>
                <li>Sheet.Overprint.Circuit - obwód pola zadruku arkusza</li>
                <li>
                  Sheet.Overprint.Longest - dłuzszy bok pola zadruku arkusza
                </li>
                <li>
                  Sheet.Overprint.Shortest - krótszy bok pola zadruku arkusza
                </li>
                <li>Sheet.Price - cena arkusza za metr kwadratowy</li>
              </ul>
              <p>
                <b>Dostępne wartości dla stronicowania:</b>
              </p>
              <ul>
                <li>Pages.Inside - ilość stron wewnętrznych</li>
                <li>Pages.Outside - ilość stron zewnętrznych</li>
              </ul>
              <p>
                <b>Dostępne wartości dla zamówionego produktu:</b>
              </p>
              <ul>
                <li>Amount - nakład</li>
                <li>Area - powierzchnia całkowita</li>
              </ul>
            </div>
          </UncontrolledCollapse>
        </CardBody>
      </Card>
    );
  };

  renderCardAdditiveConflicts = () => {
    const { additive } = this.state;

    if (!additive) {
      return <></>;
    }

    const loading = this.props.loading;
    const { additives } = this.state;

    let additiveGroups = [];
    additives.forEach((additive) => {
      if (additive.group) {
        const group = additive.group;
        if (!additiveGroups.some((g) => g.id === group.id)) {
          additiveGroups.push(group);
        }
      }
    });

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Konflikty (dodatki)</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {additiveGroups.map((group) => {
              let additivesInGroup = [0, 0];
              let additivesRender = (
                <>
                  {additives
                    .filter((a) => a.id !== additive.id)
                    .filter((a) => a.groupId === group.id)
                    .sort((a, b) =>
                      (a?.currentTranslation?.title || "").localeCompare(
                        b?.currentTranslation?.title || "",
                      ),
                    )
                    .map((a) => {
                      let checked =
                        (additive.additiveConflicts1
                          ? additive.additiveConflicts1.some(
                              (pa) => pa.secondId === a.id,
                            )
                          : false) ||
                        (additive.additiveConflicts2
                          ? additive.additiveConflicts2.some(
                              (pa) => pa.firstId === a.id,
                            )
                          : false);
                      additivesInGroup[0]++;
                      if (checked) {
                        additivesInGroup[1]++;
                      }
                      return (
                        <Col md="12" key={a.id}>
                          <UncontrolledCollapse
                            toggler={`#additiveGroup${group.id}head`}
                          >
                            <FormGroup>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  id={"additive" + a.id}
                                  type="checkbox"
                                  name="additives[]"
                                  value={a.id}
                                  checked={checked}
                                  onChange={(event) =>
                                    this.toggleAdditiveConflict(event)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"additive" + a.id}
                                >
                                  {a.currentTranslation?.title || ""}
                                </label>
                              </div>
                            </FormGroup>
                          </UncontrolledCollapse>
                        </Col>
                      );
                    })}
                </>
              );
              return (
                <Row key={group.id}>
                  <Col
                    md="12"
                    className="mb-3"
                    id={`additiveGroup${group.id}head`}
                  >
                    <b>{group.currentTranslation?.title}</b>{" "}
                    {additivesInGroup[1]}/{additivesInGroup[0]}
                  </Col>
                  {additivesRender}
                </Row>
              );
            })}
            <Row>
              <Col md="12" className="mb-3" id={`additiveGroup0head`}>
                <b>Niezalezne</b>
              </Col>
              {additives
                .filter((a) => !a.group)
                .map((a) => (
                  <Col md="12" key={a.id}>
                    <FormGroup>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          id={"additive" + a.id}
                          type="checkbox"
                          name="additives[]"
                          value={a.id}
                          checked={
                            (additive.additiveConflicts1
                              ? additive.additiveConflicts1.some(
                                  (pa) => pa.secondId === a.id,
                                )
                              : false) ||
                            (additive.additiveConflicts2
                              ? additive.additiveConflicts2.some(
                                  (pa) => pa.firstId === a.id,
                                )
                              : false)
                          }
                          onChange={(event) =>
                            this.toggleAdditiveConflict(event)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"additive" + a.id}
                        >
                          {a.currentTranslation?.title || ""}
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                ))}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardOptionConflicts = () => {
    const { additive } = this.state;

    if (!additive) {
      return <></>;
    }

    const loading = this.props.loading;
    const { options, attributes } = this.state;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Konflikty (opcje)</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {attributes.map((attribute) => {
              let optionsInAttribute = [0, 0];
              let optionsRender = (
                <>
                  {options
                    .filter((option) => option.attributeId === attribute.id)
                    .sort((a, b) =>
                      (a?.currentTranslation?.title || "").localeCompare(
                        b?.currentTranslation?.title || "",
                      ),
                    )
                    .map((option) => {
                      let checked = additive.optionConflicts
                        ? additive.optionConflicts.some(
                            (conflict) => conflict.optionId === option.id,
                          )
                        : false;
                      optionsInAttribute[0]++;
                      if (checked) {
                        optionsInAttribute[1]++;
                      }
                      return (
                        <Col md="12" key={option.id}>
                          <UncontrolledCollapse
                            toggler={`#attribute${attribute.id}head`}
                          >
                            <FormGroup>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  id={"option" + option.id}
                                  type="checkbox"
                                  name="options[]"
                                  value={option.id}
                                  checked={checked}
                                  onChange={(event) =>
                                    this.toggleOptionConflict(event)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"option" + option.id}
                                >
                                  {option.currentTranslation?.title || ""}
                                </label>
                              </div>
                            </FormGroup>
                          </UncontrolledCollapse>
                        </Col>
                      );
                    })}
                </>
              );
              return (
                <Row key={attribute.id}>
                  <Col
                    md="12"
                    className="mb-3"
                    id={`attribute${attribute.id}head`}
                  >
                    <b>{attribute.currentTranslation?.title}</b>{" "}
                    {optionsInAttribute[1]}/{optionsInAttribute[0]}
                  </Col>
                  {optionsRender}
                </Row>
              );
            })}
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.additive || this.props.loading;

    const { additive } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/products/additives/" />;
    }
    if (action === "options" && loading === false) {
      return (
        <Redirect to={`/admin/products/additives/${additive.id}/options`} />
      );
    }

    return (
      <>
        <SimpleHeader
          name={additive ? additive.currentTranslation?.title : ""}
          link="/admin/products/additives"
          parentName="Dodatki produktów"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardOptionConflicts()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardOptions()}
                {this.renderCardAdditiveConflicts()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.additive !== this.props.additive) {
      this.setState({
        additive: this.props.additive,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.groups !== this.props.groups) {
      this.setState({
        groups: this.props.groups,
      });
    }
    if (prevProps.attributes !== this.props.attributes) {
      this.setState({
        attributes: this.props.attributes,
      });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
    if (prevProps.additives !== this.props.additives) {
      this.setState({
        additives: this.props.additives,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    additive: state.productAdditives.current,
    additives: state.productAdditives.list,
    attributes: state.productAttributes.list,
    options: state.productOptions.list,
    loading: state.productAdditives.loading,
    auth: state.auth.user,
    session: state.session,
    groups: state.productAdditiveGroups.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(productAdditivesActions.read(id)),
    remove: (id) => dispatch(productAdditivesActions.remove(id)),
    update: (additive) => dispatch(productAdditivesActions.update(additive)),
    create: (additive) => dispatch(productAdditivesActions.create(additive)),
    listGroups: () => dispatch(productAdditiveGroupsActions.list(0, 1000)),
    listAdditives: () => dispatch(productAdditivesActions.list(0, 1000)),
    listAttributes: () => dispatch(productAttributesActions.list(0, 1000)),
    listOptions: () => dispatch(productOptionsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAdditive),
);

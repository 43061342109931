import * as constants from "./constants";
import IUserAuthenticated from "./model";

export const login = (email: string, password: string) => ({
  type: constants.LOGIN,
  payload: { email, password },
});
export const loginSuccess = (user: IUserAuthenticated) => ({
  type: constants.LOGIN_SUCCESS,
  payload: user,
});
export const loginFailure = (error: any) => ({
  type: constants.LOGIN_FAILURE,
  payload: error,
});

export const lock = () => ({
  type: constants.LOCK,
});
export const unlock = () => ({
  type: constants.UNLOCK,
});

export const refresh = () => ({
  type: constants.REFRESH,
});

export const refreshSuccess = (user: IUserAuthenticated) => ({
  type: constants.REFRESH_SUCCESS,
  payload: user,
});

export const logout = () => ({
  type: constants.LOGOUT,
});
export const logoutSuccess = () => ({
  type: constants.LOGOUT_SUCCESS,
});

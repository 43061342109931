import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productAttributesActions from "stores/products/productAttributes/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import { InputTypes } from "../products/Product";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class ProductAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      attribute: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        attribute: {
          id: undefined,
          hidden: false,
          inputType: "Buttons",
          translations: [],
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  toggleAttributeAffectingScope = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    const checked = event.target.checked;
    var attribute = { ...this.state.attribute };
    const currentValue = Number(attribute[name]);
    if (checked) {
      attribute[name] = currentValue | value;
    } else {
      attribute[name] = currentValue ^ value;
    }
    this.setState({ attribute });
  };

  changeAttributeData = (event) => {
    const { name, value, type } = event.target;
    let parsedValue = value;
    switch (type) {
      case "checkbox":
        parsedValue = event.target.checked;
        break;
      default:
        break;
    }
    var attribute = { ...this.state.attribute };
    attribute[name] = parsedValue;
    this.setState({ attribute });
  };

  changeAttributeTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var attribute = { ...this.state.attribute };
    if (!attribute.translations || attribute.translations.length === 0) {
      attribute.translations.push({ culture: currentCulture });
    }
    attribute.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ attribute });
  };

  changeAttributeTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeAttributeTranslationEx(name, value);
  };

  submitForm = (event) => {
    var attribute = { ...this.state.attribute };
    if (attribute.id) {
      this.setState({ action: "update" });
      this.props.update(attribute);
    } else {
      this.setState({ action: "create" });
      this.props.create(attribute);
    }
  };

  onClickOptions = (row) => {
    this.setState({ action: "options" });
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.attribute };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ attribute: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.attribute.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.attribute || this.props.loading;

    const { attribute } = this.state;
    const { auth } = this.props;

    if (!attribute || attribute.id === auth.id || !attribute.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.attribute || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        <Button
          color="default"
          onClick={this.onClickOptions}
          disabled={loading}
        >
          Opcje
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { attribute } = this.state;

    if (!attribute || !attribute.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={attribute ? attribute.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { attribute } = this.state;
    const { currentCulture } = this.state;

    if (!attribute) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = attribute.translations
      ? attribute.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Komponent formularza</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Komponent formularza"
                    type="select"
                    name="inputType"
                    value={attribute?.inputType}
                    onChange={this.changeAttributeData}
                  >
                    {InputTypes.map((type) => (
                      <option value={type.key} key={type.key}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeAttributeTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="description"
                    value={
                      editedTranslation ? editedTranslation?.description : ""
                    }
                    onChange={this.changeAttributeTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Podpowiedź</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Podpowiedź"
                    type="text"
                    name="hint"
                    value={editedTranslation ? editedTranslation?.hint : ""}
                    onChange={this.changeAttributeTranslation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardOptions = () => {
    const { attribute } = this.state;
    const { currentCulture } = this.state;

    if (!attribute) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={8} className="text-uppercase text-muted">
                <small>Właściwość</small>
              </Col>
              <Col md={4} className="text-uppercase text-muted text-center">
                <small>Wpływ</small>
              </Col>
              <Col md={8} className="text-uppercase text-muted">
                <small></small>
              </Col>
              <Col md={2} className="text-uppercase text-muted">
                <small>Wnętrze</small>
              </Col>
              <Col md={2} className="text-uppercase text-muted text-right">
                <small>Okładka</small>
              </Col>
              <Col md={12}>
                <b>Wpływ na zamówienie</b>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na format produktu?
              </Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnFormat"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isAffectingOnFormat}
                    defaultChecked={attribute.isAffectingOnFormat}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na stronicowanie?
              </Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnPages"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnPages & 1) === 1}
                    defaultChecked={attribute.isAffectingOnPages}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnPages"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnPages & 2) === 2}
                    defaultChecked={attribute.isAffectingOnPages}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">Czy zmiana opcji atrybutu wpływa na kolorystykę?</Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnColor"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnColor & 1) === 1}
                    defaultChecked={attribute.isAffectingOnColor}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnColor"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnColor & 2) === 2}
                    defaultChecked={attribute.isAffectingOnColor}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na kolorystykę drugorzędną?
              </Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnAlternateColor"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnAlternateColor & 1) === 1}
                    defaultChecked={attribute.isAffectingOnAlternateColor}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnAlternateColor"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnAlternateColor & 2) === 2}
                    defaultChecked={attribute.isAffectingOnAlternateColor}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md={12}>
                <b>Wpływ na arkusz</b>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na grubość arkusza?
              </Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnThickness"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnThickness & 1) === 1}
                    defaultChecked={attribute.isAffectingOnThickness}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnThickness"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnThickness & 2) === 2}
                    defaultChecked={attribute.isAffectingOnThickness}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na gramaturę arkusza?
              </Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnGrammage"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnGrammage & 1) === 1}
                    defaultChecked={attribute.isAffectingOnGrammage}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnGrammage"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnGrammage & 2) === 2}
                    defaultChecked={attribute.isAffectingOnGrammage}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na cenę arkusza?
              </Col>
              <Col md="2" className="text-left">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnSheetPrice"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={1}
                    checked={(attribute.isAffectingOnSheetPrice & 1) === 1}
                    defaultChecked={attribute.isAffectingOnSheetPrice}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="2" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnSheetPrice"
                    onChange={(event) =>
                      this.toggleAttributeAffectingScope(event)
                    }
                    value={2}
                    checked={(attribute.isAffectingOnSheetPrice & 2) === 2}
                    defaultChecked={attribute.isAffectingOnSheetPrice}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md={12}>
                <b>Wpływ na konfigurację</b>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na cenę produktu?
              </Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnPrice"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isAffectingOnPrice}
                    defaultChecked={attribute.isAffectingOnPrice}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na wagę produktu?
              </Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnWeight"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isAffectingOnWeight}
                    defaultChecked={attribute.isAffectingOnWeight}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md={12}>
                <b>Pozostałe</b>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na schemat produktu?
              </Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnModel"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isAffectingOnModel}
                    defaultChecked={attribute.isAffectingOnModel}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">
                Czy zmiana opcji atrybutu wpływa na szablon produktu?
              </Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAffectingOnTemplate"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isAffectingOnTemplate}
                    defaultChecked={attribute.isAffectingOnTemplate}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="8">Czy wybór opcji atrybutu jest wymagany?</Col>
              <Col md="4" className="text-center">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isRequired"
                    onChange={(event) => this.changeAttributeData(event)}
                    value={true}
                    checked={attribute.isRequired}
                    defaultChecked={attribute.isRequired}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.attribute || this.props.loading;

    const { attribute } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/products/attributes/" />;
    }
    if (action === "options" && loading === false) {
      return (
        <Redirect to={`/admin/products/attributes/${attribute.id}/options`} />
      );
    }

    return (
      <>
        <SimpleHeader
          name={attribute ? attribute.currentTranslation?.title : ""}
          link="/admin/products/attributes"
          parentName="Atrybuty produktów"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardOptions()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.attribute !== this.props.attribute) {
      this.setState({
        attribute: this.props.attribute,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    attribute: state.productAttributes.current,
    loading: state.productAttributes.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(productAttributesActions.read(id)),
    remove: (id) => dispatch(productAttributesActions.remove(id)),
    update: (attribute) => dispatch(productAttributesActions.update(attribute)),
    create: (attribute) => dispatch(productAttributesActions.create(attribute)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAttribute),
);

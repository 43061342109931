import React from "react";

import { Input } from "reactstrap";

import config from "config/global";

export default function CultureSelector(props) {
  const { currentCulture, changeCulture, ...otherProps } = props;

  return (
    <Input
      className="w-25 float-right"
      type="select"
      value={currentCulture}
      onChange={changeCulture}
      {...otherProps}
    >
      {config.cultures.map((culture) => (
        <option key={culture.code} value={culture.code}>
          {culture.native}
        </option>
      ))}
    </Input>
  );
}

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as productAttributesActions from "stores/products/productAttributes/actions";
import { API_URL as PRODUCTS_ATTRIBUTES_API_URL } from "stores/products/productAttributes/epics";

import NotificationAlert from "react-notification-alert";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";

import apiDriver from "stores/api.driver";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import Search from "components/Table/Search";

class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      productAttributes: [],
      meta: {},
      filter: [],
      redirect: null,
    };
    this.myRef = React.createRef();
    if (!this.state.productAttributes.list) {
      this.props.list();
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => <div className="dataTables_length" id="datatable-basic_length"></div>,
  };

  onTableChange = (type, newState) => {
    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      newState.filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: newState.filters.length
          ? [...newState.filters].map((f) => f.comparator + "," + f.filterVal)
          : [],
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
    });
  };

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/products/attributes/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/products/attributes/" + row.id });
  };

  onClickOptions = (row) => {
    this.setState({
      redirect: "/admin/products/attributes/" + row.id + "/options",
    });
  };

  onClickCopy = (row) => {
    this.notify("Atrybut", "Trwa kopiowanie atrybutu...");
    apiDriver
      .get(PRODUCTS_ATTRIBUTES_API_URL + row.id)
      .pipe(
        map((response) => response.response),
        map((attribute) => {
          let { id, products, options, ...newAttribute } = attribute;
          newAttribute.translations = newAttribute.translations.map((rel) => {
            let { entityId, ...tmp } = rel;
            tmp.title += " (copy)";
            return tmp;
          });
          return newAttribute;
        }),
        catchError((error) => {
          this.notify("Atrybut", "Nie udało skopiować się atrybutu.", "danger");
          return of(error);
        }),
      )
      .subscribe((attribute) => {
        this.notify("Atrybut", "Trwa kopiowanie opcji...");
        apiDriver
          .get(
            PRODUCTS_ATTRIBUTES_API_URL + row.id + "/Options?take=1000&skip=0",
          )
          .pipe(
            map((response) => response.response),
            map((options) => {
              return options.map((option) => {
                let {
                  id,
                  attribute,
                  attributeId,
                  optionConflicts,
                  optionConflicts1,
                  optionConflicts2,
                  productOptions,
                  additiveConflicts,
                  ...newOption
                } = option;
                newOption.translations = newOption.translations.map((rel) => {
                  let { entityId, ...tmp } = rel;
                  return tmp;
                });
                return newOption;
              });
            }),
            catchError((error) => {
              this.notify(
                "Atrybut",
                "Nie udało skopiować się atrybutu (błąd kopiowania opcji).",
                "danger",
              );
              return of(error);
            }),
          )
          .subscribe((options) => {
            this.notify("Atrybut", "Trwa zapisywanie nowego atrybutu...");
            attribute.options = options;
            apiDriver
              .put(PRODUCTS_ATTRIBUTES_API_URL, attribute)
              .pipe(
                map((response) => response.response),
                catchError((error) => {
                  this.notify(
                    "Atrybut",
                    "Nie udało skopiować się atrybutu.",
                    "danger",
                  );
                  return of(error);
                }),
              )
              .subscribe((response) => {
                if (response) {
                  this.notify(
                    "Atrybut",
                    "Pomyślnie skopiowano atrybut.",
                    "success",
                  );
                  this.props.list();
                } else {
                  this.notify(
                    "Atrybut",
                    "Nie udało skopiować się atrybutu.",
                    "danger",
                  );
                }
              });
          });
      });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickOptions(row);
          }}
        >
          <i className="fas fa-stream"></i>
        </Button>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickCopy(row);
          }}
        >
          <i className="fas fa-copy"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { alert } = this.state;
    const { data, loading, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "currentTranslation.title",
        text: "Tytuł",
        sort: true,
        filter: textFilter({
          placeholder: "Szukaj...",
          className: "form-control-sm",
        }),
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <>
        {alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="Lista atrybutów produktów"
          link="/admin/products"
          parentName="Produkty"
        >
          <div className="text-right">
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    className="buttons-copy buttons-html5"
                  >
                    <i className="fas fa-print mr-3"></i>
                    Drukuj
                  </Button>
                )}
                content={() => this.myRef.current}
              />
              <Button
                className="buttons-copy buttons-html5"
                color="success"
                onClick={this.onClickCreate}
              >
                <i className="fas fa-plus-circle mr-3"></i>
                <span>Dodaj atrybut</span>
              </Button>
            </ButtonGroup>
          </div>
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                {loading ? this.loadingOverlay() : ""}
                <CardHeader>
                  <h3 className="mb-0">Atrybuty produktów</h3>
                </CardHeader>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={columns}
                  search
                  bootstrap4={true}
                >
                  {(props) => {
                    return (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  
                                  <Search
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <PaginationProvider
                          pagination={paginationFactory(pagination)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <BootstrapTable
                              remote
                              ref={this.myRef}
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              id="react-bs-table"
                              wrapperClasses="table-responsive"
                              onTableChange={this.onTableChange}
                              filter={filterFactory()}
                              {...paginationTableProps}
                            />
                          )}
                        </PaginationProvider>
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.productAttributes.list,
    loading: state.productAttributes.loading,
    auth: state.auth.user,
    meta: state.productAttributes.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) =>
      dispatch(
        productAttributesActions.list(skip, take, searchText, filters, order),
      ),
    read: (id) => dispatch(productAttributesActions.read(id)),
    remove: (id) => dispatch(productAttributesActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductAttributes),
);

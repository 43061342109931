import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as pageActions from "stores/cms/pages/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import translator from "services/translator/translator";
import Editor from "components/Editor/Editor";

Dropzone.autoDiscover = false;

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      editorRef: React.createRef(),
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        page: {
          id: undefined,
          author: null,
          created: null,
          updated: null,
          translations: [],
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture: currentCulture });
  };

  changePageData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var page = { ...this.state.page };
    page[name] = value;
    this.setState({ page });
  };

  changePageTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var page = { ...this.state.page };
    if (!page.translations || page.translations.length === 0) {
      page.translations.push({ culture: currentCulture });
    }
    page.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ page });
  };

  changePageTranslation = (event) => {
    const { name, value } = event.target;
    return this.changePageTranslationEx(name, value);
  };

  submitForm = (event) => {
    var page = { ...this.state.page };
    if (page.id) {
      this.setState({ action: "update" });
      this.props.update(page);
    } else {
      this.setState({ action: "create" });
      this.props.create(page);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.page.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.page || this.props.loading;

    const { page } = this.state;
    const { auth } = this.props;

    if (!page || page.id === auth.id || !page.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.page || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { page } = this.state;

    if (!page || !page.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={page ? page.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.page };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ page: object });
    });
  };

  renderCardData = () => {
    const { page } = this.state;
    const { currentCulture } = this.state;

    if (!page) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      page?.translations?.find(
        (translation) => translation.culture === currentCulture,
      ) || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>{" "}
            {editedTranslation?.culture || currentCulture}
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation?.title || ""}
                      onChange={this.changePageTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Identyfikator adresu</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-globe" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Identyfikator adresu"
                      type="text"
                      name="url"
                      value={editedTranslation?.url || ""}
                      onChange={this.changePageTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <b>Metadane</b>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="metaTitle"
                    value={editedTranslation?.metaTitle || ""}
                    onChange={this.changePageTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="metaDescription"
                    value={editedTranslation?.metaDescription || ""}
                    onChange={this.changePageTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Słowa kluczowe</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Słowa kluczowe"
                    type="text"
                    name="metaKeywords"
                    value={editedTranslation?.metaKeywords || ""}
                    onChange={this.changePageTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <b>Treść</b>
              </Col>
              <Col md="12">
                <Editor
                  name="content"
                  value={editedTranslation?.content}
                  uploadUrl={`${config.api.cms}pl/Pages/${page.id}/Uploads/[[name]]`}
                  uploadPresignedUrl={true}
                  onChange={this.changePageTranslation}
                  />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.page || this.props.loading;

    const { page } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/cms/pages/" />;
    }

    return (
      <>
        <SimpleHeader
          name={page?.currentTranslation?.title}
          link="/admin/cms/pages"
          parentName="Strony"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({
        page: this.props.page,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    page: state.pages.current,
    loading: state.pages.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(pageActions.read(id)),
    remove: (id) => dispatch(pageActions.remove(id)),
    update: (page) => dispatch(pageActions.update(page)),
    create: (page) => dispatch(pageActions.create(page)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));

import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";

class Index extends React.Component {
  render() {
    const { user, lock } = this.props;

    if (!user) {
      return <Redirect to="/auth/login" />;
    } else if (user && lock) {
      return <Redirect to="/auth/lock" />;
    } else {
      return <Redirect to="/admin/dashboard" />;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    lock: state.auth.lock,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));

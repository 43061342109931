const parseOnChangeValue = (target) => {
  const { name, value, type, checked } = target;
  let parsedValue = value;
  switch (type) {
    case "number":
      parsedValue = !isNaN(parsedValue) ? Number(parsedValue) : undefined;
      break;
    case "checkbox":
      parsedValue = checked;
      break;
    case "datetime":
      parsedValue = value.format();
      break;
    default:
      break;
  }
  return { name: name, value: parsedValue };
};

export default parseOnChangeValue;

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";

import * as bookingsActions from "stores/bookings/bookings/actions";

import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import localizer from "stores/localizer";

class Bookings extends React.Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let tommorow = new Date();
    today.setDate(today.getDate() - 3);
    tommorow.setDate(tommorow.getDate() + 7);
    this.state = {
      alert: null,
      auth: null,
      bookings: [],
      meta: {},
      filter: {
        search: "",
        start: today,
        end: tommorow,
      },
      redirect: null,
    };
    this.myRef = React.createRef();
    if (!this.state.bookings.list) {
      this.list();
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => <div className="dataTables_length" id="datatable-basic_length"></div>,
  };

  onTableChange = (type, newState) => {
    this.list(newState);
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickTypes = () => {
    this.setState({ redirect: "/admin/booking/bookings/types" });
  };

  onClickType = (row) => {
    this.setState({ redirect: "/admin/booking/bookings/types/" + row.type.id });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/booking/bookings/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/booking/bookings/" + row.id });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  list(newState, state) {
    const { filter } = state ? state : this.state;
    if (!newState) newState = { sizePerPage: 10, page: 1 };
    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      filter.search,
      filter.start,
      filter.end,
    );
    this.setState({ meta: { ...this.props.meta, page: newState.page } });
  }

  changeSearch = (e) => {
    let state = this.state;
    state = { filter: { ...state.filter, search: e.target.value } };
    this.setState(state);
  };

  setSearch = (e) => {
    this.list(this.myRef.current.getNewestState());
  };

  changeStart = (value) => {
    let state = this.state;
    state = { filter: { ...state.filter, start: new Date(value.format()) } };
    this.setState(state);
    this.list(this.myRef.current.getNewestState(), state);
  };

  changeEnd = (value) => {
    let state = this.state;
    state = { filter: { ...state.filter, end: new Date(value.format()) } };
    this.setState(state);
    this.list(this.myRef.current.getNewestState(), state);
  };

  render = () => {
    const { redirect, filter } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { alert } = this.state;
    const { data, loading, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let todayBegin = new Date();
    todayBegin.setHours(0, 0, 0, 0);
    todayBegin = todayBegin.getTime();
    let todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    todayEnd = todayEnd.getTime();

    let columns = [
      {
        dataField: "guestId",
        text: "",
        sort: false,
        formatter: (cell, row) => {
          let start = new Date(row.start).getTime();
          let end = new Date(row.end).getTime();

          if (end < todayBegin) {
            return (
              <i className="far fa-circle text-muted" title="Archiwalne"></i>
            );
          } else if (end < todayEnd) {
            return (
              <i
                className="fas fa-check-circle text-danger"
                title="Dziś rozpoczynające się"
              ></i>
            );
          } else if (start < todayBegin) {
            return (
              <i className="far fa-circle text-success" title="Trwające"></i>
            );
          } else if (start < todayEnd) {
            return (
              <i
                className="fas fa-check-circle text-success"
                title="Dziś kończące się"
              ></i>
            );
          }

          return (
            <i className="far fa-circle text-info" title="Nadchodzące"></i>
          );
        },
      },
      {
        dataField: "start",
        text: "Początek",
        sort: true,
        formatter: (cell, row) =>
          cell
            ? new Date(cell).toISOString().substr(0, 16).replace("T", " ")
            : "",
      },
      {
        dataField: "end",
        text: "Koniec",
        sort: true,
        formatter: (cell, row) =>
          cell
            ? new Date(cell).toISOString().substr(0, 16).replace("T", " ")
            : "",
      },
      {
        dataField: "price",
        text: "Cena",
        sort: false,
        formatter: (cell, row) =>
          new Intl.NumberFormat("pl-PL", {
            style: "currency",
            currency: "PLN",
          }).format(cell),
      },
      {
        dataField: "rooms",
        text: "Zakwaterowania",
        sort: false,
        formatter: (cell, row) =>
          cell
            ? cell.map((room) => (
              <a key={room.id} href={"/admin/booking/rooms/" + room.id}>
                {localizer(room)?.currentTranslation?.title}
              </a>
            ))
            : "",
      },
      {
        dataField: "guest",
        text: "Dane rezerwującego",
        sort: false,
        formatter: (cell, row) =>
          cell ? (
            <span>
              {[cell.givenName, cell.surname].join(" ")} &nbsp;
              <a href={"tel:" + cell.phone} target="_blank" rel="noreferrer">
                <i className="fas fa-phone"></i>
              </a>{" "}
              &nbsp;
              <a href={"mailto:" + cell.email} target="_blank" rel="noreferrer">
                <i className="fas fa-envelope"></i>
              </a>
            </span>
          ) : (
            ""
          ),
      },
      {
        dataField: "people",
        text: "Liczba gości",
        sort: false,
        formatter: (cell, row) => cell.length,
      },
      {
        dataField: "created",
        text: "Utworzono",
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => cell ? (new Date(cell)).toLocaleString() : <span className="text-muted">Brak</span>
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <>
        {alert}
        <SimpleHeader name="Rezerwacje">
          <div className="text-right">
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    className="buttons-copy buttons-html5"
                  >
                    <i className="fas fa-print mr-3"></i>
                    Drukuj
                  </Button>
                )}
                content={() => this.myRef.current}
              />
              <Button
                className="buttons-copy buttons-html5"
                color="success"
                onClick={this.onClickCreate}
              >
                <i className="fas fa-plus-circle mr-3"></i>
                <span>Dodaj rezerwację</span>
              </Button>
            </ButtonGroup>
          </div>
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                {loading ? this.loadingOverlay() : ""}
                <CardHeader>
                  <h3 className="mb-0">Rezerwacje</h3>
                </CardHeader>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={columns}
                  search
                  bootstrap4={true}
                >
                  {(props) => {
                    return (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  Szukaj:
                                  <Input
                                    className="form-control-sm d-inline"
                                    placeholder="Szukaj"
                                    value={filter.search}
                                    onChange={this.changeSearch}
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        this.setSearch();
                                      }
                                    }}
                                  />
                                  &nbsp; Od:
                                  <ReactDatetime
                                    inputProps={{
                                      placeholder: "Do",
                                      className: "form-control",
                                    }}
                                    value={filter.start}
                                    timeFormat={false}
                                    dateFormat="yyyy-MM-DD"
                                    onChange={this.changeStart.bind(this)}
                                    name={"start"}
                                    className="form-control p-0 m-0 border-0 d-inline"
                                  />
                                  &nbsp; Do:
                                  <ReactDatetime
                                    inputProps={{
                                      placeholder: "Od",
                                      className: "form-control",
                                    }}
                                    value={filter.end}
                                    timeFormat={false}
                                    dateFormat="yyyy-MM-DD"
                                    onChange={this.changeEnd.bind(this)}
                                    name={"end"}
                                    className="form-control p-0 m-0 border-0 d-inline"
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <PaginationProvider
                          pagination={paginationFactory(pagination)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <BootstrapTable
                              remote
                              ref={this.myRef}
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              id="react-bs-table"
                              wrapperClasses="table-responsive"
                              onTableChange={this.onTableChange}
                              {...paginationTableProps}
                            />
                          )}
                        </PaginationProvider>
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.bookings.list,
    loading: state.bookings.loading,
    auth: state.auth.user,
    meta: state.bookings.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (skip = 0, take = 10, search = "", start = null, end = null) =>
      dispatch(bookingsActions.list(skip, take, search, start, end)),
    read: (id) => dispatch(bookingsActions.read(id)),
    remove: (id) => dispatch(bookingsActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Bookings),
);

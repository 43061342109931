import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import PropTypes from "prop-types";
// nodejs library to set properties for components
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import * as authActions from "stores/iam/auth/actions";
import { withRouter } from "react-router";

class AdminNavbar extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  refreshToken = () => {
    this.props.refresh();
    this.tokenExpire = setTimeout(this.refreshToken, 10 * 60 * 1000);
  };

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.tokenExpire = setTimeout(this.refreshToken, 1 * 60 * 1000);
    }
  }

  componentWillUnmount() {
    if (this.tokenExpire) {
      clearTimeout(this.tokenExpire);
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (user && !this.tokenExpire) {
      this.tokenExpire = setTimeout(this.refreshToken, 1 * 60 * 1000);
    } else if (!user && this.tokenExpire) {
      clearTimeout(this.tokenExpire);
    }
  }

  constructor(props) {
    super(props);
    props = {
      ...props,
      toggleSidenav: () => {},
      sidenavOpen: false,
      theme: "dark",
    };
    this.tokenExpire = null;
  }

  clickMyProfileButton = () => {
    const { user } = this.props;

    this.props.lock();
    this.props.history.push("/admin/cp/users/" + user.id);
  };

  clickLockButton = () => {
    this.props.lock();
    this.props.history.push("/auth/lock");
  };

  clickLogoutButton = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  render() {
    const { theme, sidenavOpen, toggleSidenav, user } = this.props;

    if (!user) {
      this.props.history.push("/");
      return <div />;
    }

    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": theme === "dark" },
            { "navbar-light bg-secondary": theme === "light" },
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: sidenavOpen },
                      { "sidenav-toggler-dark": theme === "dark" },
                    )}
                    onClick={toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          src={require("assets/img/theme/user.jpg").default}
                        />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {user.givenName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">
                        Cześć, {user.givenName}!
                      </h6>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={this.clickMyProfileButton}
                    >
                      <i className="ni ni-single-02" />
                      <span>Mój profil</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="#pablo"
                      onClick={this.clickLogoutButton}
                    >
                      <i className="ni ni-user-run" />
                      <span>Wyloguj się</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    active: state.users.active,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(authActions.logout()),
    lock: () => dispatch(authActions.lock()),
    refresh: () => dispatch(authActions.refresh()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminNavbar),
);

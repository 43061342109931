import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as shippingsActions from "stores/orders/shippings/actions";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardFooter,
  Button,
  Table,
} from "reactstrap";

import DeliveryModal from "./DeliveryModal";
import CollectModal from "./CollectModal";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class OrderShipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippings: [],
      deliveryModalOpen: false,
      collectModalOpen: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const { order } = this.props;
    this.props.listShippings(order.id);
  };

  loadingOverlay = () => this.props.loadingOverlay();
  parseValue = (e) => this.props.parseValue(e);
  toggleDeliveryModal = (id) =>
    id
      ? this.setState({ [id]: !this.state[id] })
      : this.setState({ deliveryModalOpen: !this.state.deliveryModalOpen });
  toggleCollectModal = (id) =>
    id
      ? this.setState({ [id]: !this.state[id] })
      : this.setState({ collectModalOpen: !this.state.collectModalOpen });

  changeOrderData = (e) => {
    const value = this.parseValue(e.target);

    if (value.value === "_") {
      switch (value.name) {
        case "shippingAddressId":
          this.props.changeOrderDataEx("shippingAddress", {});
          break;
        case "billingInfoId":
          this.props.changeOrderDataEx("billingInfo", {});
          break;
        case "senderAddressId":
          this.props.changeOrderDataEx("senderAddress", {});
          break;
        default:
          break;
      }
    }

    this.props.changeOrderDataEx(value.name, value.value);
  };

  changeOrderProperty = (e, propertyName) => {
    const value = this.props.parseValue(e.target);
    let property = {
      ...this.props.order[propertyName],
      [value.name]: value.value,
    };
    this.props.changeOrderDataEx(propertyName, property);
  };

  onClickConfirm = (shipping) => {
    const { order } = this.props;
    apiDriver
      .post(config.api.gls + `${shipping.id}/Confirm`)
      .pipe(
        map((response) => {
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        apiDriver
          .get(`${config.api.orders}pl/Shippings/${shipping.id}`)
          .subscribe({
            next: (sResponse) =>
              apiDriver
                .get(
                  `${config.api.orders}pl/ShippingMethods/${shipping.methodId}`,
                )
                .subscribe({
                  next: (shippingMethodResponse) =>
                    apiDriver
                      .patch(
                        `${config.api.orders}pl/Shippings/${shipping.id}`,
                        {
                          ...sResponse.response,
                          statusId:
                            shippingMethodResponse.response.statuses.find(
                              (m) => m.isClosed,
                            ).id,
                        },
                      )
                      .subscribe({
                        next: (shippingResponse) =>
                          this.props.listShippings(order.id),
                      }),
                }),
          });
      });
  };

  onClickDownload = (shipping) => {
    apiDriver
      .download(
        shipping.externalId + ".pdf",
        config.api.gls + `${shipping.id}/Download`,
      )
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  onClickDelete = (shipping) => {
    this.props.removeShipping(shipping.id);
  };

  renderShipping = (shipping) => {
    const { createShipping, updateShipping } = this.props;
    const shippingModalName = shipping.id + "ModalOpen";
    const shippingModalOpen = this.state[shippingModalName];
    return (
      <tr key={shipping.id}>
        <td>
          {shipping.externalId ? (
            <span>
              {shipping.externalId}
              <br />
              <small className="text-muted">{shipping.id}</small>
            </span>
          ) : (
            shipping.id
          )}
        </td>
        <td>{shipping.method ? localizer(shipping.method)?.currentTranslation?.title : ""}</td>
        <td>{shipping.status ? localizer(shipping.status)?.currentTranslation?.title : ""}</td>
        <td>
          {shipping.trackingNumber && shipping.trackingLink ? (
            <a href={shipping.trackingLink} target="_blank" rel="noreferrer">
              {shipping.trackingNumber}
            </a>
          ) : shipping.trackingNumber && !shipping.trackingLink ? (
            shipping.trackingNumber
          ) : (
            <span className="text-muted">Brak</span>
          )}
        </td>
        <td>
          {(shipping.created ? (new Date(shipping.created)).toLocaleString() : 'Nigdy')}
        </td>
        <td>
          {(shipping.updated ? (new Date(shipping.updated)).toLocaleString() : 'Nigdy')}
        </td>
        <td>
          <Button
            type="button"
            size="sm"
            color="primary"
            onClick={(e) => this.toggleDeliveryModal(shippingModalName)}
          >
            <i className="fa fa-edit"></i>
          </Button>
          {shipping.method.integration ? (
            shipping.method.integration === "GLS" ? (
              <>
                <Button
                  type="button"
                  size="sm"
                  color="success"
                  disabled={shipping.trackingNumber !== ""}
                  onClick={(e) => this.onClickConfirm(shipping)}
                >
                  <i className="fa fa-check"></i>
                </Button>
                <Button
                  type="button"
                  size="sm"
                  color="primary"
                  onClick={(e) => this.onClickDownload(shipping)}
                >
                  <i className="fa fa-download"></i>
                </Button>
              </>
            ) : (
              <React.Fragment />
            )
          ) : (
            <React.Fragment />
          )}
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => this.onClickDelete(shipping)}
          >
            <i className="fa fa-times"></i>
          </Button>
          {shipping.method ? (
            shipping.method.isShippingAddressRequired ? (
              <DeliveryModal
                shipping={shipping}
                isOpen={shippingModalOpen}
                toggle={(e) => this.toggleDeliveryModal(shippingModalName)}
                title="Dostawa"
                parseValue={this.parseValue}
                create={createShipping}
                update={updateShipping}
              />
            ) : (
              <CollectModal
                shipping={shipping}
                isOpen={shippingModalOpen}
                toggle={(e) => this.toggleDeliveryModal(shippingModalName)}
                title="Odbiór"
                parseValue={this.parseValue}
                create={createShipping}
                update={updateShipping}
              />
            )
          ) : (
            <React.Fragment />
          )}
        </td>
      </tr>
    );
  };

  renderShippings = () => {
    const { shippings } = this.state;

    return (
      <Table>
        <thead>
          <tr>
            <th>id</th>
            <th>Metoda</th>
            <th>Status</th>
            <th>List przewozowy</th>
            <th>Utworzono</th>
            <th>Zaktualizowano</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {shippings
            .sort(
              (a, b) =>
                (new Date(a.updated) || new Date(a.created)) <
                (new Date(b.updated) || new Date(b.created)),
            )
            .map(this.renderShipping)}
        </tbody>
      </Table>
    );
  };

  render = () => {
    const { deliveryModalOpen, collectModalOpen } = this.state;
    const { createShipping, updateShipping, order } = this.props;
    return (
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <CardTitle>Dostawy / odbiory</CardTitle>
            </CardHeader>
            <CardBody className="m-0 p-0">{this.renderShippings()}</CardBody>
            <CardFooter className="text-right">
              <Button
                type="button"
                color="primary"
                onClick={(e) => this.toggleDeliveryModal()}
              >
                Dodaj dostawę
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={(e) => this.toggleCollectModal()}
              >
                Dodaj odbiór
              </Button>
            </CardFooter>
          </Card>
          <DeliveryModal
            order={order}
            isOpen={deliveryModalOpen}
            toggle={(e) => this.toggleDeliveryModal()}
            title="Dostawa"
            parseValue={this.parseValue}
            create={createShipping}
            update={updateShipping}
          />
          <CollectModal
            order={order}
            isOpen={collectModalOpen}
            toggle={(e) => this.toggleCollectModal()}
            title="Dostawa"
            parseValue={this.parseValue}
            create={createShipping}
            update={updateShipping}
          />
        </Col>
      </Row>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shippingMethods !== this.props.shippingMethods) {
      this.setState({
        shippingMethods: this.props.shippingMethods,
        shippingMEthodsLoaded: false,
      });
    }
    if (prevProps.shippings !== this.props.shippings) {
      this.setState({
        shippings: this.props.shippings,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    shippings: state.shippings.list,
    shippingMethods: state.shippingMethods.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listShippings: (orderId) =>
      dispatch(shippingsActions.list(0, 1000, orderId)),
    removeShipping: (id) => dispatch(shippingsActions.remove(id)),
    createShipping: (body) => dispatch(shippingsActions.create(body)),
    updateShipping: (body) => dispatch(shippingsActions.update(body)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderShipping),
);

import * as constants from "./constants";
import IPaymentStatus from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (status: IPaymentStatus) => ({
  type: constants.READ_SUCCESS,
  payload: status,
});

export const create = (status: IPaymentStatus) => ({
  type: constants.CREATE,
  payload: status,
});
export const createSuccess = (status: IPaymentStatus) => ({
  type: constants.CREATE_SUCCESS,
  payload: status,
});

export const update = (status: IPaymentStatus) => ({
  type: constants.UPDATE,
  payload: status,
});
export const updateSuccess = (status: IPaymentStatus) => ({
  type: constants.UPDATE_SUCCESS,
  payload: status,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (skip: Number = 0, take: Number = 10) => {
  return {
    type: constants.LIST,
    payload: { skip, take },
  };
};
export const listSuccess = (list: IPaymentStatus[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (status: IPaymentStatus) => ({
  type: constants.ACTIVE,
  payload: status,
});

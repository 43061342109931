const pickCurrentTranslation = (object, overrideLocale = "") => {
  if (!object) {
    return undefined;
  }
  const locale = overrideLocale || "pl";
  object.currentTranslation =
    object.translations.find((e) => e.culture === locale) ||
    object.translations[0];
  return object;
};
export default pickCurrentTranslation;

import React, { useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";

function TimelineHeader(props) {
  const { name } = props;
  const { parentName } = props;
  const { children } = props;
  const { link } = props;

  const [redirect, setRedirect] = useState(null);

  const onClickHome = (event) => {
    setRedirect("/");
  };

  const onClickLink = (event) => {
    setRedirect(link);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="4" md="7" xs="12">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#e" onClick={onClickHome}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#e" onClick={onClickLink}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {children ? (
                <Col lg="8" md="5" xs="12">
                  {children}
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default withRouter(TimelineHeader);

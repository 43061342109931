import React from "react";
import { Input } from "reactstrap";

export const Countries = [
  {
    "code": "AT",
    "label": "Austria",
    "native": "Österreich"
  },
  {
    "code": "BE",
    "label": "Belgium",
    "native": "België/Belgique/Belgien"
  },
  {
    "code": "BG",
    "label": "Bulgaria",
    "native": "България"
  },
  {
    "code": "CY",
    "label": "Cyprus",
    "native": "Κύπρος/Kıbrıs"
  },
  {
    "code": "CZ",
    "label": "Czech Republic",
    "native": "Česká republika"
  },
  {
    "code": "DE",
    "label": "Germany",
    "native": "Deutschland"
  },
  {
    "code": "DK",
    "label": "Denmark",
    "native": "Danmark"
  },
  {
    "code": "EE",
    "label": "Estonia",
    "native": "Eesti"
  },
  {
    "code": "ES",
    "label": "Spain",
    "native": "España"
  },
  {
    "code": "FI",
    "label": "Finland",
    "native": "Suomi"
  },
  {
    "code": "FR",
    "label": "France",
    "native": "France"
  },
  {
    "code": "GR",
    "label": "Greece",
    "native": "Ελλάδα"
  },
  {
    "code": "HR",
    "label": "Croatia",
    "native": "Hrvatska"
  },
  {
    "code": "HU",
    "label": "Hungary",
    "native": "Magyarország"
  },
  {
    "code": "IE",
    "label": "Ireland",
    "native": "Éire/Ireland"
  },
  {
    "code": "IT",
    "label": "Italy",
    "native": "Italia"
  },
  {
    "code": "LT",
    "label": "Lithuania",
    "native": "Lietuva"
  },
  {
    "code": "LU",
    "label": "Luxembourg",
    "native": "Luxembourg/Lëtzebuerg"
  },
  {
    "code": "LV",
    "label": "Latvia",
    "native": "Latvija"
  },
  {
    "code": "MT",
    "label": "Malta",
    "native": "Malta"
  },
  {
    "code": "NL",
    "label": "Netherlands",
    "native": "Nederland"
  },
  {
    "code": "PL",
    "label": "Poland",
    "native": "Polska"
  },
  {
    "code": "PT",
    "label": "Portugal",
    "native": "Portugal"
  },
  {
    "code": "RO",
    "label": "Romania",
    "native": "România"
  },
  {
    "code": "SE",
    "label": "Sweden",
    "native": "Sverige"
  },
  {
    "code": "SI",
    "label": "Slovenia",
    "native": "Slovenija"
  },
  {
    "code": "SK",
    "label": "Slovakia",
    "native": "Slovensko"
  }
];

export default function CountrySelector(props) {
  const { allowNull, value, inputProps, onChange, id, name } = props;

  return (
    <Input
      type="select"
      id={id || "country"}
      name={name || "country"}
      value={value}
      onChange={onChange}
      {...inputProps}
    >
      {allowNull ? <option value="">Brak</option> :<option value="" disabled>Brak</option>}
      {Countries.map((country) => (
        <option key={country.code.toLowerCase()} value={country.code.toLowerCase()}>
          {country.label} ({country.native})
        </option>
      ))}
    </Input>
  );
}

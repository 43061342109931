import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Input,
  Table,
  Form,
  Row,
  ButtonGroup,
  Button,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import { API_URL as SENDER_ADDRESSES_API_URL } from "stores/orders/senderAddresses/epics";
import { API_URL as SHIPPING_ADDRESSES_API_URL } from "stores/orders/shippingAddresses/epics";
import { API_URL as BILLING_INFOS_API_URL } from "stores/orders/billingInfos/epics";

import Uploader from "../../orders/cards/Uploader";
import config from "config/global";
import CountrySelector from "../../orders/cards/CountrySelector";

const IsSenderAddress = "senderAddresses";
const IsShippingAddress = "shippingAddresses";
const IsBillingInfo = "billingInfos";

Dropzone.autoDiscover = false;

class CustomerAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingAddresses: undefined,
      senderAddresses: undefined,
      billingInfos: undefined,
    };
    this.myRef = React.createRef();
  }

  loadShippingAddresses = () => {
    const { customer } = this.props;
    const params = apiDriver.buildIndexAttributes({
      order: { direction: "desc", field: "updated" },
      filters: {
        userId: {
          filterVal: customer.id,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
      take: 100,
    });
    apiDriver
      .get(SHIPPING_ADDRESSES_API_URL + params)
      .pipe(
        map((response) => {
          this.setState({ shippingAddresses: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  loadSenderAddresses = () => {
    const { customer } = this.props;
    const params = apiDriver.buildIndexAttributes({
      order: { direction: "desc", field: "updated" },
      filters: {
        userId: {
          filterVal: customer.id,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
      take: 100,
    });
    apiDriver
      .get(SENDER_ADDRESSES_API_URL + params)
      .pipe(
        map((response) => {
          this.setState({ senderAddresses: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  loadBillingInfos = () => {
    const { customer } = this.props;
    const params = apiDriver.buildIndexAttributes({
      order: { direction: "desc", field: "updated" },
      filters: {
        userId: {
          filterVal: customer.id,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
      take: 100,
    });
    apiDriver
      .get(BILLING_INFOS_API_URL + params)
      .pipe(
        map((response) => {
          this.setState({ billingInfos: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  componentDidMount = () => {
    this.loadShippingAddresses();
    this.loadSenderAddresses();
    this.loadBillingInfos();
  };

  notify = (title, content, type = "default") =>
    this.props.notify(title, content, type);
  loadingOverlay = () => this.props.loadingOverlay();
  tableLoadingIndicator = () => (
    <tr>
      <td colSpan={8} align="center">
        <i className="fas fa-circle-notch fa-spin fa-4x my-5"></i>
      </td>
    </tr>
  );
  parseValue = (e) => this.props.parseValue(e);

  changeCustomerData = (e) => {
    const value = this.parseValue(e.target);

    if (value.value === "_") {
      switch (value.name) {
        case "shippingAddressId":
          this.props.changeCustomerDataEx("shippingAddress", {});
          break;
        case "billingInfoId":
          this.props.changeCustomerDataEx("billingInfo", {});
          break;
        case "senderAddressId":
          this.props.changeCustomerDataEx("senderAddress", {});
          break;
        default:
          break;
      }
    }

    this.props.changeCustomerDataEx(value.name, value.value);
  };

  saveDirtyBillingInfos = () =>
    this.state.billingInfos.map((e, i) => {
      if (e.dirty) this.saveBillingInfo(i);
      return true;
    });
  saveDirtyShippingAddresses = () =>
    this.state.shippingAddresses.map((e, i) => {
      if (e.dirty) this.saveShippingAddress(i);
      return true;
    });
  saveDirtySenderAddresses = () =>
    this.state.senderAddresses.map((e, i) => {
      if (e.dirty) this.saveSenderAddress(i);
      return true;
    });

  changeAddressData = (type, index, e) => {
    const value = this.parseValue(e.target);
    this.setState({
      [type]: this.state[type].map((address, id) => {
        if (id !== index) return address;
        return { ...address, [value.name]: value.value, dirty: true };
      }),
    });
  };

  loadingAdresssData = (type, index, value) => {
    this.setState({
      [type]: this.state[type].map((address, id) => {
        if (id !== index) return address;
        return { ...address, loading: value };
      }),
    });
  };

  undirtyAdresssData = (type, index) => {
    this.setState({
      [type]: this.state[type].map((address, id) => {
        if (id !== index) return address;
        return { ...address, loading: false, dirty: false };
      }),
    });
  };

  addShippingAddress = () =>
    this.setState({ shippingAddresses: [...this.state.shippingAddresses, {}] });
  addSenderAddress = () =>
    this.setState({ senderAddresses: [...this.state.senderAddresses, {}] });
  addBillingInfo = () =>
    this.setState({ billingInfos: [...this.state.billingInfos, {}] });
  saveShippingAddress = (index) => this.saveEntityEx(IsShippingAddress, index);
  saveSenderAddress = (index) => this.saveEntityEx(IsSenderAddress, index);
  saveBillingInfo = (index) => this.saveEntityEx(IsBillingInfo, index);
  saveEntityEx = (type, index) => {
    const { customer } = this.props;
    this.loadingAdresssData(type, index, true);
    const entity = this.state[type][index];
    if (!entity.line1)
      return this.notify("Błąd walidacji", "Wpisz właściwy adres", "danger");
    if (!entity.city)
      return this.notify(
        "Błąd walidacji",
        "Wpisz właściwą miejscowość",
        "danger",
      );
    if (!entity.postalCode)
      return this.notify(
        "Błąd walidacji",
        "Wpisz właściwy kod pocztowy",
        "danger",
      );
    if (!entity.countryCode)
      return this.notify("Błąd walidacji", "Wybierz właściwy kraj", "danger");
    entity.userId = customer.id;
    const method = entity.id ? apiDriver.patch : apiDriver.put;

    let url;
    switch (type) {
      case IsBillingInfo:
        url = `${BILLING_INFOS_API_URL}${entity.id || ""}`
        break;
      case IsShippingAddress:
        url = `${SHIPPING_ADDRESSES_API_URL}${entity.id || ""}`
        break;
      case IsSenderAddress:
        url = `${SENDER_ADDRESSES_API_URL}${entity.id || ""}`
        break;
      default:
        return;
    }

    method(url, entity)
      .pipe(
        map((response) => {
          this.setState({
            [type]: this.state[type].map((e, i) => {
              if (i !== index) return e;
              return response.response;
            }),
          });
          this.undirtyAdresssData(type, index);
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  deleteShippingAddress = (index) =>
    this.deleteEntityEx(IsShippingAddress, index);
  deleteSenderAddress = (index) =>
    this.deleteEntityEx(IsSenderAddress, index);
  deleteBillingInfo = (index) => this.deleteEntityEx(IsBillingInfo, index);
  deleteEntityEx = (type, index) => {
    if (!this.state[type][index].id) {
      return this.setState({
        [type]: [...this.state[type].filter((e, i) => i !== index)],
      });
    }
    this.loadingAdresssData(type, index, true);

    let url;
    switch (type) {
      case IsBillingInfo:
        url = `${BILLING_INFOS_API_URL}${this.state[type][index].id}`
        break;
      case IsShippingAddress:
        url = `${SHIPPING_ADDRESSES_API_URL}${this.state[type][index].id}`
        break;
      case IsSenderAddress:
        url = `${SENDER_ADDRESSES_API_URL}${this.state[type][index].id}`
        break;
      default:
        return;
    }

    apiDriver
      .removeDirect(url)
      .subscribe({
        next: (response) => {
          this.setState({
            [type]: this.state[type].map((e, i) => {
              if (i !== index) return e;
              return { ...this.state[type], deleted: new Date().toISOString() };
            }),
          });
          return response.response;
        },
        error: () => {
          this.notify('Nie mozna usunąć adresu', 'Adres jest uzywany w zamówieniu', 'danger');
          this.loadingAdresssData(type, index, false);
        },
        complete: () => this.loadingAdresssData(type, index, false)
      })
  };

  exportShippingAddresses = () => {
    const { customer } = this.props;
    apiDriver
      .download(
        "test.csv",
        `${SHIPPING_ADDRESSES_API_URL}Export/${customer.id}`,
      )
      .subscribe();
  };

  exportSenderAddresses = () => {
    const { customer } = this.props;
    apiDriver
      .download(
        "test.csv",
        `${SENDER_ADDRESSES_API_URL}Export/${customer.id}`,
      )
      .subscribe();
  };

  exportBillingInfos = () => {
    const { customer } = this.props;
    apiDriver
      .download("test.csv", `${BILLING_INFOS_API_URL}Export/${customer.id}`)
      .subscribe();
  };

  renderShippingAddress = (address, i) => {
    const onChange = (e) => this.changeAddressData(IsShippingAddress, i, e);
    return (
      <tr key={i} style={{ display: address.deleted ? "none" : "inherited" }}>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Adres"
              name="line1"
              onChange={onChange}
              value={address.line1}
              maxLength={64}
            />
            <Input
              type="text"
              placeholder="Adres (linia dodatkowa)"
              name="line2"
              onChange={onChange}
              value={address.line2}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Kod pocztowy"
              name="postalCode"
              onChange={onChange}
              value={address.postalCode}
              maxLength={16}
            />
            <Input
              type="text"
              placeholder="Miejscowość"
              name="city"
              onChange={onChange}
              value={address.city}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <CountrySelector
              name="countryCode"
              onChange={onChange}
              value={address.countryCode}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Odbiorca"
              name="person"
              onChange={onChange}
              value={address.person}
              maxLength={64}
            />
            <InputGroup>
              <Input
                type="text"
                placeholder="Numer telefonu"
                name="phone"
                onChange={onChange}
                value={address.phone}
                maxLength={64}
              />
              <Input
                type="text"
                placeholder="Adres e-mail"
                name="email"
                onChange={onChange}
                value={address.email}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          {address.loading ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <ButtonGroup>
              <Button
                type="button"
                color="primary"
                disabled={!address.dirty}
                onClick={(e) => this.saveShippingAddress(i)}
              >
                <i className="fas fa-save"></i>
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={(e) => this.deleteShippingAddress(i)}
              >
                <i className="fas fa-times"></i>
              </Button>
            </ButtonGroup>
          )}
        </td>
      </tr>
    );
  };

  renderSenderAddress = (address, i) => {
    const onChange = (e) => this.changeAddressData(IsSenderAddress, i, e);
    return (
      <tr key={i} style={{ display: address.deleted ? "none" : "inherited" }}>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Adres"
              name="line1"
              onChange={onChange}
              value={address.line1}
              maxLength={64}
            />
            <Input
              type="text"
              placeholder="Adres (linia dodatkowa)"
              name="line2"
              onChange={onChange}
              value={address.line2}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Kod pocztowy"
              name="postalCode"
              onChange={onChange}
              value={address.postalCode}
              maxLength={16}
            />
            <Input
              type="text"
              placeholder="Miejscowość"
              name="city"
              onChange={onChange}
              value={address.city}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <CountrySelector
              name="countryCode"
              onChange={onChange}
              value={address.countryCode}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Odbiorca"
              name="person"
              onChange={onChange}
              value={address.person}
              maxLength={64}
            />
            <InputGroup>
              <Input
                type="text"
                placeholder="Numer telefonu"
                name="phone"
                onChange={onChange}
                value={address.phone}
                maxLength={64}
              />
              <Input
                type="text"
                placeholder="Adres e-mail"
                name="email"
                onChange={onChange}
                value={address.email}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          {address.loading ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <ButtonGroup>
              <Button
                type="button"
                color="primary"
                disabled={!address.dirty}
                onClick={(e) => this.saveSenderAddress(i)}
              >
                <i className="fas fa-save"></i>
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={(e) => this.deleteSenderAddress(i)}
              >
                <i className="fas fa-times"></i>
              </Button>
            </ButtonGroup>
          )}
        </td>
      </tr>
    );
  };

  renderShippingAddressesCard = () => {
    const { customer } = this.props;
    const { shippingAddresses } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Adresy dostawy</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Adres</th>
                  <th>Kod pocztowy i miejscowość</th>
                  <th>Kraj</th>
                  <th>Odbiorca</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {shippingAddresses
                  ? shippingAddresses.map(this.renderShippingAddress)
                  : this.tableLoadingIndicator()}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} align="right">
                    <div className="clearfix">
                      <div className="float-left pr-3">
                        <Uploader
                          url={`${SHIPPING_ADDRESSES_API_URL}Import/${customer.id}`}
                          isPresigned={false}
                          onUploadComplete={(response) =>
                            this.loadShippingAddresses()
                          }
                        />
                      </div>
                      <div className="float-left">
                        <Button
                          type="button"
                          color="info"
                          disabled={!shippingAddresses}
                          onClick={this.exportShippingAddresses}
                        >
                          <i className="fas fa-download"></i> &nbsp; Export
                        </Button>
                      </div>
                      <div className="float-right">
                        <Button
                          type="button"
                          color="success"
                          disabled={!shippingAddresses}
                          onClick={this.addShippingAddress}
                        >
                          <i className="fas fa-plus-circle"></i> &nbsp; Dodaj
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          disabled={
                            !shippingAddresses ||
                            !shippingAddresses.some((a) => a.dirty)
                          }
                          onClick={this.saveDirtyShippingAddresses}
                        >
                          <i className="fas fa-save"></i> &nbsp; Zapisz
                          wszystkie zmiany
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderSenderAddressesCard = () => {
    const { customer } = this.props;
    const { senderAddresses } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Adresy dostawy</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Adres</th>
                  <th>Kod pocztowy i miejscowość</th>
                  <th>Kraj</th>
                  <th>Odbiorca</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {senderAddresses
                  ? senderAddresses.map(this.renderSenderAddress)
                  : this.tableLoadingIndicator()}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} align="right">
                    <div className="clearfix">
                      <div className="float-left pr-3">
                        <Uploader
                          url={`${SHIPPING_ADDRESSES_API_URL}Import/${customer.id}`}
                          isPresigned={false}
                          onUploadComplete={(response) =>
                            this.loadSenderAddresses()
                          }
                        />
                      </div>
                      <div className="float-left">
                        <Button
                          type="button"
                          color="info"
                          disabled={!senderAddresses}
                          onClick={this.exportSenderAddresses}
                        >
                          <i className="fas fa-download"></i> &nbsp; Export
                        </Button>
                      </div>
                      <div className="float-right">
                        <Button
                          type="button"
                          color="success"
                          disabled={!senderAddresses}
                          onClick={this.addSenderAddress}
                        >
                          <i className="fas fa-plus-circle"></i> &nbsp; Dodaj
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          disabled={
                            !senderAddresses ||
                            !senderAddresses.some((a) => a.dirty)
                          }
                          onClick={this.saveDirtySenderAddresses}
                        >
                          <i className="fas fa-save"></i> &nbsp; Zapisz
                          wszystkie zmiany
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderBillingInfo = (info, i) => {
    const onChange = (e) => this.changeAddressData(IsBillingInfo, i, e);
    return (
      <tr key={i} style={{ display: info.deleted ? "none" : "inherited" }}>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Nazwa firmy"
              name="buyerName"
              onChange={onChange}
              value={info.buyerName}
            />
            <Input
              type="text"
              placeholder="NIP"
              name="vatID"
              onChange={onChange}
              value={info.vatID}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Adres"
              name="line1"
              onChange={onChange}
              value={info.line1}
              maxLength={64}
            />
            <Input
              type="text"
              placeholder="Adres (linia dodatkowa)"
              name="line2"
              onChange={onChange}
              value={info.line2}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Kod pocztowy"
              name="postalCode"
              onChange={onChange}
              value={info.postalCode}
              maxLength={16}
            />
            <Input
              type="text"
              placeholder="Miejscowość"
              name="city"
              onChange={onChange}
              value={info.city}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <CountrySelector
              name="countryCode"
              onChange={onChange}
              value={info.countryCode}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              placeholder="Numer telefonu"
              name="phone"
              onChange={onChange}
              value={info.phone}
              maxLength={64}
            />
            <Input
              type="text"
              placeholder="Adres e-mail"
              name="email"
              onChange={onChange}
              value={info.email}
              maxLength={64}
            />
          </FormGroup>
        </td>
        <td>
          {info.loading ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : (
            <ButtonGroup>
              <Button
                type="button"
                color="primary"
                disabled={!info.dirty}
                onClick={(e) => this.saveBillingInfo(i)}
              >
                <i className="fas fa-save"></i>
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={(e) => this.deleteBillingInfo(i)}
              >
                <i className="fas fa-times"></i>
              </Button>
            </ButtonGroup>
          )}
        </td>
      </tr>
    );
  };

  onChangeNip = (e) => {
    const { value } = e.target;
    this.setState({ nip: value });
  };

  onSubmitNip = () => {
    const { nip, billingInfos } = this.state;
    apiDriver
      .get(`${config.api.orders}pl/BillingInfos/Gus/${nip}`)
      .subscribe((response) => {
        if (response.response) {
          let info = { ...response.response, dirty: true };
          delete info.id;
          this.setState({ billingInfos: [...billingInfos, info] });
        }
      });
  };

  renderBillingInfosCard = () => {
    const { customer } = this.props;
    const { billingInfos, nip } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane rozliczeniowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Dane nabywcy</th>
                  <th>Adres</th>
                  <th>Kod pocztowy i miejscowość</th>
                  <th>Kraj</th>
                  <th>Kontakt</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {billingInfos
                  ? billingInfos.map(this.renderBillingInfo)
                  : this.tableLoadingIndicator()}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <div className="clearfix">
                      <div className="float-left pr-3">
                        <Input value={nip} onChange={this.onChangeNip} />
                      </div>
                      <div className="float-left">
                        <Button onClick={this.onSubmitNip}>
                          Pobierz z GUS
                        </Button>
                      </div>
                    </div>
                    <div className="clearfix">
                      <div className="float-left pr-3">
                        <Uploader
                          url={`${BILLING_INFOS_API_URL}Import/${customer.id}`}
                          isPresigned={false}
                          onUploadComplete={(response) =>
                            this.loadBillingInfos()
                          }
                        />
                      </div>
                      <div className="float-left">
                        <Button
                          type="button"
                          color="info"
                          disabled={!billingInfos}
                          onClick={this.exportBillingInfos}
                        >
                          <i className="fas fa-download"></i> &nbsp; Export
                        </Button>
                      </div>
                    </div>
                  </td>
                  <td colSpan={3} align="right">
                    <Button
                      type="button"
                      color="success"
                      disabled={!billingInfos}
                      onClick={this.addBillingInfo}
                    >
                      <i className="fas fa-plus-circle"></i> &nbsp; Dodaj
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      disabled={
                        !billingInfos || !billingInfos.some((a) => a.dirty)
                      }
                      onClick={this.saveDirtyBillingInfos}
                    >
                      <i className="fas fa-save"></i> &nbsp; Zapisz wszystkie
                      zmiany
                    </Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              {this.renderShippingAddressesCard()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              {this.renderSenderAddressesCard()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">{this.renderBillingInfosCard()}</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) { }
}

function mapStateToProps(state) {
  return {
    loading: state.customers.loading,
    auth: state.auth.user,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerAddresses),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";

import * as bookingsActions from "stores/bookings/bookings/actions";
import * as roomsActions from "stores/bookings/rooms/actions";
import * as roomAdditionsActions from "stores/bookings/roomAdditions/actions";
import * as personTypesActions from "stores/bookings/personTypes/actions";
import * as dietsActions from "stores/bookings/diets/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NumericInput from "components/Form/NumericInput";

Dropzone.autoDiscover = false;

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      booking: null,
      types: [],
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      diets: [],
      personTypes: [],
      rooms: [],
      roomAdditions: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        booking: {
          id: undefined,
          start: null,
          end: null,
          created: null,
          guest: null,
          bookingRooms: [],
          people: [],
        },
        loading: false,
      });
    }
    this.props.listRooms();
    this.props.listRoomAdditions();
    this.props.listDiets();
    this.props.listPersonTypes();
  };

  changeBookingRoomAddition = (event) => {
    const { name, value, type } = event.target;
    let entity = { ...this.state.booking };
    let bookingRoomAdditions = [...this.state.booking.bookingRoomAdditions];

    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }

    bookingRoomAdditions = bookingRoomAdditions.map((bra) => {
      if (bra.roomId === Number(name)) {
        bra.additionId = parsedValue;
      }
      return bra;
    });

    entity.people = bookingRoomAdditions;
    this.setState({ booking: entity });
  };

  changeBookingPersonData = (index, prop, value, type) => {
    const name = [index, prop];
    let entity = { ...this.state.booking };
    let people = [...this.state.booking.people];

    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }

    people = people.map((a, index) => {
      if (index === Number(name[0])) {
        a[name[1]] = parsedValue;
      }
      return a;
    });

    entity.people = people;
    this.setState({ booking: entity });
  };

  changeBookingPersonData = (event) => {
    const { value, type } = event.target;
    const name = event.target.name.split(".");

    this.changeTypePricingData(name[0], name[1], value, type);
  };

  changeBookingDataEx = (name, value, type) => {
    var booking = { ...this.state.booking };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }
    booking[name] = parsedValue;
    this.setState({ booking });
  };

  changeBookingData = (event) => {
    const { name, value, type } = event.target;
    this.changeBookingDataEx(name, value, type);
  };

  changeGuestData = (event) => {
    const { name, value } = event.target;
    var booking = { ...this.state.booking };
    booking.guest[name] = value;
    this.setState({ booking });
  };

  changeBookingDate = (name, value) => {
    this.changeBookingDataEx(name, value, "datetime");
  };

  submitForm = (event) => {
    var booking = { ...this.state.booking };
    if (booking.id) {
      this.setState({ action: "update" });
      this.props.update(booking);
    } else {
      this.setState({ action: "create" });
      this.props.create(booking);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.booking.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.booking || this.props.loading;

    const { booking } = this.state;
    const { auth } = this.props;

    if (!booking || booking.id === auth.id || !booking.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.booking || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { booking } = this.state;

    if (!booking || !booking.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={booking ? booking.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderGuestData = () => {
    const { booking } = this.state;

    if (!booking) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Rejestrujący</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Id</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-tag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Id"
                      type="text"
                      name="id"
                      value={booking ? booking.guest.id : ""}
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Imię</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="givenName"
                      value={booking.guest.givenName}
                      onChange={this.changeGuestData}
                      placeholder="Imię"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Nazwisko</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="surname"
                      value={booking.guest.surname}
                      onChange={this.changeGuestData}
                      placeholder="Nazwisko"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Telefon</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="phone"
                      value={booking.guest.phone}
                      onChange={this.changeGuestData}
                      placeholder="Telefon"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Adres e-mail</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="email"
                      value={booking.guest.email}
                      onChange={this.changeGuestData}
                      placeholder="Adres e-mail"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderRoomsData = () => {
    const { booking, diets, personTypes, rooms, roomAdditions } = this.state;

    if (!booking) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Pokoje</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {booking.bookingRooms.map((bookingRoom) => {
              let currentRoom = rooms.filter(
                (room) => bookingRoom.roomId === room.id,
              );
              if (currentRoom.length > 0) {
                currentRoom = currentRoom[0];
              } else {
                currentRoom = { currentTranslation: {} };
              }
              let currentRoomAdditions = booking.bookingRoomAdditions
                .filter((bra) => bra.roomId === bookingRoom.roomId)
                .map((bra) => bra.additionId);
              if (currentRoomAdditions.length === 0) {
                currentRoomAdditions = null;
              } else if (currentRoomAdditions.length === 1) {
                currentRoomAdditions = currentRoomAdditions[0];
              }
              return (
                <Row key={bookingRoom.roomId}>
                  <Col lg="4">
                    <i className="fas fa-home"></i>{" "}
                    <b>{currentRoom.currentTranslation?.title}</b>
                  </Col>
                  <Col lg="4">
                    <Input
                      className="form-control mb-3"
                      type="select"
                      name={bookingRoom.roomId}
                      value={currentRoomAdditions}
                      onChange={this.changeBookingRoomAddition}
                      required={true}
                    >
                      <option value="00000000-0000-0000-0000-000000000000">
                        Wybierz
                      </option>
                      {roomAdditions.map((addition) => (
                        <option key={addition.id} value={addition.id}>
                          {addition.currentTranslation?.title}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col lg="3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" checked={bookingRoom.parklot} />{" "}
                        Miejsce parkingowe
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" checked={bookingRoom.pet} />{" "}
                        Pobyt ze zwierzęciem
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg="1" className="text-right">
                    <Button size="sm" color="success">
                      <i className="fas fa-plus"></i>
                    </Button>
                  </Col>
                  <Col lg="12" className="m-0 p-0">
                    <hr className="mt-0 mb-3" />
                  </Col>
                  {booking.people
                    .filter((person) => person.roomId === bookingRoom.roomId)
                    .map((person, index) => (
                      <React.Fragment key={index}>
                        <Col lg="4">
                          <Input
                            className="form-control mb-3"
                            type="select"
                            name={index + ".personTypeId"}
                            value={person.personTypeId}
                            onChange={this.changeBookingPersonData.bind(
                              this,
                              index,
                            )}
                            required={true}
                          >
                            <option
                              value="00000000-0000-0000-0000-000000000000"
                              disabled
                            >
                              Wybierz
                            </option>
                            {personTypes.map((personType) => (
                              <option key={personType.id} value={personType.id}>
                                {personType.currentTranslation?.title}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col lg="4">
                          <Input
                            className="form-control mb-3"
                            type="select"
                            name={index + ".dietId"}
                            value={person.dietId}
                            onChange={this.changeBookingPersonData.bind(
                              this,
                              index,
                            )}
                            required={true}
                          >
                            <option
                              value="00000000-0000-0000-0000-000000000000"
                              disabled
                            >
                              Wybierz
                            </option>
                            {diets.map((diet) => (
                              <option key={diet.id} value={diet.id}>
                                {diet.currentTranslation?.title}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col lg="3"></Col>
                        <Col lg="1" className="text-right">
                          <Button size="sm" color="danger">
                            <i className="fas fa-times"></i>
                          </Button>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              );
            })}
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { booking } = this.state;

    if (!booking) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Początek</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Zameldowanie",
                        className: "form-control",
                      }}
                      value={new Date(booking.start)}
                      timeFormat={false}
                      dateFormat="yyyy-MM-DD"
                      onChange={this.changeBookingDate.bind(this, "start")}
                      name={"start"}
                      className="form-control p-0 m-0 border-0"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Wymeldowanie</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Wymeldowanie",
                        className: "form-control",
                      }}
                      value={new Date(booking.end)}
                      timeFormat={false}
                      dateFormat="yyyy-MM-DD"
                      onChange={this.changeBookingDate.bind(this, "end")}
                      name={"end"}
                      className="form-control p-0 m-0 border-0"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Utworzono</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Utworzono",
                        className: "form-control",
                      }}
                      value={new Date(booking.created)}
                      timeFormat={false}
                      dateFormat="yyyy-MM-DD"
                      name={"created"}
                      className="form-control p-0 m-0 border-0"
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Cena</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <NumericInput
                      value={booking.price.toFixed(2)}
                      readOnly={true}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>PLN</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.booking || this.props.loading;

    const { booking } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/booking/bookings/" />;
    }

    return (
      <>
        <SimpleHeader
          name={booking ? booking.currentTranslation?.title : ""}
          link="/admin/booking/bookings"
          parentName="Rezerwacje"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderGuestData()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">{this.renderRoomsData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.booking !== this.props.booking) {
      this.setState({
        booking: this.props.booking,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.rooms !== this.props.rooms) {
      this.setState({
        rooms: this.props.rooms,
      });
    }
    if (prevProps.personTypes !== this.props.personTypes) {
      this.setState({
        personTypes: this.props.personTypes,
      });
    }
    if (prevProps.diets !== this.props.diets) {
      this.setState({
        diets: this.props.diets,
      });
    }
    if (prevProps.roomAdditions !== this.props.roomAdditions) {
      this.setState({
        roomAdditions: this.props.roomAdditions,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    booking: state.bookings.current,
    loading: state.bookings.loading,
    auth: state.auth.user,
    session: state.session,
    rooms: state.rooms.list,
    personTypes: state.personTypes.list,
    diets: state.diets.list,
    roomAdditions: state.roomAdditions.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(bookingsActions.read(id)),
    remove: (id) => dispatch(bookingsActions.remove(id)),
    update: (booking) => dispatch(bookingsActions.update(booking)),
    create: (booking) => dispatch(bookingsActions.create(booking)),
    listRooms: () => dispatch(roomsActions.list(0, 1000)),
    listRoomAdditions: () => dispatch(roomAdditionsActions.list()),
    listPersonTypes: () => dispatch(personTypesActions.list()),
    listDiets: () => dispatch(dietsActions.list()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Booking),
);

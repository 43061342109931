import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AlternativeHeader() {
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0">Dashboard</h6>{" "}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AlternativeHeader;

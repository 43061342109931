import React from "react";

import Dropzone from "dropzone";
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
} from "reactstrap";

import onChangeParser from "views/pages/products/OnChangeParser";
import dotNotationParser from "views/pages/products/DotNotationParser";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

export default class OrderProductAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  parseValue = onChangeParser;
  changeDotNotationValue = dotNotationParser;
  onUpdate = this.props.onUpdate;

  changeOrderProductOption = (e) => {
    const { orderProduct, product } = this.props;
    const optionId = Number(e.target.value);
    let options = [...orderProduct?.personalization?.options];
    const option = [...product?.options].find((a) => a.id === optionId);
    const optionsInAttribute = [...product?.options]
      .filter((a) => a.attributeId === option.attributeId)
      .map((a) => a.id);
    options = options.filter((a) => !optionsInAttribute.includes(a));
    options.push(option.id);
    orderProduct.personalization.options = options;
    this.onUpdate(orderProduct);
  };

  render = () => {
    const { product, orderProduct } = this.props;

    if (!orderProduct || !product) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    var attributeIds = [
      ...new Set([...product.options].map(({ attributeId }) => attributeId)),
    ];
    return attributeIds.map((attributeId) => {
      const options = [...product.options].filter(
        (o) => o.attributeId === attributeId,
      );
      const attribute = [...product.attributes].find(
        (a) => a.id === attributeId,
      );
      return (
        <Row key={attribute.id}>
          <Col md={6}>
            <Label for={attribute.id}>{localizer(attribute)?.currentTranslation?.title}</Label>
          </Col>
          <Col md={6}>
            <FormGroup>
              <InputGroup>
                <Input
                  type="select"
                  id={attribute.id}
                  name={attribute.id}
                  onChange={this.changeOrderProductOption}
                  value={options
                    .map((o) => o.id)
                    .find((o) =>
                      orderProduct.personalization?.options?.includes(o),
                    )}
                >
                  {attribute.isRequired ? (
                    <React.Fragment />
                  ) : (
                    <option value={undefined}>Brak</option>
                  )}
                  {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {localizer(option)?.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      );
    });
  };
}

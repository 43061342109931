import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";

import * as paymentsActions from "stores/orders/payments/actions";

import NotificationAlert from "react-notification-alert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Button } from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

function DotPayPaymentIntegrationButtons(props) {
  const { payment, notify } = props;
  const onClickCopy = () => {
    apiDriver
      .get(`${config.api.dotpay}Payments/${payment.id}`)
      .pipe(
        map((response) => {
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        var text = String(data);
        navigator.clipboard.writeText(text);
      });
  };
  const onClickNotify = () => {
    apiDriver
      .get(`${config.api.dotpay}Payments/${payment.id}/Notify`)
      .pipe(
        map((response) => {
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        notify(
          "Wysłano powiadomienie",
          "Wysłano powiadomienie z linkiem do zapłaty",
        );
      });
  };
  return (
    <>
      <Button
        type="button"
        size="sm"
        color="success"
        disabled={payment.status ? payment.status.isSuccessful : false}
        onClick={onClickCopy}
      >
        <i className="fa fa-copy"></i>
      </Button>
      <Button
        type="button"
        size="sm"
        color="success"
        disabled={payment.status ? payment.status.isSuccessful : false}
        onClick={onClickNotify}
      >
        <i className="fa fa-envelope"></i>
      </Button>
    </>
  );
}

function PaymentIntegrationButtons(props) {
  const { payment, notify } = props;
  switch (payment.method.integration) {
    case "DotPay":
      return (
        <DotPayPaymentIntegrationButtons notify={notify} payment={payment} />
      );
    default:
      return <React.Fragment />;
  }
}

class PaymentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      orders: [],
      meta: {},
      filter: [],
      redirect: null,
      type: this.props.type || [],
      tableState: {},
    };
    if (!this.state.orders.list) {
      this.props.list(0, 10, undefined, this.getFilters());
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.refresh === false && this.props.refresh === true) {
      this.onTableChange("type", this.state.tableState);
    }
    if (prevState.type.length !== this.state.type.length) {
      this.onTableChange("type", this.state.tableState);
    }
  };

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  getFilters = (state) => {
    const { auth } = this.props;
    const staticFilters = this.props.filters ? { ...this.props.filters } : [];
    const typeFilters = Object.assign(
      {},
      ...[...this.state.type]
        .map((type) => {
          if (type === "assigned") {
            return {
              assigneeId: {
                filterVal: auth.id,
                filterType: "Guid",
                comparator: "=",
                caseSensitive: false,
                filterKey: "assigneeId",
              },
            };
          }
          return null;
        })
        .filter((a) => a),
    );
    const dynamicFilters = state && state.filters ? { ...state.filters } : [];
    const filters = { ...dynamicFilters, ...typeFilters, ...staticFilters };
    return filters;
  };

  onTableChange = (type, newState) => {
    const filters = this.getFilters(newState);

    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: filters,
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
      tableState: { ...newState },
    });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/payment/payments/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/payment/payments/" + row.id });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
        <PaymentIntegrationButtons payment={row} notify={this.notify} />
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  toggleType = (type) => {
    let newType = [...this.state.type];
    if (type) {
      if (newType.includes(type)) {
        newType = [...newType.filter((t) => t !== type)];
      } else {
        newType.push(type);
      }
    } else {
      newType = [];
    }
    this.setState({ type: newType });
  };

  render = () => {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { data, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "externalId",
        text: "Numer",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Kwota",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "PLN",
            }).format(cell)
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        hidden: this.props.hideMethod,
        dataField: "method",
        text: "Metoda",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            cell.currentTranslation?.title
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        hidden: this.props.hideStatus,
        dataField: "status",
        text: "Status",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            cell.currentTranslation?.title
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        hidden: this.props.hideOrder,
        dataField: "order",
        text: "Zamówienie",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            cell.number ? (
              <Link to={`/admin/orders/orders/${cell.id}`}>{cell.number}</Link>
            ) : (
              <span className="text-muted">Brak</span>
            )
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "created",
        text: "Utworzono",
        formatter: (cell, row, rowIndex, formatExtraData) => cell ? (new Date(cell)).toLocaleString() : <span className="text-muted">Brak</span>,
        sort: true,
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <ToolkitProvider
          data={data}
          keyField="id"
          columns={columns}
          search
          bootstrap4={true}
        >
          {(props) => {
            return (
              <>
                <PaginationProvider pagination={paginationFactory(pagination)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      id="react-bs-table"
                      onTableChange={this.onTableChange}
                      wrapperClasses="table-responsive"
                      {...paginationTableProps}
                      noDataIndication={() => (
                        <div className="text-center text-muted py-5">
                          <i className="fas fa-database fa-5x d-block mb-3"></i>
                          Brak danych
                        </div>
                      )}
                      defaultSorted={[{ dataField: "created", order: "desc" }]}
                    />
                  )}
                </PaginationProvider>
              </>
            );
          }}
        </ToolkitProvider>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.payments.list,
    loading: state.payments.loading,
    refresh: state.payments.refresh,
    auth: state.auth.user,
    meta: state.payments.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) => dispatch(paymentsActions.list(skip, take, searchText, filters, order)),
    read: (id) => dispatch(paymentsActions.read(id)),
    remove: (id) => dispatch(paymentsActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsTable),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as sellersDataActions from "stores/invoices/sellersData/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CountrySelector from "views/pages/orders/orders/cards/CountrySelector";

Dropzone.autoDiscover = false;

class SellerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      costSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        data: {
          id: undefined,
          title: "",
          line1: "",
          line2: "",
          city: "",
          countryCode: "",
          postalCode: "",
          state: "",
          phone: "",
          email: "",
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeData = (event) => {
    const value = this.parseValue(event.target);
    let data = { ...this.state.data, [value.name]: value.value };
    this.setState({ data });
  };

  submitForm = (event) => {
    var data = { ...this.state.data };
    if (data.id) {
      this.setState({ action: "update" });
      this.props.update(data);
    } else {
      this.setState({ action: "create" });
      this.props.create(data);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickRemove = () => {
    const { data } = this.state;
    this.props.remove(data.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.data || this.props.loading;

    const { data } = this.state;
    const { auth } = this.props;

    if (!data || data.id === auth.id || !data.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickRemove} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.data || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { data } = this.state;

    if (!data || !data.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={data ? data.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardAddress = () => {
    const { data } = this.state;

    if (!data) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane adresowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Adres</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-book" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres"
                      type="text"
                      name="line1"
                      value={data.line1}
                      onChange={this.changeData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3"></Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-book" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Dodatkowa linia"
                      type="text"
                      name="line2"
                      value={data.line2}
                      onChange={this.changeData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Miejscowość</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-map" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Miejscowość"
                      type="text"
                      name="city"
                      value={data.city}
                      onChange={this.changeData}
                      maxLength={64}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kod pocztowy</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kod pocztowy"
                      type="text"
                      name="postalCode"
                      value={data.postalCode}
                      onChange={this.changeData}
                      maxLength={16}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kraj</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-flag" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <CountrySelector
                      name="countryCode"
                      value={data.countryCode}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardContact = () => {
    const { data } = this.state;

    if (!data) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane kontaktowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Telefon</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Telefon"
                      type="text"
                      name="phone"
                      value={data.phone}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Adres e-mail</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres e-mail"
                      type="text"
                      name="email"
                      value={data.email}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardBanking = () => {
    const { data } = this.state;

    if (!data) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dane konta bankowego</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="3">Numer konta bankowego</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-money-check" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Numer konta bankowego"
                      type="text"
                      name="bankAccountNumber"
                      value={data.bankAccountNumber}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Numer konta IBAN</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-money-check-alt" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Numer konta IBAN"
                      type="text"
                      name="bankIBANNumber"
                      value={data.bankIBANNumber}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Bank</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-university" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nazwa banku"
                      type="text"
                      name="bankName"
                      value={data.bankName}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kod BIC</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="far fa-university" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kod BIC"
                      type="text"
                      name="bankSwiftCode"
                      value={data.bankBIC}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { data } = this.state;

    if (!data) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Nazwa</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nazwa"
                      type="text"
                      name="name"
                      value={data?.name}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Numer NIP</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-file-invoice" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Numer NIP"
                      type="text"
                      name="vatID"
                      value={data?.vatID}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Uwagi</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-exclamation" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Uwagi"
                      type="text"
                      name="note"
                      value={data.note}
                      onChange={this.changeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.data || this.props.loading;

    const { data } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/accountancy/sellersData/" />;
    }

    return (
      <>
        <SimpleHeader
          name={data ? data.currentTranslation?.title : ""}
          link="/admin/accountancy/sellersData"
          parentName="Dane sprzedawcy"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardAddress()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardBanking()}
                {this.renderCardContact()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    data: state.sellersData.current,
    loading: state.sellersData.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(sellersDataActions.read(id)),
    remove: (id) => dispatch(sellersDataActions.remove(id)),
    update: (data) => dispatch(sellersDataActions.update(data)),
    create: (data) => dispatch(sellersDataActions.create(data)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SellerData),
);

import * as constants from "./constants";
import IAuthState from "./state";

const auth = (
  state: IAuthState = {
    user: null,
    token: null,
    error: null,
    loading: false,
    lock: false,
    tokenTimeout: null,
  },
  action: any,
) => {
  switch (action.type) {
    case constants.LOGOUT:
    case constants.LOGOUT_SUCCESS:
      return { ...state, token: null, user: null, error: null, loading: false };
    case constants.LOGIN_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case constants.LOGIN_SUCCESS:
      return { ...action.payload, loading: false };
    case constants.LOGIN:
      return { ...state, loading: true };
    case constants.LOCK:
      return { ...state, lock: true };
    case constants.UNLOCK:
      return { ...state, lock: false };
    case constants.REFRESH:
      return { ...state };
    case constants.REFRESH_SUCCESS:
      return { ...action.payload, loading: false };
    default:
      return state;
  }
};

export default auth;

import * as constants from "./constants";
import IRole from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (role: IRole) => ({
  type: constants.READ_SUCCESS,
  payload: role,
});

export const create = (role: IRole) => ({
  type: constants.CREATE,
  payload: role,
});
export const createSuccess = (role: IRole) => ({
  type: constants.CREATE_SUCCESS,
  payload: role,
});

export const update = (role: IRole) => ({
  type: constants.UPDATE,
  payload: role,
});
export const updateSuccess = (role: IRole) => ({
  type: constants.UPDATE_SUCCESS,
  payload: role,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (skip: Number = 0, take: Number = 10) => ({
  type: constants.LIST,
  payload: { skip, take },
});
export const listSuccess = (list: IRole[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (role: IRole) => ({
  type: constants.ACTIVE,
  payload: role,
});

import * as constants from "./constants";
import IPayment from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (payment: IPayment) => ({
  type: constants.READ_SUCCESS,
  payload: payment,
});

export const create = (payment: IPayment) => ({
  type: constants.CREATE,
  payload: payment,
});
export const createSuccess = (payment: IPayment) => ({
  type: constants.CREATE_SUCCESS,
  payload: payment,
});

export const update = (payment: IPayment) => ({
  type: constants.UPDATE,
  payload: payment,
});
export const updateSuccess = (payment: IPayment) => ({
  type: constants.UPDATE_SUCCESS,
  payload: payment,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (
  skip: Number = 0,
  take: Number = 10,
  searchText: string = "",
  filters: any[] = [],
  order: any = undefined,
) => {
  return {
    type: constants.LIST,
    payload: { skip, take, filters, order, searchText },
  };
};
export const listSuccess = (list: IPayment[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (payment: IPayment) => ({
  type: constants.ACTIVE,
  payload: payment,
});

export const refresh = () => ({
  type: constants.REFRESH,
});

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as invoicesActions from "stores/invoices/invoices/actions";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Button, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { InvoiceTypes } from "./Invoice";
import apiDriver from "stores/api.driver";
import config from "config/global";

import Search from "components/Table/Search";

class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      invoices: [],
      meta: {},
      filter: [],
      redirect: null,
      type: [],
      tableState: {},
      selection: []
    };
    this.tableRef = React.createRef();
    if (!this.state.invoices.list) {
      this.props.list(0, 10, undefined, this.getFilters());
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.type !== this.state.type) {
      this.onTableChange("type", this.state.tableState);
    }
  };

  getFilters = (state) => {
    const staticFilters = this.props.filters ? { ...this.props.filters } : [];
    const typeFilters = Object.assign(
      {},
      ...[...this.state.type]?.filter(type => !['Unpaid'].includes(type))
        .map((type) => {
          return {
            type: {
              filterVal: type,
              filterType: "Text",
              comparator: "=",
              caseSensitive: false,
              filterKey: "type",
            },
          };
        })
        .filter((a) => a),
    );
    if (this.state.type.includes('Unpaid')) {
      typeFilters.type = {
        filterVal: 'ProFormaInvoice',
        filterType: "Text",
        comparator: "!=",
        caseSensitive: false,
        filterKey: "type",
      };
      typeFilters.paid = {
        filterVal: "",
        filterType: "Text",
        comparator: "IS NULL",
        caseSensitive: false,
        filterKey: "paid",
      };
    }
    const dynamicFilters = state && state.filters ? { ...state.filters } : [];
    const filters = { ...dynamicFilters, ...typeFilters, ...staticFilters };
    return filters;
  };

  onTableChange = (type, newState) => {
    const filters = this.getFilters(newState);

    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: filters,
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
      tableState: { ...newState },
    });
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/accountancy/invoices/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/accountancy/invoices/" + row.id });
  };

  onClickNotify = (row) => {
    if (!row.confirmed) {
      return false;
    }

    apiDriver
      .get(`${config.api.accountancy}pl/Invoices/${row.id}/Notify`)
      .subscribe({
        next: (response) => alert(response.response),
        error: () => alert('error')
      });
  };

  onClickDemandForPayment = (row) => {
    if (!row.confirmed) {
      return false;
    }

    apiDriver
      .get(`${config.api.accountancy}pl/Invoices/${row.id}/DemandForPayment`)
      .subscribe({
        next: (response) => alert(response.response),
        error: () => alert('error')
      });
  };

  onClickMarkAsPaid = (row) => {
    if (!row.confirmed) {
      return false;
    }

    const url = `${config.api.accountancy}pl/Invoices/${row.id}`;

    apiDriver
      .get(url)
      .subscribe({
        next: (response) => {
          const invoice = response.response;
          delete invoice.buyerData;
          delete invoice.sellerData;
          delete invoice.series;
          invoice.payments = [
            ...invoice.payments,
            { value: invoice.amountRemain.grossCost, exchangeRate: 1, created: (new Date()).toISOString() }
          ];
          apiDriver
            .patch(url, invoice)
            .subscribe({
              next: (r) => this.onTableChange("paid", this.state.tableState),
              error: () => alert('error')
            })
        },
        error: () => alert('error')
      });
  };

  onClickDownload = (row) => {
    if (!row.confirmed) {
      return false;
    }

    apiDriver
      .download(
        `${row.number}.pdf`,
        `${config.api.accountancy}pl/Invoices/${row.id}/Download`,
      )
      .subscribe((data) => { });
  };

  onClickConfirm = (row) => {
    apiDriver.get(`${config.api.accountancy}pl/Invoices/${row.id}`).subscribe({
      next: (response) => {
        apiDriver
          .patch(`${config.api.accountancy}pl/Invoices/${row.id}`, {
            ...response.response,
            confirmed: true,
          })
          .subscribe({
            next: () => this.props.read(row.id),
            error: () => alert("error, on patch"),
          });
      },
      error: () => alert("error, on get"),
    });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {!row.confirmed &&
          <Button
            size="sm"
            color="success"
            className="d-print-none"
            onClick={() => this.onClickConfirm(row)}
          >
            <i className="fas fa-check"></i>
          </Button>
        }
        {row.confirmed &&
          <Button
            size="sm"
            color="success"
            className="d-print-none"
            onClick={() => this.onClickDownload(row)}
          >
            <i className="fas fa-download"></i>
          </Button>
        }
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickNotify(row);
          }}
        >
          <i className="fas fa-envelope"></i>
        </Button>
        {row.amountRemain.grossCost > 0 && (new Date(row.paymentTerm).getTime() <= new Date().getTime()) && <Button
          size="sm"
          color="warning"
          className="d-print-none"
          onClick={() => {
            this.onClickDemandForPayment(row);
          }}
        >
          <i className="fas fa-envelope"></i>
        </Button>}
        {row.amountRemain.grossCost > 0 && <Button
          size="sm"
          color="success"
          className="d-print-none"
          onClick={() => {
            this.onClickMarkAsPaid(row);
          }}
        >
          <i className="fas fa-credit-card"></i>
        </Button>}
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-edit"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  getIdsOnCurrentPage = () => {
    const { data } = this.props;
    return data.map(d => d.id);
  }

  onClickBulkDownload = () => {
    if (this.state.selection?.length === 0) {
      return alert('Brak zaznaczonych pozycji');
    }
    apiDriver.download(
      'export.pdf',
      `${config.api.accountancy}pl/Invoices/BulkDownload`,
      {},
      this.state.selection,
      'POST',
    ).subscribe({ error: () => alert('Error'), complete: () => this.setState({ loading: false }) });
    this.setState({ loading: true });
  };

  onClickBulkExport = () => {
    if (this.state.selection?.length === 0) {
      return alert('Brak zaznaczonych pozycji');
    }
    apiDriver.download(
      'export.pdf',
      `${config.api.accountancy}pl/Invoices/BulkExport`,
      {},
      this.state.selection,
      'POST',
    ).subscribe({ error: () => alert('Error'), complete: () => this.setState({ loading: false }) });
    this.setState({ loading: true });
  };

  onClickBulkNotify = () => {
    if (this.state.selection?.length === 0) {
      return alert('Brak zaznaczonych pozycji');
    }
    apiDriver.post(
      `${config.api.accountancy}pl/Invoices/BulkNotify`,
      this.state.selection,
    ).subscribe({ next: (r) => alert('Wysłano'), error: () => alert('Error'), complete: () => this.setState({ loading: false }) });
    this.setState({ loading: true });
  };

  onClickBulkDemandForPayment = () => {
    if (this.state.selection?.length === 0) {
      return alert('Brak zaznaczonych pozycji');
    }
    apiDriver.post(
      `${config.api.accountancy}pl/Invoices/BulkDemandForPayment`,
      this.state.selection,
    ).subscribe({ next: (r) => alert('Wysłano'), error: () => alert('Error'), complete: () => this.setState({ loading: false }) });
    this.setState({ loading: true });
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  formatType = (type) => {
    return InvoiceTypes.find((t) => t.id === type)?.label;
  };

  toggleType = (type) => {
    let newType = [...this.state.type];
    if (type) {
      if (newType.includes(type)) {
        newType = [...newType.filter((t) => t !== type)];
      } else {
        newType.push(type);
      }
    } else {
      newType = [];
    }
    this.setState({ type: newType });
  };

  formatSeller = (data) => (
    <>
      <div>{data?.name}</div>
      <small className="text-muted">{data?.vatID}</small>
    </>
  );

  formatBuyer = (data) => (
    <>
      <div>{data?.name}</div>
      <small className="text-muted">{data?.vatID}</small>
    </>
  );

  onSelect = (row, isSelect, rowIndex, e) => {
    const { selection } = this.state;
    if (isSelect) {
      this.setState({ selection: [...new Set([...selection, row.id])] });
    } else {
      this.setState({ selection: selection.filter(s => row.id !== s) });
    }
  }

  onSelectAll = (isSelect, rows, e) => {
    const { selection } = this.state;
    if (isSelect) {
      this.setState({ selection: [...new Set([...selection, ...rows.map(s => s.id)])] });
    } else {
      this.setState({ selection: [] });
    }
  }

  render = () => {
    const { disableTabs, disableSearch } = this.props;
    const { redirect, type, loading, selection } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { data, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "confirmed",
        text: "S",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            <i className="fas fa-check-circle text-success"></i>
          ) : (
            <React.Fragment />
          ),
        sort: true,
      },
      {
        dataField: "number",
        text: "Numer",
        sort: true,
      },
      {
        hidden: this.props.hideType,
        dataField: "type",
        text: "Rodzaj",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          this.formatType(cell),
        sort: true,
      },
      {
        hidden: this.props.hideSeller,
        dataField: "sellerData",
        text: "Sprzedajacy",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          this.formatSeller(cell),
        sort: true,
      },
      {
        hidden: this.props.hideBuyer,
        dataField: "buyerData",
        text: "Nabywca",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          this.formatBuyer(cell),
        sort: true,
      },
      {
        dataField: "amount",
        text: "Kwota",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            <span>
              {cell.grossCost.toFixed(2)} {row.currency}
            </span>
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "amountRemain",
        text: "Kwota do zaplaty",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            <span>
              {cell.grossCost.toFixed(2)} {row.currency}
            </span>
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "created",
        text: "Utworzono",
        formatter: (cell, row, rowIndex, formatExtraData) => cell ? (new Date(cell)).toLocaleString() : <span className="text-muted">Brak</span>,
        sort: true,
      },
      {
        dataField: "issued",
        text: "Data wystawienia",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            new Date(cell).toLocaleDateString()
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "paymentTerm",
        text: "Termin platnosci",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? (
            new Date(cell).toLocaleDateString()
          ) : (
            <span className="text-muted">Brak</span>
          ),
        sort: true,
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={columns}
        search
        bootstrap4={true}
      >
        {(props) => {
          return (
            <>
              <Row>
                <Col xs={3} md="auto">
                  <Button color="default" onClick={this.onClickBulkDownload} disabled={selection?.length === 0 || loading}><i className="fa fa-file-pdf"></i></Button>
                  <Button color="default" onClick={this.onClickBulkExport} disabled={selection?.length === 0 || loading}><i className="fa fa-file-csv"></i></Button>
                  <Button color="default" onClick={this.onClickBulkNotify} disabled={selection?.length === 0 || loading}><i className="fa fa-envelope"></i></Button>
                  <Button color="warning" onClick={this.onClickBulkDemandForPayment} disabled={selection?.length === 0 || loading}><i className="fa fa-envelope"></i></Button>
                </Col>
                <Col xs={12} md={3} className={disableSearch ? "d-none" : "d-inherit"}>
                  <Search className="form-control" placeholder="Szukaj..." {...props.searchProps} />
                </Col>
                <Col xs={12} md="auto" className={disableTabs ? "d-none" : "d-inherit"}>
                  <Nav pills fill>
                    <NavItem onClick={() => this.setState({ type: [] })}>
                      <NavLink href="#" active={type.length === 0}>
                        Wszystkie
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["VATInvoice"] })}>
                      <NavLink href="#" active={type.includes("VATInvoice")}>
                        Faktura VAT
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["ProFormaInvoice"] })}>
                      <NavLink href="#" active={type.includes("ProFormaInvoice")}>
                        Faktura Proforma
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["Receipt"] })}>
                      <NavLink href="#" active={type.includes("Receipt")}>
                        Paragon
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["Correction"] })}>
                      <NavLink href="#" active={type.includes("Correction")}>
                        Faktura korygująca
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["IntraCommunity"] })}>
                      <NavLink href="#" active={type.includes("IntraCommunity")}>
                        WDT
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={() => this.setState({ type: ["Unpaid"] })}>
                      <NavLink href="#" active={type.includes("Unpaid")}>
                        Nieopłacone
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <PaginationProvider pagination={paginationFactory(pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <BootstrapTable
                    remote
                    ref={this.tableRef}
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    loading={loading}
                    overlay={overlayFactory()}
                    id="react-bs-table"
                    onTableChange={this.onTableChange}
                    wrapperClasses="table-responsive"
                    selectRow={{ mode: 'checkbox', clickToSelect: true, onSelect: this.onSelect, onSelectAll: this.onSelectAll }}
                    {...paginationTableProps}
                    noDataIndication={() => (
                      <div className="text-center text-muted py-5">
                        <i className="fas fa-database fa-5x d-block mb-3"></i>
                        Brak danych
                      </div>
                    )}
                    defaultSorted={[{ dataField: "created", order: "desc" }]}
                  />
                )}
              </PaginationProvider>
            </>
          );
        }}
      </ToolkitProvider>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.invoices.list,
    loading: state.invoices.loading,
    auth: state.auth.user,
    meta: state.invoices.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) => dispatch(invoicesActions.list(skip, take, searchText, filters, order)),
    read: (id) => dispatch(invoicesActions.read(id)),
    remove: (id) => dispatch(invoicesActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoicesTable),
);

export const READ = "pbs/PRODUCT_ADDITIVE_GROUPS/READ/PENDING";
export const READ_SUCCESS = "pbs/PRODUCT_ADDITIVE_GROUPS/READ/SUCCESS";
export const CREATE = "pbs/PRODUCT_ADDITIVE_GROUPS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/PRODUCT_ADDITIVE_GROUPS/CREATE/SUCCESS";
export const UPDATE = "pbs/PRODUCT_ADDITIVE_GROUPS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/PRODUCT_ADDITIVE_GROUPS/UPDATE/SUCCESS";
export const REMOVE = "pbs/PRODUCT_ADDITIVE_GROUPS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/PRODUCT_ADDITIVE_GROUPS/REMOVE/SUCCESS";
export const LIST = "pbs/PRODUCT_ADDITIVE_GROUPS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/PRODUCT_ADDITIVE_GROUPS/LIST/SUCCESS";

export const ACTIVE = "pbs/PRODUCT_ADDITIVE_GROUPS/ACTIVE";

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as notificationTypesActions from "stores/core/notificationTypes/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

import translator from "services/translator/translator";
import CultureSelector from "views/pages/products/products/Cards/CultureSelector";
import Editor from "components/Editor/Editor";

Dropzone.autoDiscover = false;

class NotificationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notificationType: {
        translations: [],
      },
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      editorRef: React.createRef(),
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        notificationType: {
          id: undefined,
          name: "",
          translations: [],
        },
        loading: false,
      });
    }
  };

  changeNotificationTypeData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var notificationType = { ...this.state.notificationType };
    notificationType[name] = value;
    this.setState({ notificationType });
  };

  changeNotificationTypeTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var notificationType = { ...this.state.notificationType };
    let translation = notificationType.translations.filter(
      (t) => t.culture === currentCulture,
    );
    if (!translation || translation.length === 0) {
      notificationType.translations.push({ culture: currentCulture });
    }
    notificationType.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ notificationType });
  };

  changeNotificationTypeTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeNotificationTypeTranslationEx(name, value);
  };

  submitForm = (event) => {
    var notificationType = { ...this.state.notificationType };
    if (notificationType.id) {
      this.setState({ action: "update" });
      this.props.update(notificationType);
    } else {
      this.setState({ action: "create" });
      this.props.create(notificationType);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" notificationType="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.notificationType };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ notificationType: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.notificationType.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.notificationType || this.props.loading;

    const { notificationType } = this.state;
    const { auth } = this.props;

    if (
      !notificationType ||
      notificationType.id === auth.id ||
      !notificationType.id
    ) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.notificationType || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { notificationType } = this.state;

    if (!notificationType || !notificationType.id) {
      return <></>;
    }

    return (
      <Col md="12">
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-tag" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Id"
              type="text"
              name="id"
              value={notificationType ? notificationType.id : ""}
              readOnly={true}
            />
          </InputGroup>
        </FormGroup>
      </Col>
    );
  };

  renderCardData = () => {
    const { notificationType } = this.state;

    if (!notificationType) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Klucz"
                      type="text"
                      name="key"
                      value={notificationType ? notificationType.key : ""}
                      onChange={this.changeNotificationTypeData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  changeCulture = (e) => {
    this.setState({ currentCulture: e.target.value })
  }

  renderCultureSelect = () => {
    const { currentCulture } = this.state;
    return (
      <>
        <Button
          onClick={this.translate}
          disabled={currentCulture === "pl"}
          type="button"
          className="float-right"
        >
          <i className="fas fa-globe"></i>
        </Button>
        <CultureSelector
          currentCulture={currentCulture}
          changeCulture={this.changeCulture}
        />
      </>
    );
  };

  renderCardMeta = () => {
    const { notificationType } = this.state;
    const { currentCulture } = this.state;

    if (!notificationType) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      notificationType.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Metadane</h3>
          {this.renderCultureSelect()}
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="title"
                    value={editedTranslation?.title || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="textarea"
                    name="description"
                    value={editedTranslation?.description || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  onChangeEditor = (x) => {
    const { editorRef } = this.state;
    const content = editorRef.current.getContent();
    this.changeNotificationTypeTranslationEx("emailBodyTemplate", content);
  };

  renderCardPersonalization = () => {
    const { notificationType } = this.state;
    const { currentCulture } = this.state;

    if (!notificationType) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      notificationType.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Personalizacja szablonu</h3>
          {this.renderCultureSelect()}
        </CardHeader>
        <CardBody>
          <h4>Wiadomość systemowa</h4>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="systemSubjectTemplate"
                    value={editedTranslation?.systemSubjectTemplate || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="textarea"
                    name="systemBodyTemplate"
                    value={editedTranslation?.systemBodyTemplate || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <hr />
          <h4>Wiadomość e-mail</h4>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Tytuł"
                    type="text"
                    name="emailSubjectTemplate"
                    value={editedTranslation?.emailSubjectTemplate || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Editor
                  name="emailBodyTemplate"
                  value={editedTranslation?.emailBodyTemplate}
                  onChange={this.changeNotificationTypeTranslation}
                />
              </Col>
              <Col md="4">Wyślij kopię na adres e-mail</Col>
              <Col md="8">
                <Input
                  placeholder="Tytuł"
                  type="text"
                  name="emailCopy"
                  value={notificationType.emailCopy || ""}
                  onChange={this.changeNotificationTypeData}
                  rows="5"
                />
              </Col>
            </Row>
          </Form>
          <hr />
          <h4>Wiadomość SMS</h4>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    placeholder="Treść"
                    type="textarea"
                    name="smsTemplate"
                    value={editedTranslation?.smsTemplate || ""}
                    onChange={this.changeNotificationTypeTranslation}
                    rows="5"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card >
    );
  };

  render = () => {
    const loading = !this.state.notificationType || this.props.loading;

    const { notificationType } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/cp/notificationTypes/" />;
    }

    return (
      <>
        <SimpleHeader
          name={notificationType ? notificationType.name : ""}
          link="/admin/cp/notificationTypes"
          parentName="NotificationType"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardMeta()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardPersonalization()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.notificationType !== this.props.notificationType) {
      this.setState({
        notificationType: this.props.notificationType,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.access !== this.props.access) {
      this.setState({
        access: this.props.access,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    notificationType: state.notificationTypes.current,
    access: state.access.list,
    loading: state.notificationTypes.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(notificationTypesActions.read(id)),
    remove: (id) => dispatch(notificationTypesActions.remove(id)),
    update: (notificationType) =>
      dispatch(notificationTypesActions.update(notificationType)),
    create: (notificationType) =>
      dispatch(notificationTypesActions.create(notificationType)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationType),
);

import { combineEpics, ofType } from "redux-observable";
import { map, flatMap } from "rxjs/operators";
import config from "config/global";
import * as constants from "./constants";
import * as actions from "./actions";
import IAccess from "./model";
import api from "stores/api.driver";
import { AjaxResponse } from "rxjs/ajax";

export const API_URL = config.api.cp + "pl/Access/";

const createEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.CREATE),
    flatMap((action: any) =>
      api.put(API_URL, action.payload, { "Content-Type": "application/json" }),
    ),
    map((response: AjaxResponse) =>
      actions.createSuccess(response.response as IAccess),
    ),
  );
const readEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.READ),
    flatMap((action: any) => api.get(API_URL + action.payload)),
    map((response: AjaxResponse) =>
      actions.readSuccess(response.response as IAccess),
    ),
  );
const updateEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.UPDATE),
    flatMap((action: any) =>
      api.patch(API_URL + action.payload.id, action.payload, {
        "Content-Type": "application/json",
      }),
    ),
    map((response: AjaxResponse) =>
      actions.updateSuccess(response.response as IAccess),
    ),
  );
const removeEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.REMOVE),
    flatMap((action: any) => api.remove(API_URL + action.payload)),
    map((response: AjaxResponse) =>
      actions.removeSuccess(response.response as string),
    ),
  );
const listEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.LIST),
    flatMap((action: any) =>
      api.get(
        API_URL +
          "?skip=" +
          action.payload.skip +
          "&take=" +
          action.payload.take,
      ),
    ),
    map((response: AjaxResponse) =>
      actions.listSuccess(response.response as IAccess[], {
        total: Number(response.xhr.getResponseHeader("Results-Count-Total")),
        filtered: Number(
          response.xhr.getResponseHeader("Results-Count-Filtered"),
        ),
      }),
    ),
  );

export default combineEpics(
  createEpic,
  readEpic,
  updateEpic,
  removeEpic,
  listEpic,
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as usersActions from "stores/iam/users/actions";
import * as rolesActions from "stores/iam/roles/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

Dropzone.autoDiscover = false;

class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: null,
      auth: null,
      action: "",
      roles: null,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        user: {
          id: undefined,
          username: "",
          givenName: "",
          roles: [],
          userRoles: [],
          access: [],
          surname: "",
          password: "",
          newPassword: "",
          confirmNewPassword: "",
        },
        loading: false,
      });
    }
    this.props.listRoles();
  };

  changeUserData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var user = { ...this.state.user };
    user[name] = value;
    this.setState({ user });
  };

  changeUserRole = (event) => {
    let value = event.target.value;
    var user = { ...this.state.user };
    let userRoles = [...this.state.user.userRoles];
    if (event.target.checked) {
      userRoles.push({ userId: user.id, roleId: value });
    } else {
      userRoles = userRoles.filter((r) => r.roleId !== value);
    }
    user.userRoles = userRoles;
    this.setState({ user });
  };

  submitForm = (event) => {
    var user = { ...this.state.user };
    if (user.id) {
      this.setState({ action: "update" });
      this.props.update(user);
    } else {
      this.setState({ action: "create" });
      this.props.create(user);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.user.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.user || this.props.loading;

    const { user } = this.state;
    const { auth } = this.props;

    if (!user || user.id === auth.id || !user.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.user || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { user } = this.state;

    if (!user || !user.id) {
      return <></>;
    }

    return (
      <Col md="12">
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-tag" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Id"
              type="text"
              name="id"
              value={user ? user.id : ""}
              readOnly={true}
            />
          </InputGroup>
        </FormGroup>
      </Col>
    );
  };

  renderCardData = () => {
    const { user } = this.state;

    if (!user) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nazwa"
                      type="text"
                      name="username"
                      value={user ? user.username : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Imie"
                      type="text"
                      name="givenName"
                      value={user ? user.givenName : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nazwisko"
                      type="text"
                      name="surname"
                      value={user ? user.surname : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres e-mail"
                      type="email"
                      name="email"
                      value={user ? user.email : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardPassword = () => {
    const { user } = this.state;

    if (!user) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0">Ustalenie hasła</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Hasło"
                      type="password"
                      name="newPassword"
                      value={user ? user.newPassword : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Powtórz hasło"
                      type="password"
                      name="confirmNewPassword"
                      value={user ? user.confirmNewPassword : ""}
                      onChange={this.changeUserData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardRoles = () => {
    const { user } = this.state;
    const { roles } = this.state;

    if (!roles) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0">Role</h3>
        </CardHeader>
        <CardBody>
          {roles.map((role) => (
            <div key={role.id} className="form-check mb-3">
              <input
                className="form-check-input"
                id={"userRole" + role.id}
                type="checkbox"
                value={role.name}
                checked={!!user.userRoles.some((r) => r === role.name)}
                onChange={this.changeUserRole}
              />
              <label className="form-check-label" htmlFor="customCheck1">
                {role.name}
              </label>
            </div>
          ))}
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.user || this.props.loading;

    const { user } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/cp/users/" />;
    }

    return (
      <>
        <SimpleHeader
          name={user ? user.givenName + " " + user.surname : ""}
          link="/admin/cp/users"
          parentName="Uzytkownicy"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardPassword()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardRoles()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.roles !== this.props.roles) {
      this.setState({
        roles: this.props.roles,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.users.current,
    roles: state.roles.list,
    loading: state.users.loading,
    auth: state.auth.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(usersActions.read(id)),
    remove: (id) => dispatch(usersActions.remove(id)),
    update: (user) => dispatch(usersActions.update(user)),
    create: (user) => dispatch(usersActions.create(user)),
    listRoles: () => dispatch(rolesActions.list()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employee),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as dietsActions from "stores/bookings/diets/actions";
import * as personTypesActions from "stores/bookings/personTypes/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import NumericInput from "components/Form/NumericInput";

Dropzone.autoDiscover = false;

class Diet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      diet: null,
      personTypes: [],
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      photos: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        diet: {
          id: undefined,
          dietPersonTypes: [],
          translations: [],
          currentTranslation: null,
        },
        loading: false,
      });
    }
    this.props.listPersonTypes();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeDietData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    var diet = { ...this.state.diet };
    diet[name] = value;
    this.setState({ diet });
  };

  changeDietTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var diet = { ...this.state.diet };
    if (!diet.translations || diet.translations.length === 0) {
      diet.translations.push({ culture: currentCulture });
    }
    diet.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ diet });
  };

  changeDietTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeDietTranslationEx(name, value);
  };

  changeDietPersonType = (event) => {
    const { value, name } = event.target;
    let diet = { ...this.state.diet };
    let dietPersonTypes = [...this.state.diet.dietPersonTypes];
    if (value > 0) {
      if (dietPersonTypes.some((r) => r.personTypeId === name)) {
        dietPersonTypes = dietPersonTypes.map((dpt) => {
          if (dpt.personTypeId === name) {
            dpt.price = Number(value);
          }
          return dpt;
        });
      } else {
        dietPersonTypes.push({
          dietId: diet.id,
          personTypeId: name,
          price: Number(value),
        });
      }
    } else {
      dietPersonTypes = dietPersonTypes.filter((r) => r.personTypeId !== name);
    }
    diet.dietPersonTypes = dietPersonTypes;
    this.setState({ diet });
  };

  submitForm = (event) => {
    var diet = { ...this.state.diet };
    if (diet.id) {
      this.setState({ action: "update" });
      this.props.update(diet);
    } else {
      this.setState({ action: "create" });
      this.props.create(diet);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.diet.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.diet || this.props.loading;

    const { diet } = this.state;
    const { auth } = this.props;

    if (!diet || diet.id === auth.id || !diet.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.diet || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { diet } = this.state;

    if (!diet || !diet.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={diet ? diet.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardDietPersonType = () => {
    const { diet, personTypes } = this.state;

    if (!diet || !personTypes) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">
            Ceny diety{" "}
            <small>
              <span className="badge badge-info">Za dzień</span>
            </small>
          </h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {personTypes.map((personType) => {
                const dietPersonType = [...diet.dietPersonTypes].filter(
                  (dpt) => dpt.personTypeId === personType.id,
                )[0];
                return (
                  <React.Fragment key={personType.id}>
                    <Col md="3">{personType.currentTranslation?.title}</Col>
                    <Col md="3">
                      <FormGroup>
                        <InputGroup>
                          <NumericInput
                            placeholder="0"
                            min="0"
                            step="0.01"
                            name={personType.id}
                            value={dietPersonType ? dietPersonType.price : null}
                            onChange={this.changeDietPersonType}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>PLN</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { diet } = this.state;
    const { currentCulture } = this.state;

    if (!diet) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      diet.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeDietTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.diet || this.props.loading;

    const { diet } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/booking/diets/" />;
    }

    return (
      <>
        <SimpleHeader
          name={diet ? diet.currentTranslation?.title : ""}
          link="/admin/booking/diets"
          parentName="Diety"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">
                {this.renderCardDietPersonType()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.diet !== this.props.diet) {
      this.setState({
        diet: this.props.diet,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.personTypes !== this.props.personTypes) {
      this.setState({
        personTypes: this.props.personTypes,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    diet: state.diets.current,
    loading: state.diets.loading,
    auth: state.auth.user,
    session: state.session,
    personTypes: state.personTypes.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(dietsActions.read(id)),
    remove: (id) => dispatch(dietsActions.remove(id)),
    update: (diet) => dispatch(dietsActions.update(diet)),
    create: (diet) => dispatch(dietsActions.create(diet)),
    listPersonTypes: () => dispatch(personTypesActions.list()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Diet));

import { EColor } from "stores/products/products/model";

const printColors = [
  { value: EColor.OneOverZero, label: "1+0 czarny" },
  { value: EColor.OneOverOne, label: "1+1 czarny" },
  { value: EColor.FourOverZero, label: "4+0 CMYK" },
  { value: EColor.FourOverOne, label: "4+1 CMYK" },
  { value: EColor.FourOverFour, label: "4+4 CMYK" },
  { value: EColor.FiveOverZero, label: "5+0 CMYK+W" },
  { value: EColor.FiveOverFive, label: "5+5 CMYK+W" },
];

export default printColors;

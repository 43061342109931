import { combineEpics, ofType } from "redux-observable";
import { map, mergeMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import config from "config/global";
import * as constants from "./constants";
import * as actions from "./actions";
import { of } from "rxjs";
import api from "stores/api.driver";

export const API_URL = config.api.iam;

const loginEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.LOGIN),
    mergeMap((action: any) =>
      ajax
        .post(API_URL + "Authentication/SignIn", action.payload, {
          "Content-Type": "application/json",
          Origin: API_URL + "SignIn",
          "Access-Control-Request-Method": "POST",
          "Access-Control-Request-Headers": "X-Custom-Header",
        })
        .pipe(
          map((response: any) => actions.loginSuccess(response.response)),
          catchError((error: any) =>
            of({
              type: constants.LOGIN_FAILURE,
              payload: error.status,
            }),
          ),
        ),
    ),
  );

const refreshEpic = (action$: any) =>
  action$.pipe(
    ofType(constants.REFRESH),
    mergeMap((action: any) =>
      api.get(API_URL + "Authentication/Refresh").pipe(
        map((response: any) => actions.refreshSuccess(response.response)),
        catchError((error: any) =>
          of({
            type: constants.REFRESH_SUCCESS,
            payload: error.status,
          }),
        ),
      ),
    ),
  );

export default combineEpics(loginEpic, refreshEpic);

export const READ = "pbs/ORDERS/READ/PENDING";
export const READ_SUCCESS = "pbs/ORDERS/READ/SUCCESS";
export const CREATE = "pbs/ORDERS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/ORDERS/CREATE/SUCCESS";
export const UPDATE = "pbs/ORDERS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/ORDERS/UPDATE/SUCCESS";
export const REMOVE = "pbs/ORDERS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/ORDERS/REMOVE/SUCCESS";
export const LIST = "pbs/ORDERS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/ORDERS/LIST/SUCCESS";

export const ACTIVE = "pbs/ORDERS/ACTIVE";

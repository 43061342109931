import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import Dropzone from "dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Table,
  Row,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class CustomerNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationPreferences: [],
      notificationTypes: [],
      currentOption: 1,
      dropdownOpen: false,
    };
    this.myRef = React.createRef();
  }

  loadNotificationTypes = () => {
    apiDriver
      .get(config.api.cp + "pl/NotificationTypes/?Take=1000")
      .pipe(
        map((response) => {
          this.setState({ notificationTypes: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  loadNotificationPreferences = () => {
    const { customer } = this.props;
    apiDriver
      .get(config.api.cp + "pl/NotificationPreferences/" + customer.id)
      .pipe(
        map((response) => {
          this.setState({ notificationPreferences: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  saveNotificationPreferences = () => {
    const { customer } = this.props;
    const { notificationPreferences } = this.state;
    apiDriver
      .patch(
        config.api.cp + "pl/NotificationPreferences/" + customer.id,
        notificationPreferences,
      )
      .pipe(
        map((response) => {
          this.setState({ notificationPreferences: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => { });
  };

  componentDidMount = () => {
    this.loadNotificationPreferences();
    this.loadNotificationTypes();
  };

  notify = (title, content, type = "default") =>
    this.props.notify(title, content, type);
  loadingOverlay = () => this.props.loadingOverlay();
  tableLoadingIndicator = () => (
    <tr>
      <td colSpan={4} align="center">
        <i className="fas fa-circle-notch fa-spin fa-4x my-5"></i>
      </td>
    </tr>
  );
  parseValue = (e) => this.props.parseValue(e);

  changeNotificationPreferenceData = (e) => {
    const { customer } = this.props;
    const { notificationPreferences } = this.state;

    const { checked, name } = e.target;
    const splitted = name.split(".");
    const typeId = splitted[0];
    const userType = Number(splitted[1]);
    const key = splitted[2];

    const preference = notificationPreferences.find(
      (np) => np.typeId === typeId && np.userType === userType,
    );
    if (preference) {
      this.setState({
        notificationPreferences: [
          ...notificationPreferences.map((np) => {
            if (np.typeId === typeId && np.userType === userType)
              return { ...np, [key]: checked };
            return { ...np };
          }),
        ],
      });
    } else {
      this.setState({
        notificationPreferences: [
          ...notificationPreferences,
          {
            userId: customer.id,
            typeId: typeId,
            userType: userType,
            [key]: checked,
          },
        ],
      });
    }
  };

  renderNotificationPreference = (notificationType) => {
    const { notificationPreferences, currentOption } = this.state;
    const preference = notificationPreferences.find(
      (np) =>
        np.typeId === notificationType.id && np.userType === currentOption,
    );

    const onChange = this.changeNotificationPreferenceData;
    return (
      <tr key={notificationType.key}>
        <td>
          <i className={notificationType.icon}></i>
        </td>
        <td>
          {localizer(notificationType)?.currentTranslation?.title}
          <br />
          <small className="text-muted">
            {localizer(notificationType)?.currentTranslation?.description}
          </small>
        </td>
        <td>
          <label className="custom-toggle">
            <Input
              name={`${notificationType.id}.${currentOption}.system`}
              type="checkbox"
              onChange={onChange}
              value={true}
              checked={preference && preference.system}
              defaultChecked={preference && preference.system}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </td>
        <td>
          <label className="custom-toggle">
            <Input
              name={`${notificationType.id}.${currentOption}.email`}
              type="checkbox"
              onChange={onChange}
              value={true}
              checked={preference && preference.email}
              defaultChecked={preference && preference.email}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </td>
        <td>
          <label className="custom-toggle">
            <Input
              name={`${notificationType.id}.${currentOption}.sms`}
              type="checkbox"
              onChange={onChange}
              value={true}
              checked={preference && preference.sms}
              defaultChecked={preference && preference.sms}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </td>
      </tr>
    );
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  setCurrentOption = (value) => {
    this.setState({ currentOption: value });
  };

  getCurrentOptionHumanReadable = () => {
    switch (this.state.currentOption) {
      case 1:
        return "Własne";
      case 2:
        return "Globalne";
      case 3:
        return "Zdarzenia wywołane";
      case 4:
        return "Zdarzenia pod opieką";
      default:
        return "";
    }
  };

  renderNotificationPreferences = () => {
    const { customer } = this.props;
    const { notificationTypes } = this.state;

    if (!customer) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Powiadomienia</h3>
          <div className="float-right">
            <Button color="primary" onClick={this.saveNotificationPreferences}>
              Zapisz preferencje powiadomień
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>Powiadomienie</th>
                <th colSpan={3}>
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle caret>
                      {this.getCurrentOptionHumanReadable()}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => this.setCurrentOption(1)}>
                        Własne
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => this.setCurrentOption(2)}>
                        Globalne
                      </DropdownItem>
                      <DropdownItem onClick={(e) => this.setCurrentOption(3)}>
                        Zdarzenia wywołane
                      </DropdownItem>
                      <DropdownItem onClick={(e) => this.setCurrentOption(4)}>
                        Zdarzenia pod opieką
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th>System</th>
                <th>Email</th>
                <th>SMS</th>
              </tr>
            </thead>
            <tbody>
              {notificationTypes
                .sort(
                  (a, b) =>
                    localizer(a)?.currentTranslation?.title.localeCompare(
                      localizer(b)?.currentTranslation?.title,
                    ) || a.key.localeCompare(b.key),
                )
                .map(this.renderNotificationPreference)}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              {this.renderNotificationPreferences()}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) { }
}

function mapStateToProps(state) {
  return {
    loading: state.customers.loading,
    auth: state.auth.user,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerNotifications),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as invoiceSeriesActions from "stores/invoices/invoiceSeries/actions";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Button, Row, Col } from "reactstrap";
import { InvoiceTypes } from "../invoices/Invoice";

import Search from "components/Table/Search";

class InvoiceSeriesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      meta: {},
      invoices: [],
      filter: [],
      redirect: null,
      type: [],
      tableState: {},
    };
    this.myRef = React.createRef();
    if (!this.state.invoices.list) {
      this.props.list(0, 10, undefined, this.getFilters());
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    hideSizePerPage: true,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.type.length !== this.state.type.length) {
      this.onTableChange("type", this.state.tableState);
    }
  };

  getFilters = (state) => {
    const staticFilters = this.props.filters ? { ...this.props.filters } : [];
    const dynamicFilters = state && state.filters ? { ...state.filters } : [];
    const filters = { ...dynamicFilters, ...staticFilters };
    return filters;
  };

  onTableChange = (type, newState) => {
    const filters = this.getFilters(newState);

    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
      newState.searchText,
      filters,
      newState.sortOrder && newState.sortField
        ? { direction: newState.sortOrder, field: newState.sortField }
        : undefined,
    );

    this.setState({
      meta: {
        ...this.props.meta,
        page: newState.page,
        filters: filters,
        order:
          newState.sortOrder && newState.sortField
            ? newState.sortOrder + "," + newState.sortField
            : undefined,
        searchText: newState.searchText,
      },
      tableState: { ...newState },
    });
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/accountancy/invoiceSeries/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/accountancy/invoiceSeries/" + row.id });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-edit"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  formatType = (type) => {
    return InvoiceTypes.find((t) => t.id === type)?.label;
  };

  render = () => {
    const { redirect, loading } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { data, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "defaultForType",
        text: "Typ",
        formatter: (cell, row, rowIndex, formatExtraData) =>
          this.formatType(cell),
        sort: true,
      },
      {
        dataField: "sellerData.name",
        text: "Sprzedawca",
        sort: true,
      },
      {
        dataField: "format",
        text: "Format",
        sort: true,
      },
      {
        dataField: "currentNumber",
        text: "Aktualny numer",
        sort: true,
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <ToolkitProvider
        data={data}
        keyField="id"
        columns={columns}
        search
        bootstrap4={true}
      >
        {(props) => {
          return (
            <>
              <Row>
                <Col xs={12} sm={3}>
                  <Search
                    className="form-control"
                    placeholder="Szukaj..."
                    {...props.searchProps}
                  />
                </Col>
              </Row>
              <PaginationProvider pagination={paginationFactory(pagination)}>
                {({ paginationProps, paginationTableProps }) => (
                  <BootstrapTable
                    remote
                    ref={this.myRef}
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    loading={loading}
                    overlay={overlayFactory()}
                    id="react-bs-table"
                    onTableChange={this.onTableChange}
                    wrapperClasses="table-responsive"
                    {...paginationTableProps}
                    noDataIndication={() => (
                      <div className="text-center text-muted py-5">
                        <i className="fas fa-database fa-5x d-block mb-3"></i>
                        Brak danych
                      </div>
                    )}
                  />
                )}
              </PaginationProvider>
            </>
          );
        }}
      </ToolkitProvider>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.invoiceSeries.list,
    loading: state.invoiceSeries.loading,
    auth: state.auth.user,
    meta: state.invoiceSeries.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (
      skip = 0,
      take = 10,
      searchText = undefined,
      filters = undefined,
      order = undefined,
    ) => dispatch(invoiceSeriesActions.list(skip, take)),
    read: (id) => dispatch(invoiceSeriesActions.read(id)),
    remove: (id) => dispatch(invoiceSeriesActions.remove(id)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceSeriesTable),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as businessesActions from "stores/orders/businesses/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import AsyncSelector from "./../orders/cards/AsyncSelector";
import NotificationAlert from "react-notification-alert";

import apiDriver from "stores/api.driver";
import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import BusinessRebatesCard from "./RebatesCard";

Dropzone.autoDiscover = false;

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      business: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        business: {
          id: undefined,
          title: "",
          line1: "",
          line2: "",
          city: "",
          countryCode: "",
          postalCode: "",
          state: "",
          phone: "",
          email: "",
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  parseValue = (target) => {
    const { name, value, checked, type } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeBusinessData = (event) => {
    const value = this.parseValue(event.target);
    let business = { ...this.state.business };
    business = { ...business, [value.name]: value.value };
    this.setState({ business });
  };

  renderUser = (user) => ({ value: user.id, label: user.username });
  loadUsers = (inputValue) => {
    return apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((users) =>
        users.map((user) => ({
          value: user.id,
          label: user.username,
        })),
      );
  };

  submitForm = (event) => {
    var { owner, assignee, ...business } = { ...this.state.business };
    if (!business.ownerId) delete business.ownerId;
    if (!business.assigneeId) delete business.assigneeId;
    if (business.id) {
      this.setState({ action: "update" });
      this.props.update(business);
    } else {
      this.setState({ action: "create" });
      this.props.create(business);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.business.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.business || this.props.loading;

    const { business } = this.state;
    const { auth } = this.props;

    if (!business || business.id === auth.id || !business.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.business || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { business } = this.state;

    if (!business || !business.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={business ? business.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { business, users } = this.state;

    if (!business) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={business.title}
                      onChange={this.changeBusinessData}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Właściciel</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Właściciel"
                    name="ownerId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(
                      users || (business.owner ? [business.owner] : [])
                    ).map(this.renderUser)}
                    value={business.ownerId}
                    onChange={this.changeBusinessData}
                    onSearch={this.loadUsers}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Opiekun</Col>
              <Col md="9">
                <FormGroup>
                  <AsyncSelector
                    title="Opiekun"
                    name="assigneeId"
                    placeholder="Szukaj..."
                    allowNull={true}
                    options={(
                      users || (business.assignee ? [business.assignee] : [])
                    ).map(this.renderUser)}
                    value={business.assigneeId}
                    onChange={this.changeBusinessData}
                    onSearch={this.loadUsers}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.business || this.props.loading;

    const { business, action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/orders/businesses/" />;
    }

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name={business ? business.currentTranslation?.title : ""}
          link="/admin/orders/businesses"
          parentName="Przedsiębiorstwa"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="5">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="7">
              {business ? (
                <BusinessRebatesCard
                  business={business}
                  parseValue={this.parseValue}
                  notify={this.notify}
                />
              ) : (
                <React.Fragment />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.business !== this.props.business) {
      this.setState({
        business: this.props.business,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    business: state.businesses.current,
    loading: state.businesses.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(businessesActions.read(id)),
    remove: (id) => dispatch(businessesActions.remove(id)),
    update: (business) => dispatch(businessesActions.update(business)),
    create: (business) => dispatch(businessesActions.create(business)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Business),
);

export interface IVerificationTemplateTranslation {
  culture: string;
  title: string;
  content: string;
}

export enum EVerificationTemplateType {
  Info,
  Success,
  Warning,
  Danger,
}

export default interface IVerificationTemplate {
  id: string;
  type: Number;
  translations: IVerificationTemplateTranslation[];
  currentTranslation: IVerificationTemplateTranslation;
}

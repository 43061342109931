import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// react plugin for creating vector maps
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Progress,
  Container,
  Row,
  Col,
  Spinner,
  Table,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import CultureFlag from "views/pages/products/products/Cards/CultureFlag";

import { API_URL as CUSTOMERS_API_URL } from "stores/orders/customers/epics";
import { API_URL as ORDERS_API_URL } from "stores/orders/orders/epics";
import { API_URL as INVOICES_API_URL } from "stores/invoices/invoices/epics";

import apiDriver from "stores/api.driver";

import { chartOptions, parseOptions, chartExample2 } from "variables/charts.js";
import { useSelector } from "react-redux";
import OrderSource from "components/Order/Source";
import localizer from "stores/localizer";

function LoadingIndicator(props) {
  return (
    <tr>
      <td colSpan={props.cols} className="text-center">
        <Spinner />
      </td>
    </tr>
  );
}

function NoDataIndicator(props) {
  return (
    <tr>
      <td colSpan={props.cols} className="text-center text-muted p-5">
        <i className="fas fa-database fa-2x my-3"></i>
        <br />
        <small>Brak danych</small>
      </td>
    </tr>
  );
}

function MyCustomers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.auth.user);

  const onLoadData = (response) => {
    setData(response.response);
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver
      .get(
        CUSTOMERS_API_URL +
        apiDriver.buildIndexAttributes({
          filters: {
            assigneeId: {
              filterVal: user.id,
              filterType: "Guid",
              comparator: "=",
              caseSensitive: false,
              filterKey: "assigneeId",
            },
          },
        }),
      )
      .subscribe(onLoadData);
  };

  const renderData = (customer) => {
    return (
      <tr key={customer.id}>
        <td>
          <span className="fi fi-pl"></span>
        </td>
        <td>
          <div>
            {customer.givenName} {customer.surname}
          </div>
          <div>
            <small className="text-muted">{customer.email}</small>
          </div>
          {customer.business ? (
            <div>
              <small className="text-muted">{customer.business.name}</small>
            </div>
          ) : (
            <React.Fragment />
          )}
        </td>
        <td>
          {customer.business ? (
            customer.business.title
          ) : (
            <span className="text-muted">Brak</span>
          )}
        </td>
        <td></td>
        <td className="text-right">
          <Button size="sm" color="primary">
            <i className="fas fa-edit"></i>
          </Button>
        </td>
      </tr>
    );
  };

  useEffect(loadData, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h6 className="surtitle">Przypisani do mnie</h6>
            <h5 className="h3 mb-0">Kontrahenci</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table striped responsive>
        <thead>
          <tr>
            <th></th>
            <th>Kontrahent</th>
            <th>Przedsiębiorstwo</th>
            <th>Zamówienia</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data && data.length > 0 ? (
              data.map(renderData)
            ) : (
              <NoDataIndicator cols={4} />
            )
          ) : (
            <LoadingIndicator cols={4} />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

function MyOrders() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.auth.user);

  const onLoadData = (response) => {
    setData(response.response);
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver
      .get(
        ORDERS_API_URL +
        apiDriver.buildIndexAttributes({
          order: { direction: "desc", field: "created" },
          filters: {
            assigneeId: {
              filterVal: user.id,
              filterType: "Guid",
              comparator: "=",
              caseSensitive: false,
              filterKey: "assigneeId",
            },
          },
        }),
      )
      .subscribe(onLoadData);
  };

  const renderData = (order) => {
    return (
      <tr key={order.id}>
        <td className="pr-0"><CultureFlag code={order.culture || order.user?.culture || "pl"} height="12" /></td>
        <td className="px-0"><OrderSource source={order.source} /></td>
        <td>{order.number}</td>
        <td>{order.user ? order.user.username : "Brak"}</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(order.priceSum?.grossCost)}
          <small className="text-muted d-block">
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "PLN",
            }).format(order.priceSum?.netCost)}
          </small>
        </td>
        <td>{order.status ? localizer(order.status)?.currentTranslation?.title : "Koszyk"}</td>
        <td>
          {new Date(order.updated || order.ordered || order.created).toLocaleString()}
        </td>
        <td className="text-right">
          <Link to={`/admin/orders/orders/${order.id}`}>
            <Button size="sm" color="primary">
              <i className="fas fa-edit"></i>
            </Button>
          </Link>
        </td>
      </tr>
    );
  };

  useEffect(loadData, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h6 className="surtitle">Przypisane do mnie</h6>
            <h5 className="h3 mb-0">Zamówienia</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table striped responsive>
        <thead>
          <tr>
            <th colSpan={2}></th>
            <th>Numer</th>
            <th>Zamawiający</th>
            <th>Wartość</th>
            <th>Status</th>
            <th>Aktualizacja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data && data.length > 0 ? (
              data.map(renderData)
            ) : (
              <NoDataIndicator cols={6} />
            )
          ) : (
            <LoadingIndicator cols={6} />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

function LastUpdatedOrders() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoadData = (response) => {
    setData(
      response.response.sort(
        (a, b) => Date.parse(b.updated) - Date.parse(a.updated),
      ),
    );
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver
      .get(
        ORDERS_API_URL +
        apiDriver.buildIndexAttributes({
          order: { direction: "desc", field: "created" },
          filters: {
            "status.tag": {
              filterVal: "0",
              filterType: "int",
              comparator: ">",
              caseSensitive: false,
              filterKey: "status.tag",
            },
          },
          take: 5,
        }),
      )
      .subscribe(onLoadData);
  };

  const renderData = (order) => {

    return (
      <tr key={order.id}>
        <td className="pr-0"><CultureFlag code={order.culture || order.user?.culture || "pl"} height="12" /></td>
        <td className="px-0"><OrderSource source={order.source} /></td>
        <td>{order.number}</td>
        <td>{order.user ? order.user.username : "Brak"}</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(order.priceSum?.grossCost)}
          <small className="text-muted d-block">
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "PLN",
            }).format(order.priceSum?.netCost)}
          </small>
        </td>
        <td>{order.status ? localizer(order.status)?.currentTranslation?.title : "Koszyk"}</td>
        <td>
          {(new Date(order.updated || order.created)).toLocaleString()}
        </td>
        <td className="text-right">
          <Link to={`/admin/orders/orders/${order.id}`}>
            <Button size="sm" color="primary">
              <i className="fas fa-edit"></i>
            </Button>
          </Link>
        </td>
      </tr>
    );
  };

  useEffect(loadData, []);

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h6 className="surtitle">Ostatnio aktualizowane</h6>
            <h5 className="h3 mb-0">Zamówienia</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table striped responsive>
        <thead>
          <tr>
            <th colSpan={2}></th>
            <th>Numer</th>
            <th>Zamawiający</th>
            <th>Wartość</th>
            <th>Status</th>
            <th>Aktualizacja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data && data.length > 0 ? (
              data.map(renderData)
            ) : (
              <NoDataIndicator cols={6} />
            )
          ) : (
            <LoadingIndicator cols={6} />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

function UnpaidInvoices() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoadData = (response) => {
    setData(
      response.response.sort(
        (a, b) => Date.parse(b.updated) - Date.parse(a.updated),
      ),
    );
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver
      .get(
        INVOICES_API_URL +
        apiDriver.buildIndexAttributes({
          order: { direction: "desc", field: "created" },
          filters: {},
          take: 5,
        }),
      )
      .subscribe(onLoadData);
  };

  const renderData = (invoice) => {
    return (
      <tr key={invoice.id}>
        <td>{invoice.number}</td>
        <td>{invoice.buyerData?.name}</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(invoice.amount.grossCost)}
        </td>
        <td
          className={
            invoice.amountRemain.grossCost > 0 ? "text-danger" : "text-muted"
          }
        >
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(invoice.amountRemain.grossCost)}
        </td>
        <td>
          {(new Date(invoice.updated || invoice.created)).toLocaleString()}
        </td>
        <td className="text-right">
          <Button size="sm" color="primary">
            <i className="fas fa-edit"></i>
          </Button>
        </td>
      </tr>
    );
  };

  useEffect(loadData, []);

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h6 className="surtitle">Nieopłacone</h6>
            <h5 className="h3 mb-0">Dokumenty księgowe</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Numer</th>
            <th>Nabywca</th>
            <th>Wartość</th>
            <th>Do zapłaty</th>
            <th>Aktualizacja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data && data.length > 0 ? (
              data.map(renderData)
            ) : (
              <NoDataIndicator cols={6} />
            )
          ) : (
            <LoadingIndicator cols={6} />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

function LastInvoices() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const onLoadData = (response) => {
    setData(
      response.response.sort(
        (a, b) => Date.parse(b.updated) - Date.parse(a.updated),
      ),
    );
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver
      .get(
        INVOICES_API_URL +
        apiDriver.buildIndexAttributes({
          order: { direction: "desc", field: "created" },
          filters: {},
          take: 5,
        }),
      )
      .subscribe(onLoadData);
  };

  const renderData = (invoice) => {
    return (
      <tr key={invoice.id}>
        <td>{invoice.number}</td>
        <td>{invoice.buyerData?.name}</td>
        <td>
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(invoice.amount.grossCost)}
        </td>
        <td
          className={
            invoice.amountRemain.grossCost > 0 ? "text-danger" : "text-muted"
          }
        >
          {new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "PLN",
          }).format(invoice.amountRemain.grossCost)}
        </td>
        <td>
          {(new Date(invoice.updated || invoice.created)).toLocaleString()}
        </td>
        <td className="text-right">
          <Button size="sm" color="primary">
            <i className="fas fa-edit"></i>
          </Button>
        </td>
      </tr>
    );
  };

  useEffect(loadData, []);

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="8">
            <h6 className="surtitle">Najnowsze</h6>
            <h5 className="h3 mb-0">Dokumenty księgowe</h5>
          </Col>
        </Row>
      </CardHeader>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Numer</th>
            <th>Nabywca</th>
            <th>Wartość</th>
            <th>Do zapłaty</th>
            <th>Aktualizacja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data && data.length > 0 ? (
              data.map(renderData)
            ) : (
              <NoDataIndicator cols={6} />
            )
          ) : (
            <LoadingIndicator cols={6} />
          )}
        </tbody>
      </Table>
    </Card>
  );
}

function OrderTimeStatistics() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Sprzedaz",
        data: [],
        maxBarThickness: 10,
      },
    ],
  });

  const onLoadData = (response) => {
    const set = response.response;
    setData({
      labels: set.map((s) => {
        const split = s.key.split("-");
        return new Intl.DateTimeFormat(undefined, { month: "short" }).format(
          new Date(Date.UTC(split[0], Number(split[1]) - 1)),
        );
      }),
      datasets: [
        {
          label: "Sprzedaz",
          data: set.map((s) => s.count),
          maxBarThickness: 10,
        },
      ],
    });
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    apiDriver.get(ORDERS_API_URL + "Statistics").subscribe(onLoadData);
  };

  useEffect(loadData, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-muted ls-1 mb-1">Statystyki</h6>
            <h2 className="h3 mb-0">Zamówienia</h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart">
          {loading ? (
            <Spinner />
          ) : (
            <Bar
              data={data}
              options={chartExample2.options}
              className="chart-canvas"
              id="chart-bars"
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
}

function ComparePeriod(props) {
  const { title, values, icon, className, prefix, suffix } = props;

  const min = Math.min.apply(null, values);
  const max = Math.max.apply(null, values);
  const difference = Math.round(((max - min) / min) * 100);
  const progress = max === values[values.length - 1];

  return (
    <Card className={`bg-gradient-${className} border-0`}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0 text-white"
            >
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">
              {prefix}
              {values[values.length - 1]}
              {suffix}
            </span>
            <Progress
              className="progress-xs mt-3 mb-0"
              max={max}
              value={min}
              color={progress ? "success" : "danger"}
            />
          </Col>
          <Col md="auto">
            <i className={`${icon} fa-2x text-white`}></i>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm text-white">
          <i
            className={
              progress
                ? "fas fa-chevron-up text-success"
                : "fas fa-chevron-down text-danger"
            }
          ></i>
          &nbsp;
          <span className={progress ? "text-success" : "text-danger"}>
            {difference}%
          </span>{" "}
          {progress ? "więcej" : "mniej"} niz w poprzednim miesiącu
        </p>
      </CardBody>
    </Card>
  );
}

function Alternative() {
  React.useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, []);
  return (
    <>
      <AlternativeHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <ComparePeriod
              title="Ilość zamówień"
              values={[10, 12]}
              icon="fas fa-shopping-cart"
              className="primary"
            />
          </Col>
          <Col md="6" xl="3">
            <ComparePeriod
              title="Wartość zamówień"
              values={[2000, 2200]}
              icon="fas fa-euro-sign"
              className="primary"
            />
          </Col>
          <Col md="6" xl="3">
            <ComparePeriod
              title="Nowi klienci"
              values={[11, 13]}
              icon="fas fa-user"
              className="default"
            />
          </Col>
          <Col md="6" xl="3">
            <ComparePeriod
              title="Konwersja klientów"
              values={[90, 77]}
              suffix="%"
              icon="fas fa-box"
              className="default"
            />
          </Col>
        </Row>
        <div className="card-deck flex-column flex-xl-row">
          <MyOrders />
        </div>
        <div className="card-deck flex-column flex-xl-row">
          <MyCustomers />
        </div>
        <div className="card-deck flex-column flex-xl-row">
          <OrderTimeStatistics />
          <LastUpdatedOrders />
        </div>
        <div className="card-deck flex-column flex-xl-row">
          <UnpaidInvoices />
          <LastInvoices />
        </div>
      </Container>
    </>
  );
}

export default Alternative;

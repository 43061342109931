import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as paymentsActions from "stores/orders/payments/actions";
import * as paymentMethodsActions from "stores/orders/paymentMethods/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NumericInput from "components/Form/NumericInput";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      payment: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        payment: {
          id: undefined,
          title: "",
          line1: "",
          line2: "",
          city: "",
          countryCode: "",
          postalCode: "",
          state: "",
          phone: "",
          email: "",
        },
        loading: false,
      });
    }
    this.props.listMethods();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changePaymentData = (event) => {
    const value = this.parseValue(event.target);
    let payment = { ...this.state.payment, [value.name]: value.value };
    this.setState({ payment });
  };

  submitForm = (event) => {
    const { user, status, method, order, ...payment } = this.state.payment;
    if (payment.id) {
      this.setState({ action: "update" });
      this.props.update(payment);
    } else {
      this.setState({ action: "create" });
      this.props.create(payment);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.payment.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.payment || this.props.loading;

    const { payment } = this.state;
    const { auth } = this.props;

    if (!payment || payment.id === auth.id || !payment.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.payment || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { payment } = this.state;

    if (!payment || !payment.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={payment ? payment.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { payment, methods } = this.state;

    if (!payment) {
      return <></>;
    }

    const loading = this.props.loading;

    const statuses = methods.find((m) => m.id === payment.methodId)?.statuses;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Metoda płatności</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-truck" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Metoda płatności"
                      type="select"
                      name="methodId"
                      value={payment.methodId}
                      onChange={this.changePaymentData}
                    >
                      <option value="">Brak</option>
                      {methods
                        .filter((m) => !m.isPaymentAddressRequired)
                        .map((method) => (
                          <option key={method.id} value={method.id}>
                            {localizer(method)?.currentTranslation?.title}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Status</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-truck" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Status płatności"
                      type="select"
                      name="statusId"
                      value={payment.statusId}
                      onChange={this.changePaymentData}
                    >
                      <option value="">Brak</option>
                      {statuses?.map((status) => (
                        <option key={status.id} value={status.id}>
                          {localizer(status)?.currentTranslation?.title}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Kwota</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-wallet" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Kwota"
                      name="amount"
                      value={payment.amount}
                      step={0.01}
                      min={0.00}
                      onChange={this.changePaymentData}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>PLN</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Prowizja</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-credit-card" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Kwota"
                      name="provision"
                      value={payment.provision}
                      onChange={this.changePaymentData}
                      step={0.01}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>PLN</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.payment || this.props.loading;

    const { payment } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/payment/payments/" />;
    }

    return (
      <>
        <SimpleHeader
          name={payment ? payment.id : ""}
          link="/admin/payment/payments"
          parentName="Płatności"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.payment !== this.props.payment) {
      this.setState({
        payment: this.props.payment,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.methods !== this.props.methods) {
      this.setState({
        methods: this.props.methods,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    payment: state.payments.current,
    loading: state.payments.loading,
    auth: state.auth.user,
    session: state.session,
    methods: state.paymentMethods.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(paymentsActions.read(id)),
    remove: (id) => dispatch(paymentsActions.remove(id)),
    update: (payment) => dispatch(paymentsActions.update(payment)),
    create: (payment) => dispatch(paymentsActions.create(payment)),
    listMethods: () => dispatch(paymentMethodsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Payment),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as rolesActions from "stores/iam/roles/actions";

import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";

import Search from "components/Table/Search";

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      auth: null,
      roles: [],
      meta: {},
      filter: [],
      redirect: null,
    };
    this.myRef = React.createRef();
    if (!this.state.roles.list) {
      this.props.list();
    }
  }

  pagination = {
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => <div className="dataTables_length" id="datatable-basic_length"></div>,
  };

  onTableChange = (type, newState) => {
    this.props.list(
      newState.sizePerPage * (newState.page - 1),
      newState.sizePerPage,
    );
    this.setState({ meta: { ...this.props.meta, page: newState.page } });
  };

  setAlert = (content) => {
    this.setState({ alert: content });
  };

  onClickCreate = (event) => {
    this.setState({ redirect: "/admin/cp/roles/create" });
  };

  onClickEdit = (row) => {
    this.setState({ redirect: "/admin/cp/roles/" + row.id });
  };

  onClickRemove = (row) => {
    this.props.remove(row.id);
  };

  linkToActions = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          className="d-print-none"
          onClick={() => {
            this.onClickEdit(row);
          }}
        >
          <i className="fas fa-address-card"></i>
        </Button>
      </>
    );
  };

  linkToDelete = (row) => {
    return (
      <Button
        size="sm"
        color="danger"
        className="d-print-none"
        onClick={() => {
          this.onClickRemove(row);
        }}
      >
        <i className="fas fa-times-circle"></i>
      </Button>
    );
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const { alert } = this.state;
    const { data, loading, meta } = this.props;
    const metaState = this.state.meta;
    let pagination = { ...this.pagination };

    pagination.page = metaState && metaState.page ? metaState.page : meta.page;
    pagination.sizePerPage = 10;
    pagination.totalSize = meta.filtered;

    let columns = [
      {
        dataField: "name",
        text: "Nazwa",
        sort: true,
      },
      {
        dataField: "id",
        text: "",
        formatter: this.linkToActions,
        sort: false,
      },
    ];
    return (
      <>
        {alert}
        <SimpleHeader
          name="Lista ról"
          link="/admin/employees"
          parentName="Role"
        >
          <div className="text-right">
            <ButtonGroup>
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="default"
                    className="buttons-copy buttons-html5"
                  >
                    <i className="fas fa-print mr-3"></i>
                    Drukuj
                  </Button>
                )}
                content={() => this.myRef.current}
              />
              <Button
                className="buttons-copy buttons-html5"
                color="success"
                onClick={this.onClickCreate}
              >
                <i className="fas fa-plus-circle mr-3"></i>
                <span>Dodaj rolę</span>
              </Button>
            </ButtonGroup>
          </div>
        </SimpleHeader>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                {loading ? this.loadingOverlay() : ""}
                <CardHeader>
                  <h3 className="mb-0">Role</h3>
                </CardHeader>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={columns}
                  search
                  bootstrap4={true}
                >
                  {(props) => {
                    return (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  
                                  <Search
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <PaginationProvider
                          pagination={paginationFactory(pagination)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <BootstrapTable
                              remote
                              ref={this.myRef}
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              id="react-bs-table"
                              wrapperClasses="table-responsive"
                              onTableChange={this.onTableChange}
                              {...paginationTableProps}
                            />
                          )}
                        </PaginationProvider>
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    data: state.roles.list,
    loading: state.roles.loading,
    auth: state.auth.user,
    meta: state.roles.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    list: (skip = 0, take = 10) => dispatch(rolesActions.list(skip, take)),
    read: (id) => dispatch(rolesActions.read(id)),
    remove: (id) => dispatch(rolesActions.remove(id)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles));

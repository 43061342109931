import React from "react";
import {
  Button,
  CardTitle,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as productsActions from "stores/products/products/actions";
import AsyncSelector from "../orders/cards/AsyncSelector";

import { EColor, ERealizationTimeType } from "stores/products/products/model";

class AddProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      productsLoaded: false,
      product: null,
    };
  }

  toggle = () => this.props.toggle();
  parseValue = (target) => this.props.parseValue(target);

  componentDidMount = () => {
    this.props.listProducts();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products !== this.props.products) {
      this.setState({
        products: this.props.products,
        productsLoaded: false,
      });
    }
  }

  filterProducts = (inputValue) => {
    const { products } = this.state;
    return new Promise((resolve, reject) => {
      let result = products;
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map(this.renderProduct);
      resolve(result);
    });
  };
  renderProduct = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  changeProduct = (e) => {
    let { products } = this.state;
    const value = e.target.value;
    const filtered = [...products.filter((p) => p.id === value)];
    if (filtered.length === 1) {
      this.setState({ product: filtered[0] });
    } else {
      this.setState({ product: null });
    }
  };

  submit = () => {
    let { product } = this.state;
    const { onCreate } = this.props;
    this.toggle();
    onCreate({
      productId: product.id,
      title: undefined,
      taxRate: 23,
      rebate: 0,
      personalization: {
        amount: 1,
        format: { x: 1, y: 1 },
        pages: { inside: 1, outside: 0 },
        color: EColor.OneOverZero,
        options: [],
        additives: [],
        colorProfiles: [],
        realizationTimeType: ERealizationTimeType.Standard,
        project: false,
        checking: false,
      },
      prices: [],
    });
  };

  renderCancelButton = () => (
    <Button color="light" onClick={() => this.toggle()}>
      Anuluj
    </Button>
  );

  renderSubmitButton = () => {
    return (
      <Button color="primary" onClick={() => this.submit()}>
        Dodaj
      </Button>
    );
  };

  renderContent = () => {
    const { products, product } = this.state;

    return (
      <Form>
        <Row>
          <Col md="6">
            <h4 className="m-0 p-0">Wybierz produkt</h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <InputGroup>
                <AsyncSelector
                  title="Metoda odbioru"
                  name="methodId"
                  placeholder="Szukaj..."
                  options={products.map(this.renderProduct)}
                  value={product ? product.id : null}
                  onChange={this.changeProduct}
                  onSearch={this.filterProducts}
                  allowNull={true}
                  nullTitle="Niestandardowy"
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  render = () => {
    const { isOpen, title } = this.props;

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
          toggle={this.toggle}
          size="xl"
        >
          <ModalHeader>
            <CardTitle>{title}</CardTitle>
          </ModalHeader>
          <ModalBody>{this.renderContent()}</ModalBody>
          <ModalFooter>
            {this.renderCancelButton()}
            {this.renderSubmitButton()}
          </ModalFooter>
        </Modal>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    products: state.products.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listProducts: () => dispatch(productsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddProductModal),
);

import * as constants from "./constants";
import IBooking from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (booking: IBooking) => ({
  type: constants.READ_SUCCESS,
  payload: booking,
});

export const create = (booking: IBooking) => ({
  type: constants.CREATE,
  payload: booking,
});
export const createSuccess = (booking: IBooking) => ({
  type: constants.CREATE_SUCCESS,
  payload: booking,
});

export const update = (booking: IBooking) => ({
  type: constants.UPDATE,
  payload: booking,
});
export const updateSuccess = (booking: IBooking) => ({
  type: constants.UPDATE_SUCCESS,
  payload: booking,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (
  skip: Number = 0,
  take: Number = 10,
  search?: String,
  start?: Date,
  end?: Date,
) => {
  return {
    type: constants.LIST,
    payload: { skip, take, search, start, end },
  };
};
export const listSuccess = (list: IBooking[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (booking: IBooking) => ({
  type: constants.ACTIVE,
  payload: booking,
});

import * as constants from "./constants";
import IPage from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (page: IPage) => ({
  type: constants.READ_SUCCESS,
  payload: page,
});

export const create = (page: IPage) => ({
  type: constants.CREATE,
  payload: page,
});
export const createSuccess = (page: IPage) => ({
  type: constants.CREATE_SUCCESS,
  payload: page,
});

export const update = (page: IPage) => ({
  type: constants.UPDATE,
  payload: page,
});
export const updateSuccess = (page: IPage) => ({
  type: constants.UPDATE_SUCCESS,
  payload: page,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (skip: Number = 0, take: Number = 10) => ({
  type: constants.LIST,
  payload: { skip, take },
});
export const listSuccess = (list: IPage[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (page: IPage) => ({
  type: constants.ACTIVE,
  payload: page,
});

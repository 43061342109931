import * as constants from "./constants";
import IProductAdditiveGroup from "./model";

export const read = (id: string) => ({
  type: constants.READ,
  payload: id,
});
export const readSuccess = (group: IProductAdditiveGroup) => ({
  type: constants.READ_SUCCESS,
  payload: group,
});

export const create = (group: IProductAdditiveGroup) => ({
  type: constants.CREATE,
  payload: group,
});
export const createSuccess = (group: IProductAdditiveGroup) => ({
  type: constants.CREATE_SUCCESS,
  payload: group,
});

export const update = (group: IProductAdditiveGroup) => ({
  type: constants.UPDATE,
  payload: group,
});
export const updateSuccess = (group: IProductAdditiveGroup) => ({
  type: constants.UPDATE_SUCCESS,
  payload: group,
});

export const remove = (id: string) => ({
  type: constants.REMOVE,
  payload: id,
});
export const removeSuccess = (id: string) => ({
  type: constants.REMOVE_SUCCESS,
  payload: id,
});

export const list = (
  skip: Number = 0,
  take: Number = 10,
  searchText: string = "",
  filters: any[] = [],
  order: any = undefined,
) => {
  return {
    type: constants.LIST,
    payload: { skip, take, filters, order, searchText },
  };
};
export const listSuccess = (list: IProductAdditiveGroup[], listMeta: any) => ({
  type: constants.LIST_SUCCESS,
  payload: list,
  meta: listMeta,
});

export const active = (group: IProductAdditiveGroup) => ({
  type: constants.ACTIVE,
  payload: group,
});

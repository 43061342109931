import React from "react";
import { Nav } from "reactstrap";
import Tab from "./Tab";

export default class Tabs extends React.Component {
  render = () => {
    const { tabs, currentTab, setCurrentTab } = this.props;

    return (
      <Nav
        className="nav-fill flex-column flex-sm-row"
        id="tabs-text"
        pills
        role="tablist"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            {...tab}
          />
        ))}
      </Nav>
    );
  };
}

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";

import * as roomTypesActions from "stores/bookings/roomTypes/actions";
import * as personTypesActions from "stores/bookings/personTypes/actions";
import * as roomAdditionsActions from "stores/bookings/roomAdditions/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import NumericInput from "components/Form/NumericInput";

Dropzone.autoDiscover = false;

class RoomType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      type: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        type: {
          id: undefined,
          hidden: false,
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listPersonTypes();
    this.props.listRoomAdditions();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeTypeData = (event) => {
    const { name, value, type } = event.target;
    var entity = { ...this.state.type };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      default:
        break;
    }
    entity[name] = parsedValue;
    this.setState({ type: entity });
  };

  changeTypeTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var type = { ...this.state.type };
    if (!type.translations || type.translations.length === 0) {
      type.translations.push({ culture: currentCulture });
    }
    type.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ type });
  };

  changeTypeTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeTypeTranslationEx(name, value);
  };

  changeTypeLimit = (event) => {
    const { value, name } = event.target;
    let type = { ...this.state.type };
    let limits = [...this.state.type.limits];
    if (value > 0) {
      if (limits.some((l) => l.personTypeId === name)) {
        limits = limits.map((l) => {
          if (l.personTypeId === name) {
            l.limit = Number(value);
          }
          return l;
        });
      } else {
        limits.push({
          roomTypeId: type.id,
          personTypeId: name,
          limit: Number(value),
        });
      }
    } else {
      limits = limits.filter((l) => l.personTypeId !== name);
    }
    type.limits = limits;
    this.setState({ type });
  };

  changeTypeAddition = (event) => {
    const { value, name } = event.target;
    let type = { ...this.state.type };
    let roomTypeAdditions = [...this.state.type.roomTypeAdditions];
    if (value > 0) {
      if (roomTypeAdditions.some((a) => a.additionId === name)) {
        roomTypeAdditions = roomTypeAdditions.map((a) => {
          if (a.additionId === name) {
            a.price = Number(value);
          }
          return a;
        });
      } else {
        roomTypeAdditions.push({
          roomTypeId: type.id,
          additionId: name,
          price: Number(value),
        });
      }
    } else {
      roomTypeAdditions = roomTypeAdditions.filter(
        (r) => r.additionId !== name,
      );
    }
    type.roomTypeAdditions = roomTypeAdditions;
    this.setState({ type });
  };

  changeTypePricingData = (index, prop, value, type) => {
    const name = [index, prop];
    let entity = { ...this.state.type };
    let pricings = [...this.state.type.pricings];

    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      default:
        break;
    }

    pricings = pricings.map((a, index) => {
      if (index === Number(name[0])) {
        a[name[1]] = parsedValue;
      }
      return a;
    });

    entity.pricings = pricings;
    this.setState({ type: entity });
  };

  changeTypePricing = (event) => {
    const { value, type } = event.target;
    const name = event.target.name.split(".");

    this.changeTypePricingData(name[0], name[1], value, type);
  };

  changeTypePricingStart = (index, date) => {
    this.changeTypePricingData(index, "start", date, "datetime");
  };
  changeTypePricingEnd = (index, date) => {
    this.changeTypePricingData(index, "end", date, "datetime");
  };

  createTypePricing = (event) => {
    let entity = { ...this.state.type };
    let pricings = [...this.state.type.pricings];
    pricings.push({
      roomTypeId: entity.id,
      minimalDays: -1,
      maximalDays: -1,
      start: null,
      end: null,
      price: entity.price,
    });
    entity.pricings = pricings;
    this.setState({ type: entity });
  };

  deleteTypePricing = (id) => {
    let entity = { ...this.state.type };
    let pricings = [...this.state.type.pricings];
    pricings = pricings.filter((pricing, index) => index !== Number(id));
    entity.pricings = pricings;
    this.setState({ type: entity });
  };

  submitForm = (event) => {
    var type = { ...this.state.type };
    if (type.id) {
      this.setState({ action: "update" });
      this.props.update(type);
    } else {
      this.setState({ action: "create" });
      this.props.create(type);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.roomType.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.type || this.props.loading;

    const { type } = this.state;
    const { auth } = this.props;

    if (!type || type.id === auth.id || !type.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.type || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { type } = this.state;

    if (!type || !type.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={type ? type.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardLimits = () => {
    const { type, personTypes } = this.state;
    const loading = this.props.loading;

    if (!type || !personTypes) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Bazowe limity gości</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {personTypes
                .sort((a, b) => b.priority - a.priority)
                .map((personType) => {
                  const limit = [...type.limits].filter(
                    (l) => l.personTypeId === personType.id,
                  )[0];
                  return (
                    <React.Fragment key={personType.id}>
                      <Col md="2">#{personType.priority}</Col>
                      <Col md="6">{personType.currentTranslation?.title}</Col>
                      <Col md="4">
                        <FormGroup>
                          <InputGroup>
                            <NumericInput
                              placeholder="0"
                              min="0"
                              step="0.01"
                              name={personType.id}
                              value={limit ? limit.limit : 0}
                              onChange={this.changeTypeLimit}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>osób</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </React.Fragment>
                  );
                })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardPricings = () => {
    const { type } = this.state;
    const loading = this.props.loading;

    if (!type) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Okresowe strategie cenowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="6">
                Termin okresu strategii
                <br />
                <small className="text-muted">(od - do)</small>
              </Col>
              <Col md="3">
                Dni noclegowe
                <br />
                <small className="text-muted">(min - max)</small>
              </Col>
              <Col md="2">
                Cena
                <br />
                <small className="text-muted">PLN</small>
              </Col>
              <Col md="1">
                <Button
                  onClick={this.createTypePricing}
                  type="button"
                  color="success"
                  className="mt-2"
                  size="sm"
                >
                  <i className="fas fa-plus" />
                </Button>
              </Col>
            </Row>
            <Row>
              {type.pricings.map((pricing, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Początek",
                              className: "form-control",
                            }}
                            value={new Date(pricing.start)}
                            timeFormat={false}
                            dateFormat="yyyy-MM-DD"
                            onChange={this.changeTypePricingStart.bind(
                              this,
                              index,
                            )}
                            name={index + ".start"}
                            className="form-control p-0 m-0 border-0"
                          />
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Koniec",
                              className: "form-control",
                            }}
                            value={new Date(pricing.end)}
                            timeFormat={false}
                            dateFormat="yyyy-MM-DD"
                            onChange={this.changeTypePricingEnd.bind(
                              this,
                              index,
                            )}
                            name={index + ".end"}
                            className="form-control p-0 m-0 border-0"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <InputGroup>
                          <NumericInput
                            placeholder="min"
                            min="-1"
                            step="1"
                            name={index + ".minimalDays"}
                            value={pricing.minimalDays}
                            onChange={this.changeTypePricing}
                          />
                          <NumericInput
                            placeholder="max"
                            min="-1"
                            step="1"
                            name={index + ".maximalDays"}
                            value={pricing.maximalDays}
                            onChange={this.changeTypePricing}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <InputGroup>
                          <NumericInput
                            placeholder="0"
                            min="0"
                            step="0.01"
                            name={index + ".price"}
                            value={pricing.price}
                            onChange={this.changeTypePricing}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="1">
                      <Button
                        onClick={this.deleteTypePricing.bind(this, index)}
                        type="button"
                        color="danger"
                        className="mt-2"
                        size="sm"
                      >
                        <i className="fas fa-times" />
                      </Button>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardAdditions = () => {
    const { type, roomAdditions } = this.state;
    const loading = this.props.loading;

    if (!type || !roomAdditions) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dostawki kwaterunku</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {roomAdditions.map((addition) => {
                const roomTypeAddition = [...type.roomTypeAdditions].filter(
                  (l) => l.additionId === addition.id,
                )[0];
                return (
                  <React.Fragment key={addition.id}>
                    <Col md="6">{addition.currentTranslation?.title}</Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup>
                          <NumericInput
                            placeholder="0"
                            min="0"
                            step="0.01"
                            name={addition.id}
                            value={
                              roomTypeAddition ? roomTypeAddition.price : 0
                            }
                            onChange={this.changeTypeAddition}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>PLN</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { type } = this.state;
    const { currentCulture } = this.state;

    if (!type) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = type.translations
      ? type.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeTypeTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <b>Domyślny cennik</b>
              </Col>
              <Col md="3">Nocleg</Col>
              <Col md="9">
                <FormGroup>
                  <NumericInput
                    placeholder="Domyślna cena za nocleg"
                    name="price"
                    value={type ? type.price : ""}
                    onChange={this.changeTypeData}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Miejsce parkingowe</Col>
              <Col md="9">
                <FormGroup>
                  <NumericInput
                    placeholder="Domyślna cena za miejsce parkingowe"
                    name="pricePerParklot"
                    value={type ? type.pricePerParklot : ""}
                    onChange={this.changeTypeData}
                  />
                </FormGroup>
              </Col>
              <Col md="3">Pobyt zwierzięcia</Col>
              <Col md="9">
                <FormGroup>
                  <NumericInput
                    placeholder="Domyślna cena za pobyt zwierzęcia"
                    name="pricePerPet"
                    value={type ? type.pricePerPet : ""}
                    onChange={this.changeTypeData}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.type || this.props.loading;

    const { type } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/booking/rooms/types/" />;
    }

    return (
      <>
        <SimpleHeader
          name={type ? type.currentTranslation?.title : ""}
          link="/admin/booking/rooms/types"
          parentName="Rodzaje zakwaterowania"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardLimits()}
                {this.renderCardAdditions()}
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardPricings()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.personTypes !== this.props.personTypes) {
      this.setState({
        personTypes: this.props.personTypes,
      });
    }
    if (prevProps.roomAdditions !== this.props.roomAdditions) {
      this.setState({
        roomAdditions: this.props.roomAdditions,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    type: state.roomTypes.current,
    loading: state.roomTypes.loading,
    auth: state.auth.user,
    session: state.session,
    personTypes: state.personTypes.list,
    roomAdditions: state.roomAdditions.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(roomTypesActions.read(id)),
    remove: (id) => dispatch(roomTypesActions.remove(id)),
    update: (type) => dispatch(roomTypesActions.update(type)),
    create: (type) => dispatch(roomTypesActions.create(type)),
    listPersonTypes: () => dispatch(personTypesActions.list()),
    listRoomAdditions: () => dispatch(roomAdditionsActions.list()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoomType),
);

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as roomAdditionsActions from "stores/bookings/roomAdditions/actions";
import * as personTypesActions from "stores/bookings/personTypes/actions";
import * as roomTypesActions from "stores/bookings/roomTypes/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";

Dropzone.autoDiscover = false;

class RoomAddition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addition: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        addition: {
          id: undefined,
          hidden: false,
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listPersonTypes();
    this.props.listRoomTypes();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeTypeData = (event) => {
    const { name, value, addition } = event.target;
    var entity = { ...this.state.addition };
    let parsedValue = value;
    switch (addition) {
      case "number":
        parsedValue = Number(value);
        break;
      default:
        break;
    }
    entity[name] = parsedValue;
    this.setState({ addition: entity });
  };

  changeTypeTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var addition = { ...this.state.addition };
    if (!addition.translations || addition.translations.length === 0) {
      addition.translations.push({ culture: currentCulture });
    }
    addition.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ addition });
  };

  changeTypeTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeTypeTranslationEx(name, value);
  };

  changeAdditionEffect = (event) => {
    const { value, name } = event.target;
    let addition = { ...this.state.addition };
    let effects = [...this.state.addition.effects];
    if (value > 0) {
      if (effects.some((l) => l.personTypeId === name)) {
        effects = effects.map((l) => {
          if (l.personTypeId === name) {
            l.addLimit = Number(value);
          }
          return l;
        });
      } else {
        effects.push({
          roomAdditionId: addition.id,
          personTypeId: name,
          addLimit: Number(value),
        });
      }
    } else {
      effects = effects.filter((l) => l.personTypeId !== name);
    }
    addition.effects = effects;
    this.setState({ addition });
  };

  changeAdditionRoomType = (event) => {
    const { value, name } = event.target;
    let addition = { ...this.state.addition };
    let roomTypeAdditions = [...this.state.addition.roomTypeAdditions];
    if (value > 0) {
      if (roomTypeAdditions.some((a) => a.roomTypeId === name)) {
        roomTypeAdditions = roomTypeAdditions.map((a) => {
          if (a.roomTypeId === name) {
            a.price = Number(value);
          }
          return a;
        });
      } else {
        roomTypeAdditions.push({
          roomAdditionId: addition.id,
          roomTypeId: name,
          price: Number(value),
        });
      }
    } else {
      roomTypeAdditions = roomTypeAdditions.filter(
        (r) => r.roomTypeId !== name,
      );
    }
    addition.roomTypeAdditions = roomTypeAdditions;
    this.setState({ addition });
  };

  submitForm = (event) => {
    var addition = { ...this.state.addition };
    if (addition.id) {
      this.setState({ action: "update" });
      this.props.update(addition);
    } else {
      this.setState({ action: "create" });
      this.props.create(addition);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.roomAdditions.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.addition || this.props.loading;

    const { addition } = this.state;
    const { auth } = this.props;

    if (!addition || addition.id === auth.id || !addition.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.addition || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { addition } = this.state;

    if (!addition || !addition.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                addition="text"
                name="id"
                value={addition ? addition.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardEffects = () => {
    const { addition, personTypes } = this.state;
    const loading = this.props.loading;

    if (!addition || !addition.id) {
      return <></>;
    }

    if (!addition || !personTypes) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dodatkowe limity gości</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {personTypes
                .sort((a, b) => b.priority - a.priority)
                .map((personType) => {
                  const effect = [...addition.effects].filter(
                    (l) => l.personTypeId === personType.id,
                  )[0];
                  return (
                    <React.Fragment key={personType.id}>
                      <Col md="2">#{personType.priority}</Col>
                      <Col md="6">{personType.currentTranslation?.title}</Col>
                      <Col md="4">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              placeholder="0"
                              addition="number"
                              min="0"
                              step="0.01"
                              name={personType.id}
                              value={effect ? effect.addLimit : 0}
                              onChange={this.changeAdditionEffect}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>osób</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </React.Fragment>
                  );
                })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardRoomTypes = () => {
    const { addition, roomTypes } = this.state;
    const loading = this.props.loading;

    if (!addition || !addition.id) {
      return <></>;
    }

    if (!addition || !roomTypes) {
      return <></>;
    }

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Cennik dostawki</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {roomTypes.map((roomType) => {
                const roomTypeAddition = [...addition.roomTypeAdditions].filter(
                  (l) => l.roomTypeId === roomType.id,
                )[0];
                return (
                  <React.Fragment key={roomType.id}>
                    <Col md="6">{roomType.currentTranslation?.title}</Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup>
                          <Input
                            placeholder="0"
                            addition="number"
                            min="0"
                            step="0.01"
                            name={roomType.id}
                            value={
                              roomTypeAddition ? roomTypeAddition.price : 0
                            }
                            onChange={this.changeAdditionRoomType}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>PLN</InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { addition } = this.state;
    const { currentCulture } = this.state;

    if (!addition) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = addition.translations
      ? addition.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Input
            className="w-25 float-right"
            addition="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      addition="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeTypeTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.addition || this.props.loading;

    const { addition } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/booking/rooms/additions/" />;
    }

    return (
      <>
        <SimpleHeader
          name={addition ? addition.currentTranslation?.title : ""}
          link="/admin/booking/rooms/additions"
          parentName="Dodatki zakwaterowania"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              {this.renderCardEffects()}
              {this.renderCardRoomTypes()}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.addition !== this.props.addition) {
      this.setState({
        addition: this.props.addition,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.personTypes !== this.props.personTypes) {
      this.setState({
        personTypes: this.props.personTypes,
      });
    }
    if (prevProps.roomTypes !== this.props.roomTypes) {
      this.setState({
        roomTypes: this.props.roomTypes,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    addition: state.roomAdditions.current,
    loading: state.roomAdditions.loading,
    auth: state.auth.user,
    session: state.session,
    personTypes: state.personTypes.list,
    roomTypes: state.roomTypes.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(roomAdditionsActions.read(id)),
    remove: (id) => dispatch(roomAdditionsActions.remove(id)),
    update: (addition) => dispatch(roomAdditionsActions.update(addition)),
    create: (addition) => dispatch(roomAdditionsActions.create(addition)),
    listPersonTypes: () => dispatch(personTypesActions.list()),
    listRoomTypes: () => dispatch(roomTypesActions.list()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoomAddition),
);

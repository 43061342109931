import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import CultureSelector from "./CultureSelector";
import Uploader from "views/pages/orders/orders/cards/Uploader";
import { API_URL } from "stores/products/products/epics";
import NumericInput from "components/Form/NumericInput";

export default class ProductData extends React.Component {
  changeCulture = this.props.changeCulture;
  changeProductData = this.props.changeProductData;
  changeProductTranslation = this.props.changeProductTranslation;

  renderId = () => {
    const { product } = this.props;

    if (!product || !product.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={product ? product.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  render() {
    const { product, currentCulture, categories } = this.props;

    if (!product) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation =
      product.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.props.translate}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <CultureSelector
            currentCulture={currentCulture}
            changeCulture={this.changeCulture}
          />
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Kategoria</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="categoryId"
                  value={product.categoryId}
                  onChange={this.changeProductData}
                >
                  <option value="">Wybierz</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation?.title || ""}
                      onChange={this.changeProductTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Przyjazny adres</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Adres"
                      type="text"
                      name="url"
                      value={editedTranslation?.url || ""}
                      onChange={this.changeProductTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Stawka VAT</Col>
              <Col md="9">
                <div className="custom-file">
                  <Input
                    className="form-control mb-3"
                    type="select"
                    name="taxRate"
                    value={product?.taxRate}
                    onChange={this.changeProductData}
                  >
                    <option value="0.0">0%</option>
                    <option value="0.05">5%</option>
                    <option value="0.08">8%</option>
                    <option value="0.23">23%</option>
                  </Input>
                </div>
              </Col>
              <Col md="3">Grafika</Col>
              <Col md="9">
                <Uploader
                  url={API_URL + product.id + "/Photo"}
                  isPresigned={true}
                  onUpload
                  onUploadComplete={(response) =>
                    console.log(response.response)
                  }
                />
              </Col>
              <Col md="9">Czy produkt jest ukryty?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isHidden"
                    onChange={this.changeProductData}
                    checked={product.isHidden}
                    defaultChecked={product.isHidden}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="9">Czy produkt jest dostępny?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAvailable"
                    onChange={this.changeProductData}
                    checked={product.isAvailable}
                    defaultChecked={product.isAvailable}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="9">Czy produkt moze zamówić gość?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isGuestAccessible"
                    onChange={this.changeProductData}
                    checked={product.isGuestAccessible}
                    defaultChecked={product.isGuestAccessible}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="3">Priorytet wyświetlania</Col>
              <Col md="9">
                <NumericInput
                  placeholder="Priorytet"
                  name="order"
                  value={product.order}
                  onChange={this.changeProductData}
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

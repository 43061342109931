import localizer from "stores/localizer";
import * as constants from "./constants";
import IProductAdditiveGroup from "./model";
import IProductAdditiveGroupState from "./state";

const groups = (
  state: IProductAdditiveGroupState = {
    list: [],
    listMeta: { total: 0, filtered: 0, page: 1 },
    active: [],
    current: null,
    id: null,
    loading: false,
  },
  action: any,
) => {
  switch (action.type) {
    case constants.ACTIVE:
      state.active = [...state.active, action.payload];
      return { ...state };
    case constants.CREATE:
      return { ...state, loading: true };
    case constants.CREATE_SUCCESS:
      state.list = [...state.list, action.payload];
      return { ...state, loading: false };
    case constants.READ_SUCCESS:
      let toUpdate = state.list.findIndex(
        (group: IProductAdditiveGroup) => group.id === action.payload.id,
      );
      if (toUpdate > -1) {
        state.list.splice(toUpdate, 1);
      }
      state.list = [...state.list, action.payload];
      state.current = action.payload;
      localizer(state.current);
      return { ...state };
    case constants.UPDATE:
      return { ...state, loading: true, id: action.payload };
    case constants.UPDATE_SUCCESS:
      state.list = state.list.map((group: IProductAdditiveGroup) => {
        if (group.id === action.payload.id) {
          localizer(group);
          return action.payload;
        }
        return group;
      });
      if (state.current && state.current.id === action.payload.id) {
        state.current = action.payload;
        localizer(state.current);
      }
      state.loading = false;
      return { ...state };
    case constants.REMOVE:
      return { ...state, loading: true, id: action.payload };
    case constants.REMOVE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          list: state.list.filter(
            (group: IProductAdditiveGroup) => group.id !== action.payload,
          ),
          loading: false,
          id: null,
        };
      } else {
        return {
          ...state,
          list: state.list.filter(
            (group: IProductAdditiveGroup) => group.id !== state.id,
          ),
          loading: false,
          id: null,
        };
      }
    case constants.LIST_SUCCESS:
      state.list = action.payload;
      state.list.map((group: IProductAdditiveGroup) => {
        localizer(group);
        return group;
      });
      state.listMeta = { ...state.listMeta, ...action.meta };
      return { ...state };
    default:
      return state;
  }
};

export default groups;

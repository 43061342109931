import React from "react";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";

import { API_URL as PRODUCTS_API_URL } from "stores/products/products/epics";
import apiDriver from "stores/api.driver";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import PrintColors from "views/pages/products/PrintColors";
import RealizationTimeTypes from "views/pages/products/RealizationTimeTypes";

import onChangeParser from "views/pages/products/OnChangeParser";
import dotNotationParser from "views/pages/products/DotNotationParser";
import OrderProductAdditives from "./OrderProductAdditives";
import OrderProductAttributes from "./OrderProductAttributes";
import NumericInput from "components/Form/NumericInput";
import localizer from "stores/localizer";

Dropzone.autoDiscover = false;

export default class CustomPricingProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      product: undefined,
    };
    this.myRef = React.createRef();
  }

  onChangeProductData = this.props.onChangeProductData;

  componentDidMount = () => {
    this.loadProduct();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props?.pricingProduct?.productId !==
      prevProps?.pricingProduct?.productId
    ) {
      this.loadProduct();
    }
  };

  parseValue = onChangeParser;
  changeDotNotationValue = dotNotationParser;
  onUpdate = this.props.onUpdate;

  changePricingProductFormat = (event) => {
    const { pricingProduct } = this.props;
    const { value } = event.target;
    if (value === "") {
      return;
    }
    let tmp = value.split("x");
    const newFormat = { x: Number(tmp[0]), y: Number(tmp[1]) };

    const updatedPricingProduct = this.changeDotNotationValue(
      pricingProduct,
      "personalization.format",
      newFormat,
    );
    this.onUpdate(updatedPricingProduct);
  };

  changePricingProductData = (event) => {
    const { pricingProduct } = this.props;
    let value = this.parseValue(event.target);
    if (value.name === "personalization.color") {
      value.value = Number(value.value);
    }
    const updatedPricingProduct = this.changeDotNotationValue(
      pricingProduct,
      value.name,
      value.value,
    );
    this.onUpdate(updatedPricingProduct);
  };

  loadProduct = () => {
    const { pricingProduct } = this.props;
    apiDriver
      .get(`${PRODUCTS_API_URL}${pricingProduct.productId}?Full=True`)
      .pipe(
        map((response) => response.response),
        catchError((error) => of(error)),
      )
      .subscribe((response) => {
        this.setState({ product: response });
      });
  };

  updatePricing = (index, event) => {
    let pricingProduct = { ...this.props.pricingProduct };

    pricingProduct.prices = pricingProduct.prices.map((pricing, i) =>
      i === index
        ? { ...pricing, [event.target.name]: Number(event.target.value) }
        : pricing,
    );

    this.onUpdate(pricingProduct);
  };

  addPricing = () => {
    let pricingProduct = { ...this.props.pricingProduct };

    if (!pricingProduct.prices) {
      pricingProduct.prices = [];
    }
    pricingProduct.prices.push({
      amount: undefined,
      price: undefined,
      realizationTime: undefined,
    });

    this.onUpdate(pricingProduct);
  };

  removePricing = (index) => {
    let pricingProduct = { ...this.props.pricingProduct };
    this.onUpdate({
      ...pricingProduct,
      prices: pricingProduct.prices.filter((p, i) => i !== index),
    });
  };

  renderPricingRow = (pricing, index) => {
    const onChange = (event) => this.updatePricing(index, event);
    const onRemove = (event) => this.removePricing(index);
    return (
      <tr>
        <td>
          <FormGroup>
            <InputGroup>
              <NumericInput
                name="amount"
                value={pricing.amount}
                step={1}
                placeholder="0"
                min={1}
                onChange={onChange}
              />
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <InputGroup>
              <NumericInput
                name="price"
                value={pricing.price}
                placeholder="0,00"
                min={0.01}
                onChange={onChange}
                step={0.01}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>zł</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          {pricing.price
            ? new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "PLN",
            }).format(pricing.price * 1.23)
            : ""}
          <br />
          <small className="text-muted">
            {pricing.price
              ? new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "PLN",
              }).format((pricing.price * 1.23) / pricing.amount)
              : ""}{" "}
            / szt.
          </small>
        </td>
        <td>
          <FormGroup>
            <InputGroup>
              <NumericInput
                name="realizationTime"
                value={pricing.realizationTime}
                placeholder="0"
                min={0}
                onChange={onChange}
                step={1}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>h</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          <i
            className="fas fa-times text-danger"
            style={{ cursor: "pointer" }}
            onClick={onRemove}
          ></i>
        </td>
      </tr>
    );
  };

  render = () => {
    const { index, pricingProduct } = this.props;
    const { loading, product } = this.state;
    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">{localizer(product)?.currentTranslation?.title}</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <h3>Właściwości</h3>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_format_x`}>Format</Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <InputGroup>
                      <NumericInput
                        id={`product_${index}_format_x`}
                        name="personalization.format.x"
                        value={pricingProduct?.personalization?.format?.x}
                        onChange={this.changePricingProductData}
                        placeholder="1"
                        min={1}
                        step={1}
                      />
                      <InputGroupAddon>
                        <InputGroupText>x</InputGroupText>
                      </InputGroupAddon>
                      <NumericInput
                        id={`product_${index}_format_y`}
                        name="personalization.format.y"
                        value={pricingProduct?.personalization?.format?.y}
                        onChange={this.changePricingProductData}
                        placeholder="1"
                        min={1}
                        step={1}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>mm</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  {product?.printFormats?.length > 0 ? (
                    <FormGroup>
                      <Input
                        type="select"
                        onChange={this.changePricingProductFormat}
                        value={`${pricingProduct?.personalization?.format?.x}x${pricingProduct?.personalization?.format?.y}`}
                      >
                        <option value="">Niestandardowy</option>
                        {product.printFormats.map((f) => (
                          <option value={`${f.x}x${f.y}`}>
                            {localizer(f).currentTranslation?.title} ({f.x} x {f.y} mm)
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  ) : (
                    <React.Fragment />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_pages_inside`}>
                    Stronicowanie
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>wew.</InputGroupText>
                      </InputGroupAddon>
                      <NumericInput
                        id={`product_${index}_pages_inside`}
                        name="personalization.pages.inside"
                        value={pricingProduct?.personalization?.pages?.inside}
                        onChange={this.changePricingProductData}
                        placeholder="1"
                        min={0}
                        step={1}
                      />
                      <InputGroupAddon>
                        <InputGroupText>zew.</InputGroupText>
                      </InputGroupAddon>
                      <NumericInput
                        id={`product_${index}_pages_outside`}
                        name="personalization.pages.outside"
                        value={pricingProduct?.personalization?.pages?.outside}
                        onChange={this.changePricingProductData}
                        placeholder="1"
                        min={0}
                        step={1}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>stron</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_color`}>Kolorystyka</Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="select"
                      name="personalization.color"
                      value={pricingProduct.personalization.color}
                      onChange={this.changePricingProductData}
                    >
                      {PrintColors.map((c) => (
                        <option key={c.value} value={c.value}>
                          {c.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_realizationTimeType`}>
                    Czas realizacji
                  </Label>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type="select"
                      name="personalization.realizationTimeType"
                      value={pricingProduct.personalization.realizationTimeType}
                      onChange={this.changePricingProductData}
                    >
                      {RealizationTimeTypes.map((c) => (
                        <option key={c.value} value={c.value}>
                          {c.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_project`}>Projekt</Label>
                </Col>
                <Col md={8} className="text-right">
                  <label className="custom-toggle">
                    <Input
                      type="checkbox"
                      name="personalization.project"
                      onChange={this.changePricingProductData}
                      value={true}
                      checked={pricingProduct.personalization.project}
                      defaultChecked={pricingProduct.personalization.project}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for={`product_${index}_project`}>Sprawdzanie</Label>
                </Col>
                <Col md={8} className="text-right">
                  <label className="custom-toggle">
                    <Input
                      type="checkbox"
                      name="personalization.checking"
                      onChange={this.changePricingProductData}
                      value={true}
                      checked={pricingProduct.personalization.checking}
                      defaultChecked={pricingProduct.personalization.checking}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
              <h3>Opcje</h3>
              <OrderProductAttributes
                orderProduct={pricingProduct}
                product={product}
                onUpdate={this.onUpdate}
              />
              <h3>Dodatki</h3>
              <OrderProductAdditives
                orderProduct={pricingProduct}
                product={product}
                onUpdate={this.onUpdate}
              />
            </Col>
            <Col md={6}>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Nakład</th>
                    <th>Cena netto</th>
                    <th>Cena brutto</th>
                    <th>Czas realizacji (h)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pricingProduct.prices?.map(this.renderPricingRow)}
                </tbody>
                <tfoot>
                  <tr>
                    <th
                      onClick={this.addPricing}
                      colSpan={5}
                      className="text-right"
                    >
                      <Button color="success" outline={true} size="sm">
                        Dodaj wyceniany nakład
                      </Button>
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };
}

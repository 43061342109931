import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Dropzone from "dropzone";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  CardBody,
  Alert,
  Spinner,
} from "reactstrap";
import apiDriver from "stores/api.driver";
import config from "config/global";
import PaymentsTable from "../../payments/PaymentsTable";
import PaymentModal from "./PaymentModal";

import * as paymentsActions from "stores/orders/payments/actions";
import InvoicesTable from "views/pages/invoices/invoices/InvoicesTable";

Dropzone.autoDiscover = false;

class OrderAccountancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = { refresh: false, isLoading: false };
    this.myRef = React.createRef();
  }

  parseValue = this.props.parseValue;
  changeOrderData = this.props.changeOrderData;
  changeOrderProperty = this.props.changeOrderProperty;

  togglePaymentModal = (id) =>
    id
      ? this.setState({ [id]: !this.state[id] })
      : this.setState({ paymentModalOpen: !this.state.paymentModalOpen });

  createInvoice = (type) => {
    const { order } = this.props;
    apiDriver
      .get(
        `${config.api.accountancyetl}pl/OrderInvoices/${order.id}/Create/${type}`,
      )
      .subscribe(() =>
        setTimeout(() => this.setState({ isLoading: false }), 3000),
      );
    this.setState({ isLoading: true });
  };

  createCorrection = () => {
    const { order } = this.props;
    apiDriver
      .get(
        `${config.api.accountancyetl}pl/OrderInvoices/${order.id}/Correction`,
      )
      .subscribe(() =>
        setTimeout(() => this.setState({ isLoading: false }), 3000),
      );
    this.setState({ isLoading: true });
  };

  createPayment = (data) => {
    apiDriver
      .put(`${config.api.orders}pl/Payments/`, data)
      .subscribe(() => this.props.refresh());
  };

  updatePayment = () => {};

  render = () => {
    const { order } = this.props;
    const { paymentModalOpen, refresh, isLoading } = this.state;
    const hasBillingInfo =
      order.billingInfo &&
      order.billingInfo?.line1 &&
      order.billingInfo?.city &&
      order.billingInfo?.postalCode;
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <div className="clearfix">
                  <div className="float-left">Płatości</div>
                  <div className="float-right">
                    <Button
                      type="button"
                      color="primary"
                      onClick={(e) => this.togglePaymentModal()}
                    >
                      Dodaj płatność
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <PaymentsTable
                filters={{
                  orderId: {
                    filterVal: order.id,
                    filterType: "TEXT",
                    comparator: "=",
                    caseSensitive: false,
                    filterKey: "orderId",
                  },
                }}
                hideOrder={true}
              />
              <CardFooter className="text-right"></CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <div className="clearfix">
                  <div className="float-left">Dokumenty księgowe</div>
                </div>
              </CardHeader>
              <CardBody className={hasBillingInfo ? "d-none" : "d-block"}>
                <Alert color="warning">
                  <b>Brak danych rozliczeniowych.</b>
                  <p>
                    Aby zamówienie mogło mieć utworzony dokument księgowy -
                    uzupełnij dane rozliczeniowe.
                  </p>
                </Alert>
              </CardBody>
              {isLoading && (
                <CardBody className="text-center p-5">
                  <Spinner />
                </CardBody>
              )}
              {!isLoading && (
                <InvoicesTable
                  filters={{
                    externalTags: {
                      filterVal: `OMS.OrderId=${order.id}`,
                      filterType: "TEXT",
                      comparator: "LIKE",
                      caseSensitive: false,
                      filterKey: "externalTags",
                    },
                  }}
                  hideOrder
                  disableTabs
                  disableSearch
                />
              )}
              <CardFooter className="text-right">
                <div className="clearfix">
                  <div className="float-right">
                    <Button
                      type="button"
                      color="info"
                      onClick={(e) => this.createInvoice(3)}
                      disabled={!hasBillingInfo || isLoading}
                    >
                      Dodaj fakturę Proforma
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(e) => this.createInvoice(2)}
                      disabled={!hasBillingInfo || isLoading}
                    >
                      Dodaj fakturę VAT
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(e) => this.createInvoice(1)}
                      disabled={isLoading}
                    >
                      Dodaj paragon
                    </Button>
                    <Button
                      type="button"
                      color="warning"
                      onClick={(e) => this.createInvoice(4)}
                      disabled={!hasBillingInfo || order?.billingInfo?.countryCode === 'pl' || isLoading}
                    >
                      Dodaj fakturę WDT
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      onClick={(e) => this.createCorrection()}
                      disabled={isLoading}
                    >
                      Dodaj korektę
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <PaymentModal
          order={order}
          isOpen={paymentModalOpen}
          toggle={(e) => this.togglePaymentModal()}
          title="Płatność"
          parseValue={this.parseValue}
          create={this.createPayment}
          update={this.updatePayment}
          refresh={refresh}
        />
      </React.Fragment>
    );
  };
}
function mapStateToProps(state) {
  return {
    data: state.payments.list,
    loading: state.payments.loading,
    refresh: state.payments.refresh,
    auth: state.auth.user,
    meta: state.payments.listMeta,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    refresh: (id) => dispatch(paymentsActions.refresh()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderAccountancy),
);

import React, { createRef } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
} from "reactstrap";

class AsyncSelector extends React.Component {
  constructor(props) {
    super(props);
    const options = this.prepareOptions(props.options) || [];
    this.state = {
      options: options,
      cachedValue: props.value
        ? options.find((o) => o.value === props.value)
        : null,
      open: false,
      searchText: "",
      isLoading: false,
    };
    this.checkIsFetchNeeded();
    this.searchTimer = createRef(null);
  }

  toggle = () => {
    const { open } = this.state;
    if (open !== true) {
      this.setSearch({ target: { value: "" } });
    }
    this.setState({ open: !open });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.options.length !== prevProps.options.length ||
      !this.props.options.every(
        (e, i) => e.value === prevProps.options[i].value,
      )
    ) {
      this.setState({
        options: this.prepareOptions(this.props.options),
      });
    }
    if (
      this.state.options.length !== prevState.options.length ||
      !this.state.options.every(
        (e, i) => e.value === prevState.options[i].value,
      )
    ) {
      this.checkIsFetchNeeded();
    }
    if (prevProps.value !== this.props.value) {
      this.checkIsFetchNeeded();
    }
  }

  internalChange = (option) => {
    const { onChange, name } = this.props;
    onChange({
      target: {
        value: option.value,
        name: name,
        type: "select-one",
        rawValue: option,
      },
    });
    this.setState({ cachedValue: option });
  };

  setSearch = (e) => {
    const { value } = e.target;
    if(this.searchTimer.current) {
      clearTimeout(this.searchTimer.current);
    }
    this.setState({ searchText: value });
    this.searchTimer.current = setTimeout(() => this.onSearchEx(value), 250);
  };

  nullOption = () => {
    const { nullTitle } = this.props;
    return { value: "", label: nullTitle || "Brak" };
  };
  createOption = () => ({ value: "_", label: "Utwórz" });

  addOptionToResolve = (resolve, option) =>
    resolve.then((options) => {
      let result = [...option];
      result.push(option);
      return result;
    });
  addNullOption = (resolve) =>
    this.addOptionToResolve(resolve, this.nullOption());
  addCreateOption = (resolve) =>
    this.addOptionToResolve(resolve, this.createOption());

  optionSelector = () => {
    const { value } = this.props;
    const { options } = this.state;
    if (!value) return this.nullOption();
    if (value === "_") return this.createOption();
    if (!options) return "";
    let result = options.find((o) => o.value === value);
    if (!result) return "";
    return result;
  };

  prepareOptions = (opts) => {
    const { allowNull, allowCreate } = this.props;
    let options = [...opts];
    if (allowNull) options.push(this.nullOption());
    if (allowCreate) options.push(this.createOption());
    return options;
  };

  onSearchEx = (inputValue) => {
    const { onSearch } = this.props;

    this.setState({ isLoading: true });

    return new Promise((resolve, reject) => {
      onSearch(inputValue)
        .then((result) => {
          result = this.prepareOptions(result);
          this.setState({ options: result });
          this.setState({ isLoading: false });
          resolve(result);
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setState({ options: [] });
          reject(error);
        });
    });
  };

  checkIsFetchNeeded = () => {
    const { fetchNotFound, value } = this.props;
    const { options } = this.state;

    if (!fetchNotFound) return null;
    if (!value) return null;

    if (!options.some((o) => o.value === value)) {
      this.fetchNotFound();
    }
  };

  fetchNotFound = () => {
    const { fetchNotFound, value } = this.props;

    const { options } = this.state;

    this.setState({ isLoading: true });

    fetchNotFound(value)
      .then((result) => {
        this.setState({ options: [...options, result] });
        this.setState({ cachedValue: result });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { name, id, title } = this.props;
    const { options, searchText, isLoading, cachedValue } = this.state;

    const val = this.optionSelector();

    return (
      <Dropdown
        className="d-block w-100"
        isOpen={this.state.open}
        toggle={this.toggle}
      >
        <DropdownToggle className="d-block w-100 text-left">
          <div className="clearfix">
            <div className="float-left">
              {cachedValue ? cachedValue.label : val.label}
            </div>
            <div className="float-right">
              <i className="fas fa-chevron-down"></i>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="w-100">
          <DropdownItem tag="div" toggle={false}>
            <Input
              name={`${id || name}_searchText`}
              id={`${id || name}_searchText`}
              onChange={this.setSearch}
              value={searchText}
              type="text"
              placeholder="Szukaj..."
            />
          </DropdownItem>
          <DropdownItem header>{title}</DropdownItem>
          <div style={{ maxHeight: "50vh", overflow: "scroll" }}>
            {options.map((option) => (
              <DropdownItem
                className={val === option ? "text-primary" : ""}
                key={option.value ? option.value : "null"}
                onClick={() => this.internalChange(option)}
              >
                {option.label}
              </DropdownItem>
            ))}
          </div>
          {isLoading ? (
            <DropdownItem className="text-center" header>
              <Spinner />
            </DropdownItem>
          ) : (
            <React.Fragment />
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default AsyncSelector;

import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";

import session from "stores/session/reducers";

import users from "stores/iam/users/reducers";
import roles from "stores/iam/roles/reducers";
import auth from "stores/iam/auth/reducers";
import access from "stores/iam/access/reducers";

import notificationTypes from "stores/core/notificationTypes/reducers";

import pages from "stores/cms/pages/reducers";
import galleries from "stores/cms/galleries/reducers";
import galleryCategories from "stores/cms/galleryCategories/reducers";

import diets from "stores/bookings/diets/reducers";
import personTypes from "stores/bookings/personTypes/reducers";
import rooms from "stores/bookings/rooms/reducers";
import roomTypes from "stores/bookings/roomTypes/reducers";
import roomAdditions from "stores/bookings/roomAdditions/reducers";
import bookings from "stores/bookings/bookings/reducers";
import stays from "stores/bookings/stays/reducers";

import products from "stores/products/products/reducers";
import productCategories from "stores/products/productCategories/reducers";
import productAdditives from "stores/products/productAdditives/reducers";
import productAdditiveGroups from "stores/products/productAdditiveGroups/reducers";
import productAttributes from "stores/products/productAttributes/reducers";
import productOptions from "stores/products/productOptions/reducers";
import printFormats from "stores/products/printFormats/reducers";

import billingInfos from "stores/orders/billingInfos/reducers";
import coupons from "stores/orders/coupons/reducers";
import collectPoints from "stores/orders/collectPoints/reducers";
import orderNotes from "stores/orders/orderNotes/reducers";
import orderProducts from "stores/orders/orderProducts/reducers";
import orders from "stores/orders/orders/reducers";
import orderSeries from "stores/orders/orderSeries/reducers";
import orderStatuses from "stores/orders/orderStatuses/reducers";
import paymentMethods from "stores/orders/paymentMethods/reducers";
import payments from "stores/orders/payments/reducers";
import paymentStatuses from "stores/orders/paymentStatuses/reducers";
import shippingAddresses from "stores/orders/shippingAddresses/reducers";
import shippingMethods from "stores/orders/shippingMethods/reducers";
import shippings from "stores/orders/shippings/reducers";
import shippingStatuses from "stores/orders/shippingStatuses/reducers";
import verificationTemplates from "stores/orders/verificationTemplates/reducers";
import customers from "stores/orders/customers/reducers";
import businesses from "stores/orders/businesses/reducers";
import customPricings from "stores/orders/customPricings/reducers";

import buyersData from "stores/invoices/buyersData/reducers";
import invoices from "stores/invoices/invoices/reducers";
import invoiceSeries from "stores/invoices/invoiceSeries/reducers";
import sellersData from "stores/invoices/sellersData/reducers";

import * as localStorageService from "services/local-storage/local-storage.service";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  session,

  users,
  roles,
  auth,
  access,

  notificationTypes,

  pages,
  galleries,
  galleryCategories,

  diets,
  personTypes,
  rooms,
  roomTypes,
  roomAdditions,
  bookings,
  stays,

  products,
  productCategories,
  productAdditives,
  productAdditiveGroups,
  productAttributes,
  productOptions,
  printFormats,

  billingInfos,
  collectPoints,
  coupons,
  orderNotes,
  orderProducts,
  orders,
  orderSeries,
  orderStatuses,
  paymentMethods,
  payments,
  paymentStatuses,
  shippingAddresses,
  shippingMethods,
  shippings,
  shippingStatuses,
  verificationTemplates,
  customers,
  businesses,
  customPricings,

  buyersData,
  invoices,
  invoiceSeries,
  sellersData,
});

export const epicMiddleware = createEpicMiddleware();
const persistedState = localStorageService.loadState();
export const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(applyMiddleware(epicMiddleware)),
);

store.subscribe(() => {
  localStorageService.saveState({
    auth: store.getState().auth,
  });
});

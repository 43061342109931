import * as constants from "./constants";
import IShipping from "./model";
import IShippingState from "./state";

const shippings = (
  state: IShippingState = {
    list: [],
    listMeta: { total: 0, filtered: 0, page: 1 },
    active: [],
    current: null,
    id: null,
    loading: false,
  },
  action: any,
) => {
  switch (action.type) {
    case constants.ACTIVE:
      state.active = [...state.active, action.payload];
      return { ...state };
    case constants.CREATE:
      return { ...state, loading: true };
    case constants.CREATE_SUCCESS:
      state.list = [...state.list, action.payload];
      return { ...state, loading: false };
    case constants.READ_SUCCESS:
      let toUpdate = state.list.findIndex(
        (shipping: IShipping) => shipping.id === action.payload.id,
      );
      if (toUpdate > -1) {
        state.list.splice(toUpdate, 1);
      }
      state.list = [...state.list, action.payload];
      state.current = action.payload;
      return { ...state };
    case constants.UPDATE:
      return { ...state, loading: true, id: action.payload };
    case constants.UPDATE_SUCCESS:
      state.list = state.list.map((shipping: IShipping) => {
        if (shipping.id === action.payload.id) {
          return action.payload;
        }
        return shipping;
      });
      if (state.current && state.current.id === action.payload.id) {
        state.current = action.payload;
      }
      state.loading = false;
      return { ...state };
    case constants.REMOVE:
      return { ...state, loading: true, id: action.payload };
    case constants.REMOVE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          list: state.list.filter(
            (shipping: IShipping) => shipping.id !== action.payload,
          ),
          loading: false,
          id: null,
        };
      } else {
        return {
          ...state,
          list: state.list.filter(
            (shipping: IShipping) => shipping.id !== state.id,
          ),
          loading: false,
          id: null,
        };
      }
    case constants.LIST_SUCCESS:
      state.list = action.payload;
      state.list.map((shipping: IShipping) => {
        return shipping;
      });
      state.listMeta = { ...state.listMeta, ...action.meta };
      return { ...state };
    default:
      return state;
  }
};

export default shippings;

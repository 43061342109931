import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

import apiDriver from "stores/api.driver";
import config from "config/global";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

function Note(props) {
  const { note, isByClient } = props;
  const rowClass = isByClient
    ? "justify-content-start"
    : "justify-content-end text-right";
  const cardClass = isByClient ? "" : "bg-gradient-primary text-white";
  return (
    <Row className={rowClass}>
      <Col className="col-auto" style={{ minWidth: "50%" }}>
        <Card className={cardClass}>
          <CardBody className="p-2">
            <p className="mb-1">
              <b>{note.title}</b>
            </p>
            <p className="mb-1">{note.content}</p>
            <div>
              <small className="opacity-60">
                <i className="far fa-clock"></i> &nbsp;
                {new Date(note.created).toLocaleTimeString()}
              </small>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

function NoteForm(props) {
  const { order, orderProduct, onCreateNote } = props;
  const [note, setNote] = useState({ title: "", content: "" });
  const [loading, setLoading] = useState(false);

  const createNote = (e) => {
    setLoading(true);
    apiDriver
      .put(config.api.orders + "pl/OrderNotes", {
        ...note,
        orderId: order.id,
        productId: orderProduct.id || null,
      })
      .pipe(
        map((response) => {
          onCreateNote(response.response);
          resetForm();
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        setLoading(false);
      });
  };

  const onChangeData = (e) =>
    setNote({ ...note, [e.target.name]: e.target.value });

  const resetForm = (e) => {
    setNote({ title: "", content: "" });
  };

  return (
    <Form>
      <h6>Dodaj komentarz</h6>
      <FormGroup>
        <Input
          type="text"
          name="title"
          value={note.title}
          onChange={onChangeData}
          placeholder="Tytuł"
        />
        <Input
          type="textarea"
          name="content"
          value={note.content}
          onChange={onChangeData}
          placeholder="Treść komentarza"
          rows="5"
        />
      </FormGroup>
      <FormGroup align="right">
        <Button
          type="button"
          color="primary"
          onClick={createNote}
          disabled={loading}
        >
          {!loading ? (
            <span>Dodaj komentarz</span>
          ) : (
            <i className="fas fa-circle-notch fa-spin"></i>
          )}
        </Button>
      </FormGroup>
    </Form>
  );
}

class OrderNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderProduct: {},
      product: {},
      notes: [],
    };
    this.myRef = React.createRef();
  }

  loadNotes = () => {
    const { order, orderProduct } = this.props;
    let filters = {};
    if (orderProduct) {
      filters.productId = {
        filterVal: orderProduct.id,
        filterType: "Guid",
        comparator: "=",
        caseSensitive: false,
        filterKey: "productId",
      };
    }
    if (order) {
      filters.orderId = {
        filterVal: order.id,
        filterType: "Guid",
        comparator: "=",
        caseSensitive: false,
        filterKey: "productId",
      };
    }
    let attributes = apiDriver.buildIndexAttributes({
      filters: filters,
    });
    let url = `${config.api.orders}pl/OrderNotes${attributes}`;
    apiDriver
      .get(url)
      .pipe(
        map((response) => {
          this.setState({ notes: response.response });
          return response.response;
        }),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {});
  };

  onCreateNote = (note) =>
    this.setState({ notes: [...this.state.notes, note] });

  componentDidMount = () => {
    this.loadNotes();
  };

  componentDidUpdate(prevProps) {}

  loadingOverlay = () => this.props.loadingOverlay();
  parseValue = (e) => this.props.parseValue(e);
  changeDotNotationValue = (entity, notation, value) =>
    this.props.changeDotNotationValue(entity, notation, value);

  render = () => {
    const { order, orderProduct } = this.props;
    const { notes } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle>Notatki</CardTitle>
        </CardHeader>
        <CardBody>
          {notes.map((note) => (
            <Note
              note={note}
              isByClient={
                note.userId !== order.userId || note.guestId !== order.guestId
              }
            />
          ))}
        </CardBody>
        <CardFooter>
          <NoteForm
            order={order}
            orderProduct={orderProduct}
            onCreateNote={this.onCreateNote}
          />
        </CardFooter>
      </Card>
    );
  };
}

export default OrderNotes;

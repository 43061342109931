import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { connect } from "react-redux";
import * as authActions from "stores/iam/auth/actions";

class Lock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  setFocused = (toggle) => {
    this.setState({ focused: toggle });
  };

  onClickUnlock = () => {
    this.props.unlock();
  };

  render() {
    const { focused } = this.state;
    const { user } = this.props;
    const { lock } = this.props;

    if (!lock) {
      this.props.history.push("/admin/dashboard");
      return <div />;
    }

    return (
      <>
        <AuthHeader
          title="Blokada ekranu"
          lead="Wpisz ponizej swoje hasło aby odblokować ekran systemu."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="card-profile bg-secondary mt-5">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <img
                        alt="..."
                        className="rounded-circle border-secondary"
                        src={require("assets/img/theme/user.jpg").default}
                      />
                    </div>
                  </Col>
                </Row>
                <CardBody className="pt-7 px-5">
                  <div className="text-center mb-4">
                    <h3>
                      {user.firstName} {user.lastName}
                    </h3>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: focused,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={() => this.setFocused(true)}
                          onBlur={() => this.setFocused(false)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="mt-2"
                        color="info"
                        type="button"
                        onClick={this.onClickUnlock}
                      >
                        Odblokuj
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    lock: state.auth.lock,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    unlock: () => dispatch(authActions.unlock()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lock);

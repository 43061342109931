export const LOGIN = "h0pe/USERS/LOGIN/PENDING";
export const LOGIN_SUCCESS = "h0pe/AUTH/LOGIN/SUCCESS";
export const LOGIN_FAILURE = "h0pe/AUTH/LOGIN/FAILURE";
export const LOGOUT = "h0pe/AUTH/LOGOUT/PENDING";
export const LOGOUT_SUCCESS = "h0pe/AUTH/LOGOUT/SUCCESS";

export const LOCK = "h0pe/USERS/LOCK";
export const UNLOCK = "h0pe/USERS/UNLOCK";

export const REFRESH = "h0pe/USERS/REFRESH/PENDING";
export const REFRESH_SUCCESS = "h0pe/USERS/REFRESH/SUCCESS";
export const REFRESH_FAILURE = "h0pe/USERS/REFRESH/FAILURE";

import React from "react";

import { Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import CountrySelector from "./CountrySelector";

class ShippingAddressForm extends React.Component {
  render() {
    const { id, address, onChange } = this.props;

    return (
      <Row>
        <Col md="12">
          <Label inline="false" for={`${id}_line1`}>
            <small className="text-uppercase text-muted">Adres</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_line1`}
                bsSize="sm"
                type="text"
                name="line1"
                value={address?.line1}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_line2`}
                bsSize="sm"
                type="text"
                name="line2"
                value={address?.line2}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <Label inline="false" for={`${id}_postalCode`}>
            <small className="text-uppercase text-muted">Kod pocztowy</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_postalCode`}
                bsSize="sm"
                type="text"
                name="postalCode"
                value={address?.postalCode}
                onChange={onChange}
                maxLength={16}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="8">
          <Label inline="false" for={`${id}_city`}>
            <small className="text-uppercase text-muted">Miejscowość</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_city`}
                bsSize="sm"
                type="text"
                name="city"
                value={address?.city}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_countryCode`}>
            <small className="text-uppercase text-muted">Kraj</small>
          </Label>
          <FormGroup>
            <CountrySelector
              value={address?.countryCode}
              name="countryCode"
              onChange={onChange}
              inputProps={{ size: "sm" }}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_person`}>
            <small className="text-uppercase text-muted">Odbiorca</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_person`}
                bsSize="sm"
                type="text"
                name="person"
                value={address?.person}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <Label inline="false" for={`${id}_phone`}>
            <small className="text-uppercase text-muted">Telefon</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_phone`}
                bsSize="sm"
                type="text"
                name="phone"
                value={address?.phone}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <Label inline="false" for={`${id}_email`}>
            <small className="text-uppercase text-muted">Adres e-mail</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_email`}
                bsSize="sm"
                type="text"
                name="email"
                value={address?.email}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default ShippingAddressForm;

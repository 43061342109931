const changeDotNotationValue = (entity, notation, value) => {
  if (typeof notation === "string") {
    return changeDotNotationValue(entity, notation.split("."), value);
  }

  if (notation.length === 1) {
    return {
      ...entity,
      [notation[0]]: value,
    };
  } else {
    return {
      ...entity,
      [notation[0]]: changeDotNotationValue(
        entity[notation[0]],
        notation.slice(1),
        value,
      ),
    };
  }
};

export default changeDotNotationValue;

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as customPricingActions from "stores/orders/customPricings/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";

import AsyncSelector from "../orders/cards/AsyncSelector";

import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import apiDriver from "stores/api.driver";
import CustomPricingProduct from "./CustomPricingProduct";

import { API_URL as PRODUCTS_API_URL } from "stores/products/products/epics";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import AddProductModal from "./AddProductModal";

Dropzone.autoDiscover = false;

class CustomPricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customPricing: null,
      auth: null,
      action: "",
      session: null,
      users: [],
      assignees: [],
      products: [],
      currentCulture: this.props.session.culture,
      costSelectedCountry: "",
      availabilitySelectedCountry: "",
      addProductModalOpen: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        customPricing: {
          id: undefined,
          number: undefined,

          userId: undefined,
          guestId: undefined,
          creatorId: undefined,
          assigneeId: undefined,

          created: undefined,
          updated: undefined,
          orderd: undefined,

          guestAccessToken: undefined,

          user: undefined,
          guest: undefined,
          creator: undefined,
          assignee: undefined,

          products: [],
        },
        loading: false,
      });
    }
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeCustomPricingData = (event) => {
    const value = this.parseValue(event.target);
    let customPricing = {
      ...this.state.customPricing,
      [value.name]: value.value,
    };
    this.setState({ customPricing });
  };

  submitForm = (event) => {
    var customPricing = { ...this.state.customPricing };
    if (customPricing.id) {
      this.setState({ action: "update" });
      this.props.update(customPricing);
    } else {
      this.setState({ action: "create" });
      this.props.create(customPricing);
    }
  };

  loadUsers = (inputValue) => {
    return apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => result.response)
      .then((users) =>
        users.map((user) => ({
          value: user.id,
          label: user.username,
        })),
      );
  };
  renderUser = (user) => ({ value: user.id, label: user.username });

  loadProducts = () => {
    apiDriver
      .get(`${PRODUCTS_API_URL}?Take=1000`)
      .pipe(
        map((response) => response.response),
        catchError((error) => of(error)),
      )
      .subscribe((response) => {
        this.setState({ products: response });
      });
  };

  updateProduct = (index, newState) => {
    const customPricing = { ...this.state.customPricing };
    const products = [...customPricing.products];
    this.setState({
      customPricing: {
        ...customPricing,
        products: products.map((e, i) => (i === index ? newState : e)),
      },
    });
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  toggleAddProductModal = (id) =>
    id
      ? this.setState({ [id]: !this.state[id] })
      : this.setState({ addProductModalOpen: !this.state.addProductModalOpen });
  onCreateProduct = (product) => {
    const { customPricing } = this.state;
    this.setState({
      customPricing: {
        ...customPricing,
        products: [...customPricing.products, product],
      },
    });
    //this.notify('Dodano produkt', `Pomyślnie dodano produkt ${product.product ? localizer(product.product)?.currentTranslation?.title : 'niestandardowy'} do zamówienia`, 'success');
  };

  onClickDelete = () => {
    this.props.remove(this.state.customPricing.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.customPricing || this.props.loading;

    const { customPricing } = this.state;
    const { auth } = this.props;

    if (!customPricing || customPricing.id === auth.id || !customPricing.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.customPricing || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { customPricing } = this.state;

    if (!customPricing || !customPricing.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={customPricing ? customPricing.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { customPricing, users } = this.state;

    if (!customPricing) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={6}>
                <Row>{this.renderId()}</Row>
                <Row>
                  <Col md="4">Numer</Col>
                  <Col md="8">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          placeholder="Numer"
                          type="text"
                          name="number"
                          value={customPricing.number}
                          onChange={this.changeCustomPricingData}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md="4">Zamawiający</Col>
                  <Col md="8">
                    <FormGroup>
                      <AsyncSelector
                        title="Zamawiający"
                        name="userId"
                        placeholder="Szukaj..."
                        allowNull={true}
                        options={(
                          users ||
                          (customPricing.user ? [customPricing.user] : [])
                        ).map(this.renderUser)}
                        value={customPricing.userId}
                        onChange={this.changeCustomPricingData}
                        onSearch={this.loadUsers}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">Opiekun</Col>
                  <Col md="8">
                    <FormGroup>
                      <AsyncSelector
                        title="Opiekun"
                        name="assigneeId"
                        placeholder="Szukaj..."
                        allowNull={true}
                        options={(
                          users ||
                          (customPricing.assignee
                            ? [customPricing.assignee]
                            : [])
                        ).map(this.renderUser)}
                        value={customPricing.assigneeId}
                        onChange={this.changeCustomPricingData}
                        onSearch={this.loadUsers}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md="4">Utworzono</Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        readOnly={true}
                        placeholder="Wprowadzający"
                        value={
                          customPricing.created
                            ? customPricing.created
                              .substr(0, 16)
                              .replace("T", " ")
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">Zaktualizowano</Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        readOnly={true}
                        placeholder="Zaktualizowano"
                        value={(customPricing.updated ? (new Date(customPricing.updated)).toLocaleString() : 'Nie')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">Zamówiono</Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        readOnly={true}
                        placeholder="Zamówiono"
                        value={
                          customPricing.ordered
                            ? customPricing.ordered
                              .substr(0, 16)
                              .replace("T", " ")
                            : "Nie"
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">Wprowadzający</Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        type="text"
                        readOnly={true}
                        placeholder="Wprowadzający"
                        value={
                          customPricing.creator
                            ? customPricing.creator.username
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.customPricing || this.props.loading;

    const { customPricing, addProductModalOpen } = this.state;
    const { action } = this.state;

    const onClickAddProduct = (e) => this.toggleAddProductModal();

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/orders/customPricings/" />;
    }

    return (
      <>
        <SimpleHeader
          name={customPricing ? customPricing.number : ""}
          link="/admin/orders/customPricings"
          parentName="Wyceny niestandardowe"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt-6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <AddProductModal
            isOpen={addProductModalOpen}
            toggle={(e) => this.toggleAddProductModal()}
            onCreate={this.onCreateProduct}
            title="Dodaj produkt"
          />
          <div className="card-wrapper">{this.renderCardData()}</div>
          <h1 className="clearfix">
            <div className="float-left">Produkty</div>
            <div className="float-right">
              <Button color="success" onClick={onClickAddProduct}>
                Dodaj produkt
              </Button>
            </div>
          </h1>
          {customPricing &&
            customPricing.products &&
            customPricing.products.map((product, index) => (
              <CustomPricingProduct
                index={index}
                customPricing={customPricing}
                pricingProduct={product}
                onUpdate={(s) => this.updateProduct(index, s)}
              />
            ))}
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.customPricing !== this.props.customPricing) {
      this.setState({
        customPricing: this.props.customPricing,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.methods !== this.props.methods) {
      this.setState({
        methods: this.props.methods,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    customPricing: state.customPricings.current,
    loading: state.customPricings.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(customPricingActions.read(id)),
    remove: (id) => dispatch(customPricingActions.remove(id)),
    update: (customPricing) =>
      dispatch(customPricingActions.update(customPricing)),
    create: (customPricing) =>
      dispatch(customPricingActions.create(customPricing)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomPricing),
);

import React from "react";
import apiDriver from "stores/api.driver";
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }

  onChange = (x) => {
    if (!this.props.onChange) {
      return;
    }

    const content = this.editorRef.current.getContent();
    this.props.onChange({
      target: {
        name: this.props.name,
        type: 'textarea',
        value: content
      }
    });
  };

  presignedUrlUploadHandler = (blobInfo, progress) => new Promise((success, failure) => {
    const name = `${new Date().getTime()}_${blobInfo.filename()}`.replace(' ', '');
    const url = this.props.uploadUrl.replace('[[name]]', name);

    apiDriver.post(url, {}).subscribe({
      next: (response) => {
        const presignedUrl = response.response;
        const [request, progressSubscriber] = apiDriver.uploadPresigned(
          presignedUrl,
          blobInfo.blob(),
          name
        );
        progressSubscriber.subscribe((response) => {
          if (response.type === "progress")
            progress((response.loaded / response.totalSize) * 100);
        });
        request.subscribe({
          next: () => success(url.replace("/management/", "/client/")),
          error: (err) => failure(err),
        });
      },
      error: (err) => failure(err),
    });
  });

  uploadHander = (blobInfo, progress) => new Promise((success, failure) => {
    console.log(blobInfo);
    const name = `${new Date().getTime()}_${blobInfo.filename()}`.replace(' ', '');
    const url = this.props.uploadUrl.replace('[[name]]', name);
    console.log(url);
    const [request, progressSubscriber] = apiDriver.upload(
      url,
      blobInfo.blob(),
      name
    );
    progressSubscriber.subscribe((response) => {
      if (response.type === "progress")
        progress((response.loaded / response.totalSize) * 100);
    });
    request.subscribe({
      next: () => success(url.replace("/management/", "/client/")),
      error: (err) => failure(err),
    });
  });

  render = () => {
    const { uploadUrl, uploadPresignedUrl, value, menubar } = this.props;

    const init = {
      height: 500,
      menubar: menubar,
      apply_source_formatting: false,
      cleanup_on_startup: false,
      trim_span_elements: false,
      verify_html: false,
      cleanup: false,
      convert_urls: false,
      remove_script_host: false,
      valid_children: "+a[div|h1|h2|h3|h4|h5|h6|p|#text]",
      plugins: [
        "advlist", "autolink", "lists", "link", "image", "imagetools", "charmap", "print", "preview", "anchor",
        "preview", "searchreplace", "hr", "visualblocks", "code", "fullscreen",
        "insertdatetime", "media", "table", "paste", "code", "help", "wordcount",
      ],
      toolbar:
        "undo redo | " +
        "bold italic forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "removeformat",
      menu: {
        file: { title: 'File', items: 'restoredraft | preview | print' },
        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
        view: { title: 'View', items: 'code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
        insert: { title: 'Insert', items: 'image link media addcomment pageembed codesample inserttable | bullist numlist outdent indent | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
        tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
        table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
      },
      content_style:
        "body { padding: 10px; font-family:Helvetica,Arial,sans-serif; font-size:14px } .collapse { display: block !important; }",
      content_css: "/env.css",
      promotion: false,
      a11y_advanced_options: true
    };

    if (uploadUrl) {
      init.images_upload_url = uploadUrl;
      init.images_upload_handler = uploadPresignedUrl ? this.presignedUrlUploadHandler : this.uploadHander;
    }

    return (
      <TinyMCEEditor
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        licenseKey="gpl"
        onInit={(evt, editor) => (this.editorRef.current = editor)}
        onFocusOut={this.onChange}
        initialValue={value || ""}
        init={init}

      />
    );
  };
}

export default Editor;

export const READ = "pbs/ORDER_SERIES/READ/PENDING";
export const READ_SUCCESS = "pbs/ORDER_SERIES/READ/SUCCESS";
export const CREATE = "pbs/ORDER_SERIES/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/ORDER_SERIES/CREATE/SUCCESS";
export const UPDATE = "pbs/ORDER_SERIES/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/ORDER_SERIES/UPDATE/SUCCESS";
export const REMOVE = "pbs/ORDER_SERIES/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/ORDER_SERIES/REMOVE/SUCCESS";
export const LIST = "pbs/ORDER_SERIES/LIST/PENDING";
export const LIST_SUCCESS = "pbs/ORDER_SERIES/LIST/SUCCESS";

export const ACTIVE = "pbs/ORDER_SERIES/ACTIVE";

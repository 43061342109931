export const READ = "pbs/PRINT_FORMATS/READ/PENDING";
export const READ_SUCCESS = "pbs/PRINT_FORMATS/READ/SUCCESS";
export const CREATE = "pbs/PRINT_FORMATS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/PRINT_FORMATS/CREATE/SUCCESS";
export const UPDATE = "pbs/PRINT_FORMATS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/PRINT_FORMATS/UPDATE/SUCCESS";
export const REMOVE = "pbs/PRINT_FORMATS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/PRINT_FORMATS/REMOVE/SUCCESS";
export const LIST = "pbs/PRINT_FORMATS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/PRINT_FORMATS/LIST/SUCCESS";

export const ACTIVE = "pbs/PRINT_FORMATS/ACTIVE";

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as roomsActions from "stores/bookings/rooms/actions";
import * as roomTypesActions from "stores/bookings/roomTypes/actions";
import * as staysActions from "stores/bookings/stays/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";

Dropzone.autoDiscover = false;

class Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      room: null,
      types: [],
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      stays: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        room: {
          id: undefined,
          typeId: null,
          stayId: null,
          translations: [],
        },
        loading: false,
      });
    }
    this.props.listTypes();
    this.props.listStays();
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  changeRoomData = (event) => {
    const { name, value, type } = event.target;
    var room = { ...this.state.room };
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(value);
        break;
      default:
        break;
    }
    room[name] = parsedValue;
    this.setState({ room });
  };

  changeRoomTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var room = { ...this.state.room };
    if (!room.translations || room.translations.length === 0) {
      room.translations.push({ culture: currentCulture });
    }
    room.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ room });
  };

  changeRoomTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeRoomTranslationEx(name, value);
  };

  submitForm = (event) => {
    var room = { ...this.state.room };
    if (room.id) {
      this.setState({ action: "update" });
      this.props.update(room);
    } else {
      this.setState({ action: "create" });
      this.props.create(room);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickDelete = () => {
    this.props.remove(this.state.room.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.room || this.props.loading;

    const { room } = this.state;
    const { auth } = this.props;

    if (!room || room.id === auth.id || !room.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.room || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { room } = this.state;

    if (!room || !room.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={room ? room.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardData = () => {
    const { room } = this.state;
    const { currentCulture } = this.state;

    if (!room) {
      return <></>;
    }

    const { types, stays } = this.state;
    const loading = this.props.loading;
    const editedTranslation =
      room.translations.filter(
        (translation) => translation.culture === currentCulture,
      )[0] || {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Rodzaj</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="typeId"
                  value={room.typeId}
                  onChange={this.changeRoomData}
                  required={true}
                >
                  <option disabled value="">
                    Wybierz
                  </option>
                  {types.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Turnus</Col>
              <Col md="9">
                <Input
                  className="form-control mb-3"
                  type="select"
                  name="stayId"
                  value={room.stayId}
                  onChange={this.changeRoomData}
                  required={true}
                >
                  <option value="00000000-0000-0000-0000-000000000000">
                    Wybierz
                  </option>
                  {stays.map((stay) => (
                    <option key={stay.id} value={stay.id}>
                      {stay.currentTranslation?.title}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeRoomTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };
  render = () => {
    const loading = !this.state.room || this.props.loading;

    const { room } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/booking/rooms/" />;
    }

    return (
      <>
        <SimpleHeader
          name={room ? room.currentTranslation?.title : ""}
          link="/admin/booking/rooms"
          parentName="Zakwaterowania"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.room !== this.props.room) {
      this.setState({
        room: this.props.room,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.types !== this.props.types) {
      this.setState({
        types: this.props.types,
      });
    }
    if (prevProps.stays !== this.props.stays) {
      this.setState({
        stays: this.props.stays,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    room: state.rooms.current,
    loading: state.rooms.loading,
    auth: state.auth.user,
    session: state.session,
    types: state.roomTypes.list,
    stays: state.stays.list,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(roomsActions.read(id)),
    remove: (id) => dispatch(roomsActions.remove(id)),
    update: (room) => dispatch(roomsActions.update(room)),
    create: (room) => dispatch(roomsActions.create(room)),
    listTypes: () => dispatch(roomTypesActions.list()),
    listStays: () => dispatch(staysActions.list()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Room));

import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as verificationTemplatesActions from "stores/orders/verificationTemplates/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global";
import { EVerificationTemplateType } from "stores/orders/verificationTemplates/model";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class VerificationTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      verificationTemplate: null,
      auth: null,
      action: "",
      session: null,
      types: [
        { key: EVerificationTemplateType.Info, name: "Informacja" },
        { key: EVerificationTemplateType.Success, name: "Sukces" },
        { key: EVerificationTemplateType.Warning, name: "Ostrzezenie" },
        { key: EVerificationTemplateType.Danger, name: "Błąd" },
      ],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        verificationTemplate: {
          id: undefined,
          type: 0,
          translations: [],
          currentTranslation: null,
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "select-one":
        let asNumber = Number(parsedValue);
        parsedValue = !isNaN(asNumber) ? asNumber : parsedValue;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeVerificationTemplateData = (event) => {
    const value = this.parseValue(event.target);
    let verificationTemplate = {
      ...this.state.verificationTemplate,
      [value.name]: value.value,
    };
    this.setState({ verificationTemplate });
  };

  changeVerificationTemplateTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var verificationTemplate = { ...this.state.verificationTemplate };
    if (
      !verificationTemplate.translations ||
      verificationTemplate.translations.filter(
        (t) => t.culture === currentCulture,
      ).length === 0
    )
      verificationTemplate.translations.push({ culture: currentCulture });
    verificationTemplate.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ verificationTemplate });
  };

  changeVerificationTemplateTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeVerificationTemplateTranslationEx(name, value);
  };

  submitForm = (event) => {
    var verificationTemplate = { ...this.state.verificationTemplate };
    if (verificationTemplate.id) {
      this.setState({ action: "update" });
      this.props.update(verificationTemplate);
    } else {
      this.setState({ action: "create" });
      this.props.create(verificationTemplate);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.verificationTemplate };

    translator(from, to, object).then((result) => {
      object.translations = [
        ...object.translations.filter((t) => t.culture !== to),
        result,
      ];
      this.setState({ verificationTemplate: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.verificationTemplate.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.verificationTemplate || this.props.loading;

    const { verificationTemplate } = this.state;
    const { auth } = this.props;

    if (
      !verificationTemplate ||
      verificationTemplate.id === auth.id ||
      !verificationTemplate.id
    ) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.verificationTemplate || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { verificationTemplate } = this.state;

    if (!verificationTemplate || !verificationTemplate.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={verificationTemplate ? verificationTemplate.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardPreview = () => {
    const { verificationTemplate, currentCulture } = this.state;

    if (!verificationTemplate) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = verificationTemplate.translations
      ? verificationTemplate.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podgląd</h3>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Alert
            color={
              verificationTemplate.type === EVerificationTemplateType.Success
                ? "success"
                : verificationTemplate.type ===
                  EVerificationTemplateType.Warning
                ? "warning"
                : verificationTemplate.type === EVerificationTemplateType.Danger
                ? "danger"
                : "info"
            }
          >
            {editedTranslation ? editedTranslation?.content : ""}
          </Alert>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { verificationTemplate, currentCulture, types } = this.state;

    if (!verificationTemplate) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = verificationTemplate.translations
      ? verificationTemplate.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Rodzaj</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Kod"
                      type="select"
                      name="type"
                      value={verificationTemplate.type}
                      onChange={this.changeVerificationTemplateData}
                    >
                      {types.map((t) => (
                        <option key={t.key} value={t.key}>
                          {t.name}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeVerificationTemplateTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">Treść</Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Treść"
                      type="textarea"
                      name="content"
                      value={
                        editedTranslation ? editedTranslation?.content : ""
                      }
                      onChange={this.changeVerificationTemplateTranslation}
                      rows="10"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.verificationTemplate || this.props.loading;

    const { verificationTemplate } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/orders/verificationTemplates/" />;
    }

    return (
      <>
        <SimpleHeader
          name={
            verificationTemplate
              ? verificationTemplate.currentTranslation?.title
              : ""
          }
          link="/admin/verificationTemplates/verificationTemplates"
          parentName="Szablony weryfikacyjne"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardPreview()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.verificationTemplate !== this.props.verificationTemplate) {
      this.setState({
        verificationTemplate: this.props.verificationTemplate,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (prevProps.methods !== this.props.methods) {
      this.setState({
        methods: this.props.methods,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    verificationTemplate: state.verificationTemplates.current,
    loading: state.verificationTemplates.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(verificationTemplatesActions.read(id)),
    remove: (id) => dispatch(verificationTemplatesActions.remove(id)),
    update: (verificationTemplate) =>
      dispatch(verificationTemplatesActions.update(verificationTemplate)),
    create: (verificationTemplate) =>
      dispatch(verificationTemplatesActions.create(verificationTemplate)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerificationTemplate),
);

export const READ = "pbs/ORDER_PRODUCTS/READ/PENDING";
export const READ_SUCCESS = "pbs/ORDER_PRODUCTS/READ/SUCCESS";
export const CREATE = "pbs/ORDER_PRODUCTS/CREATE/PENDING";
export const CREATE_SUCCESS = "pbs/ORDER_PRODUCTS/CREATE/SUCCESS";
export const UPDATE = "pbs/ORDER_PRODUCTS/UPDATE/PENDING";
export const UPDATE_SUCCESS = "pbs/ORDER_PRODUCTS/UPDATE/SUCCESS";
export const REMOVE = "pbs/ORDER_PRODUCTS/REMOVE/PENDING";
export const REMOVE_SUCCESS = "pbs/ORDER_PRODUCTS/REMOVE/SUCCESS";
export const LIST = "pbs/ORDER_PRODUCTS/LIST/PENDING";
export const LIST_SUCCESS = "pbs/ORDER_PRODUCTS/LIST/SUCCESS";
export const LIST_BY_ORDER = "pbs/ORDER_PRODUCTS/LIST_BY_ORDER/PENDING";
export const LIST_BY_ORDER_SUCCESS = "pbs/ORDER_PRODUCTS/LIST_BY_ORDER/SUCCESS";

export const ACTIVE = "pbs/ORDER_PRODUCTS/ACTIVE";

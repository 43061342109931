import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as paymentMethodsActions from "stores/orders/paymentMethods/actions";

import Dropzone from "dropzone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import config from "config/global.ts";
import NumericInput from "components/Form/NumericInput";
import CountrySelector, { Countries } from "../orders/cards/CountrySelector";
import translator from "services/translator/translator";

Dropzone.autoDiscover = false;

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      method: null,
      auth: null,
      action: "",
      session: null,
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        method: {
          id: undefined,
          hidden: false,
          translations: [],
          statuses: [],
          provisions: [],
          availabilities: [],
        },
        loading: false,
      });
    }
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeMethodData = (event) => {
    const value = this.parseValue(event.target);
    let method = { ...this.state.method, [value.name]: value.value };
    this.setState({ method });
  };

  addProvision = (event) => {
    const { provisionSelectedCountry, method } = this.state;
    if (!provisionSelectedCountry || provisionSelectedCountry === "") {
      return;
    }
    method.provisions.push({ countryCode: provisionSelectedCountry });
    this.setState({ ...method });
  };

  deleteProvision = (index) => {
    const { method } = this.state;
    method.provisions = [...method.provisions].filter((a, i) => i !== index);
    this.setState({ ...method });
  };

  changeProvisionSelectedCountry = (event) => {
    const { value } = event.target;
    this.setState({ provisionSelectedCountry: value });
  };

  changeProvisionData = (event, index) => {
    const value = this.parseValue(event.target);
    let method = { ...this.state.method };
    let provisions = [...method.provisions].map((provision, i) => {
      if (i !== index) return provision;
      provision[value.name] = value.value;
      return { ...provision };
    });
    method = { ...method, provisions: provisions };
    this.setState({ ...method });
  };

  changeAvailabilitySelectedCountry = (event) => {
    const { value } = event.target;
    this.setState({ availabilitySelectedCountry: value });
  };

  deleteAvailability = (countryCode) => {
    const { method } = this.state;
    if (!method.availabilities.some((a) => a.countryCode === countryCode)) {
      return;
    }
    method.availabilities = [...method.availabilities].filter(
      (a) => a.countryCode !== countryCode,
    );
    this.setState({ ...method });
  };

  addAvailability = (event) => {
    const { availabilitySelectedCountry, method } = this.state;
    if (!availabilitySelectedCountry || availabilitySelectedCountry === "") {
      return;
    }
    if (
      method.availabilities.some(
        (a) => a.countryCode === availabilitySelectedCountry,
      )
    ) {
      return;
    }
    method.availabilities.push({ countryCode: availabilitySelectedCountry });
    this.setState({ ...method });
  };

  changeAvailabilityData = (event, index) => {
    const value = this.parseValue(event.target);
    let method = { ...this.state.method };
    let availabilities = method.availabilities.map((availability, i) => {
      if (i !== index) return availability;
      return { ...availability, [value.name]: value.value };
    });
    this.setState({ ...method, availabilities: availabilities });
  };

  changeStatusData = (event, id) => {
    const value = this.parseValue(event.target);
    let method = { ...this.state.method };
    let statuses = method.statuses.map((status, index) => {
      if (index !== id) return status;
      status[value.name] = value.value;
      return { ...status };
    });
    this.setState({ ...method, statuses: statuses });
  };

  changeStatusTranslation = (event, id) => {
    const { currentCulture } = this.state;
    const value = this.parseValue(event.target);
    let method = { ...this.state.method };
    let statuses = method.statuses.map((status, index) => {
      if (index !== id) return status;
      if (
        !status.translations ||
        status.translations.filter((t) => t.culture === currentCulture)
          .length === 0
      )
        status.translations.push({ culture: currentCulture });
      status.translations = status.translations.map((translation) => {
        if (translation.culture !== currentCulture) return translation;
        translation[value.name] = value.value;
        return { ...translation };
      });
      return status;
    });
    this.setState({ ...method, statuses: statuses });
  };

  addStatus = (event) => {
    let method = { ...this.state.method };
    method.statuses.push({ translations: [] });
    this.setState({ method });
  };

  deleteStatus = (index) => {
    let method = { ...this.state.method };
    method.statuses = [...method.statuses].filter((status, i) => i !== index);
    this.setState({ method });
  };

  changeMethodTranslationEx = (name, value) => {
    const { currentCulture } = this.state;
    var method = { ...this.state.method };
    if (
      !method.translations ||
      method.translations.filter((t) => t.culture === currentCulture).length ===
        0
    )
      method.translations.push({ culture: currentCulture });
    method.translations.map((t) => {
      if (t.culture === currentCulture) {
        t[name] = value;
      }
      return t;
    });
    this.setState({ method });
  };

  changeMethodTranslation = (event) => {
    const { name, value } = event.target;
    return this.changeMethodTranslationEx(name, value);
  };

  submitForm = (event) => {
    var method = { ...this.state.method };
    if (method.id) {
      this.setState({ action: "update" });
      this.props.update(method);
    } else {
      this.setState({ action: "create" });
      this.props.create(method);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  translate = () => {
    const from = "pl";
    const to = this.state.currentCulture;
    const object = { ...this.state.method };

    const promises = [];
    promises.push(
      new Promise((resolve) => {
        translator(from, to, object).then((result) => {
          resolve(result);
        });
      }),
    );
    for (let status of object.statuses) {
      promises.push(
        new Promise((resolve) => {
          translator(from, to, status).then((result) => {
            resolve(result);
          });
        }),
      );
    }

    Promise.all(promises).then((results) => {
      for (let result of results) {
        if (object.id === result.entityId) {
          object.translations = [
            ...object.translations.filter((t) => t.culture !== to),
            result,
          ];
        } else {
          object.statuses = object.statuses?.map((status) => {
            if (status.id !== result.entityId) return { ...status };
            status.translations = [
              ...status.translations.filter((t) => t.culture !== to),
              result,
            ];
            return status;
          });
        }
      }
      this.setState({ method: object });
    });
  };

  onClickDelete = () => {
    this.props.remove(this.state.method.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.method || this.props.loading;

    const { method } = this.state;
    const { auth } = this.props;

    if (!method || method.id === auth.id || !method.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickDelete} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  renderButtons = () => {
    const loading = !this.state.method || this.props.loading;

    return (
      <div className="text-right">
        <Button color="primary" onClick={this.submitForm} disabled={loading}>
          Zapisz
        </Button>
        {this.renderDeleteButton()}
      </div>
    );
  };

  renderId = () => {
    const { method } = this.state;

    if (!method || !method.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={method ? method.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardStatuses = () => {
    const { method, currentCulture } = this.state;

    if (!method) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Statusy metody</h3>
          <Button
            className="float-right"
            size="sm"
            color="primary"
            onClick={this.addStatus}
          >
            <i className="fas fa-plus"></i>
          </Button>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="4">
                <small className="text-muted">Tytuł</small>
              </Col>
              <Col md="4">
                <small className="text-muted">Opis</small>
              </Col>
              <Col md="3">
                <small className="text-muted">Właściwości</small>
              </Col>
              <Col md="1">
                <small className="text-muted"></small>
              </Col>
            </Row>
            {method.statuses.map((status, index) => {
              const currentTranslation = status.translations.find(
                (t) => t.culture === currentCulture,
              ) || { title: "", description: "" };
              return (
                <Row key={index}>
                  <Col md="4">
                    <Input
                      type="text"
                      name="title"
                      value={currentTranslation?.title}
                      onChange={(e) => this.changeStatusTranslation(e, index)}
                      placeholder="Tytuł"
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      type="text"
                      name="description"
                      value={currentTranslation?.description}
                      onChange={(e) => this.changeStatusTranslation(e, index)}
                      placeholder="Opis"
                    />
                  </Col>
                  <Col md="3">
                    <UncontrolledDropdown>
                      <DropdownToggle color="light" className="mr-0" caret>
                        Właściwości
                      </DropdownToggle>
                      <DropdownMenu right={true} style={{ width: "400px" }}>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="12">Zewnętrzne ID dla synchronizacji</Col>
                            <Col md="12" className="text-right mt-1">
                              <Input
                                type="text"
                                name="externalId"
                                value={status.externalId}
                                onChange={(e) =>
                                  this.changeStatusData(e, index)
                                }
                              />
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i className="fas fa-star"></i> Status jest
                              domyślny?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isDefault"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isDefault}
                                  defaultChecked={status.isDefault}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i className="fas fa-circle"></i> Status oznacza
                              proces zakończony?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isClosed"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isClosed}
                                  defaultChecked={status.isClosed}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i className="fas fa-check-circle text-success"></i>{" "}
                              Status oznacza proces pomyślny?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isSuccessful"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isSuccessful}
                                  defaultChecked={status.isSuccessful}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i className="fas fa-times-circle text-danger"></i>{" "}
                              Status oznacza proces odrzucony?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isRejected"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isRejected}
                                  defaultChecked={status.isRejected}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i className="far fa-circle text-primary"></i>{" "}
                              Status oznacza przetwarzanie?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isProcessing"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isProcessing}
                                  defaultChecked={status.isProcessing}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <DropdownItem tag="div" toggle={false}>
                          <Row>
                            <Col md="9">
                              <i class="fas fa-exclamation text-warning"></i>{" "}
                              &nbsp; Status wymaga uwagi klienta?
                            </Col>
                            <Col md="3" className="text-right">
                              <label className="custom-toggle">
                                <Input
                                  type="checkbox"
                                  name="isUserAttentionRequired"
                                  onChange={(e) =>
                                    this.changeStatusData(e, index)
                                  }
                                  checked={status.isUserAttentionRequired}
                                  defaultChecked={
                                    status.isUserAttentionRequired
                                  }
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            </Col>
                          </Row>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                  <Col md="1" className="text-right">
                    <Button
                      color="danger"
                      size="sm"
                      tag="a"
                      onClick={(e) => this.deleteStatus(index)}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardProvisions = () => {
    const { method, provisionSelectedCountry } = this.state;

    if (!method) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Prowizje metody</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="4">
                <small className="text-muted">Kraj</small>
              </Col>
              <Col md="4">
                <small className="text-muted">Zakres cenowy</small>
              </Col>
              <Col md="2">
                <small className="text-muted">Stała cena</small>
              </Col>
              <Col md="2">
                <small className="text-muted">Cena mnozona przez zł</small>
              </Col>
            </Row>
            {method.provisions.map((provision, index) => {
              return (
                <Row key={index}>
                  <Col md="4">
                    <Button
                      color="danger"
                      onClick={(e) => this.deleteProvision(index)}
                      tag="a"
                      size="sm"
                    >
                      <i className="fas fa-times"></i>
                    </Button>{" "}
                    &nbsp;
                    {Countries.find(({code}) => code.toLowerCase() === provision.countryCode)?.label}
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          name="minimalPrice"
                          value={provision.minimalPrice}
                          onChange={(e) => this.changeProvisionData(e, index)}
                          placeholder="0.0"
                          step={0.01}
                        />
                        <NumericInput
                          name="maximalPrice"
                          value={provision.maximalPrice}
                          onChange={(e) => this.changeProvisionData(e, index)}
                          placeholder="0.0"
                          step={0.01}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>zł</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          name="const"
                          value={provision.const}
                          onChange={(e) => this.changeProvisionData(e, index)}
                          placeholder="0.00"
                          step={0.01}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>zł</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup>
                        <NumericInput
                          name="rate"
                          value={provision.rate}
                          onChange={(e) => this.changeProvisionData(e, index)}
                          placeholder="0.00"
                          step={0.01}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>zł</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              );
            })}
            <hr />
            <Row>
              <Col md="6"></Col>
              <Col md="4">
                <CountrySelector
                  onChange={this.changeProvisionSelectedCountry}
                  value={provisionSelectedCountry}
                  />
              </Col>
              <Col md="2">
                <Button color="primary" block onClick={this.addProvision}>
                  Dodaj
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardAvailabilities = () => {
    const { method, availabilitySelectedCountry } = this.state;

    if (!method) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Dostępność metody</h3>
        </CardHeader>
        <CardBody>
          <Form>
            {method.availabilities.map((availability, index) => {
              return (
                <Row key={index}>
                  <Col md="8">
                    {Countries.find(({code}) => code.toLowerCase() === availability.countryCode)?.label}
                  </Col>
                  <Col md="4" className="text-right">
                    <Button
                      color="danger"
                      onClick={(e) =>
                        this.deleteAvailability(availability.countryCode)
                      }
                      tag="a"
                      size="sm"
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              );
            })}
            <hr />
            <Row>
              <Col md="8">
                <CountrySelector
                  value={availabilitySelectedCountry}
                  onChange={this.changeAvailabilitySelectedCountry}
                  allowNull
                />
              </Col>
              <Col md="4">
                <Button color="primary" block onClick={this.addAvailability}>
                  Dodaj
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { method } = this.state;
    const { currentCulture } = this.state;

    if (!method) {
      return <></>;
    }

    const loading = this.props.loading;
    const editedTranslation = method.translations
      ? method.translations.filter(
          (translation) => translation.culture === currentCulture,
        )[0]
      : {};

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
          <Button
            onClick={this.translate}
            disabled={currentCulture === "pl"}
            type="button"
            className="float-right"
          >
            <i className="fas fa-globe"></i>
          </Button>
          <Input
            className="w-25 float-right"
            type="select"
            value={currentCulture}
            onChange={this.changeCulture}
          >
            {config.cultures.map((culture) => (
              <option key={culture.code} value={culture.code}>
                {culture.native}
              </option>
            ))}
          </Input>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Tytuł</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-font" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Tytuł"
                      type="text"
                      name="title"
                      value={editedTranslation ? editedTranslation?.title : ""}
                      onChange={this.changeMethodTranslation}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Opis</Col>
              <Col md="9">
                <FormGroup>
                  <Input
                    placeholder="Opis"
                    type="text"
                    name="description"
                    value={
                      editedTranslation ? editedTranslation?.description : ""
                    }
                    onChange={this.changeMethodTranslation}
                  />
                </FormGroup>
              </Col>
              <Col md="9">Czy metoda wymaga podwyzszonych uprawnień?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isSpecialPermissionsRequired"
                    onChange={this.changeMethodData}
                    checked={method.isSpecialPermissionsRequired}
                    defaultChecked={method.isSpecialPermissionsRequired}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="9">Czy metoda jest dostepna dla klienta?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isSelectableByUser"
                    onChange={this.changeMethodData}
                    checked={method.isSelectableByUser}
                    defaultChecked={method.isSelectableByUser}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="9">Czy metoda jest dostepna dla gości?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isAvailableForGuest"
                    onChange={this.changeMethodData}
                    checked={method.isAvailableForGuest}
                    defaultChecked={method.isAvailableForGuest}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
              <Col md="9">Czy metoda jest w formie przedpłaty?</Col>
              <Col md="3" className="text-right">
                <label className="custom-toggle">
                  <Input
                    type="checkbox"
                    name="isRequiredToProcessOrder"
                    onChange={this.changeMethodData}
                    checked={method.isRequiredToProcessOrder}
                    defaultChecked={method.isRequiredToProcessOrder}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.method || this.props.loading;

    const { method } = this.state;
    const { action } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/payment/methods/" />;
    }

    return (
      <>
        <SimpleHeader
          name={method ? method.currentTranslation?.title : ""}
          link="/admin/payment/methods"
          parentName="Kategorie produktów"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="5">
              <div className="card-wrapper">
                {this.renderCardData()}
                {this.renderCardAvailabilities()}
              </div>
            </Col>
            <Col lg="7">
              <div className="card-wrapper">{this.renderCardStatuses()}</div>
            </Col>
            <Col lg="12">{this.renderCardProvisions()}</Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.method !== this.props.method) {
      this.setState({
        method: this.props.method,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }
}

function mapStateToProps(state) {
  return {
    method: state.paymentMethods.current,
    loading: state.paymentMethods.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(paymentMethodsActions.read(id)),
    remove: (id) => dispatch(paymentMethodsActions.remove(id)),
    update: (method) => dispatch(paymentMethodsActions.update(method)),
    create: (method) => dispatch(paymentMethodsActions.create(method)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentMethod),
);

// eslint-disable-next-line
import { store, epicMiddleware } from "stores/store";
import { combineEpics } from "redux-observable";

import userEpics from "stores/iam/users/epics";
import roleEpics from "stores/iam/roles/epics";
import accessEpics from "stores/iam/access/epics";
import authEpics from "stores/iam/auth/epics";

import notificationTypeEpics from "stores/core/notificationTypes/epics";

import pageEpics from "stores/cms/pages/epics";
import galleryEpics from "stores/cms/galleries/epics";
import galleryCategoryEpics from "stores/cms/galleryCategories/epics";

import dietEpics from "stores/bookings/diets/epics";
import personTypeEpics from "stores/bookings/personTypes/epics";
import roomEpics from "stores/bookings/rooms/epics";
import roomTypeEpics from "stores/bookings/roomTypes/epics";
import roomAdditionEpics from "stores/bookings/roomAdditions/epics";
import bookingEpics from "stores/bookings/bookings/epics";
import stayEpics from "stores/bookings/stays/epics";

import productEpics from "stores/products/products/epics";
import productCategoryEpics from "stores/products/productCategories/epics";
import productAdditiveEpics from "stores/products/productAdditives/epics";
import productAdditiveGroupEpics from "stores/products/productAdditiveGroups/epics";
import productAttributeEpics from "stores/products/productAttributes/epics";
import productOptionEpics from "stores/products/productOptions/epics";
import printFormatEpics from "stores/products/printFormats/epics";

import billingInfoEpics from "stores/orders/billingInfos/epics";
import collectPointEpics from "stores/orders/collectPoints/epics";
import couponEpics from "stores/orders/coupons/epics";
import orderNoteEpics from "stores/orders/orderNotes/epics";
import orderProductEpics from "stores/orders/orderProducts/epics";
import orderEpics from "stores/orders/orders/epics";
import orderSeriesEpics from "stores/orders/orderSeries/epics";
import orderStatusEpics from "stores/orders/orderStatuses/epics";
import paymentMethodEpics from "stores/orders/paymentMethods/epics";
import paymentEpics from "stores/orders/payments/epics";
import paymentStatusEpics from "stores/orders/paymentStatuses/epics";
import shippingAddressEpics from "stores/orders/shippingAddresses/epics";
import shippingMethodEpics from "stores/orders/shippingMethods/epics";
import shippingEpics from "stores/orders/shippings/epics";
import shippingStatusEpics from "stores/orders/shippingStatuses/epics";
import verificationTemplateEpics from "stores/orders/verificationTemplates/epics";
import customerEpics from "stores/orders/customers/epics";
import businessEpics from "stores/orders/businesses/epics";
import customPricingEpics from "stores/orders/customPricings/epics";

import buyerDataEpics from "stores/invoices/buyersData/epics";
import invoicesEpics from "stores/invoices/invoices/epics";
import invoiceSeriesEpics from "stores/invoices/invoiceSeries/epics";
import sellerDataEpics from "stores/invoices/sellersData/epics";

const rootEpic = combineEpics(
  authEpics,
  userEpics,
  roleEpics,
  accessEpics,

  notificationTypeEpics,

  pageEpics,
  galleryEpics,
  galleryCategoryEpics,

  dietEpics,
  personTypeEpics,
  roomEpics,
  roomTypeEpics,
  roomAdditionEpics,
  bookingEpics,
  stayEpics,

  productEpics,
  productCategoryEpics,
  productAdditiveEpics,
  productAdditiveGroupEpics,
  productAttributeEpics,
  productOptionEpics,
  printFormatEpics,

  billingInfoEpics,
  collectPointEpics,
  couponEpics,
  orderNoteEpics,
  orderProductEpics,
  orderEpics,
  orderSeriesEpics,
  orderStatusEpics,
  paymentMethodEpics,
  paymentEpics,
  paymentStatusEpics,
  shippingAddressEpics,
  shippingMethodEpics,
  shippingEpics,
  shippingStatusEpics,
  verificationTemplateEpics,
  customerEpics,
  businessEpics,
  customPricingEpics,

  buyerDataEpics,
  invoicesEpics,
  invoiceSeriesEpics,
  sellerDataEpics,
);

epicMiddleware.run(rootEpic);

export default epicMiddleware;
